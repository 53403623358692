import React, { useEffect, useState } from "react";

import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import EmojiPicker, { EmojiStyle, Emoji } from "emoji-picker-react";

import { Storage } from "../../../utils/storage";
import { Editor } from "../../../utils/editor";

const ViewEmojiControls = (props) => {
  const [emoji, setEmoji] = useState(null);
  const [reference, setReference] = useState(null);

  const onEmojiClick = async(emojiData, event) => {
    const reaction = {
      unified: emojiData.unified,
      skin_tone: emojiData.activeSkinTone,
    };

    let emojiResponse = null;
    if (props.sys_id_section != null) {
      emojiResponse = await Storage.getInstance().updateSectionEmoji(props.sys_id_section, reaction);
    } else if (props.sys_id_note != null) {
      emojiResponse = await Storage.getInstance().updateNoteEmoji(props.sys_id_note, reaction);
    }

    // Remove the sharing event from the database
    // console.log(props.sys_id_sharing_event);
    if (props.sys_id_sharing_event != null && props.sys_id_sharing_event.trim().length > 0) {
      // console.log(`Ignoring sharing event`, props.sys_id_sharing_event);
      await Storage.getInstance().ignoreSharedEvent(props.sys_id_sharing_event);
      props.reactionCallback({
        sys_id_sharing_event: null,
      });
    }

    // console.log(emojiResponse);
    setEmoji(emojiResponse);
  }

  const onButtonClick = async(unified) => {
    const reaction = {
      unified: unified,
      skin_tone: "neutral",
    };

    let emojiResponse = null;
    if (props.sys_id_section != null) {
      emojiResponse = await Storage.getInstance().updateSectionEmoji(props.sys_id_section, reaction);
    } else if (props.sys_id_note != null) {
      emojiResponse = await Storage.getInstance().updateNoteEmoji(props.sys_id_note, reaction);
    }

    // Remove the sharing event from the database
    if (props.sys_id_sharing_event != null && props.sys_id_sharing_event.trim().length > 0) {
      // console.log(`Ignoring sharing event`, props.sys_id_sharing_event);
      await Storage.getInstance().ignoreSharedEvent(props.sys_id_sharing_event);
      props.reactionCallback({
        sys_id_sharing_event: null,
      });
    }

    // console.log(emojiResponse);
    setEmoji(emojiResponse);
  }

  useEffect(() => {
    // console.log(Editor.getInstance().getTenant());
    if (Editor.getInstance().getTenant() != null) {
      setReference(Editor.getInstance().getTenant().reference);
      if (props.sys_id_section != null) {
        Storage.getInstance().getSectionEmoji(props.sys_id_section).then((emojiResponse) => {
          // console.log(emojiResponse);
          setEmoji(emojiResponse);
        });
      } else if (props.sys_id_note != null) {
        Storage.getInstance().getNoteEmoji(props.sys_id_note).then((emojiResponse) => {
          // console.log(emojiResponse);
          setEmoji(emojiResponse);
        });
      }
    }
  }, [props.sys_id_section, props.sys_id_note]);

  const getEmojiButtons = () => {
    // console.log(emoji);
    // console.log(reference);
    if (emoji != null && emoji.length > 0 && reference != null) {
      // console.log(reference);
      let buttons = [];
  
      for (let x = 0; x < emoji.length; x++) {
        let tenantClicked = false;
        let userList = "";
        let reactionCount = 0;

        if (emoji[x].skin_tones != null && emoji[x].skin_tones.length > 0) {
          for (let y = 0; y < emoji[x].skin_tones.length; y++) {
            if (emoji[x].skin_tones[y].users != null && emoji[x].skin_tones[y].users.length > 0) {
              for (let z = 0; z < emoji[x].skin_tones[y].users.length; z++) {
                if (reference.sys_id != emoji[x].skin_tones[y].users[z].sys_id_reference) {
                  if (emoji[x].skin_tones[y].users[z].friendly_name != null && emoji[x].skin_tones[y].users[z].friendly_name.trim().length > 0) {
                    userList += `${emoji[x].skin_tones[y].users[z].friendly_name}, `;
                  } else {
                    userList += `@${emoji[x].skin_tones[y].users[z].alias}, `;
                  }
                } else {
                  tenantClicked = true;
                }
                reactionCount++;
              }
            }
          }
        }

        if (userList.length > 0) {
          userList = `${tenantClicked == true ? "You, " : ""}${userList.substring(0, userList.length - 2)} clicked on this emoji`;
        } else if (tenantClicked == true) {
          userList = 'You clicked on this emoji';
        }

        if (tenantClicked == true) {
          buttons.push(<OverlayTrigger key={`emoji-control-${reference.sys_id}-${x}`} placement="top" overlay={<Tooltip>{userList}</Tooltip>}>
              <button key={`emoji-control-button-${reference.sys_id}-${x}`} onClick={() => onButtonClick(emoji[x].unified)} style={{"--bs-btn-border-color": "#0d6efd", "--bs-btn-hover-border-color": "#0d6efd" }} className="btn btn-sm btn-light me-1">
                <Emoji key={`emoji-control-button-span-${reference.sys_id}-${x}`} unified={emoji[x].unified} size={19} /><span className="text-muted ms-1">{reactionCount}</span>
              </button>
            </OverlayTrigger>);
        } else {
          buttons.push(<OverlayTrigger key={`emoji-control-${reference.sys_id}-${x}`} placement="top" overlay={<Tooltip>{userList}</Tooltip>}>
              <button key={`emoji-control-button-${reference.sys_id}-${x}`} onClick={() => onButtonClick(emoji[x].unified)} className="btn btn-sm btn-light me-1">
                <Emoji key={`emoji-control-button-span-${reference.sys_id}-${x}`} unified={emoji[x].unified} size={19} /><span className="text-muted ms-1">{reactionCount}</span>
              </button>
            </OverlayTrigger>);
        }
      }

      return buttons;
    }

    return null;
  }

  return (
    <React.Fragment>
      {(props.sys_id_section != null || props.sys_id_note != null) &&
        <React.Fragment>
          <OverlayTrigger
            trigger="click"
            rootClose={true}
            placement="bottom"
            overlay={
              <Popover className="shadow" style={{"--bs-popover-border-width": "0", "--bs-popover-arrow-border": "0", "--bs-popover-arrow-height": "0"}}>
                <EmojiPicker onEmojiClick={onEmojiClick} skinTonesDisabled={true} autoFocusSearch={false} emojiStyle={EmojiStyle.NATIVE} />
              </Popover>
            }
          >
            <button className="btn btn-sm btn-light text-muted me-2"><i className="bi bi-emoji-smile"></i></button>
          </OverlayTrigger>
          {getEmojiButtons()}
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default ViewEmojiControls;
