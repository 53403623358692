import axios from "axios";
import * as Constants from '../utils/constants';

export class AudioRecorder extends HTMLElement {

  static get observedAttributes() {
    return ['view'];
  }

  constructor(recorderContext, recordingCallback, readOnly) {
    super();

    this.readOnly = readOnly;

    const shadowRoot = this.attachShadow({mode: 'open'});
    const uniqueIdentifier = crypto.randomUUID();

    shadowRoot.innerHTML = `
      <style>
        :host {
          --width: 100%;
          --height: 100px;
          --border: none;
          --frequency-background-color: #337ab7;
          --frequency-bars-color: #ddeeff;
          --waveform-background-color: #337ab7;
          --waveform-color: #ddeeff;
          --waveform-progress-color: #FFFFFF;
          display: inline-flex;
          flex-direction: column;
          border: var(--border);
          box-sizing: content-box;
          min-width: 210px;
          width: var(--width);
          min-height: var(--height);
        }
                
        canvas {
          display: block;
        }

        #time-${uniqueIdentifier} {
          display: inline-block;
          align-items: center;
          padding: 5px;
        }
                
        #recording-container-${uniqueIdentifier} {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          border-radius: 15px;
          box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
        }
        
        #audio-container-${uniqueIdentifier} {
          position: relative;
          flex-grow: 1;
          pointer-events: none;
          border-radius: 15px;
          background-color: #337ab7;
          box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
        }
        
        :host([src]) #audio-container-${uniqueIdentifier} {
          pointer-events: initial;
        }
        
        #waveform-container-${uniqueIdentifier},
        #frequencies-container-${uniqueIdentifier} {
          display: none;
        }

        #waveform-${uniqueIdentifier} {
          border-radius: 15px;
        }

        #frequencies-container-${uniqueIdentifier} {
          z-index: 1;
        }

        #frequencies-${uniqueIdentifier} {
          border-radius: 15px;
        }

        :host([view="frequencies"]) #frequencies-container-${uniqueIdentifier} {
          display: block;
        }
        
        :host([view="frequencies"]) #frequencies-button-${uniqueIdentifier} {
          cursor: not-allowed;
          pointer-events: none;
        }
        
        :host([view="frequencies"]) #audio-container-${uniqueIdentifier} {
          pointer-events: none;
        }
        
        :host([view="waveform"]) #waveform-container-${uniqueIdentifier},
        :host([view="waveform"]) #progress-container-${uniqueIdentifier} {
          display: block;
        }
        
        :host([view="waveform"]) #waveform-button-${uniqueIdentifier} {
          cursor: not-allowed;
          pointer-events: none;
        }
        
        #progress-container-${uniqueIdentifier} {
          display: none;
          position: absolute;
          top: 0;
          left: 0;
          overflow: hidden;
          width: 0;
          border-right-width: 1px;
          border-right-style: solid;
          border-right-color: var(--waveform-progress-color);
        }

        #progress-${uniqueIdentifier} {
          border-radius: 15px;
        }

        audio {
          display: none;
        }
        
      </style>
      
      <audio id="input" controls></audio>
      
      <div id="recording-container-${uniqueIdentifier}" style="position:relative;">
        <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 3;" id="record-audio-${uniqueIdentifier}" part="div">
          <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" style="color: white;" class="bi bi-record-circle-fill" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-8 3a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
          </svg>
        </div>

        <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 3;" id="stop-record-audio-${uniqueIdentifier}" part="div">
          <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" style="color: white;" class="bi bi-stop-circle-fill" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.5 5A1.5 1.5 0 0 0 5 6.5v3A1.5 1.5 0 0 0 6.5 11h3A1.5 1.5 0 0 0 11 9.5v-3A1.5 1.5 0 0 0 9.5 5h-3z"/>
          </svg>
        </div>

        <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 3;" id="play-${uniqueIdentifier}" part="div">
          <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" style="color: white;" class="bi bi-play-circle-fill" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z"/>
          </svg>
        </div>
        
        <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 3;" id="pause-${uniqueIdentifier}" part="div">
          <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" style="color: white;" class="bi bi-pause-circle-fill" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.25 5C5.56 5 5 5.56 5 6.25v3.5a1.25 1.25 0 1 0 2.5 0v-3.5C7.5 5.56 6.94 5 6.25 5zm3.5 0c-.69 0-1.25.56-1.25 1.25v3.5a1.25 1.25 0 1 0 2.5 0v-3.5C11 5.56 10.44 5 9.75 5z"/>
          </svg>
        </div>

        <div id="audio-container-${uniqueIdentifier}">
          <div id="frequencies-container-${uniqueIdentifier}">
            <canvas id="frequencies-${uniqueIdentifier}"></canvas>
          </div>

          <div id="waveform-container-${uniqueIdentifier}">
            <canvas id="waveform-${uniqueIdentifier}"></canvas>
          </div>
          
          <div id="progress-container-${uniqueIdentifier}">
            <canvas id="progress-${uniqueIdentifier}"></canvas>
          </div>
        </div>
        
        <div id="controls-${uniqueIdentifier}">
          <div id="buttons-${uniqueIdentifier}">

            <div id="time-${uniqueIdentifier}" part="div">
              <span id="elapsed-time-${uniqueIdentifier}"></span> / <span id="total-time-${uniqueIdentifier}"></span>
            </div>
          </div>

        </div>
      </div>
    `;

    this.recorderContext = recorderContext;
    this.recordingCallback = recordingCallback;

    this.hours = 0;
    this.minutes = 0;
    this.seconds = 0;
    this.secs = 0;
    this.pauseTime = 0;
    this.audioBuffers = [];
    this.frequencies = false;
    this.state = 'idle';
    this.view = this.getAttribute('view') || 'frequencies';
    this.bars = parseInt(this.getAttribute('bars') || 20, 10);

    this.mediaElementSource = null;
    this.mediaStreamSource = null;

    this.nativeFileSystemSupported = 'showSaveFilePicker' in window;
    this.maxChunkLength = 524000; //1024000 * 50;
    this.canvas = this.shadowRoot.querySelector(`#waveform-${uniqueIdentifier}`);
    this.canvasContext = this.canvas.getContext('2d');
    this.progressCanvas = this.shadowRoot.querySelector(`#progress-${uniqueIdentifier}`);
    this.progressCanvasContext = this.progressCanvas.getContext('2d');
    this.audioContainer = this.shadowRoot.querySelector(`#audio-container-${uniqueIdentifier}`);
    this.frequencyCanvas = this.shadowRoot.querySelector(`#frequencies-${uniqueIdentifier}`);
    this.frequencyCanvasContext = this.frequencyCanvas.getContext('2d');
    this.waveformContainer = this.shadowRoot.querySelector(`#waveform-container-${uniqueIdentifier}`);
    this.progressContainer = this.shadowRoot.querySelector(`#progress-container-${uniqueIdentifier}`);
    this.frequenciesContainer = this.shadowRoot.querySelector(`#frequencies-container-${uniqueIdentifier}`);

    this.time = this.shadowRoot.querySelector(`#time-${uniqueIdentifier}`);
    this.playButton = this.shadowRoot.querySelector(`#play-${uniqueIdentifier}`);
    this.pauseButton = this.shadowRoot.querySelector(`#pause-${uniqueIdentifier}`);
    this.elapsedTime = this.shadowRoot.querySelector(`#elapsed-time-${uniqueIdentifier}`);
    this.totalTime = this.shadowRoot.querySelector(`#total-time-${uniqueIdentifier}`);
    this.input = this.shadowRoot.querySelector('audio');
    this.recordAudioButton = this.shadowRoot.querySelector(`#record-audio-${uniqueIdentifier}`);
    this.stopRecordAudioButton = this.shadowRoot.querySelector(`#stop-record-audio-${uniqueIdentifier}`);

    if (this.readOnly) {
      this.recordAudioButton.style = "display: none;";
      this.stopRecordAudioButton.style = "display: none;";
      this.pauseButton.style = "display: none;";
      this.time.style = "display: none;";
    } else {
      this.playButton.style = "display: none;";
      this.pauseButton.style = "display: none;";
      this.time.style = "display: none;";
      this.stopRecordAudioButton.style = "display: none;";
    }
  }

  resizeCanvas({width, height}) {
    this.canvas.width = 0;
    this.canvas.height = 0;

    this.canvas.width = width;
    this.canvas.height = height;

    this.progressCanvas.width = width;
    this.progressCanvas.height = height;

    this.frequencyCanvas.width = width;
    this.frequencyCanvas.height = height;

    this.canvasWidth = this.canvas.width;
    this.canvasHeight = this.canvas.height;

    const mimeTypes = [
      {
        type: 'audio/mpeg',
        ext: 'mp3'
      },
      {
        type: 'audio/webm',
        ext: 'webm',
      },
      {
        type: 'audio/mp4',
        ext: 'mp4'
      }
    ];

    const isSupportedMimeType = ({type}) => MediaRecorder.isTypeSupported(type);
    const defaultMime = {type: 'audio/mpeg', ext: 'mp3'};

    this.mimeType = 'isTypeSupported' in MediaRecorder ? mimeTypes.find(isSupportedMimeType) : defaultMime;
  }

  connectedCallback() {
    const hostStyle = getComputedStyle(this.shadowRoot.host);
    const waveformBackgroundColor = hostStyle.getPropertyValue('--waveform-background-color');
    const waveformColor = hostStyle.getPropertyValue('--waveform-color');
    const waveformProgressColor = hostStyle.getPropertyValue('--waveform-progress-color');

    this.canvases = [
      {
        element: this.canvas,
        context: this.canvasContext,
        fillStyle: waveformBackgroundColor,
        strokeStyle: waveformColor
      },
      {
        element: this.progressCanvas,
        context: this.progressCanvasContext,
        fillStyle: waveformBackgroundColor,
        strokeStyle: waveformProgressColor
      }
    ];

    this.frequenciesBackgroundColor = hostStyle.getPropertyValue('--frequency-background-color');
    this.frequenciesBarsColor = hostStyle.getPropertyValue('--frequency-bars-color');

    setTimeout(() => {
      const {width, height} = this.audioContainer.getBoundingClientRect();
      this.resizeCanvas({width, height});

      if('ResizeObserver' in window) {
        let observerStarted = true;

        const observer = new ResizeObserver(entries => {
          if(observerStarted) {
            observerStarted = false;
            return;
          }

          entries.forEach(({contentRect}) => {
            this.resizeCanvas(contentRect);

            if(this.view === 'waveform' && this.recording) {
              this.renderWaveform(this.recording);
            }
            if(this.view === 'frequencies' && this.analyser) {
              cancelAnimationFrame(this.frequencyAnimation);
              this.renderFrequencyAnalyzer();
            }

            observerStarted = true;
            observer.observe(this.audioContainer);
          });
        });

        observer.observe(this.audioContainer);
      }
    });

    this.showTotalTime(0);
    this.showElapsedTime(0);

    this.audioContainer.addEventListener('click', this.handleWaveformClick.bind(this));
    this.playButton.addEventListener('click', this.playPause.bind(this));
    this.pauseButton.addEventListener('click', this.playPause.bind(this));
    this.input.addEventListener('ended', this.stopAudio.bind(this));
    this.recordAudioButton.addEventListener('click', this.recordAudio.bind(this));
    this.stopRecordAudioButton.addEventListener('click', this.stopRecordAudio.bind(this));

    if (!this.readOnly) {
      const init = () => {
        this.isWebKit = 'webkitAudioContext' in window;
        this.context = new (window.AudioContext || window.webkitAudioContext)();
        this.output = this.context.destination;
        this.gainNode = this.context.createGain();
        this.analyser = this.context.createAnalyser();
        this.analyser.fftSize = 256;

        document.removeEventListener('mousedown', init);
      };

      document.addEventListener('mousedown', init);
    }
  }

  getMediaElementSource(input) {
    if(!this.mediaElementSource) {
      this.mediaElementSource = this.context.createMediaElementSource(input);

      this.mediaElementSource.connect(this.analyser);
      this.mediaElementSource.connect(this.gainNode);
    }

    return this.mediaElementSource;
  }

  getMediaStreamSource(input) {
    if(!this.mediaStreamSource) {
      this.mediaStreamSource = this.context.createMediaStreamSource(input);

      this.mediaStreamSource.connect(this.analyser);
      this.mediaStreamSource.connect(this.gainNode);
    }

    return this.mediaStreamSource;
  }

  async initializeAudio(input) {
    this.curSource = input instanceof HTMLAudioElement ? this.getMediaElementSource(input) : this.getMediaStreamSource(input);

    this.curSource.connect(this.analyser);
    this.curSource.connect(this.gainNode);
    this.gainNode.connect(this.output);
  }

  async openFile(sys_id_recording) {
    // Taken from the init code as this won't be initialized as we didn't click on anything
    this.isWebKit = 'webkitAudioContext' in window;
    this.context = new (window.AudioContext || window.webkitAudioContext)();
    this.output = this.context.destination;
    this.gainNode = this.context.createGain();
    this.analyser = this.context.createAnalyser();
    this.analyser.fftSize = 256;

    if (sys_id_recording != null && sys_id_recording.trim().length > 0) {
      try {
        // console.log(`Loading audio recording from backend`);
        const customHttp = axios.create({
          baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
          headers: {
            "Access-Token": window.localStorage.getItem('token') != null ? window.localStorage.getItem('token').toString() : '',
            "EllieNotes-Account": window.localStorage.getItem('account') != null ? window.localStorage.getItem('account').toString() : '',
            "nfy-auth-token": window.localStorage.getItem('auth_token') != null ? window.localStorage.getItem('auth_token').toString() : ''
          }
        });
        const response = await customHttp.get(`recording?sys_id_recording=${sys_id_recording}`, { responseType: "arraybuffer" });
        // console.log(response);
        // console.log(response.data);
        // console.log(typeof response.data);

        const binaryAsBlob = new Blob([response.data], { type: 'audio/webm' });

        this.src = URL.createObjectURL(binaryAsBlob);

        await this.renderWaveform(binaryAsBlob);
        await this.initializeAudio(this.input);
        this.progressContainer.style.width = 0;
    
        this.view = 'waveform';
      } catch (error) {
        // TODO - we should have a fallback here - give the user a prompt to retry
        console.log(error);
      }
    }
  }

  async loadFile(file) {
    const reader = new FileReader();

    reader.onloadend = e => {
      this.src = e.target.result;
    };

    reader.readAsDataURL(file);

    await this.renderWaveform(file);
    await this.initializeAudio(this.input);
    this.progressContainer.style.width = 0;

    this.view = 'waveform';
  }

  async saveFile(file) {
    const ext = `.${file.type.split('/').pop()}`;
    const handle = await window.showSaveFilePicker({
      types: [
        {
          description: 'Audio file',
          accept: {
            [file.type]: ext
          }
        }
      ]
    });

    const writable = await handle.createWritable();
    await writable.write({type: 'write', data: file});
    await writable.close();
  }

  async captureAudio() {
    try {
      this.stream = await navigator.mediaDevices.getUserMedia({audio: true});

      this.currentVolume = 0.5;
      this.setVolume(0);

      await this.initializeAudio(this.stream);

      this.renderFrequencyAnalyzer();
      this.view = 'frequencies';
      this.state = 'capturing';
    }
    catch(e) {
      if(e.name === 'NotAllowedError') {
        this.dispatchEvent(new CustomEvent('notallowed', {
          detail: {message: `Access to the device's microphone is not allowed`}
        }));
      }
    }
  }

  stopCaptureAudio() {
    if(this.stream) {
      this.stream.getTracks().map(track => track.stop());
      this.mediaStreamSource = null;

      cancelAnimationFrame(this.frequencyAnimation);
      this.clearFrequenciesDisplay();

      this.state = 'idle';

      this.setVolume(this.currentVolume);

      this.stopRecordAudioButton.style = "display: none;";
      this.recordAudioButton.style = "display: none;";
      this.playButton.style = "position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 3;";
    }
  }

  getRecording() {
    if (this.state != 'recording') {
      return this.sys_id_recording;
    }
    return null;
  }

  recordAudio() {
    this.captureAudio().then(() => {
      this.stopRecordAudioButton.style = "position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 3;";
      this.recordAudioButton.style = "display: none;";

      const chunks = [];

      this.recorder = new MediaRecorder(this.stream);

      const options = {type: `${this.mimeType.type}`};

      this.recorder.start(250);

      this.state = 'recording';

      const handleStopRecording = async () => {
        this.recording = new Blob(chunks, options);

        this.stopCaptureAudio();
        await this.loadFile(this.recording);

        // Send the recording up to the parent component
        this.recordingCallback(this.recorderContext, this.recording, this.mimeType.type);
      };

      const processChunk = ({data}) => {
        if(data !== undefined && data.size !== 0) {
          chunks.push(data);

          const recording = new Blob(chunks, options);
          this.renderWaveform(recording);
        }
      };

      this.recorder.addEventListener('dataavailable', processChunk);
      this.recorder.addEventListener('stop', handleStopRecording);
    });
  }

  stopRecordAudio() {
    this.stopCaptureAudio();
    this.recorder.stop();
  }

  showFrequencyAnalyzer() {
    this.view = 'frequencies';
  }

  showWaveform() {
    this.view = 'waveform';
  }

  setVolume(value) {
    this.gainNode.gain.setValueAtTime(value, this.context.currentTime);
  }

  handleWaveformClick(e) {
    if(this.curSource) {
      this.state = 'idle';
      this.input.pause();

      cancelAnimationFrame(this.timerId);
    }

    this.progressContainer.style.width = `${e.offsetX}px`;
    this.input.currentTime = (e.offsetX / this.canvasWidth) * this.duration;
    this.showElapsedTime(this.input.currentTime);
  }

  stringToArrayBuffer(byteString) {
    return new Uint8Array(byteString.length).map((_, i) => byteString.codePointAt(i));
  }

  getArrayBuffer(blob) {
    const reader = new FileReader();
    reader.readAsArrayBuffer(blob);

    return new Promise((resolve, reject) => {
      reader.onerror = err => reject(err);
      reader.onloadend = e => resolve(e.target.result);
    });
  }

  sliceAudio(buffer, start, end) {
    const chunk = start + end === buffer.byteLength ? buffer : buffer.slice(start, end);
    const blob = new Blob([new Uint8Array(chunk)]);
    const reader = new FileReader();

    reader.readAsArrayBuffer(blob);

    return new Promise((resolve, reject) => {
      reader.onloadend = e => {
        const buffer = e.target.result;

        if(this.isWebKit) {
          this.context.decodeAudioData(buffer, decodedBuffer => resolve(decodedBuffer), (err) => reject(err));
        }
        else {
          this.context.decodeAudioData(buffer)
          .then(decodedBuffer => resolve(decodedBuffer))
          .catch((err) => reject(err));
        }
      };
    });
  }

  async getAudioBuffers(buffer) {
    const bufferLength = buffer.byteLength;
    const chunkLength = bufferLength > this.maxChunkLength ? this.maxChunkLength : bufferLength;

    let start = 0;
    let end = start + chunkLength;
    const self = this;

    const slice = (buffer, start, end) => {
      async function* gen() {
        while(start < bufferLength) {
          const decodedBuffer = await self.sliceAudio(buffer, start, end);
          yield decodedBuffer;

          start += chunkLength;
          end = start + chunkLength > bufferLength ? bufferLength : start + chunkLength;
        }
      }

      return gen();
    };

    const audioBuffers = [];

    for await (const decodedBuffer of slice(buffer, start, end)) {
      audioBuffers.push(decodedBuffer);
    }

    return audioBuffers;
  }

  getNodesAfterOffset(nodes, offset) {
    let duration = 0;
    let skipped = 0;

    const remaining = nodes.filter(node => {
      duration += node.buffer.duration;
      skipped += duration < offset ? node.buffer.duration : 0;

      return duration > offset;
    });

    return [remaining, offset - skipped];
  }

  playPause() {
    let previousDiff = 0;

    const progress = () => {
      const diff = (this.input.currentTime);
      this.showElapsedTime(diff);
      const progressWidth = ((diff / this.duration) * this.canvasWidth);
      this.progressContainer.style.width = `${progressWidth}px`;

      if (diff == previousDiff) {
        this.playButton.style = "position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 3;";
        this.pauseButton.style = "display: none;";
      }

      // We do this because the math is complicated - we never draw right to the end of the container - but if the 
      // previous is the same as the current, then we've finished playing
      previousDiff = diff;

      this.timerId = requestAnimationFrame(progress);
    };

    console.log(this.input);

    if(this.state === 'playing') {
      this.playButton.style = "position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 3;";
      this.pauseButton.style = "display: none;";

      this.state = 'idle';

      this.input.pause();
      this.pauseTime = this.input.currentTime;
      this.clearFrequenciesDisplay();

      cancelAnimationFrame(this.timerId);
      cancelAnimationFrame(this.frequencyAnimation);
    }
    else {
      this.playButton.style = "display: none;";
      this.pauseButton.style = "position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 3;";

      this.state = 'playing';
      this.input.play();

      this.renderFrequencyAnalyzer();
      requestAnimationFrame(progress);
    }
  }

  stopAudio() {
    this.state = 'idle';
    this.clearFrequenciesDisplay();

    cancelAnimationFrame(this.timerId);
    cancelAnimationFrame(this.frequencyAnimation);

    this.input.currentTime = 0;
    this.progressContainer.style.width = 0;
    this.showElapsedTime(0);
  }

  formatTime(secs) {
    const minute = 60;
    const hour = 3600;

    const hrs = Math.floor(secs / hour);
    const min = Math.floor((secs % hour) / minute);
    const sec = Math.floor(secs) % minute;

    const hours = hrs < 10 ? `0${hrs}` : hrs;
    const minutes = min < 10 ? `0${min}` : min;
    const seconds = sec < 10 ? `0${sec}` : sec;
    return hours !== '00' ? `${hours}:${minutes}:${seconds}` : `${minutes}:${seconds}`;
  }

  showElapsedTime(secs) {
    this.elapsedTime.innerHTML = this.formatTime(secs);
  }

  showTotalTime(secs) {
    this.totalTime.innerHTML = this.formatTime(secs);
  }

  renderFrequencyAnalyzer() {
    const bufferLength = this.analyser.frequencyBinCount;
    const dataArray = new Float32Array(bufferLength);
    const barWidth = (this.canvasWidth - (this.bars - 1)) / this.bars;

    this.frequencyCanvasContext.clearRect(0, 0, this.canvasWidth, this.canvasHeight);

    const draw = () => {
      this.frequencyAnimation = requestAnimationFrame(draw);
      this.analyser.getFloatFrequencyData(dataArray);
      this.frequencyCanvasContext.fillStyle = this.frequenciesBackgroundColor;
      this.frequencyCanvasContext.fillRect(0, 0, this.canvasWidth, this.canvasHeight);

      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        const barHeight = (dataArray[i] + 140) * 2;

        this.frequencyCanvasContext.fillStyle = this.frequenciesBarsColor;
        this.frequencyCanvasContext.fillRect(x, this.canvasHeight - barHeight * 0.75, barWidth, barHeight * 0.75);

        x += barWidth + 1;
      }
    };

    draw();
  }

  getWaveformData(buffers) {
    const dataArrays = buffers.map(buffer => buffer.getChannelData(0));
    const totalLength = dataArrays.reduce((total, data) => total + data.length, 0);
    const channelData = new Float32Array(totalLength);

    let offset = 0;

    dataArrays.forEach(data => {
      channelData.set(data, offset);
      offset += data.length;
    });

    return channelData;
  }

  async renderWaveform(file) {
    try {
      const buffer = await this.getArrayBuffer(file);
      const audioBuffers = await this.getAudioBuffers(buffer);

      this.audioBuffers = audioBuffers;
      this.duration = audioBuffers.reduce((total, buffer) => total + buffer.duration, 0);

      this.showTotalTime(this.duration);

      const channelData = this.getWaveformData(audioBuffers);
      const drawLines = 2000;
      const totallength = channelData.length;
      const eachBlock = Math.floor(totallength / drawLines);
      const lineGap = this.canvasWidth / drawLines;

      this.canvases.forEach(canvas => {
        canvas.context.save();
        canvas.context.fillStyle = canvas.fillStyle;
        canvas.context.fillRect(0, 0, this.canvasWidth, this.canvasHeight);
        canvas.context.strokeStyle = canvas.strokeStyle;
        canvas.context.translate(0, this.canvasHeight / 2);
        canvas.context.lineWidth = 1;
        canvas.context.beginPath();

        for(let i = 0; i <= drawLines; i++) {
          const audioBuffKey = Math.floor(eachBlock * i);
          const x = i * lineGap;
          const y = channelData[audioBuffKey] * this.canvasHeight * 0.8;

          canvas.context.moveTo(x, y);
          canvas.context.lineTo(x, (y * -1));
        }

        canvas.context.stroke();
        canvas.context.restore();
        canvas.context.strokeStyle = canvas.strokeStyle;
        canvas.context.moveTo(0, this.canvasHeight / 2);
        canvas.context.lineTo(this.canvasWidth, this.canvasHeight / 2);
        canvas.context.stroke();
        canvas.context.restore();
      });
    } catch (error) {
      console.log(error);
    }
  }

  get view() {
    return this.getAttribute('view');
  }

  set view(value) {
    this.setAttribute('view', value);
  }

  get state() {
    return this.getAttribute('state');
  }

  set state(value) {
    this.setAttribute('state', value);
  }

  get src() {
    return this.getAttribute('src');
  }

  set src(value) {
    console.log(value);
    this.setAttribute('src', value);
    this.input.src = value;
  }

  clearWaveform() {
    this.canvases.forEach(canvas => canvas.context.clearRect(0, 0, canvas.element.width, canvas.element.height));
  }

  clearFrequenciesDisplay() {
    this.frequencyCanvasContext.clearRect(0, 0, this.frequencyCanvas.width, this.frequencyCanvas.height);
  }
}

customElements.define('audio-recorder', AudioRecorder);