import React, { useEffect, useState } from "react";

import { Row, Col } from 'react-bootstrap';

import { Editor } from "../../../utils/editor";

const TagPeopleMenuButton = (props) => {
  const [reference, setReference] = useState(null);

  const isTagActiveInFilter = () => {
    if (reference != null && props.references_referenced != null && props.references_referenced.length > 0) {
      for (let x = 0; x < props.references_referenced.length; x++) {
        if (reference.sys_id == props.references_referenced[x].sys_id_reference) {
          return true;
        }
      }
    }

    return false;
  }

  useEffect(() => {
    // console.log(`Loading component`);
    setReference(props.reference);
  }, [props.reference]);

  useEffect(() => {
    setReference(props.reference);
  }, [props.references_referenced]);

  return (
    <React.Fragment>
      {reference != null &&
        <div type="button" className="btn btn-light btn-sm w-100 text-start" style={isTagActiveInFilter() == true ? {color: "#FFFFFF", backgroundColor: "#3F0F3F"} : {}} onClick={() => props.setNoteViewForReference(reference)}>
          <Row>
            <Col className="text-truncate">
              <i className="bi bi-at me-2"></i>
              {reference.friendly_name != null && reference.friendly_name.trim().length > 0 ? reference.friendly_name : reference.alias}{Editor.getInstance().getTenant() != null && Editor.getInstance().getTenant().reference != null && Editor.getInstance().getTenant().reference.sys_id == reference.sys_id ? " (you)" : ""}
            </Col>
            {reference.actions_incomplete > 0 &&
              <Col xs={2} className="me-3">
                <span className="badge" style={{backgroundColor: "#FCC419"}}>{reference.actions_incomplete}</span>
              </Col>
            }
          </Row>
        </div>
      }
    </React.Fragment>
  );
};

export default TagPeopleMenuButton;
