import React, { useEffect, useState } from "react";

import { Storage } from "../../../utils/storage";
import { Editor } from "../../../utils/editor";

import { Col, Row, Alert } from "react-bootstrap";

const ReferenceCardSettings = (props) => {
  const [reference, setReference] = useState(null);
  const [verified, setVerified] = useState(false);
  const [sysIdSharing, setSysIdSharing] = useState(2);
  const [sysType, setSysType] = useState(null);
  const [tenantReferences, setTenantReferences] = useState(null);
  const [autoShareNote, setAutoShareNote] = useState(null);
  const [autoShareBlock, setAutoShareBlock] = useState(null);

  const handleReferenceIdSharingChange = (event) => handleSharing(event.target.value);

  const handleAutoShareBlockChange = async(event) => {
    setAutoShareBlock(event.target.checked);

    reference.auto_share_block = event.target.checked;

    await props.handleReferenceUpdate(reference);
  }

  const handleAutoShareNoteChange = async(event) => {
    setAutoShareNote(event.target.checked);

    reference.auto_share_note = event.target.checked;

    await props.handleReferenceUpdate(reference);
  }

  const handleSharing = (sys_id_sharing) => {
    // console.log(reference);
    setSysIdSharing(sys_id_sharing);

    if (sys_id_sharing == 1) {
      Storage.getInstance().getSharingReferences().then((references) => {
        setTenantReferences(references);
      });
    } else {
      setTenantReferences(null);
    }

    reference.sys_id_sharing = sys_id_sharing;

    // Save the sharing change back to the system
    props.handleReferenceUpdate(reference)
  }

  const tenantReferenceIsShared = (tenantReference) => {
    if (reference.shared_tenants != null && reference.shared_tenants.length > 0) {
      for (let x = 0; x < reference.shared_tenants.length; x++) {
        if (reference.shared_tenants[x].sys_id_tenant == tenantReference.sys_id_tenant_verified) {
          return true;
        }
      }
    }
    return false;
  }

  const tenantReferenceChanged = async(checked, tenantReference) => {
    if (reference.shared_tenants == null) {
      reference.shared_tenants = [];
    }

    if (checked == true) {
      // console.log(`Reference is checked`);
      // console.log(references[x]);
      reference.shared_tenants.push({
        sys_id_tenant: tenantReference.sys_id_tenant_verified,
      });
    } else {
      for (let x = 0; x < reference.shared_tenants.length; x++) {
        if (reference.shared_tenants[x].sys_id_tenant == tenantReference.sys_id_tenant_verified) {
          // Remove the shared tenant from the list
          reference.shared_tenants.splice(x, 1);
        }
      }
    }

    // Set the sharing to specific people
    reference.sys_id_sharing = 1;
    // console.log(activeReference);

    // Save the changes
    setReference(await props.handleReferenceUpdate(reference));

    Storage.getInstance().getSharingReferences().then((references) => {
      setTenantReferences(references);
    });
  }

  useEffect(() => {
    setReference(props.reference);
    setSysIdSharing(props.reference.sys_id_sharing);

    if (props.reference.sys_type == "topic") {
      setVerified(true);
    } else {
      setVerified(props.verified);
    }

    if (props.reference != null) {
      setSysType(props.reference.sys_type);

      setAutoShareNote(props.reference.auto_share_note);
      setAutoShareBlock(props.reference.auto_share_block);

      if (props.reference.sys_id_sharing == 1) {
        Storage.getInstance().getSharingReferences().then((references) => {
          setTenantReferences(references);
        });
      }
    }
  }, [props.reference, props.verified]);

  return (
    <React.Fragment>
      {reference != null && props.tenant != null &&
        <React.Fragment>
          <h5 className="text-muted">Access</h5>
          <select className="form-select" id="quick-add-sharing" value={sysIdSharing} onChange={handleReferenceIdSharingChange}>
            <option value="0">{reference.alias == null || reference.alias.trim().length == 0 ? (reference.sys_type == "topic" ? "This topic" : "This person") : ""}{reference.alias != null && reference.alias.trim().length > 0 ? (reference.sys_type == "topic" ? "#" : "@") : ""}{reference.alias} is private to me</option>
            <option value="1">{reference.alias == null || reference.alias.trim().length == 0 ? (reference.sys_type == "topic" ? "This topic" : "This person") : ""}{reference.alias != null && reference.alias.trim().length > 0 ? (reference.sys_type == "topic" ? "#" : "@") : ""}{reference.alias} {sysType == "topic" ? "can be used by" : "is visible to"}:</option>
            <option value="2">Everyone at {Editor.getInstance().getTenant() != null ? Editor.getInstance().getTenant().domain : ""} can {sysType == "topic" ? "use" : "see"} {reference.alias == null || reference.alias.trim().length == 0 ? (reference.sys_type == "topic" ? "this topic" : "this person") : ""}{reference.alias != null && reference.alias.trim().length > 0 ? (reference.sys_type == "topic" ? "#" : "@") : ""}{reference.alias}</option>
          </select>
          {tenantReferences != null && tenantReferences.length > 0 &&
            <Row className="d-flex flex-column bg-light m-1" style={{height: "20vh"}}>
              <Col className="overflow-auto flex-grow-1">
                {tenantReferences.map((tenantReference, index) => (
                    <div key={`reference-card-settings-div-${index}`} className="forms-check mt-2" style={{cursor: "pointer"}}>
                      <input key={`reference-card-settings-div-input-${index}`} id={"modal_form_label_sharing_notify_note_modal_global_" + tenantReference.sys_id} type="checkbox" className="form-check-input" onChange={() => tenantReferenceChanged(!tenantReferenceIsShared(tenantReference), tenantReference)} defaultChecked={tenantReferenceIsShared(tenantReference)} />
                      <label key={`reference-card-settings-div-label-${index}`} className="form-check-label text-reset ms-2">{tenantReference.friendly_name != null && tenantReference.friendly_name.trim().length > 0 ? tenantReference.friendly_name : tenantReference.alias}</label>
                    </div>
                  ))}
              </Col>
            </Row>
          }
          <div className="mt-3">
            <div className="form-check">
              <input type="checkbox" disabled={!verified} className="form-check-input" id="auto-share-block" checked={autoShareBlock} onChange={handleAutoShareBlockChange} />
              <label className="form-check-label" htmlFor="auto-share-block">
                Automatically share actions
              </label>
            </div>
            <div className="form-check">
              <input type="checkbox" disabled={!verified} className="form-check-input" id="auto-share-note" checked={autoShareNote} onChange={handleAutoShareNoteChange} />
              <label className="form-check-label" htmlFor="auto-share-note">
                Automatically share notes
              </label>
            </div>
          </div>

        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default ReferenceCardSettings;
