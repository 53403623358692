import * as chrono from 'chrono-node';

import { Editor } from "./editor";
import { Formatter } from "./formatter";
import { 
  EllieBlockStatus, 
  EllieActionSetting,
  EllieDate, 
  EllieBlockReference, 
  EllieNote, 
  EllieBlock, 
  EllieReference, 
  EllieNoteReference,
  EllieSharingType,
  NotifyBlockSentiment,
  NotifyBlockSizing,
  NotifySection,
  NotifyBasicTenant,
  NotifyLink,
  NotifyTenant,
  NotifyLinkExternalIdentifier,
  NotifyProvider,
  NotifyComment,
  Storage,
} from "./storage";

const domparser = new DOMParser();

export interface EditorSections {
  tidbits: EditorSection[];
  actions: EditorSection[];
}

export interface EditorSection {
  sys_id_temp: string;
  sys_id_note: string;
  editor_blocks: EditorBlock[];
  ellie_blocks: EllieBlock[];
  tenant?: NotifyBasicTenant;
}

export interface EditorNote {
  sys_id_note: string;
  title?: string;
  tags?: string;
  sys_date_updated: number;
  editor_blocks: EditorBlock[];
  tenant?: NotifyBasicTenant;
  references_referenced?: EllieNoteReference[];
  pinned?: boolean;
  sys_id_sharing_event?: string;
}

export interface EditorBlock {
  id: string;
  type: string;
  data: EditorBlockData;
  sys_id_block: string;
}

export interface EditorBlockData {
  text: string;
  caption?: string;
  dates?: EllieDate[];
  status?: EllieBlockStatus;
  sizing?: NotifyBlockSizing;
  sentiment?: NotifyBlockSentiment;
  items?: string[];
  style?: string;
  references_referenced?: EllieBlockReference[];
}

export interface EllieParsedResult {
  content: string;
  search?: string;
  data?: EditorBlockData;
  references: Map<string, EllieBlockReference>;
}

export interface ReferenceResult {
  html: string;
  id: string;
}

export class Parser {
  static getNoteForStorage = async(previousNote: EllieNote, title: string, tags: string, blocks: EditorBlock[]): Promise<EllieNote> => {
    // Apply the references to the editor for the tags
    const references_referenced = Parser.getNoteReferencesReferencedFromContent(tags);
    Editor.getInstance().setTagsCache(references_referenced);
    
    // Construct the note
    const note: EllieNote = {
      sys_id: previousNote == null ? crypto.randomUUID() : previousNote.sys_id,
      sys_date_created: previousNote != null ? previousNote.sys_date_created : new Date().getTime(),
      sys_date_updated: new Date().getTime(),
      title: Parser.removeHTMLTags(title),
      tags: Parser.parseReferencesFromHTML("", new Map(), tags).content,
      references_referenced: references_referenced,
      blocks_captured: [],
      blocks_referenced: [],
      sections: [],
    }

    if (blocks != null && blocks.length > 0) {
      // The blocks are always in order as per the document
      for (let x = 0; x < blocks.length; x++) {
        const sys_id: string = Editor.getInstance().getRegisteredBlockSysId(blocks[x].id, true);
        const cachedBlock: EllieBlock | undefined = Editor.getInstance().getCachedBlock(sys_id);
        // console.log(`Cached block: ${sys_id}`);

        // Parse the references for the block
        // const parsedResult: EllieParsedResult = Parser.parseReferencesFromHTML(sys_id, Parser.getBlockDataAsStringFromBlock(blocks[x]));
        // I don't think we need this as we've already done the db processing in the note itself
        // const content: string = await Parser._parseReferencesToHTML(parsedResult.content);
        const parsedResult: EllieParsedResult = Parser.parseBlockContentInDataForStorage(sys_id, blocks[x]);

        // We get some things out from the action as it has features to do parsing and to allow the user
        // to override what the intelligence thinks. We also only do sentiment and sizing analysis on the action
        // currently - eventually we should do this on the section
        // We have to add an extra check on references referenced for actions as old actions won't have the
        // references referenced in the data block
        const block: EllieBlock = {
          sys_id: sys_id,
          sys_id_note: note.sys_id,
          sys_date_created: new Date().toUTCString(),
          sys_date_updated: new Date().toUTCString(),
          editor_id_block: blocks[x].id,
          editor_type_block: blocks[x].type,
          data: parsedResult.data,
          search: parsedResult.search!,
          dates: Parser.parseDatesFromHTML(parsedResult.search!),
          status: !blocks[x].data.status && blocks[x].type == "action" ? EllieBlockStatus.NotStarted : blocks[x].data.status!,
          sentiment: NotifyBlockSentiment.NotResolved,
          sizing: NotifyBlockSizing.NotResolved,
          references_referenced: Array.from(parsedResult.references.values()),
          order: x,
        };

        if (cachedBlock != null) {
          // This an existing block in the cache, so we update it
          block.sys_date_created = cachedBlock.sys_date_created;
        }
        // console.log(`Parsed result ready for storage: ${blockToJSON(block)}`);

        note.blocks_captured!.push(block);

        // Cache the block so we have it for sections and date referencing above
        Editor.getInstance().setCachedBlock(block);

        // Create the blocks for the note
        note.blocks_referenced!.push({
          sys_id_block: block.sys_id,
          order: x,
        });
      }

      // Apply sections to the note
      this.applyNoteSections(note);

      // Clear up any orphaned blocks from the registry and get the ones to clean in storage
      note.blocks_orphaned = Editor.getInstance().cleanOrphanedBlocks(note.blocks_captured!);
    }

    // console.log(`Parser result for note is: ${JSON.stringify(note)}`);
    return note;
  }

  static getCommentForStorage(content: string) {
    const references: Map<string, EllieBlockReference> = new Map();

    content = Parser.parseReferencesFromHTML(null, references, content).content;

    const references_referenced = Array.from(references.values());

    return {
      content: content,
      references_referenced: references_referenced,
    };
  }

  static removeHTMLTags(content: string): string {
    if (content != null && content.trim().length > 0) {
      // Remove all tags from the content
      content = content.replace(/<[^>]*>/g, ' ');
      // Remove non breaking spaces
      content = content.replace(/&nbsp;/gm, " ");
      // Remove any double spaces
      content = content.replace(/\s{2,}/g, ' ');
      // Trim the content
      content = content.trim();
    }

    return content;
  }

  static getNoteReferencesReferencedFromContent(content: string): EllieNoteReference[] {
    let references_referenced: EllieNoteReference[] = [];

    if (content != null && content.trim().length > 0) {
      const blockReferences: Map<string, EllieBlockReference> = new Map();
      content = Parser.parseReferencesFromHTML("", blockReferences, content).content;
      const keys = Array.from(blockReferences.keys());

      for (let y = 0; y < keys.length; y++) {
        let value: EllieBlockReference | undefined = blockReferences.get(keys[y]);
        // console.log(`Adding reference: ${keys[y]}`);
        references_referenced.push({
          sys_id_reference: value!.sys_id_reference,
          sys_type: value!.sys_type,
          alias: value!.alias,
          shared: false,
        });
      }
    }

    return references_referenced;
  }

  static getBlankNoteSection(note: EllieNote): NotifySection {
    return {
      sys_id: crypto.randomUUID(),
      sys_type: "tidbit",
      sys_id_tenant_origin: note.sys_id_tenant_origin,
      sys_id_note: note.sys_id,
      sys_date_created: new Date().toUTCString(),
      sys_date_updated: new Date().toUTCString(),
      blocks: [],
      blocks_referenced: [],
      search: "",
      content: "",
      status: EllieBlockStatus.NotStarted,
      sizing: NotifyBlockSizing.NotResolved,
      sentiment: NotifyBlockSentiment.NotResolved,
      dates: [],
      references: [],
      references_referenced: [],
    };
  }

  static applyNoteSectionDetailsForFirstBlock(section: NotifySection, block: EllieBlock) {
    section.search += block.search + " ";
    section.content += JSON.stringify(block.data);

    if (block.dates != null && block.dates.length > 0) {
      section.dates = section.dates.concat(block.dates);
    }

    if (block.references_referenced != null && block.references_referenced.length > 0) {
      for (let y = 0; y < block.references_referenced.length; y++) {
        let addReference: boolean = true;
        for (let z = 0; z < section.references_referenced.length; z++) {
          if (block.references_referenced[y].sys_id_reference == section.references_referenced[z].sys_id_reference) {
            addReference = false;
            break;
          }
        }

        if (addReference == true) {
          section.references_referenced.push(block.references_referenced[y]);
        }
      }
    }
  }

  static isTypingTagsOnly(content: string) {
    if (content != null && content.trim().length > 0) {
      // For some reason innerText converts some spaces to a weird character
      content = content.replace(/ /g, ' ');
      const entries = content.split(' ');
      // console.log(entries);

      for (let x = 0; x < entries.length; x++) {
        if (entries[x].trim().length > 0 &&
            entries[x].trim().indexOf("#") < 0 && 
            entries[x].trim().indexOf("@") < 0) {
          // console.log(`Entering a tag that isn't valid`);
          return entries[x];
        }
      }
    }

    return null;
  }

  static applyNoteSections(note: EllieNote) {
    const sections: NotifySection[] = [];

    // console.log(note.blocks_captured);
    if (note.blocks_captured != null && note.blocks_captured.length > 0) {
      let section: NotifySection = this.getBlankNoteSection(note);

      for (let x = 0; x < note.blocks_captured.length; x++) {
        const block: EllieBlock = note.blocks_captured[x];

        // If we have any empty block, we have a section break, but we don't want to include the block
        // in the current section
        if ((block.search == null || block.search.trim().length == 0) && block.editor_type_block != "recorder") {
          // Only push the section if it has blocks in it
          // console.log(`Block is empty ${x}`);
          if (section.blocks_referenced != null && section.blocks_referenced.length > 0) {
            section.links = this.parseLinksFromSectionContent(note, section);
            // Add the current section to the list
            // console.log(`Pushing block as we have referenced blocks`);
            sections.push(section);
          }

          // Reset for a new section
          // We don't put blank blocks into a section as the note will load it's blocks by
          // referencing them directly - as in - it ignores sections to load the note - the
          // sections are a result of the note
          section = this.getBlankNoteSection(note);
        } else if (block.editor_type_block == "action") {
          // Only push the section if it has blocks in it
          // console.log(`Block is action: ${x}`);
          if (section.blocks_referenced != null && section.blocks_referenced.length > 0) {
            section.links = this.parseLinksFromSectionContent(note, section);
            // Add the current section to the list
            // console.log(`Pushing block as we have referenced blocks`);
            sections.push(section);
          }

          section = this.getBlankNoteSection(note);
          section.sys_type = "action";

          // We use the first block to define the section info to help with persistence
          section.sys_id = block.sys_id;
          section.sys_date_created = block.sys_date_created;
          section.sys_date_updated = block.sys_date_updated;
          section.status = block.status;

          section.blocks_referenced.push({
            sys_id_block: block.sys_id,
            order: block.order,
          });
          section.blocks.push(block);

          block.sys_id_section = section.sys_id,

          this.applyNoteSectionDetailsForFirstBlock(section, block);

          // If we're at the end, we want to push the final section
          if (x == (note.blocks_captured.length - 1)) {
            section.links = this.parseLinksFromSectionContent(note, section);
            // console.log(`Pushing final block as we have nothing else: ${x}`);
            sections.push(section);
          }
        } else {
          // If this is the first block in the section, we need to apply properties from it
          // console.log(`Block is something other than an action or empty ${x}`);
          if (section.blocks_referenced.length == 0) {
            section.sys_id = block.sys_id;
            section.sys_date_created = block.sys_date_created;
            section.sys_date_updated = block.sys_date_updated;
          }

          section.blocks_referenced.push({
            sys_id_block: block.sys_id,
            order: block.order,
          });
          section.blocks.push(block);

          block.sys_id_section = section.sys_id,

          this.applyNoteSectionDetailsForFirstBlock(section, block);

          // If we're at the end, we want to push the final section
          if (x == (note.blocks_captured.length - 1)) {
            section.links = this.parseLinksFromSectionContent(note, section);
            // console.log(`Pushing final block as we have nothing else: ${x}`);
            sections.push(section);
          }
        }
      }
    }

    // Apply the sections to the note using the cache as it will apply any changes made by the user
    note.sections = Editor.getInstance().setSectionCache(sections);

    // Put the sections into the editor cache
    // console.log(note.sections);
    return note.sections;
  }

  static getLinkProvider(link: string): string {
    if (link != null && link.startsWith("https://docs.google") == true) {
      return NotifyProvider.Google;
    } else if (link != null && link.indexOf("force.com") > 0) {
      return NotifyProvider.Salesforce;
    } else if (link != null && link.indexOf(".slack.com") > 0) {
      return NotifyProvider.Slack;
    }
    return NotifyProvider.Link;
  }

  static getGoogleDocId(link: string): NotifyLinkExternalIdentifier {
    let sys_type = null;
    let sys_id_external = null;

    // This is a google document, so we extract the identifier from it
    // console.log(`Link is a Google link`);
    let linkInSectionMatch = null;
    if (link.indexOf("/presentation/d/") > 0) {
      linkInSectionMatch = link.match(/\/presentation\/d\/([a-zA-Z0-9-_]+)/);
      sys_type = `${NotifyProvider.Google}.slides`;
    } else if (link.indexOf("/spreadsheets/d/") > 0) {
      linkInSectionMatch = link.match(/\/spreadsheets\/d\/([a-zA-Z0-9-_]+)/);
      sys_type = `${NotifyProvider.Google}.sheets`;
    } else if (link.indexOf("/document/d/") > 0) {
      linkInSectionMatch = link.match(/\/document\/d\/([a-zA-Z0-9-_]+)/);
      sys_type = `${NotifyProvider.Google}.docs`;
    } else {
      throw new Error("Document type not supported yet for Google");
    }
    
    // console.log(linkInSectionMatch);
    sys_id_external = linkInSectionMatch[1];
    // console.log(sys_id_external);

    return {
      sys_type: sys_type,
      sys_id_external: sys_id_external,
    };
  }

  static getSlackObjectId(link: string): NotifyLinkExternalIdentifier {
    let sys_type = null;
    let sys_id_external = null;

    // Links for Slack messages look like this:
    // https://gowildly.slack.com/archives/C011BF8K5AB/p1684630155019089
    // This is a salesforce record, so we extract the identifier and object type from it
    console.log(`Link is a Slack link`);

    // TODO - we should use code like this to test that this is in fact a message link and not
    // a Canvas or channel or something else link
    const workspace = link.substring(8, link.indexOf("."));

    const parsedUrl = new URL(link);
    const pathSplit = parsedUrl.pathname.split('/').filter(Boolean);
    const channel_id = pathSplit[1];
    const message_id = pathSplit[2];
    
    console.log(workspace);
    console.log(channel_id);
    console.log(message_id);

    // This will be "slack.message"
    sys_type = `${NotifyProvider.Slack}.message`;
    console.log(sys_type);

    // We send the entire URL because the URL is the identifier
    sys_id_external = link;
    console.log(sys_id_external);

    return {
      sys_type: sys_type,
      sys_id_external: sys_id_external,
    };
  }

  static getSalesforceRecordId(link: string): NotifyLinkExternalIdentifier {
    let sys_type = null;
    let sys_id_external = null;

    // This is a salesforce record, so we extract the identifier and object type from it
    console.log(`Link is a Salesforce link`);
    const linkSegments = link.split('/');
    const linkSegmentsLength = linkSegments.length;

    // This will be something like "salesforce.Opportunity"
    sys_type = `${NotifyProvider.Salesforce}.${linkSegments[linkSegmentsLength - 3]}`;
    console.log(sys_type);

    // This will be the identifier - e.g. 0068Y00001OuyQJQAZ
    sys_id_external = linkSegments[linkSegmentsLength - 2];
    console.log(sys_id_external);

    return {
      sys_type: sys_type,
      sys_id_external: sys_id_external,
    };
  }

  static parseLinksFromSectionContent(note: EllieNote, section: NotifySection): NotifyLink[] {
    const notifyLinks: NotifyLink[] = [];
    const linksInSection = [...section.content.matchAll(/(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/g)];

    // console.log(`Getting links contained in section.`);
    // console.log(linksInSection);
    if (linksInSection != null && linksInSection.length > 0) {
      for (let i = 0; i < linksInSection.length; i++) {
        // This is the full URL
        const linkInSection = linksInSection[i][0];
        // console.log(linkInSection);
        let sys_type = null;
        let sys_id_external = null;

        if (this.getLinkProvider(linkInSection) == NotifyProvider.Google) {
          const externalIdentifier: NotifyLinkExternalIdentifier = this.getGoogleDocId(linkInSection);
          sys_type = externalIdentifier.sys_type;
          sys_id_external = externalIdentifier.sys_id_external;
          console.log(externalIdentifier);
        } else if (this.getLinkProvider(linkInSection) == NotifyProvider.Salesforce) {
          const externalIdentifier: NotifyLinkExternalIdentifier = this.getSalesforceRecordId(linkInSection);
          sys_type = externalIdentifier.sys_type;
          sys_id_external = externalIdentifier.sys_id_external;
          console.log(externalIdentifier);
        } else if (this.getLinkProvider(linkInSection) == NotifyProvider.Slack) {
          const externalIdentifier: NotifyLinkExternalIdentifier = this.getSlackObjectId(linkInSection);
          sys_type = externalIdentifier.sys_type;
          sys_id_external = externalIdentifier.sys_id_external;
          console.log(externalIdentifier);
        } else {
          sys_type = "link";
          if (linkInSection.indexOf("?") > 0) {
            sys_id_external = linkInSection.substring(0, linkInSection.indexOf("?"));
          } else if (linkInSection.indexOf("#") > 0) {
            sys_id_external = linkInSection.substring(0, linkInSection.indexOf("#"));
          } else {
            sys_id_external = linkInSection;
          }
          
          // Clean up the end of the string with any spaces of trailing forward slashes
          sys_id_external = sys_id_external.trim();
          if (sys_id_external.substring(sys_id_external.length - 1) == "/") {
            sys_id_external = sys_id_external.substring(0, sys_id_external.length - 1);
          }
        }

        let duplicate = false;
        // Check to make sure the external ID is unique for this section so we're not adding twice
        for (let x = 0; x < notifyLinks.length; x++) {
          if (notifyLinks[x].sys_id_external == sys_id_external) {
            duplicate = true;
            break;
          }
        }

        if (duplicate == false) {
          notifyLinks.push({
            sys_id: crypto.randomUUID(),
            sys_id_external: sys_id_external,
            sys_id_note: note.sys_id,
            sys_id_section: section.sys_id,
            sys_type: sys_type,
            sys_date_created: new Date().getTime(),
            sys_date_updated: new Date().getTime(),
            full_url: linkInSection,
          });
        }
      }
    }

    // console.log(notifyLinks);
    return notifyLinks;
  }

  static parseBlockContentInDataForStorage(sys_id: string, block: EditorBlock): EllieParsedResult {
    const references: Map<string, EllieBlockReference> = new Map();
    let content = "";

    if (block.type == "paragraph") {
      block.data.text = Parser.parseReferencesFromHTML(sys_id, references, block.data.text).content;
      content = block.data.text;
    } else if (block.type == "header") {
      block.data.text = Parser.parseReferencesFromHTML(sys_id, references, block.data.text).content;
      content = block.data.text;
    } else if (block.type == "action") {
      block.data.text = Parser.parseReferencesFromHTML(sys_id, references, block.data.text).content;
      content = block.data.text;
    } else if (block.type == "image") {
      block.data.caption = Parser.parseReferencesFromHTML(sys_id, references, block.data.caption).content;
      content = block.data.caption;
    } else if (block.type == "list") {
      content = this._parseItemsFromList(sys_id, references, block.data.items, content);
    } else if (block.type == "recorder") {
      content = "";
    }

    let data: EditorBlockData = JSON.parse(JSON.stringify(block.data));

    // If this isn't an action, we remove the dates from the data as this is all system generated aside
    // from the action
    if (block.type != "action") {
      data.dates = undefined;
    }

    return {
      content: content,
      search: content.trim().length > 0 ? content.trim().toLowerCase() : undefined,
      references: references,
      data: data,
    }
  }

  static _parseItemsFromList = (sys_id: string, references: Map<string, EllieBlockReference>, items: any, content: string) => {
    if (items != null && items.length > 0) {
      for (let x = 0; x < items.length; x++) {
        // Replace the content for each item
        // console.log(`Parsing item: ${block.data.items[x]}`);
        items[x].content = Parser.parseReferencesFromHTML(sys_id, references, items[x].content).content;
        content += items[x].content + ". ";
        content = this._parseItemsFromList(sys_id, references, items[x].items, content);
      }
    }

    return content;
  }


/*   static parseNoteListContentForEditor = async (notes: EllieNote[]) => {
    if (notes != null && notes.length > 0) {
      for (let x = 0; x < notes.length; x++) {
        await Parser.parseNoteContentForEditor(notes[x]);
      }
    }
    return notes;
  } */

  // ENTRY
  static parseNoteContentForEditor = async (note: EllieNote) => {
    await Parser._parseReferencesFromListToHTML(note.blocks_captured!);

    if (note.tags) {
      note.tags = await Parser._parseReferencesToHTML(note.tags, note.references_referenced, note.references);
      note.tags.trim();
      note.tags = note.tags + "&nbsp;";
    }
  }

  static parseCommentsContentForEditor = async(comments: NotifyComment[]) => {
    // console.log(comments);
    if (comments != null && comments.length > 0) {
      for (let x = 0; x < comments.length; x++) {
        comments[x].content = await Parser._parseReferencesToHTML(comments[x].content, comments[x].references_referenced, comments[x].references);
      }
    }

    return comments;
  }

  // ENTRY
  static getBlocksForEditor = (note: EllieNote) => {
    const editorBlocks: EditorBlock[] = [];

    // console.log(note);
    // console.log(`Note for editor: ${JSON.stringify(note)}`);
    // console.log(`Note Blocks for editor: ${JSON.stringify(note.note_blocks)}`);
    if (note.blocks_referenced != null && note.blocks_referenced.length > 0) {
      // Sort the blocks according to the order
      note.blocks_captured!.sort((a, b) => (a.order! > b.order!) ? 1 : -1);

      // Reconstruct the blocks as per the editor
      for (let x = 0; x < note.blocks_captured!.length; x++) {
        editorBlocks.push(Parser.getBlockForEditor(note.blocks_captured![x]));
      }
    }

    // console.log(`Blocks to render in editor: ${JSON.stringify(editorBlocks)}`);
    // console.log(editorBlocks);
    return editorBlocks;
  }

  // ENTRY
  static getBlockForEditor = (block: EllieBlock): EditorBlock => {
    // console.log(`Block for editor: ${JSON.stringify(block)}`);
    Editor.getInstance().setRegisteredBlockUuid(block.sys_id, block.editor_id_block);
    Editor.getInstance().setCachedBlock(block);

    // Only add the stored block if we're dealing with an action
    if (block.editor_type_block == "action") {
      block.data.stored_block = block;
    }

    return {
      id: block.editor_id_block,
      type: block.editor_type_block,
      data: block.data,
      sys_id_block: block.sys_id,
    };
  }

  // ENTRY
  static convertToBlocksForHistory = async(notes: EllieNote[]): Promise<EditorNote[]> => {
    const editorNotes: EditorNote[] = [];
    if (notes != null && notes.length > 0) {
      for (let w = 0; w < notes.length; w++) {
        editorNotes.push(await Parser.convertToBlockForHistory(notes[w]));
      }
    }
    // console.log(editorNotes);
    return editorNotes;
  }

  // ENTRY
  static convertToBlockForHistory = async(note: EllieNote): Promise<EditorNote> => {
    const editorBlocks: EditorBlock[] = [];

    if (note.blocks_captured != null && note.blocks_captured!.length > 0) {
      for (let x = 0; x < note.blocks_captured!.length; x++) {
        await Parser._parseBlockContentInDataForEditor(note.blocks_captured![x]);

        editorBlocks.push({
          id: note.blocks_captured![x].editor_id_block,
          type: note.blocks_captured![x].editor_type_block,
          data: note.blocks_captured![x].data,
          sys_id_block: note.blocks_captured![x].sys_id,
        });
      }
    }

    return {
      sys_id_note: note.sys_id,
      sys_date_updated: note.sys_date_updated,
      title: note.title ? note.title : '',
      tags: note.tags ? await Parser._parseReferencesToHTML(note.tags!, note.references_referenced, note.references) : '',
      editor_blocks: editorBlocks,
      tenant: note.tenant,
      references_referenced: note.references_referenced,
      pinned: note.pinned,
      sys_id_sharing_event: note.sys_id_sharing_event,
    };
  }

  // ENTRY
  static convertToBlocksForEditor = async(blocks: EllieBlock[]): Promise<EditorBlock[]> => {
    blocks.sort((a, b) => (a.order > b.order) ? 1 : -1);

    const editorBlocks: EditorBlock[] = [];
    if (blocks != null && blocks.length > 0) {
      for (let x = 0; x < blocks.length; x++) {
        await Parser._parseBlockContentInDataForEditor(blocks[x]);

        // Add the Notify block to the block data so we can use it
        blocks[x].data.stored_block = blocks[x];

        editorBlocks.push({
          id: blocks[x].editor_id_block,
          type: blocks[x].editor_type_block,
          data: blocks[x].data,
          sys_id_block: blocks[x].sys_id,
        });
      }
    }
    // console.log(`Returning blocks for editor: ${JSON.stringify(editorBlocks)}`);
    return editorBlocks;
  }

  static parseTopicsFromPlainText = (content: string): string[] => {
    const topics: string[] = [];
    // This looks for @steve type mentions - not internal format stuff
    const matches = [...content.matchAll(/(?:^|[^a-zA-Z0-9_＠!@#$%&*])(?:(?:#)(?!\/))([a-zA-Z0-9/_]{1,100})(?:\b(?!#)|$)/g)];

    if (matches != null && matches.length > 0) {
      for (let i = 0; i < matches.length; i++) {
        // Cut of the preceding > and @
        const match = matches[i][0].substring(2);
        topics.push(match);
      }
    }

    return topics;
  }

  static parsePeopleFromPlainText = (content: string): string[] => {
    const persons: string[] = [];
    const matches: any[] = content.match(/(?:^|[^a-zA-Z0-9_＠!@#$%&*])(?:(?:@)(?!\/))([a-zA-Z0-9\/_-]{1,100})(?:\b(?!@)|$)/g)!;

    if (matches != null && matches.length > 0) {
      for (let i = 0; i < matches.length; i++) {
        // Cut of the preceding > and @
        let match = matches[i].substring(1);

        // If the match starts with @, trim that off (this is when the user is actually selected)
        if (match.indexOf("@") == 0) {
          match = match.substring(1);
        }

        persons.push(match);
      }
    }

    return persons;
  }

  static _parseReferencesFromListToHTML = async(blockList: EllieBlock[]): Promise<EllieBlock[]> => {
    // console.log(`Content list: ${JSON.stringify(contentList)}`);
    if (blockList != null && blockList.length > 0) {
      for (let x = 0; x < blockList.length; x++) {
        // console.log(`Content list entry text: ${JSON.stringify(contentList[x])}`);
        await Parser._parseBlockContentInDataForEditor(blockList[x]);
        // console.log(`Parsed content is: ${blockList[x].content}`);
      }
    }
    return blockList;
  }

  static _parseBlockContentInDataForEditor = async(block: EllieBlock) => {
    if (block.editor_type_block == "paragraph") {
      block.data.text = await Parser._parseReferencesToHTML(block.data.text, block.references_referenced, block.references);
    } else if (block.editor_type_block == "action") {
      block.data.text = await Parser._parseReferencesToHTML(block.data.text, block.references_referenced, block.references);
    } else if (block.editor_type_block == "list") {
      await this._parseItemsInList(block.data.items, block.references_referenced, block.references);
    } else if (block.editor_type_block == "image") {
      block.data.caption = await Parser._parseReferencesToHTML(block.data.caption, block.references_referenced, block.references);
    }
  }

  static _parseItemsInList = async(items: any, references_referenced: EllieBlockReference[], references: EllieReference[]) => {
    if (items != null && items.length > 0) {
      for (let x = 0; x < items.length; x++) {
        // LEGACY: Check to see if the items have a content property, if not we translate to the new lists interface
        if (items[0].content == null) {
          for (let x = 0; x < items.length; x++) {
            items[x] = {
              content: items[x],
            };
          }
        }

        // Replace the content for each item
        items[x].content = await Parser._parseReferencesToHTML(items[x].content, references_referenced, references);

        // Continue up down the branches if there are any
        await this._parseItemsInList(items[x].items, references_referenced, references);
      }
    }
  }

  // Used for intelligence as it incrementally adds to the content
  static parseReferencesToHTML = async(content: string): Promise<string> => {
    if (content != null && content.trim().length > 0) {
      // console.log(references_referenced);
      // console.log(references);
      // console.log(`Content being processed: ${content}`);
      const matches: any[] = content.match(/({!en{)(topic|person)(}ga{([a-zA-Z0-9\/_-]{1,100})}gd})/g)!;
      // console.log(`Matches for content: ${JSON.stringify(matches)}`);

      if (matches != null && matches.length > 0) {
        // Go through each of these matches and create the replacement
        for (let i = 0; i < matches.length; i++) {
          const match = matches[i];
          // console.log(`Match found: ${match}`);
          let prefix = "";

          if (match.indexOf("person") >= 0) {
            prefix = "@";
          } else if (match.indexOf("topic") >= 0) {
            prefix = "#";
          } else {
            throw new Error("System Error: parsed reference is not a topic or a person.");
          }

          const id = match.substring(
            match.indexOf("}ga{") + 4,
            match.indexOf("}gd}")
          );

          const reference = await Storage.getInstance().getReference(id);
          // console.log(reference);

          content = content.replace(match, Parser.constructReferenceString(prefix, reference).html);
        }
      }
    }

    // console.log(`Returning content: ${content}`);
    return content;
  }

  static parsePlainReferencesToHTML = async(content: string) => {
    if (content != null && content.trim().length > 0) {
      const topicMatches =  content.match(/(?:^|[^a-zA-Z0-9_＠!@#$%&*])(?:(?:#)(?!\/))([a-zA-Z0-9\/_-]{1,100})(?:\b(?!#)|$)/g);
      console.log(`Topic matches:`, topicMatches);
      const personMatches = content.match(/(?:^|[^a-zA-Z0-9_＠!@#$%&*])(?:(?:@)(?!\/))([a-zA-Z0-9\/_-]{1,100})(?:\b(?!@)|$)/g);
      console.log(`Person matches:`, personMatches);

      if (topicMatches != null && topicMatches.length > 0) {
        for (let y = 0; y < topicMatches.length; y++) {
          const references = await Storage.getInstance().findTopics(topicMatches[y].trim().toLowerCase().substring(1), null, "yes");
          if (references != null && references.length > 0) {
            // Take the first topic in the results
            content = content.replace('#' + references[0].alias.toLowerCase(), Parser.constructReferenceString("#", references[0]).html);
          }
        }
      }

      if (personMatches != null && personMatches.length > 0) {
        for (let y = 0; y < personMatches.length; y++) {
          const references = await Storage.getInstance().findPeople(personMatches[y].trim().toLowerCase().substring(1), null, "yes");
          if (references != null && references.length > 0) {
            // Take the first person in the results
            content = content.replace('@' + references[0].alias.toLowerCase(), Parser.constructReferenceString("@", references[0]).html);
          }
        }
      }
    }

    return content;
  }

  // Copied to sections.js and intelligence.js
  static _parseReferencesToHTML = async(content: string, references_referenced: any[], references: EllieReference[]): Promise<string> => {
    if (content != null && content.trim().length > 0) {
      // console.log(references_referenced);
      // console.log(references);
      // console.log(`Content being processed: ${content}`);
      const matches: any[] = content.match(/({!en{)(topic|person)(}ga{([a-zA-Z0-9\/_-]{1,100})}gd})/g)!;
      // console.log(`Matches for content: ${JSON.stringify(matches)}`);

      if (matches != null && matches.length > 0) {
        // Go through each of these matches and create the replacement
        for (let i = 0; i < matches.length; i++) {
          const match = matches[i];
          // console.log(`Match found: ${match}`);
          let prefix = "";

          if (match.indexOf("person") >= 0) {
            prefix = "@";
          } else if (match.indexOf("topic") >= 0) {
            prefix = "#";
          } else {
            throw new Error("System Error: parsed reference is not a topic or a person.");
          }

          const id = match.substring(
            match.indexOf("}ga{") + 4,
            match.indexOf("}gd}")
          );

          const reference = Parser.getReferenceFromObjectReferences(id, references_referenced, references);
          // const reference = await Storage.getInstance().getReference(id);

          content = content.replace(match, Parser.constructReferenceString(prefix, reference).html);
        }
      }
    }

    // console.log(`Returning content: ${content}`);
    return content;
  }

  // Copied to sections.js and intelligence.js
  static getReferenceFromObjectReferences(sys_id_reference: string, references_referenced: EllieBlockReference[], references: EllieReference[]): EllieReference {
    if (references_referenced != null && references_referenced.length > 0) {
      // First go through the references referenced to find the correct entry
      for (let x = 0; x < references_referenced.length; x++) {
        if (references_referenced[x].sys_id_reference == sys_id_reference) {
          // We have our reference, but we now need to check if we have a full reference for it based on the user permissions
          if (references != null && references.length > 0) {
            for (let y = 0; y < references.length; y++) {
              if (references[y].sys_id == sys_id_reference) {
                references[y].is_private = false;
                return references[y];
              }
            }

            // If we get here, we didn't find a reference, which means we don't have permission to view it
            return {
              sys_id: references_referenced[x].sys_id_reference,
              alias: "private",
              friendly_name: "Private",
              sys_id_action_setting: EllieActionSetting.ConvertManuallyToAnAction,
              sys_type: references_referenced[x].sys_type,
              sys_id_sharing: EllieSharingType.DomainSharing,
              is_private: true,
            }
          } else {
            // We don't have permission to any references in this block!
            // console.log(`Reference is private: ${sys_id_reference}`)
            return {
              sys_id: references_referenced[x].sys_id_reference,
              alias: "private",
              friendly_name: "Private",
              sys_id_action_setting: EllieActionSetting.ConvertManuallyToAnAction,
              sys_type: references_referenced[x].sys_type,
              sys_id_sharing: EllieSharingType.DomainSharing,
              is_private: true,
            }
          }  
        }
      }
    } else {
      // console.log(`Reference is not known: ${sys_id_reference}`)
      return {
        sys_id: sys_id_reference,
        alias: "UNKNOWN",
        friendly_name: "",
        sys_id_action_setting: EllieActionSetting.ConvertManuallyToAnAction,
        sys_type: null,
        sys_id_sharing: EllieSharingType.DomainSharing,
        is_private: false,
      }
    }
  }

  static updateIntelligenceContentToHtml(content: string): string {
    content = content.replace(/(?:\r\n|\r|\n)/g, ' <br/>');
    content = content.replace(/[^">]((http|https|ftp|ftps)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?)[^"<]/g, ' <a href="$1" class="link-light" target="_blank">$1</a> ');

    const document: any = domparser.parseFromString(content, "text/html");
    const links: any = document.getElementsByTagName("a");

    if (links != null) {
      for (const link of links) {
        link.setAttribute("target", "_blank");
        link.className = "link-light";
      }
    }

    return document.getElementsByTagName("body")[0].innerHTML;
  }

  static convertAlias(alias: string): string {
    if (alias != null && alias.trim().length > 0) {
      alias = alias.replace(/[^a-zA-Z0-9-_]/g, "-");
      alias = alias.toLowerCase();
    }
    return alias;
  }

  static cleanAlias(alias: string): string {
    if (alias != null && alias.trim().length > 0) {
      alias = alias.replace(/_/g, '-');
      alias = alias.replace(/[^a-zA-Z0-9-]/g, '');
    }
    return alias;
  }

  static makeFriendly(alias: string) {
    alias = alias.replace("#", "");
    alias = alias.replace("@", "");
    alias = alias.replace(/-/g, " ");
    alias = alias.replace(/_/g, " ");
    const aliasParts: string[] = alias.split(" ");

    let reconstructed = "";
    if (aliasParts != null && aliasParts.length > 0) {
      for (let x = 0; x < aliasParts.length; x++) {
        reconstructed += aliasParts[x].charAt(0).toUpperCase() + aliasParts[x].slice(1) + " ";
      }
    }

    return reconstructed.trim();
  }

  static parseTagsToTitle = (content: string): string => {
    let title = "";

    content = content.replace(/&nbsp;/gm, " ");

    const document: any = domparser.parseFromString(content, "text/html");
    const mentions: any = document.getElementsByClassName("en-mention");

    if (mentions != null && mentions.length > 0) {
      Array.from(mentions).forEach(
        function(element: any, index: number, array: any) {
          const type = element.getAttribute("data-type");
          const alias = element.innerHTML;

          if (mentions.length == 1 && type == "person") {
            title = `${Parser.makeFriendly(alias)}`;
          } else if (type == "person") {
            title += `${Parser.makeFriendly(alias)} / `;
          } else if (mentions.length == 1 && type == "topic") {
            title = `${Parser.makeFriendly(alias)}`;
          } else {
            return;
          }
        }
      );
    }

    return title;
  }

  static determineIfContentIsAction = (content: string): boolean  => {
    let isAction = false;

    // Clean up the non breaking spaces
    content = content.replace(/&nbsp;/gm, " ").trim();
    // console.log(content);

    const document: any = domparser.parseFromString(content, "text/html");
    const mentions: any = document.getElementsByClassName("en-mention");

    // If any of the references wants to be an action, then the content is an action
    if (mentions != null && mentions.length > 0) {
      Array.from(mentions).forEach(
        function(element: any, index: number, array: any) {
          const sys_id_action_setting = Number.parseInt(element.getAttribute("data-action-setting"));

          if (sys_id_action_setting == EllieActionSetting.OnlyConvertToAnActionIfMentionedFirst) {
            // console.log(element.outerHTML);
            if (content.indexOf(element.outerHTML) == 0) {
              isAction = true;
              return;
            }
          }

          if (sys_id_action_setting == EllieActionSetting.AlwaysConvertToAnAction) {
            isAction = true;
            return;
          }
        }
      );
    }

    return isAction;
  }

  static parseReferencesFromHTML = (sys_id_block: string, references: Map<string, EllieBlockReference>, content: string): EllieParsedResult => {
    // Clean up the non breaking spaces
    content = content.replace(/&nbsp;/gm, " ");

    const document: any = domparser.parseFromString(content, "text/html");
    const mentions: any = document.getElementsByClassName("en-mention");
    // console.log(`Mentions in the document are: ${JSON.stringify(mentions)}`);

    if (mentions != null && mentions.length > 0) {
      Array.from(mentions).forEach(
        function(element: any, index: number, array: any) {
          const sys_type = <string>element.getAttribute("data-type");
          const sys_id = element.getAttribute("data-sys-id");
          const alias = element.innerHTML.substring(1);
          // console.log(`data-type: ${type}, data-sys-id: ${sys_id}`);
  
          if (sys_id != null && sys_id.trim().length > 0) {
            // Only parse the reference if the callback for assigning the identifier has completed
            // console.log(`Replacing element with internal identifier for id: ${element.getAttribute("id")}`);
            if (document.getElementById(element.getAttribute("id")) != null) {
              document.getElementById(element.getAttribute("id")).parentNode.replaceChild(document.createTextNode(`{!en{${sys_type}}ga{${sys_id}}gd}`), element);
            } else {
              console.log(`Cannot find element: ${JSON.stringify(element)}`);
            }

            // console.log(`Adding reference to refefences: ${sys_id}`);
            references.set(
              sys_id,
              {
                sys_id_reference: sys_id,
                sys_type: sys_type,
                sys_id_block: sys_id_block,
                status: EllieBlockStatus.NotStarted,
                alias: alias,
                shared: false,
              });
          }
        }
      );
    }

    // console.log(`Resultant content post parsing is: ${document.getElementsByTagName("body")[0].innerHTML}`);
    return {
      content: document.getElementsByTagName("body")[0].innerHTML,
      references: references,
    };
  }

  // Copied to sections.js
  static constructReferenceString = (prefix: string, reference: EllieReference): ReferenceResult => {
    const id: string = crypto.randomUUID();
    let modifiedAlias = reference.alias;

    if (reference.is_private == undefined || reference.is_private == null) {
      reference.is_private = false;
    }

    // Check to see if this reference is me
    let tenant: NotifyTenant = Editor.getInstance().getTenant();
    if (tenant != null && 
        tenant.reference != null && 
        tenant.reference.sys_id == reference.sys_id &&
        reference.alias.indexOf("(me)") < 0) {
      modifiedAlias = `${reference.alias} (me)`;
    }

    // console.log(reference);
    return {
      html: `<span id="${id}" class="en-mention ${reference.is_private == false ? (prefix == "@" ? "person" : "topic") : "private"}" data-sys-id="${reference.sys_id}" data-type="${prefix == "#" ? "topic": "person"}" data-reference-type="${reference.sys_type}" data-action-setting="${reference.sys_id_action_setting}" contenteditable="false">${prefix}${(modifiedAlias)}</span>`,
      id: id,
    }
  }

  static areDatesEqual = (date1: EllieDate, date2: EllieDate): boolean => {
    if (date1.date_start == date2.date_start &&
        date1.date_end == date2.date_end &&
        date1.all_day == date2.all_day) {
      return true;
    } else {
      return false;
    }
  }

  static getNowEllieDate = (timeNow: number): EllieDate => {
    let date_start = new Date().getTime();
    if (timeNow != null && timeNow > 0) {
      date_start = timeNow;
    }

    return {
      sys_id_temp: crypto.randomUUID(),
      content_index: -1,
      content: "",
      date_start: new Date().toUTCString(),
      date_end: null,
      all_day: true,
      due_date: false,
    };
  }

  static parseDatesFromHTML = (content: string): EllieDate[] => {
    const parsedDates: EllieDate[] = [];
    if (content != null && content.trim().length > 0) {
      const parsedResult: any = chrono.parse(content);
      // console.log(parsedResult);

      if (parsedResult != null && parsedResult.length > 0) {
        for (let x = 0; x < parsedResult.length; x++) {
          let start: any = {
            ...parsedResult[x].start.impliedValues,
            ...parsedResult[x].start.knownValues,
          };
          let startDateTime = Date.parse(Formatter.formatDateFromParts(start.year, start.month, start.day, start.hour, start.minute, start.second));

          let end: any = null;
          let endDateTime = 0;
          if (parsedResult[x].end) {
            end = {
              ...parsedResult[x].end.impliedValues,
              ...parsedResult[x].end.knownValues,
            };
            endDateTime = Date.parse(Formatter.formatDateFromParts(end.year, end.month, end.day, end.hour, end.minute, end.second));
          }

          parsedDates.push({
            sys_id_temp: crypto.randomUUID(),
            content_index: parsedResult[x].index,
            content: parsedResult[x].text,
            date_start: new Date(startDateTime).toUTCString(),
            date_end: new Date(endDateTime).toUTCString(),
            all_day: parsedResult[x].start.knownValues.hour != null ? false : true,
            due_date: false,
          });
        }
      }
    }

    return parsedDates;
  }
}
