// import PouchDB from 'pouchdb';
// import find from 'pouchdb-find';
import { NotifyFilter } from './editor';
import http from "./http";

// PouchDB.plugin(find);

export enum NotifyProvider {
  Salesforce = 'salesforce',
  Google = 'google',
  Slack = 'slack',
  Link = 'link',
};

export enum EllieBlockStatus {
  NotStarted = 1,
  InProgress = 2,
  Complete = 3,
  Parked = 4,
  NotDoing = 5,
};

export enum NotifyBlockSizing {
  NotResolved = 0,
  Small = 1,
  Medium = 2,
  Large = 3,
};

export enum NotifyBlockSentiment {
  NotResolved = 0,
  Positive = 1,
  Neutral = 2,
  Negative = 3,
};

export enum EllieSharingType {
  NotShared = 0,
  TenantSharing = 1,
  DomainSharing = 2,
}

export enum EllieActionSetting {
  AlwaysConvertToAnAction = 1,
  OnlyConvertToAnActionIfMentionedFirst = 2,
  ConvertManuallyToAnAction = 3,
}

export interface NotifyTranscription {
  sys_id_recording: string;
  transcription: string;
  completed: boolean;
}

export interface NotifyBasicTenant {
  email: string;
  picture: string;
  given_name: string;
  family_name: string;
}

export interface NotifyComment {
  sys_id: string;
  sys_id_section: string;
  sys_id_tenant_origin: string;
  sys_date_created: number;
  content: string;
  search: string;
  tenant: NotifyTenant;
  references_referenced?: EllieNoteReference[];
  references?: EllieReference[];
}

export interface NotifyAuthenticationStatus {
  sys_provider: string;
  is_supported: boolean;
  is_authenticated: boolean;
}

export interface EllieDate {
  sys_id_temp: string;
  content_index: number;
  content: string;
  date_start: string;
  date_end: string;
  all_day: boolean;
  due_date: boolean;
}

export interface EllieBlock {
  _id?: string;
  sys_type?: string;
  sys_id_tenant_origin?: string;
  sys_id: string;
  sys_id_note: string;
  sys_id_section?: string;
  sys_date_created: string;
  sys_date_updated: string;
  editor_id_block: string;
  editor_type_block: string;
  data: any;
  search: string;
  status: EllieBlockStatus;
  sizing?: NotifyBlockSizing;
  sentiment?: NotifyBlockSentiment;
  dates?: EllieDate[];
  references?: EllieReference[];
  references_referenced: EllieBlockReference[];
  order: number;
};

export interface NotifySection {
  sys_id: string;
  sys_type?: string;
  sys_id_tenant_origin?: string;
  sys_id_note: string;
  sys_date_created: string;
  sys_date_updated: string;
  blocks: EllieBlock[];
  blocks_referenced: EllieBlockPointer[];
  content?: string;
  search: string;
  status: EllieBlockStatus;
  sizing?: NotifyBlockSizing;
  sentiment?: NotifyBlockSentiment;
  dates?: EllieDate[];
  links?: NotifyLink[];
  references?: EllieReference[];
  references_referenced: EllieBlockReference[];
  tenant?: NotifyBasicTenant;
  sys_id_sharing_event?: string;
}

export interface NotifyLink {
  sys_id: string;
  sys_id_external: string;
  sys_type: string;
  sys_id_note: string;
  sys_id_section: string;
  sys_date_created: number;
  sys_date_updated: number;
  full_url: string;
}

export interface EllieBlockPointer {
  sys_id_block: string;
  order?: number;
}

export interface NotifyNoteSharing {
  references_referenced: EllieNoteReference[];
  references: EllieReference[];
}

export interface EllieNote {
  _id?: string;
  sys_type?: string;
  sys_id_tenant_origin?: string;
  sys_id: string;
  sys_date_created: number;
  sys_date_updated: number;
  sys_id_block_title?: string;
  title?: string;
  tags?: string;
  blocks_captured?: EllieBlock[];
  blocks_referenced?: EllieBlockPointer[];
  blocks_orphaned?: string[];
  references_referenced?: EllieNoteReference[];
  references?: EllieReference[];
  filter?: NotifyFilter;
  sections?: NotifySection[];
  tenant?: NotifyBasicTenant;
  pinned?: boolean;
  done?: boolean;
  sys_id_sharing_event?: string;
}

export interface EllieNoteReference {
  sys_id_reference: string;
  sys_type: string;
  alias?: string;
  shared?: boolean;
}

export interface EllieBlockReference {
  sys_id_reference: string;
  sys_type: string;
  sys_id_block: string;
  status: EllieBlockStatus;
  alias?: string;
  shared?: boolean;
}

export interface EllieReference {
  sys_id: string;
  alias: string;
  friendly_name: string;
  email?: string;
  sys_id_action_setting: EllieActionSetting;
  sys_type: string;
  sys_id_sharing: EllieSharingType;
  shared_domains?: NotifyDomainSharing[];
  shared_tenants?: NotifyTenantSharing[];
  is_private?: boolean;
  actions_total?: number;
  actions_incomplete?: number;
  sys_id_tenant_verified?: string;
  details?: EllieReferenceDetails;
  auto_share_note?: boolean;
  auto_share_block?: boolean;
}

export interface EllieReferenceDetails {
  link: string;
  phone: string;
  description: string;
  linked_in: string;
  twitter: string;
}

export interface NotifyLinkExternalIdentifier {
  sys_type: string;
  sys_id_external: string;
}

export interface NotifyDomainSharing {
  sys_id?: string;
  sys_id_domain: string;
  domain?: string;
}

export interface NotifyTenantSharing {
  syd_id?: string;
  sys_id_tenant: string;
  tenant?: NotifyTenant;
}

export interface NotifyTenant {
  sys_id: string;
  ext_id_user: string;
  ext_id_team: string;
  sys_provider: string;
  email: string;
  picture: string;
  given_name: string;
  family_name: string;
  sys_id_domain: string;
  domain?: string;
  reference?: EllieReference;
}

export interface NotifyUserInfo {
  sys_id_tenant: string;
  sys_provider: string;
  ext_id_user: string;
  ext_id_team: string;
  ext_profile: string;
  picture: string;
  given_name: string;
  family_name: string;
  email: string;
}

export interface NotifyIntelligenceResult {
  sizing: NotifyBlockSizing;
  sentiment: NotifyBlockSentiment;
}

export interface ElliePeople {
  _id?: string;
  sys_type: string;
  sys_id_tenant: string;
  sys_id_tenant_user: string;
  sys_id: string;
  sys_date_created: number;
  sys_date_updated: number;
  sys_id_external: string;
  alias: string;
  friendly_name: string;
  email?: string;
  sys_id_action_setting: EllieActionSetting;
  sys_id_sharing: EllieSharingType;
  shared_domains?: NotifyDomainSharing[];
  shared_tenants?: NotifyTenantSharing[];
  sys_id_tenant_verified?: string;
}

export interface EllieTopic {
  _id?: string;
  sys_type: string;
  sys_id_tenant: string;
  sys_id: string;
  sys_date_created: number;
  sys_date_updated: number;
  sys_id_external: string;
  alias: string;
  friendly_name: string;
  email?: string;
  sys_id_action_setting: EllieActionSetting;
  sys_id_sharing: EllieSharingType;
  shared_domains?: NotifyDomainSharing[];
  shared_tenants?: NotifyTenantSharing[];
}

export interface NotifyInsights {
  i7: NotifyInsight[];
  i28: NotifyInsight[];
  references: EllieReference[];
}

export interface NotifyGraphNode {
  id: string;
  name: string;
  group: string;
}

export interface NotifyGraphLink {
  source: string;
  target: string;
}

export interface NotifyGraph {
  nodes: NotifyGraphNode[];
  links: NotifyGraphLink[];
}

export interface NotifyInsight {
  sys_id_reference: string;
  sys_type?: string;
  reference_count: number;
  time_interval: number;
}

export interface NotifyField {
  name: string;
  value: string;
  monitor: boolean;
}

export interface SharingEvent {
  sys_id: string;
  sys_id_tenant: string;
  sys_id_note: string;
  sys_id_section: string;
  sys_date_created: number;
}

export interface CommentEvent {
  sys_id: string;
  sys_id_tenant: string;
  sys_id_note: string;
  sys_id_section: string;
  sys_id_comment: string;
  sys_date_created: number;
}

export interface IntelligenceEvent {
  sys_id: string;
  sys_id_registered_event_object: string;
  sys_id_tenant: string;
  pre_event_fields: NotifyField[];
  post_event_fields: NotifyField[];
}

export interface IntelligenceRecommendation {
  sys_id: string;
  sys_id_registered_event_object: string;
  sys_id_tenant: string;
  pre_intelligence_fields: NotifyField[];
  post_intelligence_fields: NotifyField[];
}

export interface NotifyInvitorInfo {
  email: string;
  picture: string;
  given_name: string;
  family_name: string;
  sys_id_domain: string;
}

export interface NotifySearchResult {
  sys_id_section_link?: string;
  sys_id_section: string;
  sys_id_note: string;
}

export interface NotifyReaction {
  unified: string;
  skin_tones: NotifyReactionSkinTone[];
}

export interface NotifyReactionSkinTone {
  skin_tone: string;
  users: NotifyReactionSkinToneUser[];
}

export interface NotifyReactionSkinToneUser {
  sys_id_reference: string;
  friendly_name: string;
  alias: string;
}

declare function emit (val: any): any;
declare function emit (key: any, value: any): any;

export class Storage {
  private static _storage: Storage;
  private _db: any = null;
  private _tenantId: string = '';

  get storage(): any {
    return Storage._storage;
  }
  set storage(value: any) {
    Storage._storage = value;
  }

  get db(): any {
    return this._db;
  }
  set db(value: any) {
    this._db = value;
  }

  get tenantId(): string {
    if (this._tenantId == null || this._tenantId.trim().length == 0) {
      throw new Error(`User not currently logged in.`);
    }
    return this._tenantId;
  }
  set tenantId(tenantId: string) {
    this._tenantId = tenantId;
  }

  constructor() {
    // this.db = new PouchDB('ellienotes', {auto_compaction: true});
    // this.db.createIndex({
    //   index: {
    //     fields: ['sys_type', 'sys_id_tenant', 'sys_id', 'sys_date_created', 'sys_date_updated', 'sys_id_block_title', 'blocks_captured', 'blocks_referenced', 'title', 'tags', 'references_referenced'],
    //     name: 'idx_notes',
    //     ddoc: 'idx_notes',
    //   }
    // });
    // this.db.createIndex({
    //   index: {
    //     fields: ['sys_type', 'sys_id_tenant_origin', 'sys_id', 'sys_id_note', 'sys_date_created', 'sys_date_updated', 'editor_id_block', 'editor_type_block', 'data', 'search', 'status', 'dates', 'references_referenced'],
    //     name: 'idx_blocks',
    //     ddoc: 'idx_blocks',
    //   }
    // });
    // this.db.createIndex({
    //   index: {
    //     fields: ['sys_type', 'sys_id_tenant', 'sys_id_tenant_user', 'sys_id', 'sys_date_created', 'sys_date_updated', 'sys_id_external', 'alias', 'friendly_name', 'email', 'title', 'description', 'sys_id_action_setting'],
    //     name: 'idx_persons',
    //     ddoc: 'idx_persons',
    //   }
    // });
    // this.db.createIndex({
    //   index: {
    //     fields: ['sys_type', 'sys_id_tenant', 'sys_id', 'sys_date_created', 'sys_date_updated', 'sys_id_external', 'alias', 'friendly_name', 'description', 'sys_id_action_setting'],
    //     name: 'idx_topics',
    //     ddoc: 'idx_topics',
    //   }
    // });

    // this.tenantId = window.localStorage.getItem('account') != null ? window.localStorage.getItem('account')!.toString() : '';
  }

  static getInstance() {
    if (!Storage._storage) {
      Storage._storage = new Storage();
      // Storage._storage.migrate();
    }
    return Storage._storage;
  }

  static getFirst(resultSet: any) {
    if (resultSet != null && resultSet.docs != null && resultSet.docs.length > 0) {
      if (resultSet.docs.length > 1) {
        console.log(`Something weird going on: ${JSON.stringify(resultSet.docs)}`);
      }
      return resultSet.docs[0];
    }
    return null;
  }

  async migrate() {
    const people = await this.db.find({
      selector: {
        sys_type: {$eq: 't_people'},
      },
    });

    const elliePeople = <ElliePeople[]>people.docs;

    if (elliePeople != null && elliePeople.length > 0) {
      for (let x = 0; x < elliePeople.length; x++) {
        http.put(`api/v1/reference/person`, {
          sys_id: elliePeople[x].sys_id,
          sys_id_tenant_origin: elliePeople[x].sys_id_tenant,
          sys_id_sharing: 2,
          sys_date_created: elliePeople[x].sys_date_created,
          sys_date_updated: elliePeople[x].sys_date_updated,
          alias: elliePeople[x].alias,
          friendly_name: elliePeople[x].friendly_name,
        });
      }
    }

    const topics = await this.db.find({
      selector: {
        sys_type: {$eq: 't_topics'},
      },
    });

    const ellieTopics = <EllieTopic[]>topics.docs;

    if (ellieTopics != null && ellieTopics.length > 0) {
      for (let x = 0; x < ellieTopics.length; x++) {
        http.put(`api/v1/reference/topic`, {
          sys_id: ellieTopics[x].sys_id,
          sys_id_tenant_origin: ellieTopics[x].sys_id_tenant,
          sys_id_sharing: 2,
          sys_date_created: ellieTopics[x].sys_date_created,
          sys_date_updated: ellieTopics[x].sys_date_updated,
          alias: ellieTopics[x].alias,
          friendly_name: ellieTopics[x].friendly_name,
        });
      }
    }

    const blocks = await this.db.find({
      selector: {
        sys_type: {$eq: 't_blocks'},
      },
    });

    const ellieBlocks = <EllieBlock[]>blocks.docs;

    if (ellieBlocks != null && ellieBlocks.length > 0) {
      for (let x = 0; x < ellieBlocks.length; x++) {
        http.put(`api/v1/note/${ellieBlocks[x].sys_id_note}/block`, ellieBlocks[x]);
      }
    }

    const notes = await this.db.find({
      selector: {
        sys_type: {$eq: 't_notes'},
      },
    });

    const ellieNotes = <EllieNote[]>notes.docs;

    if (ellieNotes != null && ellieNotes.length > 0) {
      for (let x = 0; x < ellieNotes.length; x++) {
        http.put(`api/v1/note`, ellieNotes[x]);
      }
    }
  }

  replicate() {
/*     if (window.navigator.onLine) {
      console.log('Replicating the database');

      if (this.tenantId) {
        // This will throw an error if the tenant identifier is not assigned
      }

      const remoteUrl = "https://apikey-v2-24os067q6cyovv16gobc1zxk9f1jix2sb16w144inzoe:893e70f677371880fc021ee50654b8de@513df4fe-96e2-487a-b769-71d515d97628-bluemix.cloudantnosqldb.appdomain.cloud";
      const remotedb = new PouchDB(`${remoteUrl}/ellienotes`);

      this.db.sync(remotedb);
    } else {
      console.log(`Ellie Notes not connected to the internet`);
    } */
  }

  async getTenant(): Promise<NotifyTenant> {
    // console.log("getTenant");
    let tenant: any = await http.get(`api/v1/tenant`);
    return <NotifyTenant>tenant.data;
  }

  async getIntelligenceResult(content: string): Promise<NotifyIntelligenceResult> {
    // console.log("getIntelligenceResult");
    // console.log(content);
    const intelligenceResult: any = await http.post(`api/v1/intelligence`, { content: content });
    // console.log(intelligenceResult);
    return <NotifyIntelligenceResult>intelligenceResult.data;
  }

  async getReference(sys_id: string): Promise<EllieReference> {
    // console.log("getReference");
    let reference: any = await http.get(`api/v1/reference/${sys_id}`);
    return <EllieReference>reference.data;
  }

  async getPinnedReferences(sys_type: string): Promise<EllieReference[]> {
    // console.log("getPinnedReferences");
    let pinnedResponse: any = await http.get(`api/v1/reference/pinned?sys_type=${sys_type}`);
    return <EllieReference[]>pinnedResponse.data;
  }

  async findReferenceSummaries(search: string): Promise<EllieReference[]> {
    // console.log("findReferenceSummaries");
    let reference: any = await http.get(`api/v1/reference/summary?find=${search}`);
    return <EllieReference[]>reference.data;
  }

  async findPeople(search: string, pinned: string, exact: string): Promise<ElliePeople[]> {
    if (search == null) {
      search = "";
    }
    if (pinned == null) {
      pinned = "";
    }
    if (exact == null) {
      exact = "";
    }
    // console.log("findPeople");
    const people: any = await http.get(`api/v1/reference/person?find=${search}&pinned=${pinned}&exact=${exact}`);
    // console.log(people);
    return <ElliePeople[]>people.data;
  }

  async setPeople(reference: EllieReference): Promise<ElliePeople> {
    // console.log("setPeople");
    const person: any = await http.put(`api/v1/reference/person`, reference);
    // console.log(person);
    return <ElliePeople>person.data;
  }

  async updatePeople(people: ElliePeople, shared_tenants: NotifyTenantSharing[], shared_domains: NotifyDomainSharing[]) {
    // console.log("updatePeople");
    people.shared_tenants = shared_tenants;
    people.shared_domains = shared_domains;

    const personResponse: any = await http.put(`api/v1/reference/person`, people);
    // console.log(personResponse);
    return <ElliePeople>personResponse.data;
  }

  async findTopics(search: string, pinned: string, exact: string): Promise<EllieTopic[]> {
    if (search == null) {
      search = "";
    }
    if (pinned == null) {
      pinned = "";
    }
    if (exact == null) {
      exact = "";
    }
    // console.log("findTopics");
    const topics: any = await http.get(`api/v1/reference/topic?find=${search}&pinned=${pinned}&exact=${exact}`);
    // console.log(topics);
    return <EllieTopic[]>topics.data;
  }

  async mergeReferences(sys_id_reference_mergee: string, sys_id_reference_merger: string): Promise<string> {
    // console.log("mergeReferences");
    const mergeResponse: any = await http.post(`api/v1/reference/merge`, {
      sys_id_reference_mergee: sys_id_reference_mergee,
      sys_id_reference_merger: sys_id_reference_merger,
    });

    // console.log(mergeResponse.data);
    return <string>mergeResponse.data;
  }

  async getSharingReferences(): Promise<ElliePeople[]> {
    // console.log("getSharingReferences");
    const people: any = await http.get(`api/v1/reference/sharing`);
    // console.log(topic);
    return <ElliePeople[]>people.data;
  }

  async setTopic(reference: EllieReference): Promise<EllieTopic> {
    // console.log("setTopic");
    // console.log(reference);
    const topic: any = await http.put(`api/v1/reference/topic`, reference);
    // console.log(topic);
    return <EllieTopic>topic.data;
  }

  async updateTopic(topic: EllieTopic, shared_tenants: NotifyTenantSharing[], shared_domains: NotifyDomainSharing[]) {
    // console.log("updateTopic");
    topic.shared_tenants = shared_tenants;
    topic.shared_domains = shared_domains;
    const topicResponse: any = await http.put(`api/v1/reference/topic`, topic);
    // console.log(topicResponse);
    return <EllieTopic>topicResponse.data;
  }

  async pinReference(reference: EllieReference) {
    // console.log(reference);
    // console.log("pinReference");
    const referencePinnedResponse: any = await http.post(`api/v1/reference/${reference.sys_id}/collation?sys_type=${reference.sys_type}`);
    // console.log(referencePinnedResponse);
  }

  async updateSectionEmoji(sys_id_section: string, reaction: NotifyReaction): Promise<NotifyReaction[]> {
    // console.log("updateSectionEmoji");
    const sectionEmojiResponse: any = await http.post(`api/v1/section/${sys_id_section}/emoji`, reaction);
    // console.log(sectionEmojiResponse);
    return <NotifyReaction[]>sectionEmojiResponse.data;
  }

  async getSectionEmoji(sys_id_section: string, reaction: NotifyReaction): Promise<NotifyReaction[]> {
    // console.log("getSectionEmoji");
    const sectionEmojiResponse: any = await http.get(`api/v1/section/${sys_id_section}/emoji`);
    // console.log(sectionEmojiResponse);
    return <NotifyReaction[]>sectionEmojiResponse.data;
  }

  async updateNoteEmoji(sys_id_section: string, reaction: NotifyReaction): Promise<NotifyReaction[]> {
    // console.log("updateNoteEmoji");
    const noteEmojiResponse: any = await http.post(`api/v1/note/${sys_id_section}/emoji`, reaction);
    // console.log(noteEmojiResponse);
    return <NotifyReaction[]>noteEmojiResponse.data;
  }

  async getNoteEmoji(sys_id_section: string, reaction: NotifyReaction): Promise<NotifyReaction[]> {
    // console.log("getNoteEmoji");
    const noteEmojiResponse: any = await http.get(`api/v1/note/${sys_id_section}/emoji`);
    // console.log(noteEmojiResponse);
    return <NotifyReaction[]>noteEmojiResponse.data;
  }

  async archiveReference(reference: EllieReference): Promise<string> {
    // console.log(reference);
    // console.log("archiveReference");
    const archiveReferenceResponse: any = await http.post(`api/v1/reference/${reference.sys_id}/archive`);
    // console.log(archiveReferenceResponse);
    return <string>archiveReferenceResponse.data;
  }

  async getInsights(sys_id_reference: string): Promise<NotifyInsights> {
    // console.log("getInsights");
    let querystring = "";
    if (sys_id_reference != null && sys_id_reference.trim().length > 0) {
      querystring = `?sys_id_reference=${sys_id_reference}`;
    }
    // console.log(querystring);

    const insightResponse: any = await http.get(`api/v1/insight${querystring}`);
    // console.log(insightResponse);

    return <NotifyInsights>insightResponse.data;
  }

  async getGraph(sys_id_note: string, filter: NotifyFilter, days: number, references_referenced: EllieNoteReference[]): Promise<NotifyGraph> {
    // console.log("getGraph");
    filter.days = days;
    filter.sys_id_note = sys_id_note;
    filter.sys_id_references = [];
    if (references_referenced != null && references_referenced.length > 0) {
      for (let x = 0; x < references_referenced.length; x++) {
        filter.sys_id_references.push(references_referenced[x].sys_id_reference);
      }
    }
    // console.log(filter);

    const graphResponse: any = await http.post(`api/v1/graph`, filter);
    // console.log(graphResponse);

    return <NotifyGraph>graphResponse.data;
  }

  async getSectionsByReferencesReferencedForTimeline(sys_id_note: string, filter: NotifyFilter, count: boolean, references_referenced: EllieNoteReference[]): Promise<NotifySection[]> {
    // console.log("getSectionsByReferencesReferencedForTimeline");
    filter.sys_id_note = sys_id_note;
    filter.sys_id_references = [];
    if (references_referenced != null && references_referenced.length > 0) {
      for (let x = 0; x < references_referenced.length; x++) {
        filter.sys_id_references.push(references_referenced[x].sys_id_reference);
      }
    }

    // console.log(filter);
    // console.log(count);
    const sectionResponse: any = await http.post(`api/v1/section/timeline?count=${count}`, filter);
    // console.log(sectionResponse.data);
    return <NotifySection[]>sectionResponse.data;
  }

  async getNotesForTopicsOrPersons(sys_id_note: string, filter: NotifyFilter, count: boolean, references_referenced: EllieNoteReference[]): Promise<EllieNote[]> {
    // console.log("getNotesForTopicsOrPersons");
    filter.sys_id_note = sys_id_note;
    filter.sys_id_references = [];
    if (references_referenced != null && references_referenced.length > 0) {
      for (let x = 0; x < references_referenced.length; x++) {
        filter.sys_id_references.push(references_referenced[x].sys_id_reference);
      }
    }

    const notesResponse: any = await http.post(`api/v1/note/timeline?count=${count}`, filter);
    return <EllieNote[]>notesResponse.data;
  }

  async getLinksForTopicsOrPersons(sys_id_note: string, filter: NotifyFilter, count: boolean, references_referenced: EllieBlockReference[]): Promise<NotifyLink[]> {
    // console.log("getLinksForTopicsOrPersons");
    filter.sys_id_note = sys_id_note;
    filter.sys_id_references = [];
    if (references_referenced != null && references_referenced.length > 0) {
      for (let x = 0; x < references_referenced.length; x++) {
        filter.sys_id_references.push(references_referenced[x].sys_id_reference);
      }
    }

    const linksResponse: any = await http.post(`api/v1/link/timeline?count=${count}`, filter);
    return <NotifyLink[]>linksResponse.data;
  }

  async getNote(sys_id_note: string): Promise<EllieNote> {
    // console.log("getNote");
    const noteResponse: any = await http.get(`api/v1/note/${sys_id_note}`);
    // console.log(noteResponse);

    const note: EllieNote = <EllieNote>noteResponse.data;
    // console.log(note);
    return note;
  }

  async getNoteSharing(sys_id_note: string): Promise<NotifyNoteSharing> {
    // console.log("getNoteSharing");
    const noteSharingResponse: any = await http.get(`api/v1/note/${sys_id_note}/sharing`);
    // console.log(noteSharingResponse);

    return <NotifyNoteSharing>noteSharingResponse.data;
  }

  async pinSection(sys_id_section: string, sys_type: string, pinned: boolean): Promise<string> {
    // console.log("pinSection");
    const sectionPinnedResponse: any = await http.post(`api/v1/section/${sys_id_section}/collation?pinned=${pinned}&sys_type=${sys_type}`);
    // console.log(pinSection);

    return <string>sectionPinnedResponse.data;
  }

  async pinNote(sys_id_note: string, pinned: boolean): Promise<string> {
    // console.log("pinNote");
    const notePinnedResponse: any = await http.post(`api/v1/note/${sys_id_note}/collation?pinned=${pinned}`);
    // console.log(pinNote);

    return <string>notePinnedResponse.data;
  }

  async getPinnedSections(): Promise<NotifySection[]> {
    // console.log("getPinnedSections");
    const pinnedSectionsResponse: any = await http.get(`api/v1/section/collation`);
    // console.log(pinnedSectionsResponse.data);

    return <NotifySection[]>pinnedSectionsResponse.data;
  }

  async getPinnedNotes(): Promise<EllieNote[]> {
    // console.log("getPinnedNotes");
    const pinnedNotesResponse: any = await http.get(`api/v1/note/collation`);
    // console.log(pinnedNotesResponse.data);

    return <EllieNote[]>pinnedNotesResponse.data;
  }

  async setNote(note: EllieNote, filter: NotifyFilter, done: boolean) {
    // console.log("setNote");
    // Apply the filter to the note so we remember if for next time
    note.filter = filter;

    // Set the done status so the backend can optimize what to do for the save and sharing rules etc
    note.done = done;

    const noteResponse: any = await http.put(`api/v1/note`, note);
  }

  async deleteNote(sys_id_note: string) {
    // console.log("deleteNote");
    const noteResponse: any = await http.delete(`api/v1/note/${sys_id_note}`);
  }

  async searchNotes(search: string): Promise<EllieNote[]> {
    throw new Error('Not implemented!!!');
  }

  async saveActionStatusChange(sys_id_section: string, status: EllieBlockStatus) {
    // console.log("saveActionStatusChange");
    const blockResponse: any = await http.put(`api/v1/section/${sys_id_section}?status=${status}`);
  }

  async saveSentimentChange(sys_id_section: string, sentiment: NotifyBlockSentiment) {
    // console.log("saveSentimentChange");
    const blockResponse: any = await http.put(`api/v1/section/${sys_id_section}?sentiment=${sentiment}`);
  }

  async saveSizingChange(sys_id_section: string, sizing: NotifyBlockSizing) {
    // console.log("saveSizingChange");
    const blockResponse: any = await http.put(`api/v1/section/${sys_id_section}?sizing=${sizing}`);
  }

  async saveSectionDueDateChange(sys_id_section: string, due_date: EllieDate) {
    console.log(`saveSectionDueDateChange`);
    const dueDateResponse: any = await http.put(`api/v1/section/${sys_id_section}?due_date=true`, due_date);
    console.log(dueDateResponse.data);
  }

  async saveNoteSharingChange(sys_id_note: string, references_referenced: EllieNoteReference) {
    // console.log("saveNoteSharingChange");
    const blockResponse: any = await http.put(`api/v1/note/${sys_id_note}?sharing=true`, references_referenced);
  }

  async saveSectionSharingChange(sys_id_section: string, references_referenced: EllieBlockReference) {
    // console.log("saveSectionSharingChange");
    const blockResponse: any = await http.put(`api/v1/section/${sys_id_section}?sharing=true`, references_referenced);
  }

  async inviteReference(sys_id_reference: string) {
    // console.log("inviteReference");
    const response: any = await http.post(`api/v1/tenant/invite/${sys_id_reference}`);
  }

  async getInvitorInfo(sys_id_reference: string): Promise<NotifyInvitorInfo> {
    // console.log("getInvitorInfo");
    const response: any = await http.get(`api/v1/tenant/invite/${sys_id_reference}`);
    // console.log(response.data);
    return <NotifyInvitorInfo>response.data;
  }

  async connectReference(sys_id_reference: string) {
    console.log("connectReference");
    const response: any = await http.post(`api/v1/tenant/connect/${sys_id_reference}`);
    console.log(response.data);
    return response.data;
  }

  async getSharingEvents(): Promise<SharingEvent[]> {
    // console.log("getSharingEvents");
    const sharingEventsResponse: any = await http.get(`api/v1/note/event`);
    // console.log(sharingEventsResponse.data);
    return <SharingEvent[]>sharingEventsResponse.data;
  }

  async getCommentEvents(): Promise<CommentEvent[]> {
    // console.log("getCommentEvents");
    const commentEventsResponse: any = await http.get(`api/v1/note/comment_event`);
    // console.log(commentEventsResponse.data);
    return <CommentEvent[]>commentEventsResponse.data;
  }

  async getIntelligenceEvents(): Promise<IntelligenceEvent[]> {
    // console.log("getIntelligenceEvents");
    const intelligenceChanges: any = await http.get(`api/v1/intelligence/event`);
    // console.log(intelligenceChanges.data);
    return <IntelligenceEvent[]>intelligenceChanges.data;
  }

  async stopMonitoringIntelligenceEvents(sys_id_registered_event_object: string) {
    // console.log("stopMonitoringIntelligenceEvents");
    const intelligenceResult: any = await http.delete(`api/v1/intelligence/event/monitor/${sys_id_registered_event_object}`);
    // console.log(intelligenceResult.data);
  }

  async saveIntelligenceRecommendation(sys_id_registered_event_object: string, eventObject: any) {
    // console.log("saveIntelligenceRecommendation");
    const intelligenceResult: any = await http.put(`api/v1/intelligence/event/monitor/${sys_id_registered_event_object}`, eventObject);
    // console.log(intelligenceResult.data);
  }

  async ignoreSharedEvent(sys_id_registered_shared_event: string) {
    // console.log("ignoreSharedEvent");
    const sharedResult: any = await http.delete(`api/v1/note/event/${sys_id_registered_shared_event}`);
    // console.log(sharedResult.data);
  }

  async ignoreCommentEvent(sys_id_registered_comment_event: string) {
    // console.log("ignoreCommentEvent");
    const commentResult: any = await http.delete(`api/v1/note/comment_event/${sys_id_registered_comment_event}`);
    // console.log(commentResult.data);
  }

  async ignoreIntelligenceEvent(sys_id_registered_event_object_event: string) {
    // console.log("ignoreIntelligenceEvent");
    const intelligenceResult: any = await http.delete(`api/v1/intelligence/event/${sys_id_registered_event_object_event}`);
    // console.log(intelligenceResult.data);
  }

  async ignoreIntelligenceRecommendation(sys_id_registered_event_object_recommendation: string) {
    // console.log("ignoreIntelligenceRecommendation");
    const intelligenceResult: any = await http.delete(`api/v1/intelligence/recommendation/${sys_id_registered_event_object_recommendation}`);
    // console.log(intelligenceResult.data);
  }

  async getIntelligenceRecommendations(): Promise<IntelligenceRecommendation[]> {
    // console.log("getIntelligenceRecommendations");
    const intelligenceRecommendations: any = await http.get(`api/v1/intelligence/recommendation`);
    // console.log(intelligenceRecommendations.data);
    return <IntelligenceRecommendation[]>intelligenceRecommendations.data;
  }

  async getSection(sys_id_section: string): Promise<NotifySection> {
    // console.log("getSection");
    const sectionResponse: any = await http.get(`api/v1/section/${sys_id_section}`);
    // console.log(sectionResponse.data);
    return <NotifySection>sectionResponse.data;
  }

  async setCommentForSectionId(sys_id_section: String, comment: NotifyComment): Promise<NotifyComment[]> {
    // console.log("setCommentForSectionId");
    const commentResponse: any = await http.post(`api/v1/section/${sys_id_section}/comment`, comment);
    // console.log(commentResponse.data);
    return <NotifyComment[]>commentResponse.data;
  }

  async deleteCommentForSectionId(sys_id_section: string, sys_id_comment: string): Promise<NotifyComment[]> {
    // console.log("setCommentForSectionId");
    const commentResponse: any = await http.delete(`api/v1/section/${sys_id_section}/comment?sys_id=${sys_id_comment}`);
    // console.log(commentResponse.data);
    return await this.getCommentsForSectionId(sys_id_section);
  }

  async getCommentsForSectionId(sys_id_section: string): Promise<NotifyComment[]> {
    // console.log("getCommentsForSectionId");
    const commentResponse: any = await http.get(`api/v1/section/${sys_id_section}/comment`);
    // console.log(commentResponse.data);
    return <NotifyComment[]>commentResponse.data;
  }

  async setCommentForNoteId(sys_id_note: String, comment: NotifyComment): Promise<NotifyComment[]> {
    // console.log("setCommentForNoteId");
    const commentResponse: any = await http.post(`api/v1/note/${sys_id_note}/comment`, comment);
    // console.log(commentResponse.data);
    return <NotifyComment[]>commentResponse.data;
  }

  async deleteCommentForNoteId(sys_id_note: string, sys_id_comment: string): Promise<NotifyComment[]> {
    // console.log("deleteCommentForNoteId");
    const commentResponse: any = await http.delete(`api/v1/note/${sys_id_note}/comment?sys_id=${sys_id_comment}`);
    // console.log(commentResponse.data);
    return await this.getCommentsForNoteId(sys_id_note);
  }

  async getCommentsForNoteId(sys_id_note: string): Promise<NotifyComment[]> {
    // console.log("getCommentsForNoteId");
    const commentResponse: any = await http.get(`api/v1/note/${sys_id_note}/comment`);
    // console.log(commentResponse.data);
    return <NotifyComment[]>commentResponse.data;
  }

  async registerTenant(userInfo: NotifyUserInfo): Promise<NotifyUserInfo> {
    // console.log("registerTenant");
    const userInfoResponse: any = await http.post(`auth/google/callback`, userInfo);
    // console.log(userInfoResponse.data);
    return <NotifyUserInfo>userInfoResponse.data;
  }

  async isAppAuthenticated(sys_provider: NotifyProvider): Promise<NotifyAuthenticationStatus> {
    // console.log("isAppAuthenticated");
    const authenticatedResponse: any = await http.get(`api/v1/integration/auth?sys_provider=${sys_provider}`);
    // console.log(authenticatedResponse.data);
    return <NotifyAuthenticationStatus>authenticatedResponse.data;
  }

  async getPreviewImageForLink(sys_id_external: string): Promise<Blob> {
    // console.log("getLinkPreview");
    const linkPreviewResponse: any = await http.get(`api/v1/link/preview?sys_id_external=${sys_id_external}`, { responseType: "arraybuffer" });
    // console.log(linkPreviewResponse.data);
    return new Blob([linkPreviewResponse.data], { type: 'image/png' });
  }

  async getPreviewRecordMetadataForLink(sys_id_external: string, sys_type: string): Promise<Blob> {
    // console.log("getPreviewMetadataForLink");
    const linkMetadataResponse: any = await http.get(`api/v1/link/metadata?sys_id_external=${sys_id_external}&sys_type=${sys_type}`);
    // console.log(getPreviewMetadataForLink.data);
    return linkMetadataResponse.data;
  }

  async getPreviewMessageMetadataForLink(sys_id_external: string, sys_type: string): Promise<Blob> {
    // console.log("getPreviewMessageMetadataForLink");
    const linkMetadataResponse: any = await http.get(`api/v1/link/message?sys_id_external=${sys_id_external}&sys_type=${sys_type}`);
    // console.log(getPreviewMetadataForLink.data);
    return linkMetadataResponse.data;
  }

  async getPreviewWebsiteForLink(sys_id_external: string, sys_type: string): Promise<Blob> {
    // console.log("getPreviewWebsiteForLink");
    const linkWebsiteResponse: any = await http.get(`api/v1/link/info?sys_id_external=${sys_id_external}&sys_type=${sys_type}`);
    // console.log(linkWebsiteResponse.data);
    return linkWebsiteResponse.data;
  }

  async getTranscriptionForRecording(sys_id_recording: string): Promise<NotifyTranscription> {
    // console.log("getTranscriptionForRecording");
    const transcriptionResponse: any = await http.get(`api/v1/transcription?sys_id=${sys_id_recording}`);
    // console.log(transcriptionResponse.data);
    return <NotifyTranscription>transcriptionResponse.data;
  }

  async getIntelligenceSummary(prompt: string, sys_id_note: string, filter: NotifyFilter, references_referenced: EllieNoteReference[]): Promise<string> {
    // console.log("getIntelligenceSummary");
    filter.sys_id_note = sys_id_note;
    filter.sys_id_references = [];
    if (references_referenced != null && references_referenced.length > 0) {
      for (let x = 0; x < references_referenced.length; x++) {
        filter.sys_id_references.push(references_referenced[x].sys_id_reference);
      }
    }

    const transcriptionSummaryResponse: any = await http.post(`api/v1/intelligence/summary`, {
      prompt: prompt,
      filter: filter,
    });
    // console.log(transcriptionSummaryResponse.data);
    return <string>transcriptionSummaryResponse.data;
  }

  async getIntelligenceEdits(prompt: string, content: string): Promise<string> {
    // console.log("getIntelligenceEdits");
    const transcriptionEditorResponse: any = await http.post(`api/v1/intelligence/editor`, {
      prompt: prompt,
      content: content,
    });
    // console.log(transcriptionEditorResponse.data);
    return <string>transcriptionEditorResponse.data;
  }

  async getSemanticSearchResults(search: string, references_referenced: string[], limit: number): Promise<NotifySearchResult[]> {
    // console.log("getSemanticSearchResults");
    // console.log(search);    
    const intelligenceSemanticSearchResponse: any = await http.post(`api/v1/intelligence/search`, {
      references_referenced: references_referenced,
      search: search,
      limit: limit,
    });
    // console.log(intelligenceSemanticSearchResponse.data);
    return <NotifySearchResult[]>intelligenceSemanticSearchResponse.data;
  }
}