import { Editor } from "../utils/editor";
import { Storage } from "../utils/storage";

export default class NotifySection {
  constructor({data, config, api, block}) {
    this.api = api;
    this.data = data;
    this.config = config;
    this.block = block;
  }

  save() {
    return {};
  }

  wrap(blockContent) {
    return blockContent;
  }

  render() {
    return {
      icon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="-4 -4 24 24"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/></svg>',
      label: 'Section Details',
      closeOnActivate: true,
      onActivate: () => {
        console.log(this.block.id);
        const sys_id_block = Editor.getInstance().getRegisteredBlockSysId(this.block.id, false);
        console.log(sys_id_block);

        if (sys_id_block != null && sys_id_block.trim().length > 0) {
          // Find the right section for our block
          const section = Editor.getInstance().getCachedSectionForBlockId(sys_id_block);

          // Get the section from storage rather than the editor - this is to ensure we have the references
          Storage.getInstance().getSection(section.sys_id).then((sectionResult) => {
            console.log(sectionResult);
            Editor.getInstance().showSectionDetails(sectionResult);
          });
        }
      }
    };
  }

  static get isTune() {
    return true;
  }
}