import React, { useEffect } from "react";

const Tags = (props) => {
  useEffect(() => {
    // if (props.note != null) {
    //   console.log(props.note.tags);
    // }
  }, [props.note]);

  return (
    <React.Fragment>
      {props.note != null &&
        <div dangerouslySetInnerHTML={{ __html: props.note.tags }} />
      }
    </React.Fragment>
  );
};

export default Tags;
