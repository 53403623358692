import { Parser } from "../utils/parser";

import Action from "../blocks/action";

import { EllieBlockStatus } from "../utils/storage";

import Header from '@editorjs/header';
import Paragraph from '@editorjs/paragraph';
import List from '@editorjs/list';
import Events from "../utils/events";

// Copied from ../index.js
Object.defineProperty(Header, 'sanitize',  {
  get() {
    return {
      text: sanitizeConfig,
    };
  }
});

const NewHeaderTool = (function (original) {
  function Header() {
    original.apply(this, arguments) 
    this.validate = function (savedData) {
      return true
    }
  }
  Header.prototype = original.prototype 
  Header.prototype.constructor = Header 
  return Header
})(Header);
// Copied from ../index.js

export default class Converter {
  constructor({data, config, api, block}) {
    this.api = api;
    this.data = data;
    this.config = config;
    this.block = block;

    if (this.data == null) {
      this.data = {};
    }

    this.wrapper = null;
  }

  save() {
    return {};
  }

  wrap(blockContent) {
    // console.log(blockContent);

    const myWrapper = document.createElement('div');
    
    // Set up the wrapper so we have something to list against as well as style
    // myWrapper.setAttribute('data-en-block-id', crypto.randomUUID());
    // myWrapper.setAttribute('data-en-block-type', block.name)
    // myWrapper.classList.add('en-block-id');

    // This gets the list of blocks in the document - so we can iterate over them in order
    // TODO This does assume that the blocks in the editor are added in order
    // const blocks = document.getElementsByClassName('en-block-id');

    // We could backwards through the blocks as this method is called each time a block is added
    // even when it's the initial load. This count does not include the latest block in the view
    // so is basically an enumeration of the previous blocks excluding the current
    const blocksCount = this.api.blocks.getBlocksCount();
    // console.log(`Block count: ${blocksCount}`);
    // console.log(`Block type: ${this.block.name}`);
    let isInAction = false;

    for (let x = 0; x < blocksCount; x++) {
      const currentBlock = this.api.blocks.getBlockByIndex(x);
      // console.log(currentBlock.holder);

      // We go through the list of blocks from the beginning to see if this block is part of an
      // existing action and therefore indented
      if (currentBlock.name == "action") {
        // console.log(`Block is an action`);
        isInAction = true;
        continue;
      }

      // If we have a blank paragraph, this block is no longer in any action
      if (currentBlock.name == "paragraph" && currentBlock.holder.children[0].children[0].children[0].innerHTML.trim().length == 0) {
        // console.log(`Block is an empty paragraph`);
        isInAction = false;
        continue;
      }

      // If we have a header, this block is no longer in any action
      if (currentBlock.name == "header") {
        // console.log(`Block is a header`);
        isInAction = false;
        continue;
      }
    }

    // Now get this block
    // console.log(blockContent);

    // If we have a blank paragraph, this block is no longer in any action
    if ((this.block.name == "paragraph" && blockContent.children[0].innerHTML.trim().length == 0) || this.block.name == "action") {
      // console.log(`This block is an empty paragraph`);
      isInAction = false;
    }

    // console.log(`Checking if block is in an action`);
    if (isInAction) {
      // console.log(`Block is in an action`);
      myWrapper.setAttribute("style", `margin-left: 40px; border-left: 4px solid lightgray; padding-left: ${this.block.name == "paragraph" ? "19" : "0"}px;`);
    } else {
      myWrapper.setAttribute("style", ``);
    }

    myWrapper.append(blockContent);

    // Check to see if we have a list - which we need to treat differently due to the complexity of the rendering
    // const listContent = blockContent.querySelectorAll(".cdx-nested-list");
    // if (listContent != null && listContent.length > 0) {
    //   console.log("This is a list");
    //   listContent.addEventListener('change', (event) => {
    //     console.log(event);
    //   });
    // } else {
      const editableContent = blockContent.querySelectorAll('[contenteditable=true]');
      if (editableContent != null && editableContent.length == 1) {
        let editableDiv = editableContent[0];

        // Enable clicks on references
        // Events.clickEnableMentionsOnParentElement(editableDiv);

        const localApi = this.api;

        editableDiv.addEventListener('keyup', (event) => {
          var code = (event.keyCode || event.which);

          // Keys to ignore - in this case the arrow keys
          if (code == 37 || code == 38 || code == 39 || code == 40) {
            return;
          }

          // Check to see if this content is associated with an action
          if (this.block.name != "action") {
            // console.log(localApi);
            let blockIndex = this.api.blocks.getCurrentBlockIndex();
            let isInAction = false;

            // Now we know where we are, we can scan backwards to see if we have an action
            for (let x = blockIndex; x >= 0; x--) {
              const currentBlock = this.api.blocks.getBlockByIndex(x);

              if (currentBlock.name == "action") {
                // console.log(`Block is an action`);
                isInAction = true;
                break;
              }

              // If we have a blank paragraph, this block is no longer in any action
              if (currentBlock.name == "paragraph" && currentBlock.holder.children[0].children[0].children[0].innerHTML.trim().length == 0) {
                // console.log(`Block is an empty paragraph`);
                break;
              }

              // If we have a header, this block is no longer in any action
              if (currentBlock.name == "header") {
                // console.log(`Block is a header`);
                isInAction = false;
                break;
              }
            }

            if (isInAction) {
              // console.log(`Block is in an action`);
              myWrapper.setAttribute("style", `margin-left: 40px; border-left: 4px solid lightgray; padding-left: ${this.block.name == "paragraph" ? "19" : "0"}px;`);
            }      
          }

          // Only monitor this if we haven't already converted to a task
          if (this.block.name != "list") {
            if (this.block.name == "paragraph") {
              if (event.key === " " && 
                  (editableDiv.innerHTML == "-&nbsp;" || editableDiv.innerHTML == "1.&nbsp;")) {
                let listType = "unordered"
                if (editableDiv.innerHTML == "1.&nbsp;") {
                  listType = "ordered";
                }

                this._replaceBlock(
                  "list", 
                  {
                    style: listType,
                    items: [
                      { content: "" },
                    ],
                    converter: {
                      active_type: this.data.active_type,
                    },
                  },
                  {
                    class: List,
                    inlineToolbar: true,
                    config: {
                      defaultStyle: listType
                    }
                  },
                );  
              }
            }

            const isAction = Parser.determineIfContentIsAction(editableDiv.innerHTML);
            // console.log(this.data);

            // Check to see if we have persons - but only do this automation if the user hasn't already
            // assigned this block manually
            if (isAction) {
              if (this.block.name != "action") {
                // We have persons in the content, we need to convert this block to a task
                this._replaceBlock(
                  "action", 
                  {
                    text: editableDiv.innerHTML,
                    status: EllieBlockStatus.NotStarted,
                  }, 
                  { 
                    class: Action, 
                    inlineToolbar: true,
                  },
                );
              }
            } else {
              // console.log(`Person: ${JSON.stringify(person)}`);
              // console.log(`This is the block name: ${JSON.stringify(this.block.name)}`);
              if (this.block.name == "action") {
                // Put the block back to being a paragraph
                this._replaceBlock(
                  "paragraph", 
                  {
                    text: editableDiv.innerHTML,
                  }, 
                  { 
                    class: Paragraph, 
                    inlineToolbar: true,
                  },
                );
              }
            }
          }

          // Tell the editor we should save
          // Editor.getInstance().save(this.api, document.getElementById("loading-indicator"));
        });
      }
    // }

    this.wrapper = myWrapper;

    return myWrapper;
  }

  render() {
    return document.createElement("span");
    /* 
    const holder = document.createElement('div');

    const icon = document.createElement('div');
    icon.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-card-text" viewBox="0 0 16 16"><path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/><path d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8zm0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"/></svg>';

    const label = document.createElement('div');

    if (this.block.name == "action") {
      label.innerText = 'Change to Paragraph';
    } else if (this.block.name == "paragraph") {
      label.innerText = 'Change to Action';
    } else {
      label.innerText = 'Not applicable';
    }

    holder.appendChild(icon);
    holder.appendChild(label);

    holder.addEventListener('click', () => {
      // console.log(this.data);
      const editableContent = this.wrapper.querySelectorAll('[contenteditable=true]');
      if (editableContent != null && editableContent.length > 0) {
        let editableDiv = editableContent[0];
        // console.log(editableDiv);

        if (this.block.name == "action") {
          this.data.active_type = "paragraph";
          this._replaceBlock(
            "paragraph", 
            {
              text: editableDiv.innerHTML,
              converter: {
                active_type: this.data.active_type,
              },
            }, 
            { 
              class: Paragraph, 
              inlineToolbar: true,
            },
          );
        } else if (this.block.name == "paragraph") {
          this.data.active_type = "action";
          this._replaceBlock(
            "action", 
            {
              text: editableDiv.innerHTML,
              status: EllieBlockStatus.NotStarted,
              converter: {
                active_type: this.data.active_type,
              },
            }, 
            { 
              class: Action, 
              inlineToolbar: true,
            },
          );
        }
      }
    });

    return holder; */
  }

  static get isTune() {
    return true;
  }

/*   this.controlsDiv = document.createElement('span');
  this.controlsDiv.classList.add("en-paragraph-controls");
  this.controlsDiv.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-stack" viewBox="0 0 16 16"><path d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.598.598 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.598.598 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.598.598 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535L7.733.063z"/><path d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.598.598 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.659z"/></svg>';
  
  if (!this.readOnly) {
    this.controlsDiv.addEventListener('click', this.onDrillClick);
  } */

  _replaceBlock(name, data, config) {
    // console.log(data);
    const blockIndex = this.api.blocks.getCurrentBlockIndex();
    this.api.blocks.delete(blockIndex);
    this.api.blocks.insert(name, data, config, blockIndex, true);
    this.api.caret.setToBlock(blockIndex, "end");
  }
}
