import React, { useEffect, useState } from "react";

import { Row, Col, Card } from "react-bootstrap";

import Block from "./Block";

import { Parser } from "../../../utils/parser";

import ViewSectionControls from "./ViewSectionControls";
import ViewSectionNoteTags from "./ViewSectionNoteTags";

const ViewTidbitPanel = (props) => {
  const [tidbit, setTidbit] = useState(null);
  const [unread, setUnread] = useState(false);

  const reactionCallback = (callback) => {
    console.log(callback);
    if (callback != null && callback.sys_id_sharing_event == null) {
      console.log(`Setting note sharing to be off`);
      setUnread(false);
    }
  }

  const getEditorBlocksForTidbit = async(tidbit) => {
    const editorBlocks = await Parser.convertToBlocksForEditor(tidbit.blocks);

    // Apply the editor blocks
    tidbit.editor_blocks = editorBlocks;

    // We have to do a little interface adjustment
    tidbit.ellie_blocks = tidbit.blocks;

    setTidbit(tidbit);
  }

  useEffect(() => {
    if (props.tidbit != null) {
      // console.log(props.tidbit);
      getEditorBlocksForTidbit(props.tidbit);

      if (props.tidbit.sys_id_sharing_event != null && props.tidbit.sys_id_sharing_event.trim().length > 0) {
        setUnread(true);
      }
    }
  }, [props.tidbit]);

  return (
    <React.Fragment>
      {tidbit != null && tidbit.editor_blocks != null && tidbit.editor_blocks.length > 0 &&
        <React.Fragment>
          <Card style={unread == true ? {borderColor: "#FFD57E"} : {borderColor: "#FFFFFF"}} className={`rounded shadow-sm ${props.removeTopBottom == true ? '' : 'mt-2'}`}>
            <Card.Body key={`section-action-${tidbit.sys_id}`}>
              {(tidbit.is_private == null || tidbit.is_private == false) &&
                <React.Fragment>
                  {tidbit.note_title != null && tidbit.note_title.trim().length > 0 && tidbit.note_tags != null && tidbit.note_tags.trim().length > 0 &&
                    <Row className="mb-1">
                      <Col>
                        {tidbit.note_title != null && tidbit.note_title.trim().length > 0 &&
                          <React.Fragment>
                            <span className="opacity-50 me-1" style={{fontSize: "0.8em"}}>Note:</span><span className="opacity-50 fw-semibold me-2" style={{fontSize: "0.8em"}} dangerouslySetInnerHTML={{ __html: tidbit.note_title }} />
                          </React.Fragment>
                        }
                        {tidbit.note_tags != null && tidbit.note_tags.trim().length > 0 &&
                          <ViewSectionNoteTags note_tags={tidbit.note_tags} note_references={tidbit.note_references} note_references_referenced={tidbit.note_references_referenced} />
                        }
                      </Col>
                    </Row>
                  }
                  {tidbit.editor_blocks.map((block, index) => (
                    <Row key={`view-tidbit-panel-rando-panel-row-${index}-${block.sys_id}`}>
                      <Col key={`view-tidbit-panel-rando-panel-row-col-${index}`}>
                        <Block key={`view-tidbit-panel-rando-panel-row-col-block-${index}`} block={block} isAction={false} />
                      </Col>
                    </Row>
                  ))}
                  <ViewSectionControls reactionCallback={reactionCallback} processChange={props.processChange} includePadding={true} section={tidbit} viewNote={props.viewNote} handleLoadPinnedSections={props.handleLoadPinnedSections} />
                </React.Fragment>
              }
              {tidbit.is_private == true &&
                <Alert variant="info">You don't have permission to view this block unfortunately <span className="fs-5">🥹</span></Alert>
              }
            </Card.Body>
          </Card>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default ViewTidbitPanel;