import React, { useEffect, useState } from "react";

import Gravitar from "../../../utils/gravitar";

const ReferenceCardDetails = (props) => {
  const [reference, setReference] = useState(null);
  const [link, setLink] = useState('');
  const [phone, setPhone] = useState('');
  const [description, setDescription] = useState('');
  const [twitter, setTwitter] = useState('');
  const [linkedIn, setLinkedIn] = useState('');
  const [enableLink, setEnableLink] = useState(false);
  const [enablePhone, setEnablePhone] = useState(false);
  const [enableTwitter, setEnableTwitter] = useState(false);
  const [enableLinkedIn, setEnableLinkedIn] = useState(false);

  const handleAdditionalCollapseState = (event) => {
    setTimeout(() => {
      const additionalCollapse = document.getElementById(`nfy-reference-details-collapse-${props.extension}`);
      if (additionalCollapse.classList.contains("show")) {
        window.localStorage.setItem('additional_collapse_state', 'show');
      } else {
        window.localStorage.setItem('additional_collapse_state', '');
      }
    }, 1000);    
  }

  const handleLinkChange = (event) => {
    setLink(event.target.value);

    if (Gravitar.isValidUrl(event.target.value) == true) {
      setEnableLink(true);
    } else {
      setEnableLink(false);
    }
  }

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);

    if (Gravitar.isValidPhone(event.target.value) == true) {
      setEnablePhone(true);
    } else {
      setEnablePhone(false);
    }
  }

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  }

  const handleTwitterChange = (event) => {
    setTwitter(event.target.value);

    if (Gravitar.isValidTwitterUrl(event.target.value) == true) {
      setEnableTwitter(true);
    } else {
      setEnableTwitter(false);
    }
  }

  const handleLinkedInChange = (event) => {
    setLinkedIn(event.target.value);

    if (Gravitar.isValidLinkedInUrl(event.target.value) == true) {
      setEnableLinkedIn(true);
    } else {
      setEnableLinkedIn(false);
    }
  }

  const changesExist = () => {
    if (reference.details != null) {
      if (reference.details.link != link) {
        return true;
      }

      if (reference.details.phone != phone) {
        return true;
      }

      if (reference.details.twitter != twitter) {
        return true;
      }

      if (reference.details.linked_in != linkedIn) {
        return true;
      }

      if (reference.details.description != description) {
        return true;
      }
    }

    if (reference.details == null &&
       ((link != null && link.trim().length > 0) || 
        (phone != null && phone.trim().length > 0) ||
        (twitter != null && twitter.trim().length > 0) ||
        (linkedIn != null && linkedIn.trim().length > 0) ||
        (description != null && description.trim().length > 0))) {
      return true;
    }

    return false;
  }
  
  const handleReferenceUpdate = async () => {
    if (changesExist() == true) {
      // Apply the fields to the reference
      reference.details = {
        link: link,
        phone: phone,
        twitter: twitter,
        linked_in: linkedIn,
        description: description,
      };

      // Save using the provided handler
      updateReference(await props.handleReferenceUpdate(reference));
    }
  }

  const updateReference = (reference) => {
    setReference(reference);

    if (reference != null && reference.details != null) {
      setLink(reference.details.link == null ? '' : reference.details.link);

      if (Gravitar.isValidUrl(reference.details.link) == true) {
        setEnableLink(true);
      } else {
        setEnableLink(false);
      }
  
      setPhone(reference.details.phone == null ? '' : reference.details.phone);

      if (Gravitar.isValidPhone(reference.details.phone) == true) {
        setEnablePhone(true);
      } else {
        setEnablePhone(false);
      }

      setDescription(reference.details.description == null ? '' : reference.details.description);

      setTwitter(reference.details.twitter == null ? '' : reference.details.twitter);

      if (Gravitar.isValidTwitterUrl(reference.details.twitter) == true) {
        setEnableTwitter(true);
      } else {
        setEnableTwitter(false);
      }

      setLinkedIn(reference.details.linked_in == null ? '' : reference.details.linked_in);    

      if (Gravitar.isValidLinkedInUrl(reference.details.linked_in) == true) {
        setEnableLinkedIn(true);
      } else {
        setEnableLinkedIn(false);
      }
    }
  }

  useEffect(() => {
    if (window.localStorage.getItem('additional_collapse_state') == null) {
      window.localStorage.setItem('additional_collapse_state', '');
    }

    updateReference(props.reference);
  }, [props.reference]);

  return (
    <React.Fragment>
      {reference != null &&
        <React.Fragment>
          <a data-bs-toggle="collapse" onClick={handleAdditionalCollapseState} style={{textDecoration: "none"}} className="text-reset nfy-reference-details-collapse" href={`#nfy-reference-details-collapse-${props.extension}`} role="button" aria-expanded={window.localStorage.getItem('additional_collapse_state') == "show" ? "true" : "false"} aria-controls={`nfy-reference-details-collapse-${props.extension}`}>
            <svg xmlns="http://www.w3.org/2000/svg" style={{marginBottom: "3px"}} width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill me-1 text-muted nfy-collapse-toggle" viewBox="0 0 16 16">
              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
            </svg>
            <span className="text-muted">Additional Details</span>
          </a>
          <div className={`collapse ${window.localStorage.getItem('additional_collapse_state')} pt-2`} id={`nfy-reference-details-collapse-${props.extension}`}>
            <div className="input-group mb-3">
              <input type="text" className="form-control" placeholder="Link" value={link} onBlur={handleReferenceUpdate} onChange={handleLinkChange} />
              <a href={link} target="_blank" className={`btn btn-outline-secondary ${enableLink == false ? 'disabled' : ''}`} type="button"><i className="bi bi-box-arrow-up-right"></i></a>
            </div>
            <div className="input-group mb-3">
              <input type="text" className="form-control" placeholder="Phone" value={phone} onBlur={handleReferenceUpdate} onChange={handlePhoneChange} />
              <a href={`tel:${phone}`} className={`btn btn-outline-secondary ${enablePhone == false ? 'disabled' : ''}`} type="button"><i className="bi bi-telephone-fill"></i></a>
            </div>
            <div className="input-group mb-3">
              <input type="text" className="form-control" placeholder="Twitter" value={twitter} onBlur={handleReferenceUpdate} onChange={handleTwitterChange} />
              <a href={twitter} target="_blank" className={`btn btn-outline-secondary ${enableTwitter == false ? 'disabled' : ''}`} type="button"><i className="bi bi-twitter"></i></a>
            </div>
            <div className="input-group mb-3">
              <input type="text" className="form-control" placeholder="LinkedIn" value={linkedIn} onBlur={handleReferenceUpdate} onChange={handleLinkedInChange} />
              <a href={linkedIn} target="_blank" className={`btn btn-outline-secondary ${enableLinkedIn == false ? 'disabled' : ''}`} type="button"><i className="bi bi-linkedin"></i></a>
            </div>
            <div className="mb-3">
              <textarea rows="4" className="form-control" placeholder="Description" value={description} onBlur={handleReferenceUpdate} onChange={handleDescriptionChange} />
            </div>
          </div>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default ReferenceCardDetails;
