import axios from "axios";

// TODO: The problem with this approach is that this file is "printed" when the app loads, so any changes to local storage
// are only applied with a hard refresh on the browser
export default axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "Access-Token": window.localStorage.getItem('token') != null ? window.localStorage.getItem('token').toString() : '',
    "EllieNotes-Account": window.localStorage.getItem('account') != null ? window.localStorage.getItem('account').toString() : '',
    "nfy-auth-token": window.localStorage.getItem('auth_token') != null ? window.localStorage.getItem('auth_token').toString() : '',
  }
});
