import axios from "axios";

import { Storage } from "../utils/storage";

import { AudioRecorder } from "./audio-recorder";

import Paragraph from '@editorjs/paragraph';
import Action from "./action";

class Recorder {
  constructor({data, config, api, readOnly, block}) {
    this.data = data;
    this.config = config;
    this.api = api;
    this.readOnly = readOnly;
    this.block = block;

    // Get the CSS from the editor to use for tools etc
    this.css = {
      block: this.api.styles.block,
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,
      wrapper: 'ce-paragraph',
    };

    this.onKeyUp = this.onKeyUp.bind(this);
    this.setTranscription = this.setTranscription.bind(this);
    this.setTranscriptionAsIdeas = this.setTranscriptionAsIdeas.bind(this);
    this.setTranscriptionAsActions = this.setTranscriptionAsActions.bind(this);
    this.doTranscription = this.doTranscription.bind(this);
    this.addToNote = this.addToNote.bind(this);
    this.expandContent = this.expandContent.bind(this);

    if (this.data != null && this.data.sys_id_recording != null && this.data.sys_id_recording.trim().length > 0) {
      this.readOnly = true;
    }

    this.audioRecorder = new AudioRecorder(this, this._processRecording, readOnly);

    // Initialize the data object if we don't have one
    if (this.data == null) {
      this.data = {};
    } else {
      // console.log(this.data);
      this.audioRecorder.openFile(this.data.sys_id_recording);
    }
  }

  onKeyUp(e) {
    if (e.code !== 'Backspace' && e.code !== 'Delete') {
      return;
    }
  }

  static get isReadOnlySupported() {
    return true;
  }

  render() {
    this.recording = document.createElement('div');

    this.expand = document.createElement('button');
    this.expand.setAttribute('type', 'button');
    this.expand.setAttribute('style', '--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;');
    this.expand.classList.add('btn');
    this.expand.classList.add('btn-outline-secondary');
    this.expand.innerHTML = 'Expand';

    if (this.data != null && this.data.transcription != null && this.data.transcription.length > 0) {
    } else {
      this.expand.classList.add('d-none');
    }

    this.transcriptionStatus = document.createElement('div');
    this.transcriptionStatus.classList.add('mt-2');

    if (this.data != null && this.data.transcription != null && this.data.transcription.length > 0) {
      this.transcriptionStatus.innerHTML = "<b>Transcription</b>";
    } else {
      this.transcriptionStatus.classList.add('d-none');
    }

    this.transcription = document.createElement('div');
    this.transcription.classList.add('mt-1');
    this.transcription.contentEditable = true;

    if (this.data != null && this.data.transcription != null && this.data.transcription.length > 0) {
      this.transcription.innerHTML = this.data.transcription;
    } else {
      this.transcription.classList.add('d-none');
    }

    // this.transcriptionControlsIdeas = document.createElement('button');
    // this.transcriptionControlsIdeas.setAttribute('type', 'button');
    // this.transcriptionControlsIdeas.classList.add('btn');
    // this.transcriptionControlsIdeas.classList.add('btn-sm');
    // this.transcriptionControlsIdeas.classList.add('me-2');
    // this.transcriptionControlsIdeas.innerHTML = "Ideas and Thoughts";

    this.transcriptionControlsActions = document.createElement('button');
    this.transcriptionControlsActions.setAttribute('type', 'button');
    this.transcriptionControlsActions.classList.add('btn');
    this.transcriptionControlsActions.classList.add('btn-sm');
    this.transcriptionControlsActions.classList.add('me-2');
    this.transcriptionControlsActions.innerHTML = "Actions and Followups";

    if (this.data != null && this.data.intelligence != null && this.data.intelligence.length > 0) {
      // this.transcriptionControlsIdeas.classList.add('btn-outline-secondary');
      this.transcriptionControlsActions.classList.add('btn-outline-secondary');
    } else {
      // this.transcriptionControlsIdeas.classList.add('btn-primary');
      this.transcriptionControlsActions.classList.add('btn-primary');
    }

    this.transcriptionControls = document.createElement('div');
    this.transcriptionControls.classList.add('mt-3');
    // this.transcriptionControls.appendChild(this.transcriptionControlsIdeas);
    this.transcriptionControls.appendChild(this.transcriptionControlsActions);

    if (this.data != null && this.data.transcription != null && this.data.transcription.length > 0) {
    } else {
      this.transcriptionControls.classList.add('d-none');
    }

    this.intelligenceStatus = document.createElement('div');
    this.intelligenceStatus.classList.add('mt-3');

    if (this.data != null && this.data.intelligence != null && this.data.intelligence.length > 0) {
      this.intelligenceStatus.innerHTML = "<b>Intelligent Transcription</b>";
    } else {
      this.intelligenceStatus.classList.add('d-none');
    }

    this.intelligence = document.createElement('div');
    this.intelligence.classList.add('mt-1');

    if (this.readOnly) {
      this.intelligence.classList.add('mb-3');
    }

    if (this.data != null && this.data.intelligence != null && this.data.intelligence.length > 0) {
      this.intelligence.innerHTML = this.data.intelligence;
    } else {
      this.intelligence.classList.add('d-none');
    }

    this.intelligenceControlsAddToNote = document.createElement('button');
    this.intelligenceControlsAddToNote.setAttribute('type', 'button');
    this.intelligenceControlsAddToNote.classList.add('btn');
    this.intelligenceControlsAddToNote.classList.add('btn-sm');
    this.intelligenceControlsAddToNote.classList.add('me-2');
    this.intelligenceControlsAddToNote.innerHTML = "Add to Note";

    if (this.data != null && this.data.intelligence != null && this.data.intelligence.length > 0) {
      this.intelligenceControlsAddToNote.classList.add('btn-outline-secondary');
    } else {
      this.intelligenceControlsAddToNote.classList.add('btn-primary');
    }

    this.intelligenceControls = document.createElement('div');
    this.intelligenceControls.classList.add('mt-3');
    this.intelligenceControls.appendChild(this.intelligenceControlsAddToNote);

    if (this.data != null && this.data.intelligence != null && this.data.intelligence.length > 0) {
    } else {
      this.intelligenceControls.classList.add('d-none');
    }

    this.transcription.addEventListener('keyup', this.onKeyUp);
    this.intelligence.addEventListener('keyup', this.onKeyUp);

    // this.transcriptionControlsIdeas.addEventListener('click', this.setTranscriptionAsIdeas);
    this.transcriptionControlsActions.addEventListener('click', this.setTranscriptionAsActions);
    this.intelligenceControlsAddToNote.addEventListener('click', this.addToNote);
    this.expand.addEventListener('click', this.expandContent);

    this.collapse = document.createElement('div');
    this.collapse.classList.add('collapse');
    this.collapse.appendChild(this.transcriptionStatus);
    this.collapse.appendChild(this.transcription);

    if (!this.readOnly) {
      this.collapse.appendChild(this.transcriptionControls);
    }

    this.collapse.appendChild(this.intelligenceStatus);
    this.collapse.appendChild(this.intelligence);

    if (!this.readOnly) {
      this.collapse.appendChild(this.intelligenceControls);
    }

    if (this.data != null && this.data.transcription != null && this.data.transcription.length > 0) {
    } else {
      this.collapse.classList.add('show');
    }

    this.recording.appendChild(this.audioRecorder);
    this.recording.appendChild(this.expand);
    this.recording.appendChild(this.collapse);

    return this.recording;
  }
  
  validate(savedData) {
    if (this.sys_id_recording == null) {
      return false;
    }
    return true;
  }
 
  save(blockElement) {
    return {
      sys_id_recording: this.sys_id_recording,
      transcription: this.transcription.innerHTML,
      intelligence: this.intelligence.innerHTML,
    };  
  }
   
  static get isReadOnlySupported() {
    return true;
  }

  static get toolbox() {
    return {
      icon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-record-circle" viewBox="-2 -2 20 20"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/></svg>',
      title: 'Recording'
    };
  }

  async _processRecording(context, file, mimeType) {
    const tempFileName = `${crypto.randomUUID()}.${mimeType}`;

    let formData = new FormData();
    formData.append('audio', file, tempFileName);

    try {
      // console.log(`Saving audio recording to backend`);
      const customHttp = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Token": window.localStorage.getItem('token') != null ? window.localStorage.getItem('token').toString() : '',
          "EllieNotes-Account": window.localStorage.getItem('account') != null ? window.localStorage.getItem('account').toString() : '',
          "nfy-auth-token": window.localStorage.getItem('auth_token') != null ? window.localStorage.getItem('auth_token').toString() : '',
        }
      });
      const response = await customHttp.post('recording', formData);

      // console.log(response.data.data);
      // Now poll to get the transcription so we can link to that also
      context.getTranscription(response.data.data.sys_id_recording);
      context.sys_id_recording = response.data.data.sys_id_recording;
    } catch (error) {
      // TODO - we should have a fallback here - place the audio recording into storage and give the user a prompt to retry
      console.log(error);
    }
  }

  getTranscription(sys_id_recording) {
    this.transcriptionStatus.classList.remove("d-none");
    this.transcriptionStatus.innerHTML = "<b>Transcribing...</b>";

    var transcriptionSetFunction = this.setTranscription;

    var refreshId = setInterval(function() {
      // console.log(`Checking for transcription complete for: ${sys_id_recording}`);
      Storage.getInstance().getTranscriptionForRecording(sys_id_recording).then((transcriptionResponse) => {
        // console.log(transcriptionResponse);
        if (transcriptionResponse.completed == true) {
          // console.log(`Clearing interval for: ${refreshId}`);
          clearInterval(refreshId);

          // Enable the controls for what type of transcription
          transcriptionSetFunction(transcriptionResponse.transcription);
        }
      });
    }, 5000);
  }

  setTranscription(transcription) {
    // console.log(`Setting transcription: ${transcription}`);
    this.transcription.classList.remove("d-none");
    this.transcription.innerHTML = transcription;

    this.transcriptionControls.classList.remove("d-none");

    this.transcriptionStatus.innerHTML = "<b>Transcription</b>";
  }

  setTranscriptionAsIdeas() {
    this.transcriptionControlsActions.classList.remove("btn-primary");
    this.transcriptionControlsActions.classList.add("btn-outline-secondary");

    // this.transcriptionControlsIdeas.classList.remove("btn-primary");
    // this.transcriptionControlsIdeas.classList.add("btn-outline-secondary");

    const prompt = `Please edit the transcription as follows: correct spelling and grammar, bring ideas together and summarize, use sentences and paragraphs.`;
    this.doTranscription(prompt, this.transcription.innerHTML);
  }

  setTranscriptionAsActions() {
    this.transcriptionControlsActions.classList.remove("btn-primary");
    this.transcriptionControlsActions.classList.add("btn-outline-secondary");

    // this.transcriptionControlsIdeas.classList.remove("btn-primary");
    // this.transcriptionControlsIdeas.classList.add("btn-outline-secondary");

    const prompt = `Please edit the transcription as follows: correct spelling and grammar, bring ideas together and summarize, use sentences and paragraphs, and create a section called \"Tasks\" with a bulleted list of any ideas that sound like specific tasks.`;
    this.doTranscription(prompt, this.transcription.innerHTML);
  }

  doTranscription(prompt, content) {
    this.intelligenceStatus.classList.remove("d-none");
    this.intelligenceStatus.innerHTML = "<b>Applying Intelligence...</b>";
    this.intelligenceContent = "";

    // Send the latest edits up
    Storage.getInstance().getIntelligenceEdits(prompt, content).then((transcribeEditorResult) => {
      this.intelligenceStatus.innerHTML = "<b>Intelligent Transcription</b>";

      this.intelligenceControls.classList.remove("d-none");

      this.intelligence.classList.remove("d-none");
      this.intelligence.innerHTML = transcribeEditorResult.content;
      this.intelligenceContent = transcribeEditorResult.content;
    });
  }

  expandContent() {
    if (this.expand.className.indexOf("d-none") >= 0) {
      // This is the situation initially
      this.collapse.classList.add("show");
      this.expand.innerHTML = "Collapse";
    } else if (this.collapse.className.indexOf("show") >= 0) {
      this.collapse.classList.remove("show");
      this.expand.innerHTML = "Expand";
    } else {
      this.collapse.classList.add("show");
      this.expand.innerHTML = "Collapse";
    }
  }

  addToNote() {
    const intelligenceFragments = this.intelligenceContent.split("<br/>");
    // console.log(intelligenceFragments);

    let blockIndex = this.api.blocks.getCurrentBlockIndex() + 1;

    if (intelligenceFragments != null && intelligenceFragments.length > 0) {
      for (let x = 0; x < intelligenceFragments.length; x++) {
        // console.log(intelligenceFragments[x]);
        if (intelligenceFragments[x].indexOf("<span id=") == 0) {
          this.api.blocks.insert(
            "action", 
            {
              text: intelligenceFragments[x],
            }, 
            {
              class: Action, 
              inlineToolbar: true,
            },
            blockIndex, 
            true
          );
        } else {
          this.api.blocks.insert(
            "paragraph", 
            {
              text: intelligenceFragments[x],
            }, 
            {
              class: Paragraph, 
              inlineToolbar: true,
            },
            blockIndex, 
            true
          );
        }
        
        blockIndex++;
      }
    }

    this.intelligenceControlsAddToNote.classList.remove('btn-primary');
    this.intelligenceControlsAddToNote.classList.add('btn-outline-secondary');

    this.collapse.classList.remove("show");
    this.expand.classList.remove("d-none");
  }
}

export default Recorder;
