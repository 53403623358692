import React, { useEffect, useState, useRef } from "react";

import * as THREE from 'three';

// import { CSS2DRenderer } from '../../../three/CSS2DRenderer';
import { UnrealBloomPass } from '../../../three/UnrealBloomPass.js';
import { ForceGraph3D } from 'react-force-graph';

import { Storage } from "../../../utils/storage";
import { Editor } from "../../../utils/editor";

// const extraRenderers = [new CSS2DRenderer()];

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const ViewNetworkPanel = (props) => {
  const [graph, setGraph] = useState(null);
  const [effectsApplied, setEffectsApplied] = useState(false);
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  
  const fgRef = useRef();
  
  const loadGraphData = async(references_referenced) => {
    let graphData = await Storage.getInstance().getGraph(null, {}, props.filterGraphDays, references_referenced);
    // console.log(JSON.stringify(graphData));
    setGraph(graphData);

    if (effectsApplied == false) {
      const bloomPass = new UnrealBloomPass();
      bloomPass.strength = 0.5;
      bloomPass.radius = 0.25;
      bloomPass.threshold = 0.1;
      fgRef.current.postProcessingComposer().addPass(bloomPass);
      setEffectsApplied(true);
    }
  }

  const handleNodeClick = (node, event) => {
    Editor.getInstance().executeSetNoteViewForReference(node.id);
  }

  /*   useInterval(() => {
    const newDataset = generateDataset()
    setDataset(newDataset)
  }, 2000)
 */

  // Handler to call on window resize
  function handleResize() {
    // Set window width/height to state
    setHeight(window.innerHeight);
    setWidth(window.innerWidth);
  }

  function getNodeOpacity(node) {
    return node.opacity;
  }

  function getNodeColor(node) {
    if (node.group == 1) {
      // This is a topic
      return "#2EB67D";
    } else {
      return "#36C5F0";
    }
  }

  function nodeThreeObject(node) {
    const sphereGeometry = new THREE.SphereGeometry(node.actions_incomplete == 0 ? 3 : (node.actions_incomplete * 3));
    const sphereMaterial = new THREE.MeshBasicMaterial({
      color: getNodeColor(node),
      transparent: true,
      opacity: getNodeOpacity(node),
    });
    const sphereMesh = new THREE.Mesh(sphereGeometry, sphereMaterial);
    return sphereMesh;
  }

  useEffect(() => {
    // console.log(props.viewIndex);
    window.addEventListener("resize", handleResize);

    if (props.viewIndex == 0) {
      loadGraphData();
    }
  }, [props.filterGraphDays, props.viewIndex]);

  return (
    <div style={props.viewIndex == 0 ? { position: 'fixed', top: 0, left: 0, right: 0, bottom: 0 } : { display: "none", position: 'fixed', top: 0, left: 0, right: 0, bottom: 0 }}>
      {graph != null &&
        <ForceGraph3D
          height={height}
          width={width}
          ref={fgRef}
          graphData={graph}
          backgroundColor="#000049"
          nodeRelSize={2}
          nodeVal={(node) => node.actions_incomplete == 0 ? 1 : (node.actions_incomplete * node.actions_incomplete)}
          onNodeClick={handleNodeClick}
          nodeAutoColorBy="group"
          nodeLabel="name"
          nodeThreeObject={nodeThreeObject}
        />
      }
    </div>
  );
};

export default ViewNetworkPanel;
