import React, { useEffect, useState } from "react";

import { Storage } from "../../../utils/storage";
import { Editor } from "../../../utils/editor";

import { Col, Row, Tab, Nav, OverlayTrigger, Tooltip, Card } from "react-bootstrap";

import ViewActionPanel from "./ViewActionPanel";
import ViewTidbitPanel from "./ViewTidbitPanel";
import ViewNotePanel from "./ViewNotePanel";
import ResourceCard from "./ResourceCard";
// import SurfaceGPTReferenceSections from "./SurfaceGPTReferenceSections";
// import { Parser } from "../../../utils/parser";
import { Formatter } from "../../../utils/formatter";

const SurfaceNotesView = (props) => {
  const actionsDefaultFilter = {
    tidbits: false,
    actions: true,
    notes: false,
    events: false,
    links: false,
    past: false,
    future: true,
    overdue: true,
    last_days: null,
    direction: "asc",
  };

  const tidbitsDefaultFilter = {
    tidbits: true,
    actions: false,
    notes: false,
    events: false,
    links: false,
    past: true,
    future: true,
    overdue: false,
    last_days: null,
    direction: "desc",
  };

  const notesDefaultFilter = {
    tidbits: false,
    actions: false,
    notes: true,
    events: false,
    links: false,
    past: true,
    future: true,
    overdue: false,
    last_days: null,
    direction: "desc",
  };

  const linksDefaultFilter = {
    tidbits: false,
    actions: false,
    notes: false,
    events: false,
    links: true,
    past: true,
    future: true,
    overdue: false,
    last_days: null,
    direction: "desc",
  };

  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("actions");
  const [referencesReferenced, setReferencesReferenced] = useState(null);

  const [notes, setNotes] = useState([]);
  const [hasNotes, setHasNotes] = useState(false);
  const [links, setLinks] = useState([]);
  const [hasLinks, setHasLinks] = useState(false);
  const [actions, setActions] = useState([]);
  const [hasActions, setHasActions] = useState(false);
  const [tidbits, setTidbits] = useState([]);
  const [hasTidbits, setHasTidbits] = useState(false);
  const [showView, setShowView] = useState(true);

  const isActionsTabDisabled = () => {
    if (hasActions == false && hasTidbits == false) {
      return true;
    }
    return false;
  }

  const handlTabChange = async(tabKey) => {
    // console.log(`tabKey: ${tabKey}`);
    setActiveTab(tabKey);

    if (tabKey == "actions") {
      await loadDataForTimeline(actionsDefaultFilter, referencesReferenced);
    } else if (tabKey == "notes") {
      await loadDataForTimeline(notesDefaultFilter, referencesReferenced);
    } else if (tabKey == "links") {
      await loadDataForTimeline(linksDefaultFilter, referencesReferenced);
    }
  }

  const loadTimeline = async(references_referenced) => {
    // console.log(`Loading timeline after debounce`);
    setLoading(true);
    
    let sys_id_note = Editor.getInstance().getNote() != null ? Editor.getInstance().getNote().sys_id : null;

    let actionsFilter = actionsDefaultFilter;
    actionsFilter.pinned = props.filterByPinned;
    actionsFilter.date = props.filterByDate;
    actionsFilter.unread = props.filterByUnread;

    let tidbitsFilter = tidbitsDefaultFilter;
    tidbitsFilter.pinned = props.filterByPinned;
    tidbitsFilter.date = props.filterByDate;
    tidbitsFilter.unread = props.filterByUnread;

    let notesFilter = notesDefaultFilter;
    notesFilter.pinned = props.filterByPinned;
    notesFilter.date = props.filterByDate;
    notesFilter.unread = props.filterByUnread;

    let linksFilter = linksDefaultFilter;
    linksFilter.pinned = props.filterByPinned;
    linksFilter.date = props.filterByDate;
    linksFilter.unread = props.filterByUnread;

    // console.log(references_referenced);
    // Get the counts to determine how the timeline should render - or if it should render
    let actionsCounter = await Storage.getInstance().getSectionsByReferencesReferencedForTimeline(sys_id_note, actionsFilter, true, references_referenced);
    actionsCounter.section_count = parseInt(actionsCounter.section_count);
    // console.log(actionsCounter);
    let tidbitsCounter = null;
    if (references_referenced != null && references_referenced.length > 0) {
      tidbitsCounter = await Storage.getInstance().getSectionsByReferencesReferencedForTimeline(sys_id_note, tidbitsFilter, true, references_referenced);
      tidbitsCounter.section_count = parseInt(tidbitsCounter.section_count);
    }
    // Get the counts to determine how the timeline should render - or if it should render
    let notesCounter = await Storage.getInstance().getNotesForTopicsOrPersons(sys_id_note, notesFilter, true, references_referenced);
    notesCounter.note_count = parseInt(notesCounter.note_count);
    // console.log(notesCounter);
    let linksCounter = await Storage.getInstance().getLinksForTopicsOrPersons(sys_id_note, linksFilter, true, references_referenced);
    linksCounter.link_count = parseInt(linksCounter.link_count);
    // console.log(linksCounter);

    // Make sure we activate the tabs in the right way
    if (actionsCounter.section_count > 0) {
      setHasActions(true);
    } else {
      setHasActions(false);
    }
    if (tidbitsCounter != null && tidbitsCounter.section_count > 0) {
      setHasTidbits(true);
    } else {
      setHasTidbits(false);
    }
    if (notesCounter.note_count > 0) {
      setHasNotes(true);
    } else {
      setHasNotes(false);
    }
    if (linksCounter.link_count > 0) {
      setHasLinks(true);
    } else {
      setHasLinks(false);
    }

    let filter = {};
    // Now figure out what view should be the default view
    if (actionsCounter.section_count > 0) {
      // console.log(`Timeline has actions`);
      filter = actionsDefaultFilter;
      setActiveTab("actions");
    } else if (tidbitsCounter != null && tidbitsCounter.section_count > 0) {
      filter = tidbitsDefaultFilter;
      setActiveTab("actions");
    } else if (notesCounter.note_count > 0) {
      // console.log(`Timeline has notes`);
      filter = notesDefaultFilter;
      setActiveTab("notes");
    } else if (linksCounter.link_count > 0) {
      // console.log(`Timeline has links`);
      filter = linksDefaultFilter;
      setActiveTab("links");
    }

    if (actionsCounter.section_count == 0 && 
        notesCounter.note_count == 0 &&
        linksCounter.link_count == 0 &&
        (tidbitsCounter == null || tidbitsCounter.section_count == 0)) {
      // We have nothing in the system - this is a new user with no data, so we should disable distracting UI
      setShowView(false);
    } else {
      setShowView(true);
      // Finally, load the data for the timeline
      await loadDataForTimeline(filter, references_referenced);
    }
  }

  const loadDataForTimeline = async(filter, references_referenced) => {
    // console.log(`Loading data for timeline`);
    if (loading == false) {
      setLoading(true);
    }

    // Set the filter by pinned situation
    filter.pinned = props.filterByPinned;
    filter.date = props.filterByDate;
    filter.unread = props.filterByUnread;

    let sys_id_note = Editor.getInstance().getNote() != null ? Editor.getInstance().getNote().sys_id : null;

    if (filter.actions == true) {
        // console.log(`Loading actions`);
        const actionsResponse = await Storage.getInstance().getSectionsByReferencesReferencedForTimeline(sys_id_note, actionsDefaultFilter, false, references_referenced);
        // console.log(actionsResponse);
        setActions(actionsResponse);
    } else {
      setActions([]);
    }

    let tidbitsResponse = [];

    // Only load the summary if we have some filter applied - otherwise it won't make any sense
    if (references_referenced != null && references_referenced.length > 0) {
      let tidbitsFilter = tidbitsDefaultFilter;
      tidbitsFilter.pinned = props.filterByPinned;
      tidbitsFilter.date = props.filterByDate;
      tidbitsFilter.unread = props.filterByUnread;

      // console.log(`Loading tidbits`);
      tidbitsResponse = await Storage.getInstance().getSectionsByReferencesReferencedForTimeline(sys_id_note, tidbitsFilter, false, references_referenced);
      // console.log(tidbitsResponse);
      setTidbits(tidbitsResponse);
    } else {
      setTidbits([]);
    }

    if (filter.notes == true) {
      // console.log(`Loading notes`);
      const notesResponse = await Storage.getInstance().getNotesForTopicsOrPersons(sys_id_note, filter, false, references_referenced);
      // console.log(notesResponse);
      setNotes(notesResponse);
    } else {
      setNotes([]);
    }
    
    if (filter.links == true) {
      // console.log(`Loading links`);
      const linksResponse = await Storage.getInstance().getLinksForTopicsOrPersons(sys_id_note, filter, false, references_referenced);
      // console.log(linksResponse);
      setLinks(linksResponse);
    } else {
      setLinks([]);
    }
    
    setLoading(false);
  }

  useEffect(() => {
    // console.log(`Loading standard useEffect`);
    // console.log(props.references_referenced);
    setReferencesReferenced(props.references_referenced);

    if (props.tenant == null) {
      setShowView(false);
    } else {
      setShowView(true);
      // Load the timeline
      loadTimeline(props.references_referenced);
    }
  }, [props.references_referenced, props.tenant, props.filterByPinned, props.filterByDate, props.filterByUnread]);

  return (
    <Row className="pt-5">
      <Col>
        {showView == true &&
          <Tab.Container
            id="nfy-surface-notes-view-tabs"
            activeKey={activeTab}
            onSelect={(tabKey) => handlTabChange(tabKey)}
            variant="pills"
          >
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey="actions" disabled={isActionsTabDisabled()}><i className="bi bi-lightning-fill me-2"></i>Actions</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="notes" disabled={!hasNotes}><i className="bi bi-journals me-2"></i>Notes</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="links" disabled={!hasLinks}><i className="bi bi-stack me-2"></i>Surface</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="actions">
                <Row>
                  {loading == false &&
                    <Col className="overflow-auto flex-grow-1">
                      {actions != null && actions.length > 0 && actions.map((action, index) => (
                        <ViewActionPanel key={`view-action-panel-rando-row-col-${index}`} processChange={props.processChange} action={action} viewNote={props.viewNote} handleLoadPinnedSections={props.handleLoadPinnedSections} />
                      ))}
                      {(actions == null || actions.length == 0) &&
                        <Row className="mt-3 ms-2 mb-3">
                          <Col>
                            <span className="text-muted">You're all caught up with your {props.filterByPinned == true ? (props.filterByUnread == true ? "pinned and unread " : "pinned ") : (props.filterByUnread == true ? "unread " : "")}actions{props.filterByDate != null && props.filterByDate.trim().length > 0 ? ` on ${Formatter.datesContent(props.calendarFilterByDate)}` : ""}! <span className="fs-5">🏖️</span></span>
                          </Col>
                        </Row>
                      }
                      {tidbits != null && tidbits.length > 0 && 
                        <React.Fragment>
                          <Row className="ms-1 me-1 border-end rounded border-bottom border-top border-start mt-5 mb-3 pt-2 pb-2 text-muted fw-bolder bg-light">
                            <Col>
                              <i className="bi bi-card-text me-1"></i>Related Blocks
                            </Col>
                          </Row>
                          {tidbits.map((tidbit, index) => (
                            <ViewTidbitPanel key={`view-tidbit-panel-rando-row-${index}`} processChange={props.processChange} tidbit={tidbit} viewNote={props.viewNote} handleLoadPinnedSections={props.handleLoadPinnedSections} />
                          ))}
                        </React.Fragment>
                      }
                    </Col>
                  }
                  {loading == true &&
                    <Col className="flex-grow-1 position-relative">
                      <div className="position-absolute top-50 start-50 translate-middle">
                        <div className="heartbeat-loader" style={{marginTop: "150px"}}>
                          Loading...
                        </div>
                      </div>
                    </Col>
                  }
                </Row>
              </Tab.Pane>
            </Tab.Content>
            <Tab.Content>
              <Tab.Pane eventKey="notes">
                <Row>
                  {loading == false &&
                    <Col className="overflow-auto flex-grow-1">
                      {notes != null && notes.length > 0 && notes.map((note, index) => (
                        <ViewNotePanel key={`view-note-panel-rando-row-${index}`} processChange={props.processChange} scrolling={false} handleLoadPinnedNotes={props.handleLoadPinnedNotes} note={note} loadNote={props.loadNote} />
                      ))}
                      {(notes == null || notes.length == 0) &&
                        <Row className="mt-3 ms-2 mb-3">
                          <Col>
                            <span className="text-muted">You don't have any {props.filterByPinned == true ? (props.filterByUnread == true ? "pinned and unread " : "pinned ") : (props.filterByUnread == true ? "unread " : "")}notes to view{props.filterByDate != null && props.filterByDate.trim().length > 0 ? ` for ${Formatter.datesContent(props.calendarFilterByDate)}` : ""}! <span className="fs-5">🏖️</span></span>
                          </Col>
                        </Row>
                      }
                    </Col>
                  }
                  {loading == true &&
                    <Col className="flex-grow-1 position-relative">
                      <div className="position-absolute top-50 start-50 translate-middle">
                        <div className="flower-loader" style={{marginTop: "150px"}}>
                          Loading...
                        </div>
                      </div>
                    </Col>
                  }
                </Row>
              </Tab.Pane>
            </Tab.Content>
            <Tab.Content>
              <Tab.Pane eventKey="links">
                <Row>
                  {loading == false &&
                    <Col className="overflow-auto flex-grow-1">
                      <Row>
                        {links != null && links.length > 0 && links.map((link, index) => (
                          <Col key={`resource-card-panel-rando-row-col-${index}`} lg={6}>
                            <ResourceCard key={`resource-card-panel-rando-row-col-resource-card-${index}`} resourceLink={link} />
                          </Col>
                        ))}
                      </Row>
                    </Col>
                  }
                  {loading == true &&
                    <Col className="flex-grow-1 position-relative">
                      <div className="position-absolute top-50 start-50 translate-middle">
                        <div className="circles-loader" style={{marginTop: "150px"}}>
                          Loading...
                        </div>
                      </div>
                    </Col>
                  }
                </Row>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        }
      </Col>
    </Row>
  );
};

export default SurfaceNotesView;
