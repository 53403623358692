import React, { useEffect, useState } from "react";

import { Row, Col, OverlayTrigger, Tooltip, Popover } from "react-bootstrap";

import Block from "./Block";
import { Editor } from "../../../utils/editor";
import { Formatter } from "../../../utils/formatter";
import { Storage } from "../../../utils/storage";

const SectionResultPanel = (props) => {
  const [sectionResult, setSectionResult] = useState(null);
  const [isAction, setIsAction] = useState(false);
  const [isShared, setIsShared] = useState(false);
  const [sizing, setSizing] = useState(0);
  const [sentiment, setSentiment] = useState(0);
  const [referencesForSharing, setReferencesForSharing] = useState([]);

  const handleSizing = (sizing) => {
    Storage.getInstance().saveSizingChange(sectionResult.sys_id, sizing).then(() => {
      console.log("Sizing changed");
      setSizing(sizing);
    });
  }

  const handleSentiment = (sentiment) => {
    Storage.getInstance().saveSentimentChange(sectionResult.sys_id, sentiment).then(() => {
      console.log("Sentiment changed");
      setSentiment(sentiment);
    });
  }

  const handleSharing = () => {
    Storage.getInstance().getSection(sectionResult.sys_id).then((sectionForSharing) => {
      const forSharing = [];      
      if (sectionForSharing.references != null && sectionForSharing.references.length > 0) {
        for (let x = 0; x < sectionForSharing.references.length; x++) {
          const reference = sectionForSharing.references[x];
    
          for (let y = 0; y < sectionForSharing.references_referenced.length; y++) {
            if (sectionForSharing.references_referenced[y].sys_id_reference == reference.sys_id) {
              reference.shared = sectionForSharing.references_referenced[y].shared;
              break;
            }
          }
          forSharing.push(reference);
        }
      }

      setReferencesForSharing(forSharing);
    });
  }

  const handleSharingChange = (reference, shared) => {
    const references_referenced = [];
    let sharedWithSomething = false;
    if (referencesForSharing != null && referencesForSharing.length > 0) {
      for (let x = 0; x < referencesForSharing.length; x++) {
        if (reference.sys_id == referencesForSharing[x].sys_id) {
          referencesForSharing[x].shared = shared;
        }

        if (referencesForSharing[x].shared == true) {
          sharedWithSomething = true;
        }
        
        references_referenced.push({
          sys_id_reference: referencesForSharing[x].sys_id,
          sys_type: referencesForSharing[x].sys_type,
          shared: referencesForSharing[x].shared,
        })
      }
    }

    Storage.getInstance().saveSectionSharingChange(sectionResult.sys_id, references_referenced).then(() => {
      console.log("Section sharing changed");

      // Not sure this is needed, but have updated the sharing settings for the references so the view needs to be updated
      setReferencesForSharing(referencesForSharing);
      setIsShared(sharedWithSomething);
    });
  }

  useEffect(() => {
    if (props.sectionResult != null && props.sectionResult.ellie_blocks != null && props.sectionResult.ellie_blocks.length > 0) {
      let tempIsAction = false;
      for (let x = 0; x < props.sectionResult.ellie_blocks.length; x++) {
        if (props.sectionResult.ellie_blocks[x].type == "action") {
          tempIsAction = true;
          break;
        }
      }

      let tempIsShared = false;
      if (props.sectionResult.references_referenced != null && props.sectionResult.references_referenced.length > 0) {
        for (let x = 0; x < props.sectionResult.references_referenced.length; x++) {
          if (props.sectionResult.references_referenced[x].shared == true) {
            tempIsShared = true;
            break;
          }
        }
      }

      setIsAction(tempIsAction);
      setIsShared(tempIsShared);
    }

    if (props.sectionResult != null) {
      setSizing(props.sectionResult.sizing);
      setSentiment(props.sectionResult.sentiment);
    }

    setSectionResult(props.sectionResult);
  }, [props.sectionResult]);

  return (
    <React.Fragment>
      {sectionResult != null &&
        <React.Fragment>
          <Row>
            <div className="col-11">
              {sectionResult.editor_blocks != null && sectionResult.editor_blocks.length > 0 && sectionResult.editor_blocks.map((block, index) => (
                <React.Fragment key={`section-result-panel-react-fragment-${index}`}>
                  {isAction == true &&
                    <div key={`section-result-panel-react-fragment-div-${index}`} style={{border: 0, outline: 0, width: "100%", display: `${index == 0 ? "inline-block" : "block"}`, backgroundColor: block.data.block_duplicate ? '#f3f4f7' : undefined }}>
                      <div key={`section-result-panel-react-fragment-div-div-${index}`} style={block.type != "action" ? {marginLeft: "0px", borderLeft: "4px solid lightgray", paddingLeft: (block.type == "paragraph" ? "60px" : "40px")} : {}}>
                        <Block key={`section-result-panel-react-fragment-div-div-block-${index}`} block={block} isAction={props.isAction} />
                      </div>
                    </div>
                  }
                  {isAction == false &&
                    <div key={`section-result-panel-react-fragment-false-${index}`} style={{border: 0, outline: 0, display: "inline-block"}}>
                      <Block key={`section-result-panel-react-fragment-false-block-${index}`} block={block} isAction={props.isAction} />
                    </div>
                  }
                </React.Fragment>
              ))}
            </div>
            {props.viewNote != null &&
              <div className="col-1">
                <Row>
                  <Col>
                    <div className="d-grid d-md-flex justify-content-md-end">
                      <OverlayTrigger placement="top" overlay={<Tooltip>View original note</Tooltip>}>
                        <button className="btn btn-sm btn-outline-secondary" onClick={() => props.viewNote(sectionResult)}><i className="bi bi-question-square"></i></button>
                      </OverlayTrigger>
                    </div>
                  </Col>
                </Row>
              </div>
            }
          </Row>
          <Row className="mt-3">
            <Col className="col">
              {sectionResult.tenant != null &&
                <div className="me-2" style={{display: "inline-block"}}>
                  <img style={{height: "30px", width: "30px"}} className="rounded-circle border border-1" src={sectionResult.tenant.picture}></img> <span className="text-muted fw-semibold">{sectionResult.tenant.given_name} {sectionResult.tenant.family_name}</span>
                </div>
              }
              {sectionResult.tenant == null && Editor.getInstance().getTenant() != null &&
                <div className="me-2" style={{display: "inline-block"}}>
                  <img style={{height: "30px", width: "30px"}} className="rounded-circle border border-1" src={Editor.getInstance().getTenant().picture}></img> <span className="text-muted fw-semibold">{Editor.getInstance().getTenant().given_name} {Editor.getInstance().getTenant().family_name}</span>
                </div>
              }
              <OverlayTrigger
                trigger="click"
                placement={"top"}
                overlay={
                  <Popover>
                    <Popover.Body>
                      {referencesForSharing != null && referencesForSharing.length > 0 &&
                        <React.Fragment>
                          <h6>Share this with:</h6>
                          {referencesForSharing.map((referenceForSharing, index) => (
                            <div key={`section-result-panel-reference-sharing-div-${index}`} className="form-check">
                              <input key={`section-result-panel-reference-sharing-div-input-${index}`} type="checkbox" onChange={() => handleSharingChange(referenceForSharing, !referenceForSharing.shared)} className="form-check-input" id={"sharing-" + sectionResult.sys_id + referenceForSharing.sys_id} defaultChecked={referenceForSharing.shared} />
                              <label key={`section-result-panel-reference-sharing-div-label-${index}`} className="form-check-label" forhtml={"sharing-" + sectionResult.sys_id + referenceForSharing.sys_id}>
                                {referenceForSharing.sys_type == "topic" ? "#" : "@"}{referenceForSharing.alias}
                              </label>
                            </div>
                          ))}
                        </React.Fragment>
                      }
                    </Popover.Body>
                  </Popover>
                }
              >
                <span className={isShared == true ? "me-5 badge bg-success" : "me-5 badge text-bg-light text-muted"} style={{cursor: "pointer"}} onClick={handleSharing}>{isShared == false ? "Not " : ""}Shared</span>
              </OverlayTrigger>
              <div className="me-3" style={{display: "inline-block"}}>
                <span className="text-muted">Sizing: </span>
                <i className="ms-1 bi bi-circle-fill" onClick={() => {handleSizing(1)}} style={{color: sizing == 1 ? "#30B67D" : "lightgray", fontSize: "10px", cursor: "pointer"}}></i>
                <i className="ms-1 bi bi-circle-fill" onClick={() => {handleSizing(2)}} style={{color: sizing == 2 ? "#F2994B" : "lightgray", fontSize: "14px", cursor: "pointer"}}></i>
                <i className="ms-1 bi bi-circle-fill" onClick={() => {handleSizing(3)}} style={{color: sizing == 3 ? "#9B51E0" : "lightgray", fontSize: "18px", cursor: "pointer"}}></i>
              </div>
              <div className="me-3" style={{display: "inline-block"}}>
                {sectionResult.dates != null && sectionResult.dates.length > 0 &&
                  <React.Fragment>
                    {sectionResult.dates.map((date, index) => (
                      <React.Fragment key={`section-result-panel-reference-dates-react-fragment-${index}`}>
                        {index < 1 &&
                          <div key={`section-result-panel-reference-dates-react-fragment-div-${index}`} className="fs-6 badge text-bg-light">
                            <span key={`section-result-panel-reference-dates-react-fragment-div-span-${index}`} className="fw-normal text-muted">{Formatter.datesContent(date.date_start)}</span>
                            <span key={`section-result-panel-reference-dates-react-fragment-div-span-2-${index}`} className="ms-1 btn-close" style={{cursor: "pointer"}}></span>
                          </div>
                        }
                        {index == 1 &&
                          <span key={`section-result-panel-reference-dates-react-fragment-span-${index}`}>...</span>
                        }
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                }
                {sectionResult.dates == null || sectionResult.dates.length == 0 &&
                  <div className="fs-6 badge fw-normal text-bg-light text-muted">{Formatter.datesContent(sectionResult.sys_date_created)}</div>
                }
              </div>
            </Col>
{/*             <Col className="col-2">
              <Row>
                <Col>
                  <div className="d-grid d-md-flex justify-content-md-end">
                    <OverlayTrigger
                      trigger="click"
                      placement={"left"}
                      overlay={
                        <Popover>
                          <Popover.Body>
                            <span className="fs-5">👀 ✅<br/>
                            👍 👎<br/>
                            0️⃣ 1️⃣ 2️⃣ 3️⃣ 4️⃣ 5️⃣ 6️⃣ 7️⃣ 8️⃣ 9️⃣ 🔟</span>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <button key={crypto.randomUUID()} className="btn btn-sm btn-outline-secondary">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-emoji-smile" viewBox="0 0 16 16">
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                          <path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z"/>
                        </svg>
                      </button>
                    </OverlayTrigger>
                  </div>
                </Col>
              </Row>
            </Col> */}
          </Row>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default SectionResultPanel;
