import React, { useEffect, useState } from "react";

import { NotifyProvider, Storage } from "../../../utils/storage";

import { Card, Row, Col } from 'react-bootstrap';
import ResourceCardMetadata from "./ResourceCardMetadata";
import ResourceCardMessages from "./ResourceCardMessages";

const ResourceCard = (props) => {
  const [resource, setResource] = useState(null);
  const [loading, setLoading] = useState(false);

  const loadResourceLink = async(resourceLink) => {
    // console.log(resourceLink);
    if (resourceLink.sys_type.indexOf(NotifyProvider.Google) >= 0) {
      // console.log(`This is a google resource`);
      if (resourceLink.sys_type == "google.slides") {
        resourceLink.title = "Google Slide";
        resourceLink.logo = "./assets/google_slides_logo.png";
        resourceLink.backgroundColor = "#FFD04B";
        resourceLink.textColor = "text-white";
        if (resourceLink.full_url == null || resourceLink.full_url.trim().length == 0) {
          resourceLink.link = `https://docs.google.com/presentation/d/${resourceLink.sys_id_external}/edit`;
        } else {
          resourceLink.link = resourceLink.full_url;
        }
      } else if (resourceLink.sys_type == "google.docs") {
        resourceLink.title = "Google Doc";
        resourceLink.logo = "./assets/google_documents_logo.png";
        resourceLink.backgroundColor = "#4688F4";
        resourceLink.textColor = "text-white";
        if (resourceLink.full_url == null || resourceLink.full_url.trim().length == 0) {
          resourceLink.link = `https://docs.google.com/document/d/${resourceLink.sys_id_external}/edit`;
        } else {
          resourceLink.link = resourceLink.full_url;
        }
      } if (resourceLink.sys_type == "google.sheets") {
        resourceLink.title = "Google Sheet";
        resourceLink.logo = "./assets/google_sheets_logo.png";
        resourceLink.backgroundColor = "#1FA463";
        resourceLink.textColor = "text-white";
        if (resourceLink.full_url == null || resourceLink.full_url.trim().length == 0) {
          resourceLink.link = `https://docs.google.com/spreadsheets/d/${resourceLink.sys_id_external}/edit`;
        } else {
          resourceLink.link = resourceLink.full_url;
        }
      }
      
      // Get the image for the link
      // console.log(`Getting image for Google link`);
      const binaryAsBlob = await Storage.getInstance().getPreviewImageForLink(resourceLink.sys_id);

      // Append the image to the bottom of the card
      resourceLink.image = binaryAsBlob;
      // console.log(resourceLink.image);
    } else if (resourceLink.sys_type.indexOf(NotifyProvider.Salesforce) >= 0) {
      // console.log(`This is a salesforce resource`);
      if (resourceLink.sys_type.toLowerCase() == "salesforce.account") {
        resourceLink.title = "Salesforce Account";
        resourceLink.logo = "./assets/salesforce/account.svg";
        resourceLink.backgroundColor = "#1798c1";
        resourceLink.textColor = "text-white";
        resourceLink.link = resourceLink.full_url;
      } else if (resourceLink.sys_type.toLowerCase() == "salesforce.case") {
        resourceLink.title = "Salesforce Case";
        resourceLink.logo = "./assets/salesforce/case.svg";
        resourceLink.backgroundColor = "#1798c1";
        resourceLink.textColor = "text-white";
        resourceLink.link = resourceLink.full_url;
      } else if (resourceLink.sys_type.toLowerCase() == "salesforce.contact") {
        resourceLink.title = "Salesforce Contact";
        resourceLink.logo = "./assets/salesforce/contact.svg";
        resourceLink.backgroundColor = "#1798c1";
        resourceLink.textColor = "text-white";
        resourceLink.link = resourceLink.full_url;
      } else if (resourceLink.sys_type.toLowerCase() == "salesforce.lead") {
        resourceLink.title = "Salesforce Lead";
        resourceLink.logo = "./assets/salesforce/lead.svg";
        resourceLink.backgroundColor = "#1798c1";
        resourceLink.textColor = "text-white";
        resourceLink.link = resourceLink.full_url;
      } else if (resourceLink.sys_type.toLowerCase() == "salesforce.opportunity") {
        resourceLink.title = "Salesforce Opportunity";
        resourceLink.logo = "./assets/salesforce/opportunity.svg";
        resourceLink.backgroundColor = "#1798c1";
        resourceLink.textColor = "text-white";
        resourceLink.link = resourceLink.full_url;
      }

      // Account #5867e8
      // Case #ff5d2d
      // Contact #5867e8
      // Lead #ff5d2d
      // Opportunity #ff5d2d

      // Get the image for the link
      // console.log(`Getting metadata and data for Salesforce link`);
      const metadataResponse = await Storage.getInstance().getPreviewRecordMetadataForLink(resourceLink.sys_id_external, resourceLink.sys_type);
      // console.log(metadataResponse);

      // Append the details of the record to the bottom of the card
      resourceLink.metadata = metadataResponse;
    } else if (resourceLink.sys_type.indexOf(NotifyProvider.Slack) >= 0) {
      // console.log(`This is a slack resource`);
      if (resourceLink.sys_type.toLowerCase() == "slack.message") {
        resourceLink.title = "Slack Message";
        resourceLink.logo = "./assets/slack_logo_small.png";
        resourceLink.backgroundColor = "#4A154B";
        resourceLink.textColor = "text-white";
        resourceLink.link = resourceLink.full_url;

        // Get the image for the link
        // console.log(`Getting metadata and data for Slack link`);
        const metadataResponse = await Storage.getInstance().getPreviewMessageMetadataForLink(resourceLink.sys_id_external, resourceLink.sys_type);
        // console.log(metadataResponse);

        // Append the details of the record to the bottom of the card
        resourceLink.messages = metadataResponse;
      }
    } else {
      resourceLink.title = "Website";
      resourceLink.link = resourceLink.sys_id_external;

      // console.log(`Getting website info`);
      const websiteResponse = await Storage.getInstance().getPreviewWebsiteForLink(resourceLink.sys_id_external, resourceLink.sys_type);
      // console.log(websiteResponse);
      
      let metadata = null;
      // TODO - need to figure out why this is sometimes one and sometimes the other
      if (websiteResponse.metadata != null && websiteResponse.metadata.metadata != null) {
        metadata = websiteResponse.metadata.metadata;
      } else if (websiteResponse.metadata != null) {
        metadata = websiteResponse.metadata;
      }

      if (metadata != null) {
        // Append the website details to the bottom of the card
        if (metadata.favicons != null && metadata.favicons.length > 0) {
          resourceLink.logo = metadata.favicons[0];
        }
        if (metadata.siteName != null && metadata.siteName.trim().length > 0) {
          resourceLink.title = metadata.siteName;
        }
        if (metadata.images != null && metadata.images.length > 0) {
          resourceLink.metadata_image = metadata.images[0];
        }
        if (metadata.title != null && metadata.title.trim().length > 0) {
          resourceLink.metadata_title = metadata.title;
        }
        if (metadata.description != null && metadata.description.trim().length > 0) {
          resourceLink.metadata_description = metadata.description;
        }
      } else {
        console.log(`Metadata is null for ${resourceLink.sys_id_external}/${resourceLink.sys_type}`);
      }
    }

    setLoading(false);
    setResource(resourceLink);
  }

  useEffect(() => {
    // setResource(props.resourceLink);
    setLoading(true);
    loadResourceLink(props.resourceLink);
  }, [props.resourceLink]);

  return (
    <React.Fragment>
      {resource != null &&
        <Card className={`border-0 shadow-sm ${props.removeTopBottom == true ? '' : 'mt-3'}`}>
          <Card.Header style={{backgroundColor: resource.backgroundColor}}>
            <Row>
              <Col lg="9">
                <img src={resource.logo} className="me-1" style={{maxHeight: "30px"}}/>
                <span className={`fw-bold align-middle ${resource.textColor}`}>{resource.title}</span>
              </Col>
              <Col>
                <div className="d-grid d-md-flex justify-content-md-end">
                  <a href={resource.link} target="_blank" className="btn btn-sm btn-light"><i className="bi bi-box-arrow-up-right"></i></a>
                </div>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            {resource.image != null &&
              <img src={URL.createObjectURL(resource.image)} className="card-img-bottom" />
            }
            {resource.metadata != null &&
              <ResourceCardMetadata metadata={resource.metadata} />
            }
            {resource.messages != null &&
              <ResourceCardMessages messages={resource.messages} />
            }
            {resource.sys_type == NotifyProvider.Link &&
              <React.Fragment>
                {resource.metadata_title != null &&
                  <Row>
                    <Col>
                      <h6>{resource.metadata_title}</h6>
                    </Col>
                  </Row>
                }
                {resource.metadata_title != null &&
                  <Row>
                    <Col>
                      <h6>{resource.metadata_description}</h6>
                    </Col>
                  </Row>
                }
                <img src={resource.metadata_image} className="card-img-bottom" />
              </React.Fragment>
            }
          </Card.Body>
        </Card>
      }
      {loading == true &&
        <Card className="border-0 shadow-sm mt-3">
          <Card.Body>
            <Row>
              <Col className="flex-grow-1 position-relative" style={{height: "100px"}}>
                <div className="position-absolute top-50 start-50 translate-middle">
                  <div className="timer-loader">
                    Loading...
                  </div>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      }
    </React.Fragment>
  );
};

export default ResourceCard;
