import React, { useEffect, useState } from "react";

import { OverlayTrigger, Tooltip, Col, Row, Card } from "react-bootstrap";

import { Storage } from "../../../utils/storage";

import ViewActionPanel from "./ViewActionPanel";
import ViewTidbitPanel from "./ViewTidbitPanel";
import { Parser } from "../../../utils/parser";
import ResourceCard from "./ResourceCard";
import ViewNotePanel from "./ViewNotePanel";

const FlexpaneViewIntelligenceSearch = (props) => {
  const [search, setSearch] = useState('');
  const [searchHtml, setSearchHtml] = useState('');
  const [searchResults, setSearchResults] = useState(null);
  const [intelligencePrompt, setIntelligencePrompt] = useState('');
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [intelligenceStream, setIntelligenceStream] = useState('');
  const [references, setReferences] = useState(null);

  const handleIntelligencePromptChange = (event) => {
    setIntelligencePrompt(event.target.value);
  }

  const handleIntelligencePromptKeyUp = async(event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();

      await loadIntelligenceSearch(intelligencePrompt);
    }
  }

  const loadIntelligenceStructure = async(search) => {
    setLoadingSearch(true);

    // console.log(search.references);
    // Convert the search references to references referenced
    let references_referenced = [];
    if (search.references != null) {
      references_referenced = Array.from(search.references.keys());
    }
    // console.log(references_referenced);
    
    console.log({
      sys_id_references: references_referenced,
    });
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}api/v1/intelligence/structure`,
      {
        method: 'POST',
        body: JSON.stringify({
          filter: {
            sys_id_references: references_referenced,
          },
        }),
        headers: {
          "Content-Type": "application/json",
          "Access-Token": window.localStorage.getItem('token') != null ? window.localStorage.getItem('token').toString() : '',
          "EllieNotes-Account": window.localStorage.getItem('account') != null ? window.localStorage.getItem('account').toString() : '',
          "nfy-auth-token": window.localStorage.getItem('auth_token') != null ? window.localStorage.getItem('auth_token').toString() : '',
        },
      }
    )

    let prev = "";
    const reader = response.body
      .pipeThrough(new TextDecoderStream())
      .getReader();
    
    while (true) {
      const { value, done } = await reader.read();
      if (done) break;
      // console.log(`Previous`, prev);
      // console.log(`Value`, value);
      prev = prev + value;

      prev = await Parser.parsePlainReferencesToHTML(prev, references);
      setIntelligenceStream(prev);
    }
  }

  const loadIntelligenceSearch = async(search) => {
    setLoadingSearch(true);

    // console.log(search.references);
    // Convert the search references to references referenced
    let references_referenced = [];
    if (search.references != null) {
      references_referenced = Array.from(search.references.keys());
    }
    // console.log(references_referenced);
    
    const searchResultsResponse = await Storage.getInstance().getSemanticSearchResults(search.content, references_referenced, 20);
    const tempSearchResults = [];
    let tempReferences = [];

    console.log(searchResultsResponse.matches);
    if (searchResultsResponse != null && searchResultsResponse.matches != null && searchResultsResponse.matches.length > 0) {
      // console.log(`Has matches`);
      for (let x = 0; x < searchResultsResponse.matches.length; x++) {
        // console.log(searchResultsResponse.matches[x].metadata);
        if (searchResultsResponse.matches[x].metadata.sys_id_section_link != null && searchResultsResponse.matches[x].metadata.sys_id_section_link.trim().length > 0) {
          // This result is coming from a linked reference
          tempSearchResults.push({
            section: null,
            note: null,
            resource: {
              sys_id: searchResultsResponse.matches[x].metadata.sys_id_section_link,
              sys_id_external: searchResultsResponse.matches[x].metadata.sys_id_external,
              sys_type: searchResultsResponse.matches[x].metadata.sys_type,
              full_url: searchResultsResponse.matches[x].metadata.full_url,
            },
            score: searchResultsResponse.matches[x].score,
          })
        } else {
          let section = null;
          let note = null;

          if (searchResultsResponse.matches[x].metadata.sys_id_section != null && searchResultsResponse.matches[x].metadata.sys_id_section.trim().length > 0) {
            section = await Storage.getInstance().getSection(searchResultsResponse.matches[x].metadata.sys_id_section);
            if (section != null && section.references != null && section.references.length > 0) {
              tempReferences = tempReferences.concat(section.references);
            }
          } else if (searchResultsResponse.matches[x].metadata.sys_id_note != null && searchResultsResponse.matches[x].metadata.sys_id_note.trim().length > 0) {
            note = await Storage.getInstance().getNote(searchResultsResponse.matches[x].metadata.sys_id_note);
            if (note != null && note.references != null && note.references.length > 0) {
              tempReferences = tempReferences.concat(note.references);
            }
          }
          // console.log(section);

          // This result is coming from a section
          tempSearchResults.push({
            section: section,
            note: note,
            resource: null,
            score: searchResultsResponse.matches[x].score,
          });
        }
      }
    }

    // Sort the results putting the lowest score results at the top (closest)
    tempSearchResults.sort((a, b) => (a.score > b.score) ? 1 : -1);

    // Assign the populated search results
    // console.log(`Temporary references`, tempReferences);
    console.log(`Temporary search results: `, tempSearchResults);
    setReferences(tempReferences);
    setSearchResults(tempSearchResults);
    setLoadingSearch(false);

    console.log({
      search: Parser.removeHTMLTags(search.content),
      references_referenced: references_referenced,
      limit: 20,
    });
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}api/v1/intelligence/prompt`,
      {
        method: 'POST',
        body: JSON.stringify({
          search: Parser.removeHTMLTags(search.content),
          references_referenced: references_referenced,
          limit: 20,
        }),
        headers: {
          "Content-Type": "application/json",
          "Access-Token": window.localStorage.getItem('token') != null ? window.localStorage.getItem('token').toString() : '',
          "EllieNotes-Account": window.localStorage.getItem('account') != null ? window.localStorage.getItem('account').toString() : '',
          "nfy-auth-token": window.localStorage.getItem('auth_token') != null ? window.localStorage.getItem('auth_token').toString() : '',
        },
      }
    )

    let prev = "";
    const reader = response.body
      .pipeThrough(new TextDecoderStream())
      .getReader();
    
    while (true) {
      const { value, done } = await reader.read();
      if (done) break;
      // console.log(`Previous`, prev);
      // console.log(`Value`, value);
      prev = prev + value;

      prev = await Parser.parsePlainReferencesToHTML(prev, references);
      setIntelligenceStream(prev);
    }
  }

  useEffect(() => {
    if (props.search != null && props.search.content != null && props.search.content.trim().length > 0) {
      setSearch(props.search.content);
      setSearchHtml(props.searchHtml);
      setIntelligenceStream('');
      loadIntelligenceSearch(props.search);
      // loadIntelligenceStructure(props.search);
    }
  }, [props.search]);

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Row className="mt-3">
            <Col>
              <Card className="rounded border-0 shadow-sm">
                <Card.Body>
                  <Row className="pb-2">
                    <Col>
                      <div className="notify-inner-circles-loader">Intelligence</div> <span className="fw-bolder text-muted">{loadingSearch == true ? `${props.tenant != null ? props.tenant.given_name : ""}GPT thinking...` : "GPT Response"}</span>
                    </Col>
                    <Col>
                      <div className="d-grid d-md-flex justify-content-md-end">
                        <OverlayTrigger placement="top" overlay={<Tooltip>Close this GPT chat</Tooltip>}>
                          <button className="btn btn-sm btn-light text-muted" onClick={() => props.closeIntelligenceSearch()}><i className="bi bi-x-lg"></i></button>
                        </OverlayTrigger>
                      </div>
                    </Col>
                  </Row>
                  <Row className="d-flex flex-column" style={{height: "calc(100vh - 165px)"}}>
                    <Col className="overflow-auto flex-grow-1">
                      <Row>
                        <Col xs={1}>
                          &nbsp;
                        </Col>
                        <Col>
                          <Card className="rounded border-0" style={{backgroundColor: "#F4EDE4"}}>
                            <Card.Body>
                              <Row>
                                <Col className="text-end">
                                  <div className="d-inline p-3 text-end" dangerouslySetInnerHTML={{ __html: searchHtml }} />
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="m-2 p-3 text-start rounded bg-secondary">
                          <Card className="rounded border-0 shadow-sm mt-2 mb-1">
                            <Card.Body>
                              <Row>
                                <Col className="p-2">
                                  <div dangerouslySetInnerHTML={{ __html: intelligenceStream }} />
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                          {loadingSearch == true &&
                            <Row>
                              <Col className="flex-grow-1 position-relative">
                                <div className="position-absolute top-50 start-50 translate-middle">
                                  <div className="inner-circles-loader">
                                    Generating {props.tenant != null ? props.tenant.given_name : ""}GPT response...
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          }
                          {searchResults != null && searchResults.length > 0 &&
                            <React.Fragment>
                              <Row className="pt-2">
                                <Col>
                                  <a data-bs-toggle="collapse" style={{textDecoration: "none"}} className="nfy-search-collapse me-1" href="#nfy-search-collapse" role="button" aria-expanded="true" aria-controls="nfy-search-collapse">
                                    <svg xmlns="http://www.w3.org/2000/svg" style={{marginBottom: "3px", color: "#ffffff"}} width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill me-1 nfy-collapse-toggle" viewBox="0 0 16 16">
                                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                    </svg>
                                    <span className="text-white fw-bolder text-decoration-none">Search results:</span>
                                  </a>
                                </Col>
                              </Row>
                              <div className="collapse show" id="nfy-search-collapse">
                                <Row>
                                  <Col>
                                    {searchResults.map((searchResult, index) => (
                                      <React.Fragment key={`surface-gpt-reference-fragment-root-${index}`}>
                                        {searchResult.section != null && 
                                          <React.Fragment key={`surface-gpt-reference-fragment-child-1-${index}`}>
                                            {searchResult.section.sys_type == "action" &&
                                              <Row className="mb-2" key={`surface-gpt-reference-fragment-row-1-${index}`}>
                                                <Col key={`surface-gpt-reference-fragment-col-1-${index}`}>
                                                  <ViewActionPanel key={`surface-gpt-reference-fragment-action-${index}`} removeTopBottom={true} processChange={props.processChange} action={searchResult.section} viewNote={props.viewNote} handleLoadPinnedSections={props.handleLoadPinnedSections} />
                                                </Col>
                                              </Row>
                                            }
                                            {searchResult.section.sys_type == "tidbit" &&
                                              <Row className="mb-2" key={`surface-gpt-reference-fragment-row-2-${index}`}>
                                                <Col key={`surface-gpt-reference-fragment-child-2-${index}`}>
                                                  <ViewTidbitPanel key={`surface-gpt-reference-fragment-action-${index}`} removeTopBottom={true} processChange={props.processChange} tidbit={searchResult.section} viewNote={props.viewNote} handleLoadPinnedSections={props.handleLoadPinnedSections} />
                                                </Col>
                                              </Row>
                                            }
                                            {searchResult.section.links != null && searchResult.section.links.length > 0 &&
                                              <Row className="mb-2" key={`surface-gpt-reference-fragment-row-3-${index}`}>
                                                <Col key={`surface-gpt-reference-fragment-child-3-${index}`}>
                                                  {searchResult.section.links.map((link, linkIndex) => (
                                                    <ResourceCard key={`resource-card-panel-surface-gpt-row-col-resource-card-${linkIndex}`} removeTopBottom={true} resourceLink={link} />
                                                  ))}
                                                </Col>
                                              </Row>
                                            }
                                          </React.Fragment>
                                        }
                                        {searchResult.note != null &&
                                          <Row className="mb-2" key={`surface-gpt-reference-fragment-row-4-${index}`}>
                                            <Col key={`surface-gpt-reference-fragment-child-4-${index}`}>
                                              <ViewNotePanel key={`surface-gpt-reference-fragment-note-${index}`} removeTopBottom={true} processChange={props.processChange} scrolling={false} handleLoadPinnedNotes={props.handleLoadPinnedNotes} note={searchResult.note} loadNote={props.loadNote} removeMargin={true} />
                                            </Col>
                                          </Row>
                                        }
                                        {searchResult.resource != null &&
                                          <Row className="mb-2" key={`surface-gpt-reference-fragment-row-5-${index}`}>
                                            <Col key={`surface-gpt-reference-fragment-child-5-${index}`}>
                                              <ResourceCard key={`resource-card-panel-surface-gpt-row-col-resource-card-${index}`} removeTopBottom={true} resourceLink={searchResult.resource} />
                                            </Col>
                                          </Row>
                                        }
                                      </React.Fragment>
                                    ))}
                                  </Col>
                                </Row>
                              </div>
                            </React.Fragment>
                          }
                        </Col>
                        <Col xs={1}>
                          &nbsp;
                        </Col>
                      </Row>
{/*                       <Row>
                        <Col className="mt-2">
                          <input className="form-control" id="intelligence-chat-surface-prompt" disabled={true} placeholder={`Ask more questions related to this topic`} aria-label="Prompt" value={intelligencePrompt} onKeyUp={handleIntelligencePromptKeyUp} onChange={handleIntelligencePromptChange} />
                        </Col>
                      </Row> */}
                      {searchResults != null && searchResults.error_response != null && searchResults.error_response.trim().length > 0 &&
                        <Row>
                          <Col className="m-2 p-3 text-start rounded bg-secondary">
                            <span className="text-white">{searchResults.error_response}</span>
                          </Col>
                          <Col xs={1}>
                            &nbsp;
                          </Col>
                        </Row>
                      }
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default FlexpaneViewIntelligenceSearch;
