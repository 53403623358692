import React, { useEffect, useState } from "react";

import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ResourceCardMessagesMessage from "./ResourceCardMessagesMessage";

const ResourceCardMessages = (props) => {
  const [messages, setMessages] = useState(null);
  const [key, setKey] = useState(crypto.randomUUID());

  useEffect(() => {
    setMessages(props.messages);
    // console.log(props.messages);
  }, [props.messages]);

  return (
    <React.Fragment>
      {messages != null &&
        <React.Fragment>
          <ResourceCardMessagesMessage message={messages}/>
          {messages.messages != null && messages.messages.length > 0 &&
            <React.Fragment>
              <Row>
                <Col className="text-muted pt-2 pb-2">
                  <div style={{width: "100%", borderBottom: "1px solid #D3D3D3", lineHeight: "0.1em", margin: "10px 0 20px" }}>
                    <span style={{background: "#fff", fontSize: "0.8em"}} className="pe-2 text-muted">{messages.messages.length}{messages.messages.length > 1 ? " replies" : " reply"}</span>
                  </div>
                </Col>
              </Row>
              {messages.messages.map((message, index) => (
                <ResourceCardMessagesMessage key={`${key}-message-row-${index}`} message={message}/>
              ))}
            </React.Fragment>
          }
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default ResourceCardMessages;
