import { Md5 } from 'ts-md5';

export default class Gravitar {
  static getPersonImageUrl = (email: string) => {
    const notFoundAvatar = `${process.env.REACT_APP_FRONTEND_BASE_URL}assets/person.png`;

    if (email != null) {
      email = email.trim();
      email = email.toLowerCase();

      // Get a 200px sized profile picture, and if one doesn't exist, use the example.com image
      // return notFoundAvatar;
      return `https://www.gravatar.com/avatar/${Md5.hashStr(email)}?s=200&d=${encodeURIComponent(notFoundAvatar)}`;
    } else {
      return notFoundAvatar;
    }
  }

  static getTopicImageUrl = (website: string) => {
    website = website.trim();
    website = website.toLowerCase();

    // Get a 200px sized profile picture, and if one doesn't exist, use the example.com image
    return `/assets/topic.png`;
  }

  static isValidEmailAddress = (email: string) => {
    if (email == null || email.trim().length == 0) {
      return false;
    }

    const pattern = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

    if (pattern.test(email) == true) {
      return true;
    }
    return false;
  }

  static isValidTwitterUrl = (twitter: string) => {
    if (twitter == null || twitter.trim().length == 0) {
      return false;
    }

    const patternUrl = /^(http(s)?:\/\/)?([\w]+\.)?twitter.com\/[0-9a-zA-Z_]{1,20}/;

    if (patternUrl.test(twitter) == true) {
      return true;
    } else {
      const patternUsername = /[0-9a-zA-Z_]{1,20}/;

      if (patternUsername.test(twitter) == true) {
        return true;
      }      
    }
    return false;
  }

  static isValidLinkedInUrl = (linkedin: string) => {
    if (linkedin == null || linkedin.trim().length == 0) {
      return false;
    }

    const patternUrl = /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/;

    if (patternUrl.test(linkedin) == true) {
      return true;
    } else {
      const patternUsername = /[0-9a-zA-Z_]{1,20}/;

      if (patternUsername.test(linkedin) == true) {
        return true;
      }      
    }
    return false;
  }

  static isValidUrl = (link: string) => {
    if (link == null || link.trim().length == 0) {
      return false;
    }

    const pattern = /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i;

    if (pattern.test(link) == true) {
      return true;
    }
    return false;
  }

  static isValidPhone = (phone: string) => {
    if (phone == null || phone.trim().length == 0) {
      return false;
    }

    const pattern = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

    if (pattern.test(phone) == true) {
      return true;
    }
    return false;
  }
}
