import { Parser } from "./parser";
import { EllieBlock, EllieNote, EllieBlockStatus, Storage, EllieReference, EllieNoteReference, NotifySection, NotifyTenant, EllieSharingType, EllieBlockReference, ElliePeople, EllieTopic, NotifyProvider } from "./storage";

import { Modal, Popover } from 'bootstrap';

export interface NotifyFilter {
  tidbits: boolean;
  actions: boolean;
  notes: boolean;
  events: boolean;
  links: boolean;
  past: boolean;
  future: boolean;
  overdue: boolean;
  direction: string;
  sys_id_note?: string;
  sys_id_references?: string[];
  days?: number;
}

export class Editor {
  private static _editor: Editor;
  private _autoSave: boolean = true;
  private _showDrillView: boolean = false;
  private _timer: any;
  private _drillViewProxy: any;
  private _clearNoteProxy: any;
  private _setNoteViewForReference: any;
  private _viewReference: any;
  private _registry: Map<string, string>;
  private _blockCache: Map<string, EllieBlock>;
  private _sectionCache: NotifySection[];
  private _sectionCacheChanged: NotifySection[];
  private _references: EllieReference[];
  private _note!: EllieNote;
  private _filter!: NotifyFilter;
  private _title: string;
  private _tags: string;
  private _tagsCache: EllieNoteReference[];
  private _tagsCacheChanged: EllieNoteReference[];
  private _api: any;
  private _tenant: NotifyTenant;

  private _handleShowProviderAuthentication: any;
  private _handleShowReferenceCard: any;
  private _handleShowReferenceCards: any;
  private _handleShowSectionDetails: any;
  private _handleLoadPinnedTags: any;

  private _blockDiv: HTMLElement;
  private _sizingDiv: HTMLElement;
  private _sharingDiv: HTMLElement;
  private _sentimentDiv: HTMLElement;
  private _dateDiv: HTMLElement;
  private _modalTitle: HTMLElement;
  private _dateFieldLabel: HTMLElement;
  private _sharingFieldLabel: HTMLElement;
  private _authModalBody: HTMLElement;
  private _modal: Modal;
  private _authModal: Modal;
  private _activeReference: EllieReference;
  private _section: NotifySection;
  private _googleAccessToken: string;

  get editor(): any {
    return Editor._editor;
  }
  set editor(value: any) {
    Editor._editor = value;
  }

  get autoSave(): boolean {
    return this._autoSave;
  }
  set autoSave(value: boolean) {
    this._autoSave = value;
  }

  get showDrillView(): boolean {
    return this._showDrillView;
  }
  set showDrillView(value: boolean) {
    this._showDrillView = value;
  }

  get registry(): Map<string, string> {
    return this._registry;
  }
  set registry(value: Map<string, string>) {
    this._registry = value;
  }

  get blockCache(): Map<string, EllieBlock> {
    return this._blockCache;
  }
  set blockCache(value: Map<string, EllieBlock>) {
    this._blockCache = value;
  }

  get sectionCache(): NotifySection[] {
    return this._sectionCache;
  }
  set sectionCache(value: NotifySection[]) {
    this._sectionCache = value;
  }

  get tagsCache(): EllieNoteReference[] {
    return this._tagsCache;
  }
  set tagsCache(value: EllieNoteReference[]) {
    this._tagsCache = value;
  }

  get filter(): NotifyFilter {
    return this._filter;
  }
  set filter(value: NotifyFilter) {
    this._filter = value;
  }

  get references(): EllieReference[] {
    return this._references;
  }
  set references(value: EllieReference[]) {
    this._references = value;
  }

  get note(): EllieNote {
    return this._note;
  }
  set note(value: EllieNote) {
    this._note = value;
  }

  get timer(): any {
    return this._timer;
  }
  set timer(value: any) {
    this._timer = value;
  }

  get viewReference(): any {
    return this._viewReference;
  }
  set viewReference(value: any) {
    this._viewReference = value;
  }

  get setNoteViewForReference(): any {
    return this._setNoteViewForReference;
  }
  set setNoteViewForReference(value: any) {
    this._setNoteViewForReference = value;
  }

  get drillViewProxy(): any {
    return this._drillViewProxy;
  }
  set drillViewProxy(value: any) {
    this._drillViewProxy = value;
  }

  get clearNoteProxy(): any {
    return this._clearNoteProxy;
  }
  set clearNoteProxy(value: any) {
    this._clearNoteProxy = value;
  }

  constructor() {
    // Initialize the timer property
    this.timer = null;
    this.autoSave = true;
    this._registry = new Map();
    this._blockCache = new Map();
    this._sectionCache = [];
    this._sectionCacheChanged = [];
    this._tagsCache = [];
    this._tagsCacheChanged = [];
    this._tenant = null;
    
    this._title = "";
    this._tags = "";
  }

  static getInstance() {
    if (!Editor._editor) {
      Editor._editor = new Editor();

      setInterval(() => {
        if (Editor.getInstance().getNote() != null) {
          console.log(`Replicating data`);
          Storage.getInstance().replicate();
        }
      }, 60000);
    }
    return Editor._editor;
  }

  static get WAIT_INTERVAL() {
    // Wait three seconds before executing the save operation
    return 3000;
  }

  static get DEFAULT_DOCUMENT() {
    return {
      time: new Date().getTime(),
      blocks: [
        {
          type: "paragraph",
          data: {
            text: "",
          },
        },
      ],
      version: "2.25.0"
    };
  }

  static get SECTION_MODAL_ID() {
    return "notify_section_modal_global";
  }

  static get AUTH_MODAL_ID() {
    return "notify_auth_modal_global";
  }

  setAutoSave(autoSave: boolean) {
    if (!autoSave) {
      clearTimeout(this.timer);
    }
    this.autoSave = autoSave;
  }

  setFilter(filter: NotifyFilter) {
    this.filter = filter;
  }

  getFilter(): NotifyFilter {
    return this.filter;
  }

  setNote(note: EllieNote) {
    this.note = note;
  }

  getNote(): EllieNote {
    return this.note;
  }

  setNoteTitle(title: string) {
    this._title = title;
  }

  getNoteTitle(): string {
    return this._title;
  }

  setNoteTags(tags: string) {
    this._tags = tags;
  }

  getNoteTags(): string {
    return this._tags;
  }

  setTenant(tenant: NotifyTenant) {
    this._tenant = tenant;
  }

  getTenant(): NotifyTenant {
    return this._tenant;
  }

  setHandleLoadPinnedTags(handleLoadPinnedTags: any) {
    this._handleLoadPinnedTags = handleLoadPinnedTags;
  }

  setHandleShowProviderAuthentication(handleShowProviderAuthentication: any) {
    this._handleShowProviderAuthentication = handleShowProviderAuthentication;
  }

  setHandleShowReferenceCard(handleShowReferenceCard: any) {
    this._handleShowReferenceCard = handleShowReferenceCard;
  }

  setHandleShowReferenceCards(handleShowReferenceCards: any) {
    this._handleShowReferenceCards = handleShowReferenceCards;
  }

  setHandleShowSectionDetails(handleShowSectionDetails: any) {
    this._handleShowSectionDetails = handleShowSectionDetails;
  }

  setEditorAPI(api: any) {
    this._api = api;
  }

  resetTagsCache() {
    this._tagsCache = [];
    this._tagsCacheChanged = [];
  }

  getTagsIncludeSharing(): boolean {
    if (this.tagsCache != null && this.tagsCache.length > 0) {
      for (let x = 0; x < this.tagsCache.length; x++) {
        if (this.tagsCache[x].shared == true) {
          return true;
        }
      }
    }
    return false;
  }

  setTagsCacheChange(reference_referenced: EllieNoteReference) {
    if (this._tagsCacheChanged == null) {
      this._tagsCacheChanged = [];
    }

    let add = true;
    if (this._tagsCacheChanged.length > 0) {
      for (let x = 0; x < this._tagsCacheChanged.length; x++) {
        if (this._tagsCacheChanged[x].sys_id_reference == reference_referenced.sys_id_reference) {
          add = false;
          break;
        }
      }
    }

    if (add == true) {
      console.log(reference_referenced);
      this._tagsCacheChanged.push(reference_referenced);
    }
  }

  setTagsCache(references_referenced: EllieNoteReference[]): EllieNoteReference[] {
    if (this._tagsCacheChanged != null && this._tagsCacheChanged.length > 0) {
      // We have some changes to apply
      // console.log(`Has changes to tags from note edits`);
      for (let x = 0; x < this._tagsCacheChanged.length; x++) {
        if (references_referenced != null && references_referenced.length > 0) {
          for (let y = 0; y < references_referenced.length; y++) {
            // We basically reset everything except the references referenced
            if (this._tagsCacheChanged[x].sys_id_reference == references_referenced[y].sys_id_reference) {
              // We have a matching reference referenced, so apply the sharing
              // console.log(`Applying sharing changes: ${this._tagsCacheChanged[x].shared}`);
              references_referenced[y].shared = this._tagsCacheChanged[x].shared;
              break;
            }
          }
        }
      }
    }
    // console.log(references_referenced);
    this.tagsCache = references_referenced;

    return references_referenced;
  }

  getTagsCache() {
    return this.tagsCache;
  }

  resetSectionCache() {
    this._sectionCache = [];
    this._sectionCacheChanged = [];
  }

  setSectionCache(sections: NotifySection[]): NotifySection[] {
    if (this._sectionCacheChanged != null && this._sectionCacheChanged.length > 0) {
      // We have some changes to apply
      // console.log(`Has changes to sections from note edits`);
      for (let x = 0; x < this._sectionCacheChanged.length; x++) {
        if (sections != null && sections.length > 0) {
          for (let y = 0; y < sections.length; y++) {
            // We basically reset everything except the references referenced
            if (this._sectionCacheChanged[x].sys_id == sections[y].sys_id) {
              if (sections[y].references_referenced != null && sections[y].references_referenced.length > 0) {
                // Apply the references referenced to the sections
                // console.log(`Going through references to find changes to sharing`);
                for (let a = 0; a < sections[y].references_referenced.length; a++) {
                  if (this._sectionCacheChanged[x].references_referenced != null && this._sectionCacheChanged[x].references_referenced.length > 0) {
                    for (let b = 0; b < this._sectionCacheChanged[x].references_referenced.length; b++) {
                      if (sections[y].references_referenced[a].sys_id_reference == this._sectionCacheChanged[x].references_referenced[b].sys_id_reference) {
                        // We have a matching reference referenced, so apply the sharing
                        // console.log(`Applying sharing changes: ${this._sectionCacheChanged[x].references_referenced[b].shared}`);
                        sections[y].references_referenced[a].shared = this._sectionCacheChanged[x].references_referenced[b].shared;
                        break;
                      }
                    }
                  }
                }
              }
              break;
            }
          }
        }
      }
    }
    // console.log(sections);
    this.sectionCache = sections;

    return sections;
  }

  getSectionCache() {
    return this.sectionCache;
  }

  setViewReferenceProxy(viewReference: any) {
    this.viewReference = viewReference;
  }

  setSetNoteViewForReferenceProxy(setNoteViewForReference: any) {
    this.setNoteViewForReference = setNoteViewForReference;
  }

  executeViewReference(sys_id_reference: string) {
    console.log(sys_id_reference);
    this.viewReference(sys_id_reference);
  }

  async executeSetNoteViewForReference(sys_id_reference: string) {
    console.log(sys_id_reference);
    const reference = await Storage.getInstance().getReference(sys_id_reference);

    this.setNoteViewForReference(reference);
  }

  setClearNoteProxy(clearNoteProxy: any) {
    this.clearNoteProxy = clearNoteProxy;
  }

  setClearNote() {
    this.clearNoteProxy();
  }

  createRegistry() {
    this.registry = new Map();
    this.blockCache = new Map();
    this.sectionCache = [];
  }

  getRegisteredBlockSysId(editor_id_block: string, register: boolean) {
    // console.log(`Getting registered block: ${JSON.stringify(editor_id_block)}`);
    let sys_id: string = this.registry.get(editor_id_block)!;
    
    if (register == true && (sys_id == null || sys_id.trim().length == 0)) {
      // console.log(`Registered block not found: ${JSON.stringify(editor_id_block)}`);
      sys_id = crypto.randomUUID();
      this.setRegisteredBlockUuid(sys_id, editor_id_block);
    }

    return sys_id;
  }

  cleanOrphanedBlocks(blocks: EllieBlock[]): string[] {
    const orphanedBlocks: string[] = [];
    const keys = Array.from(this._registry.keys());

    for (let x = 0; x < keys.length; x++) {
      let found = false;
      for (let y = 0; y < blocks.length; y++) {
        if (keys[x] == blocks[y].editor_id_block) {
          found = true;
          continue;
        }
      }

      if (found == false) {
        // console.log(`Clearing orphaned doc: ${keys[x]}`);
        // Add to the list of orphaned blocks so we can delete from storage
        orphanedBlocks.push(this._registry.get(keys[x])!);
        // Remove from the registry
        this._registry.delete(keys[x]);
      }
    }

    return orphanedBlocks;
  }

  setRegisteredBlockUuid(sys_id: string, editor_id_block: string) {
    // console.log(`Setting registered block: ${JSON.stringify(editor_id_block)}`);
    this.registry.set(
      editor_id_block,
      sys_id
    );
  }

  getCachedBlock(sys_id: string) {
    // console.log(this.blockCache);
    return this.blockCache.get(sys_id);
  }

  setCachedBlock(block: EllieBlock) {
    this.blockCache.set(block.sys_id, block);
  }

  getCachedSectionForBlockId(sys_id_block: string) {
    // Based on the block identifier, we find the relevant section
    // console.log(this.sectionCache);
    if (this.sectionCache != null && this.sectionCache.length > 0) {
      for (let x = 0; x < this.sectionCache.length; x++) {
        // Check the section blocks for the provided identifier
        // console.log(this.sectionCache[x]);
        for (let y = 0; y < this.sectionCache[x].blocks_referenced.length; y++) {
          if (this.sectionCache[x].blocks_referenced[y].sys_id_block == sys_id_block) {
            // Return the matching section
            return this.sectionCache[x];
          }
        }
      }
    }

    // The user likely selected an blank paragraph
    // console.log(`No section could be found for identifier.`);
    return null;
  }

  getBlockForSysId(note: EllieNote, sys_id: string) {
    if (note != null && note.blocks_captured != null && note.blocks_captured.length > 0) {
      for (let x = 0; x < note.blocks_captured.length; x++) {
        if (note.blocks_captured[x].sys_id === sys_id) {
          return note.blocks_captured[x];
        }
      }
    }
    return null;
  }

  getGoogleAccessToken() {
    return this._googleAccessToken;
  }

  setGoogleAccessToken(googleAccessToken: string) {
    this._googleAccessToken = googleAccessToken;
  }

  async forceSave(data: any, done: boolean) {
    // Check to make sure we have some blocks
    if (data != null && data.blocks != null && data.blocks.length > 0) {
      // Parse the note so we have all of the blocks in the right format for storage
      // console.log(`Note pre parsing: ${JSON.stringify(this.note)}`);
      // console.log(`Title: ${this._title}`);
      // console.log(`Tags: ${this._tags}`);
      this.note = await Parser.getNoteForStorage(this.note, this._title, this._tags, data.blocks);
      // console.log(`Note post parsing: ${JSON.stringify(this.note)}`);

      // console.log(`Inserting note root properties: ${JSON.stringify(noteResult)}`);
      // This stores the notes and the blocks
      await Storage.getInstance().setNote(this.note, this.filter, done);
    }
  }

  async saveActionStatusChange(sys_id_block: string, status: EllieBlockStatus) {
    await Storage.getInstance().saveActionStatusChange(sys_id_block, status);
    
    Editor.getInstance().loadPinnedTags();
  }

  async save(api: any, isDone: boolean) {
    // clearTimeout(this.timer);

    let hasContent = false;
    // Check to see if any blocks have content
    if (api.blocks.getBlocksCount() > 0) {
      for (let x = 0; x < api.blocks.getBlocksCount(); x++) {
        if (api.blocks.getBlockByIndex(x).isEmpty == false) {
          hasContent = true;
          break;
        }
      }
    }

    // Only perform the save if the note has more than one block or the first block
    // has at least some content
    if (hasContent == true) {
      // console.log(`Block count is: ${api.blocks.getBlocksCount()}`);
      // console.log(`First block content is empty: ${api.blocks.getBlockByIndex(0).isEmpty}`);
      
      // loader.classList.add('heartbeat-loader');
      // loader.classList.remove('heartbeat-loader-still');
  
      if (this.autoSave) {
        const data = await api.saver.save();

        // console.log(`Note being saved: ${JSON.stringify(data)}`);
        await Editor.getInstance().forceSave(data, isDone);
      }            
    }
  }

  loadPinnedTags() {
    this._handleLoadPinnedTags();
  }

  showProviderAuthentication(sys_provider: string, sys_id_external: string) {
    this._handleShowProviderAuthentication(sys_provider, sys_id_external);
  }

  showReferenceCard(reference: EllieReference) {
    this._handleShowReferenceCard(reference);
  }

  showReferenceCards() {
    this._handleShowReferenceCards();
  }

  showSectionDetails(section: NotifySection) {
    this._handleShowSectionDetails(section);
  }

  getReferenceCard(popover: Popover, activeReference: EllieReference) {
    const actionsPercent = Math.floor(100 * ((activeReference.actions_total - activeReference.actions_incomplete) / activeReference.actions_total));
    
    const progressIconIcon = document.createElement("i");
    progressIconIcon.className = activeReference.sys_type == "person" ? "bi bi-emoji-smile-fill" : (activeReference.sys_type == "global" ? "bi bi-globe" : "bi bi-clipboard-heart-fill");

    const progressIcon = document.createElement("div");
    progressIcon.classList.add("fs-1");
    progressIcon.appendChild(progressIconIcon);

    const progress = document.createElement("div");
    progress.setAttribute("role", activeReference.sys_type == "person" ? "progressbar-person" : "progressbar-topic");
    progress.setAttribute("aria-valuenow", actionsPercent.toString());
    progress.setAttribute("aria-valuemin", "0");
    progress.setAttribute("aria-valuemax", "100");
    progress.setAttribute("style", `--value: ${actionsPercent}; margin-left: 6px; --size: 6em;`);
    progress.appendChild(progressIcon);

    const row1col1 = document.createElement("div");
    row1col1.classList.add("col-2");
    row1col1.style.minWidth = "108px";
    row1col1.appendChild(progress);

    const prefix = document.createElement("span");
    prefix.classList.add("text-muted");
    prefix.style.fontSize = "1em";
    prefix.innerText = activeReference.sys_type == "person" ? "@" : "#";

    const alias = document.createElement("span");
    alias.classList.add("text-muted");
    alias.classList.add("text-lowercase");
    alias.classList.add("en-reference-card-alias");
    alias.classList.add("w-100");
    alias.setAttribute("id", activeReference.sys_id + "-reference-card-alias");
    alias.setAttribute("data-placeholder", "Alias (required)");
    alias.contentEditable = "true";
    alias.innerText = activeReference.alias;

    const friendlyName = document.createElement("div");
    friendlyName.classList.add("fs-3");
    friendlyName.classList.add("en-reference-card-title");
    friendlyName.setAttribute("id", activeReference.sys_id + "-reference-card-friendly-name");
    friendlyName.setAttribute("data-placeholder", "Friendly Name");
    friendlyName.contentEditable = activeReference.sys_type == "global" ? "false" : "true";
    friendlyName.innerText = activeReference.friendly_name;

    const email = document.createElement("div");
    if (activeReference.sys_type == "person") {
      email.classList.add("text-muted");
      email.classList.add("en-reference-card-email");
      email.setAttribute("id", activeReference.sys_id + "-reference-card-email");
      email.setAttribute("data-placeholder", "Email");
      email.contentEditable = "false"; // activeReference.sys_id_tenant_verified != null && activeReference.sys_id_tenant_verified.trim().length > 0 ? "false" : "true";
      email.innerHTML = activeReference.email != null && activeReference.email.trim().length > 0 ? activeReference.email : "&nbsp;";
    } else {
      email.innerHTML = "&nbsp;";
    }

    const actions = document.createElement("div");
    actions.classList.add("text-muted");
    actions.innerText = `${activeReference.actions_total - activeReference.actions_incomplete} / ${activeReference.actions_total} Actions Complete`;

    const row1col2 = document.createElement("div");
    row1col2.classList.add("col");

    row1col2.appendChild(prefix);
    row1col2.appendChild(alias);
    row1col2.appendChild(friendlyName);
    row1col2.appendChild(email);
    row1col2.appendChild(actions);
    // row1col2.appendChild(buttonBuffer);

    const row1 = document.createElement("div");
    row1.classList.add("row");
    row1.appendChild(row1col1);
    row1.appendChild(row1col2);

    return row1;
  }

  _createShareWithTenantsList(activeReference: EllieReference, references: ElliePeople[], sharingPeople: HTMLElement) {
    const sharingPeopleTitle = document.createElement("h6");
    sharingPeopleTitle.classList.add("mt-3");
    sharingPeopleTitle.innerHTML = "Share with:";

    sharingPeople.appendChild(sharingPeopleTitle);

    if (references != null && references.length > 0) {
      for (let x = 0; x < references.length; x++) {
        const sharingPeopleCheck = document.createElement("input");
        sharingPeopleCheck.classList.add("form-check-input");
        sharingPeopleCheck.setAttribute("id", "modal_form_label_sharing_notify_note_modal_global_" + references[x].sys_id);
        sharingPeopleCheck.setAttribute("data-sys-id-reference", references[x].sys_id);
        sharingPeopleCheck.type = "checkbox";
        sharingPeopleCheck.defaultChecked = false;

        // Check to see if this tenant reference is being shared with already
        if (activeReference.shared_tenants != null && activeReference.shared_tenants.length > 0) {
          for (let y = 0; y < activeReference.shared_tenants.length; y++) {
            if (activeReference.shared_tenants[y].sys_id_tenant == references[x].sys_id_tenant_verified) {
              sharingPeopleCheck.defaultChecked = true;
              break;
            }
          }
        }

        sharingPeopleCheck.addEventListener('change', () => {
          if (activeReference.shared_tenants == null) {
            activeReference.shared_tenants = [];
          }

          if (sharingPeopleCheck.checked == true) {
            // console.log(`Reference is checked`);
            // console.log(references[x]);
            activeReference.shared_tenants.push({
              sys_id_tenant: references[x].sys_id_tenant_verified,
            })
          } else {
            for (let y = 0; y < activeReference.shared_tenants.length; y++) {
              if (activeReference.shared_tenants[y].sys_id_tenant == references[x].sys_id_tenant_verified) {
                // Remove the shared tenant from the list
                activeReference.shared_tenants.splice(y, 1);
              }
            }
          }

          // Set the sharing to specific people
          activeReference.sys_id_sharing = EllieSharingType.TenantSharing;
          // console.log(activeReference);

          if (activeReference.sys_type == "person") {
            // console.log("Updating sharing to specific for person");
            Storage.getInstance().updatePeople(<ElliePeople><unknown>activeReference, activeReference.shared_tenants, null);
          } else {
            // console.log("Updating sharing to specific for topic");
            Storage.getInstance().updateTopic(<EllieTopic><unknown>activeReference, activeReference.shared_tenants, null);
          }
        });

        const sharingPeopleLabel = document.createElement("label");
        sharingPeopleLabel.classList.add("form-check-label");
        sharingPeopleLabel.classList.add("text-reset");
        sharingPeopleLabel.classList.add("ms-2");
        sharingPeopleLabel.setAttribute("for", "modal_form_label_sharing_notify_note_modal_global_" + references[x].sys_id);
        sharingPeopleLabel.innerHTML = references[x].alias;

        const sharingReferenceDiv = document.createElement("div");
        sharingReferenceDiv.classList.add("forms-check");
        sharingReferenceDiv.style.cursor = "pointer";
        sharingReferenceDiv.appendChild(sharingPeopleCheck);
        sharingReferenceDiv.appendChild(sharingPeopleLabel);

        sharingPeople.appendChild(sharingReferenceDiv);
      }
    }
  }

  _createAuthModal() {
    let authModal = document.getElementById(Editor.AUTH_MODAL_ID);

    const modalTitle = document.createElement("h1");
    modalTitle.classList.add("modal-title");
    modalTitle.classList.add("fs-5");
    modalTitle.setAttribute("id", `modal_label_${Editor.AUTH_MODAL_ID}`);

    const authModalClose = document.createElement("button");
    authModalClose.classList.add("btn-close");
    authModalClose.setAttribute("type", "button");
    authModalClose.setAttribute("data-bs-dismiss", "modal");
    authModalClose.setAttribute("aria-label", "Close");

    const authModalHeader = document.createElement("div");
    authModalHeader.classList.add("modal-header");
    authModalHeader.appendChild(modalTitle);
    authModalHeader.appendChild(authModalClose);

    this._authModalBody = document.createElement("div");
    this._authModalBody.classList.add("modal-body");
    this._authModalBody.setAttribute("data-showing", "no");

    const authModalContent = document.createElement("div");
    authModalContent.classList.add("modal-content");
    authModalContent.appendChild(authModalHeader);
    authModalContent.appendChild(this._authModalBody);

    const authModalDialog = document.createElement("div");
    authModalDialog.classList.add("modal-dialog");
    authModalDialog.classList.add("modal-fullscreen");
    authModalDialog.appendChild(authModalContent);

    authModal.classList.add("modal");
    authModal.classList.add("fade");
    authModal.setAttribute("id", `modal_${Editor.AUTH_MODAL_ID}`);
    authModal.setAttribute("tabindex", "-1");
    authModal.setAttribute("aria-labelledby", `modal_label_${Editor.AUTH_MODAL_ID}`);
    authModal.setAttribute("aria-hidden", "true");
    authModal.appendChild(authModalDialog);

    this._authModal = new Modal(authModal, {backdrop: true, focus: true, keyboard: true});
  }

  _removeAllChildNodes(parent: HTMLElement) {
    while (parent.firstChild) {
      parent.removeChild(parent.firstChild);
    }
  }
}
