import React, { useEffect, useState } from "react";

import { Row, Col } from 'react-bootstrap';
import EventObjectIntelligenceCardMetadataField from "./EventObjectIntelligenceCardMetadataField";

const EventObjectIntelligenceCardMetadata = (props) => {
  const [eventObject, setEventObject] = useState(null);
  const [eventType, setEventType] = useState('');
  const [key, setKey] = useState(crypto.randomUUID());
  const [acknowledgeDisabled, setAcknowledgeDisabled] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [stopDisabled, setStopDisabled] = useState(false);

  const openLink = (url) => {
    window.open(url);
  }

  const acknowledgeEvent = async() => {
    setAcknowledgeDisabled(true);
    setStopDisabled(true);
    setSaveDisabled(true);
    await props.acknowledge("event", eventObject);
  }

  const acknowledgeIntelligence = async() => {
    setAcknowledgeDisabled(true);
    setStopDisabled(true);
    setSaveDisabled(true);
    await props.acknowledge("recommendation", eventObject);
  }

  const stopIntelligence = async() => {
    setAcknowledgeDisabled(true);
    setStopDisabled(true);
    setSaveDisabled(true);
    await props.stop("recommendation", eventObject);
  }

  const saveIntelligence = async() => {
    setAcknowledgeDisabled(true);
    setStopDisabled(true);
    setSaveDisabled(true);
    await props.save("recommendation", eventObject);
  }

  const undoChange = (field) => {
    for (let x = 0; x < eventObject.metadata_fields.fields.length; x++) {
      if (eventObject.metadata_fields.fields[x].name == field.name) {
        eventObject.metadata_fields.fields[x].changed = field.changed;
        eventObject.metadata_fields.fields[x].value = field.value;
        eventObject.metadata_fields.fields[x].old_value = field.old_value;
        eventObject.metadata_fields.fields[x].new_value = field.new_value;
        console.log(field);

        setEventObject(eventObject);
        break;
      }
    }
  }

  const mergePostFieldsWithMetadata = (eventObjectToMerge) => {
    if (eventObjectToMerge != null &&
        eventObjectToMerge.metadata_fields != null && 
        eventObjectToMerge.metadata_fields.fields != null && 
        eventObjectToMerge.metadata_fields.fields.length > 0 &&
        eventObjectToMerge.post_fields != null &&
        eventObjectToMerge.post_fields.length > 0) {
      // We have an object to process
      for (let x = 0; x < eventObjectToMerge.metadata_fields.fields.length; x++) {
        let changed = false;
        for (let y = 0; y < eventObjectToMerge.post_fields.length; y++) {
          if (eventObjectToMerge.metadata_fields.fields[x].name == eventObjectToMerge.post_fields[y].name) {
            if (eventObjectToMerge.post_fields[y].changed == true) {
              changed = true;
              eventObjectToMerge.metadata_fields.fields[x].new_value = eventObjectToMerge.post_fields[y].new_value;
              eventObjectToMerge.metadata_fields.fields[x].old_value = eventObjectToMerge.post_fields[y].old_value;
            }
            break;
          }
        }

        eventObjectToMerge.metadata_fields.fields[x].changed = changed;
      }
    }

    console.log(eventObjectToMerge);
    setEventObject(eventObjectToMerge);
  }

  useEffect(() => {
    setEventType(props.eventType);
    mergePostFieldsWithMetadata(props.eventObject);
    // console.log(props.eventObject);
  }, [props.eventObject]);

  return (
    <React.Fragment>
      {eventObject != null &&
        <React.Fragment>
          <Row>
            <Col>
              <form>
                {eventObject.metadata_fields.fields != null && eventObject.metadata_fields.fields.length > 0 && eventObject.metadata_fields.fields.map((field, index) => (
                  <EventObjectIntelligenceCardMetadataField key={`${key}-form-div-${index}`} openLink={openLink} undoChange={undoChange} field={field} eventType={eventType} />
                ))}
              </form>
            </Col>
          </Row>
          <Row className="mt-3">
            <div className="d-grid gap-2">
              {eventType == "event" &&
                <React.Fragment>
                  <button className="btn btn-info text-white fw-bolder" onClick={acknowledgeEvent}>👍 Got it!</button>
                  <button className="btn btn-light" disabled={stopDisabled} onClick={stopIntelligence}>🛑 Stop These Notifications</button>
                </React.Fragment>
              }
              {eventType == "recommendation" &&
                <React.Fragment>
                  <button className="btn btn-warning text-white fw-bolder" disabled={saveDisabled} onClick={saveIntelligence}>✅ Save Changes</button>
                  <button className="btn btn-light" disabled={acknowledgeDisabled} onClick={acknowledgeIntelligence}>👎 Skip Recommendation</button>
                </React.Fragment>
              }
            </div>
          </Row>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default EventObjectIntelligenceCardMetadata;
