import { Editor } from "./editor";
import { EllieReference } from "./storage";

import { Popover } from "bootstrap";

export default class Events {
  static clickEnableMentionsOnParentElement = (parentDiv: Element) => {
    // console.log(`Finding mention references`);
    const embeddedReferences = parentDiv.getElementsByTagName('span');
    for (let i = 0; i < embeddedReferences.length; i++) {
      // console.log(`Checking for class name: ${embeddedReferences[i].className}`);
      if (embeddedReferences[i].className.indexOf('en-mention') >= 0) {
        // console.log(`Adding on click event to: ${embeddedReferences[i].getAttribute("data-sys-id")}`);
        // console.log(`Adding click handler to span.`);
        // const element_id = embeddedReferences[i].getAttribute("id");
        // const sys_id_reference = embeddedReferences[i].getAttribute("data-sys-id");    
        // const popover = this.applyReferencePopover(embeddedReferences[i]);

        embeddedReferences[i].onclick = function() {
          // popover.hide();
          Editor.getInstance().executeViewReference(embeddedReferences[i].getAttribute("data-sys-id"));
        } 

/*         embeddedReferences[i].addEventListener('show.bs.popover', () => {
          // console.log(document.getElementById(element_id).getAttribute("data-loading"));
          if (document.getElementById(element_id).getAttribute("data-loading") == "no") {
            // console.log("Not loading");
            Storage.getInstance().getReference(sys_id_reference).then((referenceResponse) => {   
              // console.log("Setting loading to yes");
              document.getElementById(element_id).setAttribute("data-loading", "yes");
              // console.log(referenceResponse);
              // console.log(popover);
              this.generateReferencePopover(popover, referenceResponse);
              setTimeout(() => {
                // console.log("Setting loading to no");
                document.getElementById(element_id).setAttribute("data-loading", "no");
              }, 250);
            });
          }
        }); */
      } else {
        // console.log(`Not adding click handler for: ${embeddedReferences[i].getAttribute("data-sys-id")}`);
      }
    }
  }

  static clickEnableMentionsOnElement = (referenceDiv: HTMLElement) => {
    // console.log(`Adding on click event to: ${referenceDiv.getAttribute("data-sys-id")}`);
    // console.log(`Adding click handler to span.`);
    // const element_id = referenceDiv.getAttribute("id");
    // const sys_id_reference = referenceDiv.getAttribute("data-sys-id");
    // const popover = this.applyReferencePopover(referenceDiv);

    referenceDiv.onclick = function(event) {
      // popover.hide();
      Editor.getInstance().executeViewReference(referenceDiv.getAttribute("data-sys-id"));
      event.preventDefault();
      event.stopPropagation();
    }

/*     referenceDiv.addEventListener('show.bs.popover', () => {
      // console.log(document.getElementById(element_id).getAttribute("data-loading"));
      if (document.getElementById(element_id).getAttribute("data-loading") == "no") {
        // console.log("Not loading");
        Storage.getInstance().getReference(sys_id_reference).then((referenceResponse) => {   
          // console.log("Setting loading to yes");
          document.getElementById(element_id).setAttribute("data-loading", "yes");
          // console.log(referenceResponse);
          // console.log(popover);
          this.generateReferencePopover(popover, referenceResponse);
          setTimeout(() => {
            // console.log("Setting loading to no");
            document.getElementById(element_id).setAttribute("data-loading", "no");
          }, 250);
        });
      }
    }); */
  }

  static applyReferencePopover = (referenceElement: HTMLElement) => {
    referenceElement.setAttribute("data-bs-toggle", "popover");
    referenceElement.setAttribute("data-bs-content", "Loading...");
    referenceElement.setAttribute("data-loading", "no");

    const popover = new Popover(referenceElement, {
      placement: 'top',
      trigger: 'hover focus',
      html: true,
    });

/*     referenceElement.addEventListener('mouseenter', function() {
      var myPopover = Popover.getInstance(this);
      if (myPopover) {
        console.log(`Showing popover`);
        myPopover.show();
      }
    }); */

    // Handle click event on the close button
/*     referenceElement.addEventListener('click', '#popoverCloseButton', function() {
      $('[data-toggle="popover"]').popover('hide');
    }); */
    return popover;
  }

  static generateReferencePopover = (popover: Popover, reference: EllieReference) => {
    const aliasDiv = document.createElement("div");
    aliasDiv.classList.add("text-muted");
    aliasDiv.innerHTML = reference.alias;

    const friendlyNameDiv = document.createElement("div");
    friendlyNameDiv.classList.add("fs-3");
    friendlyNameDiv.innerHTML = reference.friendly_name;

    const emailDiv = document.createElement("div");
    emailDiv.classList.add("text-muted");
    emailDiv.innerHTML = reference.email;

/*     const expandButton = document.createElement("button");
    expandButton.classList.add("btn");
    expandButton.classList.add("btn-sm");
    expandButton.classList.add("btn-outline-secondary");
    expandButton.innerHTML = "<i class='bi bi-arrows-fullscreen'></i> Expand";
    expandButton.addEventListener('click', () => {
      popover.hide();
      Editor.getInstance().setHandleShowReferenceCard()
    }); */

    const wrapperDiv = document.createElement("div");
    wrapperDiv.appendChild(aliasDiv);
    wrapperDiv.appendChild(friendlyNameDiv);
    wrapperDiv.appendChild(emailDiv);
    // wrapperDiv.appendChild(expandButton);

    popover.setContent({
      '.popover-body': Editor.getInstance().getReferenceCard(popover, reference),
    });
  }
}