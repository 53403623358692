import React, { useEffect, useState } from "react";

import { OverlayTrigger, Tooltip, Col, Row, Modal, Tab, Nav, Card, Alert } from "react-bootstrap";

import ReferenceCardSettings from "./ReferenceCardSettings";

import { Storage } from "../../../utils/storage";
import Gravitar from "../../../utils/gravitar";
import ReferenceCardDetails from "./ReferenceCardDetails";
import { Parser } from "../../../utils/parser";
import ViewActionPanel from "./ViewActionPanel";

const FlexpaneViewReference = (props) => {
  const [reference, setReference] = useState(false);
  const [referenceMergees, setReferenceMergees] = useState(null);
  const [referenceMergeeTopCandidates, setReferenceMergeeTopCandidates] = useState(null);
  const [referenceMergee, setReferenceMergee] = useState(null);
  const [showMergeModal, setShowMergeModal] = useState(false);
  const [archived, setArchived] = useState(false);
  const [activeTab, setActiveTab] = useState("details");
  const [activeSettingsTab, setActiveSettingsTab] = useState("access");
  const [isMerging, setIsMerging] = useState(false);
  const [hasMergeResults, setHasMergeResults] = useState(false);
  const [actionsPercent, setActionsPercent] = useState(0);
  const [pinned, setPinned] = useState(false);
  const [loading, setLoading] = useState(false);
  const [actions, setActions] = useState(null);
  const [autoShareBlock, setAutoShareBlock] = useState(false);
  const [autoShareNote, setAutoShareNote] = useState(false);

  const [alias, setAlias] = useState("");
  const [friendlyName, setFriendlyName] = useState("");
  const [email, setEmail] = useState("");
  const [invite, setInvite] = useState(false);
  const [enableInvite, setEnableInvite] = useState(false);

  const actionsDefaultFilter = {
    tidbits: false,
    actions: true,
    notes: false,
    events: false,
    links: false,
    past: false,
    future: true,
    overdue: true,
    last_days: null,
    direction: "asc",
  };

  const handleAliasChange = (event) => {
    const alias = Parser.convertAlias(event.target.value);
    setAlias(alias);
  }

  const handleFriendlyNameChange = (event) => {
    setFriendlyName(event.target.value);
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value);

    if (Gravitar.isValidEmailAddress(event.target.value) == true) {
      setEnableInvite(true);
    } else {
      setEnableInvite(false);
    }
  }

  const changesExist = () => {
    if (reference.alias != alias) {
      return true;
    }

    if (reference.friendly_name != friendlyName) {
      return true;
    }

    if (reference.email != email) {
      return true;
    }

    return false;
  }

  const handleReferenceSettingsUpdate = (reference) => {
    setAutoShareBlock(reference.auto_share_block);
    setAutoShareNote(reference.auto_share_note);

    props.handleReferenceSettingsUpdate(reference);
  }
  
  const handleReferenceUpdate = async (invite) => {
    if (changesExist() == true || invite == true) {
      // Apply the fields to the reference
      reference.alias = alias;
      reference.friendly_name = friendlyName;
      reference.email = email;
      reference.invite = invite;
      console.log(`Updating reference`, reference);

      // Set the invite flag
      setInvite(invite);

      // Save using the provided handler
      updateReference(await props.handleReferenceDetailsUpdate(reference));
    }
  }

  const handlTabChange = async(tabKey) => {
    setActiveTab(tabKey);
  }

  const handlSettingsTabChange = async(tabKey) => {
    setActiveSettingsTab(tabKey);
  }

  const changePinnedStatus = async() => {
    setPinned(!pinned);
    const response = await Storage.getInstance().pinReference(reference);
    props.handlePinned(reference.sys_type);
  }

  const updateReference = (updatedReference) => {
    // console.log(updatedReference);
    setReference(updatedReference);
    setPinned(updatedReference.pinned);

    if (props.sys_id_reference != "global") {
      // console.log(updatedReference);
      if (updatedReference.actions_total > 0) {
        setActionsPercent(Math.floor(100 * ((updatedReference.actions_total - updatedReference.actions_incomplete) / updatedReference.actions_total)));
      } else {
        setActionsPercent(100);
      }
    }
  }

  const filterOutMeAndVerified = (realtimeReference, references) => {
    return references.filter(function (ref) {
      return ref.sys_id != realtimeReference.sys_id &&
             (ref.sys_id_tenant_verified == null || ref.sys_id_tenant_verified.trim().length == 0);
    });
  }

  const getReferencesToMerge = async(realtimeReference, search) => {
    if (realtimeReference != null) {
      let mergees = null;
      // TODO - need to filter out this reference from the lists
      if (realtimeReference.sys_type == "topic") {
        const topics = await Storage.getInstance().findTopics(search, null, null);
        mergees = filterOutMeAndVerified(realtimeReference, topics);
        setReferenceMergees(mergees);

        const exactTopics = await Storage.getInstance().findTopics(realtimeReference.alias, null, "yes");
        setReferenceMergeeTopCandidates(filterOutMeAndVerified(realtimeReference, exactTopics));
      } else {
        const people = await Storage.getInstance().findPeople(search, null, null);
        mergees = filterOutMeAndVerified(realtimeReference, people);
        setReferenceMergees(mergees);

        const exactPeople = await Storage.getInstance().findPeople(realtimeReference.alias, null, "yes");
        setReferenceMergeeTopCandidates(filterOutMeAndVerified(realtimeReference, exactPeople));
      }

      // This is a one-way flag to make sure the tab doesn't disable if search results return nothing
      if (hasMergeResults == false && mergees != null && mergees.length > 0) {
        setHasMergeResults(true);
      }
    }
  }

  const handleSearchTags = (event) => {
    if (event.key === 'Enter') {
      getReferencesToMerge(reference, document.getElementById("nft-manage-merge-tags-flexpane-search").value);
    }
  }

  const handleSearchTagsClick = () => {
    getReferencesToMerge(reference, document.getElementById("nft-manage-merge-tags-flexpane-search").value);
  }

  const handleArchiveTag = () => {
    Storage.getInstance().archiveReference(reference).then((response) => {
      setArchived(!archived);
    });
  }

  const handleMergeTag = (referenceToMerge) => {
    setIsMerging(false);
    setReferenceMergee(referenceToMerge);
    setShowMergeModal(true);
  }

  const performTagMerge = () => {
    setIsMerging(true);

    Storage.getInstance().mergeReferences(referenceMergee.sys_id, reference.sys_id).then((response) => {
      console.log(`Tag archive response: ${response}`);
      // Redirect the whole browser so we're refreshing the view - this will make sure we don't have latent
      // references that no longer work
      window.location.replace(process.env.REACT_APP_FRONTEND_BASE_URL);
    });
  }

  const loadActionsForReference = async(reference) => {
    setLoading(true);

    const actions = await Storage.getInstance().getSectionsByReferencesReferencedForTimeline(null, actionsDefaultFilter, false, [{sys_id_reference: reference.sys_id}]);

    setActions(actions);
    setLoading(false);
  }

  useEffect(() => {
    updateReference(props.reference);
    getReferencesToMerge(props.reference, "");

    if (props.reference != null) {
      setAlias(props.reference.alias);
      setFriendlyName(props.reference.friendly_name);
      setEmail(props.reference.email);
      setInvite(props.reference.invite);

      setAutoShareBlock(props.reference.auto_share_block);
      setAutoShareNote(props.reference.auto_share_note);
  
      if (props.reference.email != null && props.reference.email.trim().length > 0) {
        setEnableInvite(true);
      } else {
        setEnableInvite(false);
      }

      // Now the invite property has been set to true (to tell the UI it has been sent already)
      // We set it to false in the reference itself or we'll end up sending invite requests any
      // time the reference is updated
      props.reference.invite = false;
      updateReference(props.reference);

      setArchived(props.reference.archived);
      if (props.reference.archived == true) {
        setActiveTab("archive");
      }

      loadActionsForReference(props.reference);
    }
  }, [props.reference]);

  return (
    <React.Fragment>
      <Modal show={showMergeModal} onHide={() => setShowMergeModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Merge Tags</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {referenceMergee != null && reference != null &&
            <Row>
              <Col>
                Before clicking on the merge, button, here's a quick explanation of what's going to happen:
                <ol>
                  <li>{referenceMergee.sys_type == "topic" ? "#" : "@"}{referenceMergee.alias} will be replaced with the {reference.sys_type == "topic" ? "#" : "@"}{reference.alias} tag.</li>
                  <li>All of the notes, actions, and blocks that reference {referenceMergee.sys_type == "topic" ? "#" : "@"}{referenceMergee.alias}, will now reference {reference.sys_type == "topic" ? "#" : "@"}{reference.alias}.</li>
                  <li>None of you data will be deleted (except for the {referenceMergee.sys_type == "topic" ? "#" : "@"}{referenceMergee.alias} tag itself).</li>
                  <li>Once you merge, the process can't be reversed!</li>
                </ol>
              </Col>
            </Row>
          }
        </Modal.Body>
        <Modal.Footer>
          <button type="button" disabled={isMerging} className="btn btn-light" onClick={() => { setShowMergeModal(false) }}>Cancel</button>
          <button type="button" disabled={isMerging} className="btn btn-danger" onClick={() => { performTagMerge() }}>{isMerging == true ? <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span> : null}Merge</button>
        </Modal.Footer>
      </Modal>

      <Row>
        <Col>
          {reference != null &&
            <React.Fragment>
              <Row className="mt-3">
                <Col>
                  <Card className="rounded border-0 shadow-sm" id={reference.sys_id + "-reference-card"}>
                    <Card.Body>
                      <Row>
                        <Col>
                          <div className="float-end">
                            <OverlayTrigger placement="top" overlay={<Tooltip>{`${pinned == true ? "Unpin" : "Pin"} this ${reference.sys_type}`}</Tooltip>}>
                              <button className={`btn btn-sm btn-light ${pinned == true ? "" : "text-muted"} me-3`} style={pinned == true ? { backgroundColor: "#dee2e6"} : {}} onClick={() => changePinnedStatus()}><i className={`bi bi-pin${pinned == true ? "-fill" : ""}`}></i></button>
                            </OverlayTrigger>
                            <OverlayTrigger placement="top" overlay={<Tooltip>Close this {reference.sys_type}</Tooltip>}>
                              <button className="btn btn-sm btn-light text-muted" onClick={props.handleClose}><i className="bi bi-x-lg"></i></button>
                            </OverlayTrigger>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <div className="col-2" style={{minWidth: "108px"}}>
                          <div role={reference.sys_type == "person" ? "progressbar-person" : "progressbar-topic"} aria-valuenow={actionsPercent} aria-valuemin="0" aria-valuemax="100" style={{"--value": `${actionsPercent}`, "--size": "6em", marginLeft: '6px'}}>
                            {reference.sys_type == "topic" &&
                              <img style={{height: "60px", width: "60px"}} className="rounded-circle border border-1" src={Gravitar.getTopicImageUrl('')}></img>
                            }
                            {reference.sys_type == "person" &&
                              <img style={{height: "60px", width: "60px"}} className="rounded-circle border border-1" src={Gravitar.getPersonImageUrl(reference.email)}></img>
                            }
                          </div>
                        </div>
                        <div className="col">
                          <span className="text-muted">{(reference.sys_type == "person" ? "@" : "#")}</span>
                          <span className="text-muted text-lowercase w-100">{alias}</span>&nbsp;{props.tenant != null && props.tenant.reference != null && props.tenant.reference.sys_id == reference.sys_id ? <span className="text-muted">(me)</span> : ""}
                          <div className="fs-3">{friendlyName}</div>
                          {reference.sys_type == "person" && 
                            <React.Fragment>
                              <div className="text-muted d-inline-block">{email}</div>
                              {reference.sys_id_tenant_verified != null && reference.sys_id_tenant_verified.trim().length > 0 &&
                                <span>
                                  <i className="ms-2 bi bi-patch-check-fill fs-6" style={{color: "#35C6F0"}}></i>
                                </span>
                              }
                            </React.Fragment>
                          }
                          {reference.sys_type == "topic" &&
                            <React.Fragment>
                              <div>&nbsp;</div>
                            </React.Fragment>
                          }
                        </div>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Tab.Container
                    activeKey={activeTab}
                    onSelect={(tabKey) => handlTabChange(tabKey)}
                    variant="pills"
                  >
                    <Nav variant="pills">
                      {archived == false &&
                        <React.Fragment>
                          <Nav.Item>
                            <Nav.Link eventKey="details"><i className="bi bi-info-circle me-2"></i>Details</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="actions"><i className="bi bi-lightning-fill me-2"></i>Actions</Nav.Link>
                          </Nav.Item>
                        </React.Fragment>
                      }
                      <Nav.Item>
                        <Nav.Link eventKey="settings"><i className="bi bi-gear me-2"></i>Settings</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="actions">
                        <Row className="d-flex flex-column" style={{height: "60vh"}}>
                          {loading == false &&
                            <Col className="overflow-auto flex-grow-1">
                              {actions != null && actions.length > 0 && actions.map((action, index) => (
                                <ViewActionPanel key={`view-action-panel-rando-row-col-${index}`} processChange={props.processChange} action={action} viewNote={props.viewNote} handleLoadPinnedSections={props.handleLoadPinnedSections} />
                              ))}
                              {actions == null || actions.length == 0 &&
                                <Row className="mt-3 ms-2 mb-3">
                                  <Col>
                                    <span className="text-muted">You're all caught up with your actions! <span className="fs-5">🏖️</span></span>
                                  </Col>
                                </Row>
                              }
                            </Col>
                          }
                          {loading == true &&
                            <Col className="flex-grow-1 position-relative">
                              <div className="position-absolute top-50 start-50 translate-middle">
                                <div className="heartbeat-loader" style={{marginTop: "150px"}}>
                                  Loading...
                                </div>
                              </div>
                            </Col>
                          }
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                    <Tab.Content>
                      <Tab.Pane eventKey="details">
                        <Row className="d-flex flex-column" style={{height: "60vh"}}>
                          <Col className="overflow-auto flex-grow-1">
                            <div className="input-group mt-3 mb-3">
                              <span className="input-group-text">{reference.sys_type == "person" ? "@" : "#"}</span>
                              <input type="text" className="form-control" placeholder={`name (e.g. ${reference.sys_type == "person" ? "emma" : "my-team"})`} value={alias} onBlur={() => handleReferenceUpdate(false)} onChange={handleAliasChange} />
                            </div>
                            <div className="mb-3">
                              <input type="text" className="form-control" placeholder="Friendly name" value={friendlyName} onBlur={() => handleReferenceUpdate(false)} onChange={handleFriendlyNameChange} />
                            </div>
                            {reference.sys_type == "person" &&
                              <div className="input-group mb-3">
                                <input type="email" className="form-control" id="quick-add-email" disabled={reference.sys_id_tenant_verified == null || reference.sys_id_tenant_verified.trim().length == 0 ? false : true} placeholder="Email address" value={email} onBlur={() => handleReferenceUpdate(false)} onChange={handleEmailChange} />
                                {(reference.sys_id_tenant_verified == null || reference.sys_id_tenant_verified.trim().length == 0) &&
                                  <button className="btn btn-outline-secondary" disabled={!enableInvite} type="button" onClick={() => handleReferenceUpdate(true)}><i className="bi bi-send-fill me-1"></i>{invite == true ? "Invite Sent" : "Send Invite"}</button>
                                }
                                <a href={`mailto:${email}`} className="btn btn-outline-secondary" disabled={!enableInvite} type="button"><i className="bi bi-mailbox"></i></a>
                              </div>
                            }
                            <ReferenceCardDetails extension="flexpane" tenant={props.tenant} reference={props.reference} handleReferenceUpdate={props.handleReferenceDetailsUpdate} />
                            <Row className="mt-3">
                              <Col>
                                <ReferenceCardSettings tenant={props.tenant} reference={props.reference} handleReferenceUpdate={handleReferenceSettingsUpdate} verified={reference.sys_id_tenant_verified != null && reference.sys_id_tenant_verified.trim().length > 0 ? true : false} />
                              </Col>
                            </Row>
                            {(reference.sys_id_tenant_verified == null || reference.sys_id_tenant_verified.trim().length == 0) && reference.sys_type != "topic" &&
                              <Alert key="info" variant="info" className="mt-3" style={{fontSize: "0.85em"}}>
                                <i className="bi bi-person-fill-lock me-2"></i>Everything you write about <strong>{reference.alias != null && reference.alias.trim().length > 0 ? (reference.sys_type == "topic" ? "#" : "@") : ""}{reference.alias}</strong> is <strong>private</strong> to you. If you want to work with {reference.alias != null && reference.alias.trim().length > 0 ? (reference.sys_type == "topic" ? "#" : "@") : ""}{reference.alias} in Notify, add their email, and click on the invite button.
                              </Alert>
                            }
                            {reference.sys_id_tenant_verified != null && reference.sys_id_tenant_verified.trim().length > 0 && reference.sys_type != "topic" &&
                              <React.Fragment>
                                {reference.auto_share_block == false && reference.auto_share_note == false &&
                                  <Alert key="info" variant="info" className="mt-3" style={{fontSize: "0.85em"}}>
                                    <i className="bi bi-person-fill-lock me-2"></i>Everything you write about <strong>{reference.alias != null && reference.alias.trim().length > 0 ? (reference.sys_type == "topic" ? "#" : "@") : ""}{reference.alias}</strong> is <strong>private</strong> to you by default. You can share actions and notes manually using the 'Change who can see this' button associated with the action or note.
                                  </Alert>
                                }
                                {reference.auto_share_block == true && reference.auto_share_note == false &&
                                  <Alert key="success" variant="success" className="mt-3" style={{fontSize: "0.85em"}}>
                                    <i className="bi bi-people-fill me-2"></i>Every action you create including <strong>{reference.alias != null && reference.alias.trim().length > 0 ? (reference.sys_type == "topic" ? "#" : "@") : ""}{reference.alias}</strong> will be shared with them. You can share notes manually using the 'Change who can see this' button associated with the note.
                                  </Alert>
                                }
                                {reference.auto_share_block == false && reference.auto_share_note == true &&
                                  <Alert key="success" variant="success" className="mt-3" style={{fontSize: "0.85em"}}>
                                    <i className="bi bi-people-fill me-2"></i>Every note you tag with <strong>{reference.alias != null && reference.alias.trim().length > 0 ? (reference.sys_type == "topic" ? "#" : "@") : ""}{reference.alias}</strong> will be shared with them. You can share actions manually using the 'Change who can see this' button associated with the action.
                                  </Alert>
                                }
                                {reference.auto_share_block == true && reference.auto_share_note == true &&
                                  <Alert key="success" variant="success" className="mt-3" style={{fontSize: "0.85em"}}>
                                    <i className="bi bi-people-fill me-2"></i>Every note you tag with <strong>{reference.alias != null && reference.alias.trim().length > 0 ? (reference.sys_type == "topic" ? "#" : "@") : ""}{reference.alias}</strong> and every action you create including {reference.alias != null && reference.alias.trim().length > 0 ? (reference.sys_type == "topic" ? "#" : "@") : ""}{reference.alias} will be shared with them. You can unshare actions and notes manually using the 'Change who can see this' button associated with the action or note.
                                  </Alert>
                                }
                              </React.Fragment>
                            }
                          </Col>
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                    <Tab.Content>
                      <Tab.Pane eventKey="settings">
                        <Row>
                          <Col className="pt-1">
                            <Tab.Container
                              activeKey={activeSettingsTab}
                              onSelect={(tabKey) => handlSettingsTabChange(tabKey)}
                              variant="pills"
                            >
                              <Nav variant="pills">
                                {archived == false &&
                                  <React.Fragment>
                                    <Nav.Item>
                                      <Nav.Link eventKey="merge"><i className="bi bi-intersect me-2"></i>Merge</Nav.Link>
                                    </Nav.Item>
                                  </React.Fragment>
                                }
                                <Nav.Item>
                                  <Nav.Link eventKey="archive"><i className="bi bi-archive me-2"></i>Archive</Nav.Link>
                                </Nav.Item>
                              </Nav>
                              <Tab.Content>
                                <Tab.Pane eventKey="merge">
                                  {archived == false && hasMergeResults == true &&
                                    <Row className="mt-3">
                                      <Col>
                                        <Row>
                                          <Col>
                                            <h5 className="text-muted">Merge Tags</h5>
                                            <div className="form-text">
                                              Merging tags is helpful to make your notes easier to manage.
                                            </div>
                                          </Col>
                                        </Row>
                                        {referenceMergeeTopCandidates != null && referenceMergeeTopCandidates.length > 0 &&
                                          <React.Fragment>
                                            <Row className="mt-3">
                                              <Col>
                                                <h6 className="text-muted">Top Candidates</h6>
                                                <div className="form-text">You might want to merge the following tags with <strong>{reference.sys_type == "topic" ? "#" : "@"}{reference.alias}</strong> as they have exactly the same alias!</div>
                                              </Col>
                                            </Row>
                                            <Row className="d-flex flex-column bg-light m-1" style={{height: "20vh"}}>
                                              <Col className="overflow-auto flex-grow-1">
                                                {referenceMergeeTopCandidates.map((tag, index) => (
                                                  <Row key={`nfy-merge-top-tag-row-${index}`}>
                                                    <Col key={`nfy-merge-top-tag-row-col-${index}`} className="mt-2">
                                                      <OverlayTrigger key={`nfy-merge-top-tag-row-col-overlay-${index}`} placement="top" overlay={<Tooltip>{`Merge this ${tag.sys_type}`}</Tooltip>}>
                                                        <button key={`nfy-merge-top-tag-row-col-button-${index}`} className={`btn btn-sm btn-light text-muted me-2`} onClick={() => handleMergeTag(tag)}><i className={`bi bi-intersect`}></i></button>
                                                      </OverlayTrigger>
                                                      {tag.friendly_name != null && tag.friendly_name.trim().length > 0 ? tag.friendly_name : tag.alias}
                                                    </Col>
                                                  </Row>
                                                ))}
                                              </Col>
                                            </Row>
                                          </React.Fragment>
                                        }
                                        {hasMergeResults == true &&
                                          <React.Fragment>
                                            <Row className="mt-3">
                                              <Col>
                                                <h6 className="text-muted">Tags Available to Merge</h6>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col>
                                                <div className="input-group mb-1">
                                                  <input type="text" id="nft-manage-merge-tags-flexpane-search" className="form-control" onKeyUp={handleSearchTags} placeholder={`Search for ${reference.sys_type == "topic" ? "topics" : "people"}`} />
                                                  <button className="btn btn-outline-secondary" type="button" onClick={handleSearchTagsClick}><i className="bi bi-search me-2"></i>Search</button>
                                                </div>
                                              </Col>
                                            </Row>
                                            <Row className="d-flex flex-column bg-light m-1" style={{height: "20vh"}}>
                                              <Col className="overflow-auto flex-grow-1">
                                                {referenceMergees.map((tag, index) => (
                                                  <Row key={`nfy-merge-top-tag-row-${index}`}>
                                                    <Col key={`nfy-merge-top-tag-row-col-${index}`} className="mt-2">
                                                      <OverlayTrigger key={`nfy-merge-top-tag-row-col-overlay-${index}`} placement="top" overlay={<Tooltip>{`Merge this ${tag.sys_type}`}</Tooltip>}>
                                                        <button key={`nfy-merge-top-tag-row-col-button-${index}`} className={`btn btn-sm btn-light text-muted me-2`} onClick={() => handleMergeTag(tag)}><i className={`bi bi-intersect`}></i></button>
                                                      </OverlayTrigger>
                                                      {tag.friendly_name != null && tag.friendly_name.trim().length > 0 ? tag.friendly_name : tag.alias}
                                                    </Col>
                                                  </Row>
                                                ))}
                                              </Col>
                                            </Row>
                                          </React.Fragment>
                                        }
                                      </Col>
                                    </Row>
                                  }
                                  {archived == false && hasMergeResults == false &&
                                    <Row className="mt-3">
                                      <Col>
                                        <Row>
                                          <Col>
                                            <h5 className="text-muted">Merge Tags</h5>
                                            <div className="form-text">
                                              You currently only have 1 {reference.sys_type == "person" ? "person" : "topic"} tag, so nothing to merge with! <span className="fs-5">🙂</span>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  }
                                </Tab.Pane>
                              </Tab.Content>
                              <Tab.Content>
                                <Tab.Pane eventKey="archive">
                                  <Row className="mt-3">
                                    <Col>
                                      {archived == false &&
                                        <React.Fragment>
                                          <h5 className="text-muted">Archive</h5>
                                          <div className="form-text mb-3">
                                            If you no longer want to use this tag going forward, you can archive it simply by clicking
                                            on the button below. Don't worry, no data will be lost!
                                          </div>
                                        </React.Fragment>
                                      }
                                      {archived == true &&
                                        <React.Fragment>
                                          <h5 className="text-muted">Restore</h5>
                                          <div className="form-text mb-3">
                                            If you want to use this tag again, you can restore it simply by clicking
                                            on the button below.
                                          </div>
                                        </React.Fragment>
                                      }
                                      <OverlayTrigger placement="top" overlay={<Tooltip>{`Archive this ${reference.sys_type}`}</Tooltip>}>
                                        <button className={`btn btn-sm btn-outline-danger w-100`} onClick={() => handleArchiveTag()}><i className={`bi bi-archive${archived == true ? "-fill" : ""} me-2`}></i>{archived == true ? "Restore" : "Archive"}</button>
                                      </OverlayTrigger>
                                    </Col>
                                  </Row>
                                </Tab.Pane>
                              </Tab.Content>
                            </Tab.Container>
                          </Col>
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </Col>
              </Row>
            </React.Fragment>
          }
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default FlexpaneViewReference;
