import React, { useEffect, useState } from "react";

import { Parser } from "../../../utils/parser";
import Events from "../../../utils/events";

const ViewSectionNoteTags = (props) => {
  const [tags, setTags] = useState('');

  useEffect(() => {
    if (props.note_tags != null && props.note_references_referenced != null && props.note_references != null) {
      Parser._parseReferencesToHTML(props.note_tags, props.note_references_referenced, props.note_references).then((result) => {
        // Set the tags content
        setTags(result);

        // Enable click events on any references
        // Events.clickEnableMentionsOnParentElement(container);
      });
    }
  }, [props.note]);

  return (
    <span className="opacity-50" style={{fontSize: "0.8em"}} dangerouslySetInnerHTML={{ __html: tags }}/>
  );
};

export default ViewSectionNoteTags;
