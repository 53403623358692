import React, { useState, useRef, useCallback, useEffect } from "react";
import measureText from "../utils/measure_text";

import { Parser } from "../../../utils/parser";
import { Editor } from "../../../utils/editor";

const MentionPopover = ({
  position,
  mentionableProperties,
  mentionPrefixes,
  prefix,
  mentionNodeName,
  text,
  range,
  close,
  searchFunctions,
  loader,
  searchResultBuilders,
  mentionBuilders,
  uniqueKeys,
  style,
  onMention,
  createTag,
}) => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [friendlyName, setFriendlyName] = useState("");
  const [email, setEmail] = useState("");
  const [referenceIdSharing, setReferenceIdSharing] = useState(2);
  const [sendInvite, setSendInvite] = useState(false);
  const [isTagsInput, setIsTagsInput] = useState(false);

  const usersRef = useRef(users);
  const textRef = useRef(text);
  const selectedIndexRef = useRef(selectedIndex);
  const prefixIndexRef = useRef(mentionPrefixes.indexOf(prefix));
  const prefixRef = useRef(prefix);

  const handleFriendlyNameChange = (event) => setFriendlyName(event.target.value);
  const handleEmailChange = (event) => {
    // console.log(event.target);
    // console.log(event.target.value);
    if (event.target.value.trim().length > 0) {
      // console.log(`Setting send invite to true`);
      setSendInvite(true);
    }

    setEmail(event.target.value);
  }
  const handleReferenceIdSharingChange = (event) => setReferenceIdSharing(event.target.value);

  useEffect(() => {
    usersRef.current = users;
  }, [users]);

  useEffect(() => {
    prefixRef.current = prefix;
    prefixIndexRef.current = mentionPrefixes.indexOf(prefix);
  }, [prefix]);

  useEffect(() => {
/*     if (document.activeElement != null && document.activeElement.getAttribute("id") == "en-tags-input") {
      setIsTagsInput(true);
    } else {
      setIsTagsInput(false);
    } */

    // console.log(text);
    textRef.current = text;
  }, [text]);

  useEffect(() => {
    selectedIndexRef.current = selectedIndex;
  }, [selectedIndex]);

  const selectUser = (user, keyboard = false, space = false) => {
    console.log(user);
    // console.log(keyboard);
    // console.log(`Selecting user`);
    if (user === undefined) {
      // console.log(`User not defined`);
      return;
    }

    const isNew = typeof user === "string";
    let mention = null;

    // console.log(isNew);
    if (isNew) {
      // Build the user object from any details in the form
      mention = {
        alias: user,
        friendly_name: friendlyName == null || friendlyName.trim().length == 0 ? Parser.makeFriendly(textRef.current) : friendlyName,
        email: prefix == "@" ? email : "",
        sys_id_sharing: referenceIdSharing,
        invite: (email != null && email.trim().length > 0) ? sendInvite : false,
      }
    } else {
      mention = user;
    }

    let selection = getSelection();
    console.log(selection);

    const r = keyboard ? selection.getRangeAt(0) : range;
    console.log(r);

    const backup = r.endContainer.parentElement;
    console.log(backup);

    r.insertNode(
      r.createContextualFragment(
        mentionBuilders[prefixIndexRef.current](mention, isNew) + "\u00A0"
      )
    );

    onMention(user, prefixRef.current, isTagsInput);

    console.log(`Backup.childNotes`, backup.childNodes);
    console.log(`TextRef.current`, textRef.current);
    console.log(`user[]`, user[mentionableProperties[prefixIndexRef.current]]);
    const mapping = Array.from(backup.childNodes)
      .map((node, i) => {
        console.log(`nodeName`, node.nodeName);
        console.log(`innerText`, node.innerText);
        if (node.nodeName === mentionNodeName &&
            node.innerText === prefixRef.current + (isNew ? textRef.current : user[mentionableProperties[prefixIndexRef.current]]) &&
            backup.childNodes[i - 1] &&
            backup.childNodes[i - 1].data &&
            backup.childNodes[i - 1].data.includes(prefixRef.current + textRef.current)) {
          console.log(`Returning index i`, i);
          return i;
        } else {
          return undefined;
        }
      })
      .filter((e) => e !== undefined);

    const index = mapping[0];

    if (backup.childNodes[index - 1]) {
      backup.childNodes[index - 1].nodeValue = backup.childNodes[
        index - 1
      ].nodeValue.replace(prefixRef.current + textRef.current, "");
    }

    if (space == true) {
      // Weirdly in chrome, the last node is not in the node list - so the count is one larger than the
      // nodelist actually contains... no idea why
      // for (let x = backup.childNodes.length - 1; x >= 0; x--) {
      backup.childNodes[backup.childNodes.length - 2].nodeValue = " ";
      console.log(`Updated child node`, backup.childNodes[backup.childNodes.length - 2]);
      // }
    }

    selection = window.getSelection();
    selection.removeAllRanges();
    selection.setBaseAndExtent(
      backup.childNodes[index + 1],
      1,
      backup.childNodes[index + 1],
      1
    );
    // console.log(selection);

    close();
  };

  const setUserBasedOnSpace = () => {
    let mentionData = textRef.current;

    console.log(prefixRef.current);
    console.log(textRef.current);
    // TODO: this is where we could automatically create the tag for them as they may have spaced
    // out of it because they didn't realize they had to hit the button
    const cleanMentionData = mentionData.trim();
    if (cleanMentionData.includes(" ") == false) {
      // console.log(`Create the mention`, cleanMentionData);
      const mention = {
        alias: cleanMentionData,
        friendly_name: Parser.makeFriendly(cleanMentionData),
        email: null,
        sys_id_sharing: 2,
        invite: false,
        auto_tag: true,
      }

      // The user hit space after a mention, so we should create the mention for them
      // This is copied from MentionPopover
      let selection = getSelection();
      // console.log(selection);
  
      const r = selection.getRangeAt(0);
  
      const backup = r.endContainer.parentElement;
      console.log(backup);

      r.insertNode(
        r.createContextualFragment(
          createTag(prefixRef.current, mention, true) + "\u00A0"
        )
      );
      
      const mapping = Array.from(backup.childNodes)
        .map((node, i) => {
          if (
            node.nodeName === "SPAN" &&
            node.innerText === prefixRef.current + cleanMentionData
          ) {
            return i;
          } else {
            return undefined;
          }
        })
        .filter((e) => e !== undefined);
      console.log(mapping);

      const index = mapping[mapping.length - 1];
      console.log(index);
      console.log(backup.childNodes[index - 1].data);
      // backup.childNodes[index - 1].data = backup.childNodes[index - 1].data.substring(0, backup.childNodes[index - 1].data.length - 1);
      console.log(backup.childNodes[index - 1].data);
      console.log(backup.childNodes);
              
      if (backup.childNodes[index - 1]) {
        console.log(backup.childNodes[index - 1].nodeValue);
        backup.childNodes[index - 1].nodeValue = backup.childNodes[
          index - 1
        ].nodeValue.replace(prefixRef.current + cleanMentionData, "");
        console.log(backup.childNodes[index - 1].nodeValue);
      }

      // Remove the space from the beginning (as this will have come from hitting the space bar)
      // let offsetIndex = index - 1;
      backup.childNodes[backup.childNodes.length - 2].nodeValue = " ";

      // console.log(backup.childNodes[offsetIndex]);
      const updatedSelection = window.getSelection();
      // console.log(updatedSelection);
      updatedSelection.removeAllRanges();
      updatedSelection.setBaseAndExtent(
        backup.childNodes[index + 1],
        1,
        backup.childNodes[index + 1],
        1
      );
      // console.log(JSON.stringify(backup.childNodes));
      // This is copied from MentionPopover
    }

    // This will stop the modal from showing and simply create the tag
    close();
  }

  const keysOverrider = useCallback((e) => {
    // console.log(`Calling key overrider!`);
    // console.log(e);
    // console.log(text);
    // console.log(textRef.current);
    if (text) {
      if (
        e.key === "ArrowDown" ||
        e.key === "ArrowUp" ||
        e.key === "Enter" ||
        e.key === "Escape"
      ) {
        // console.log(`Stopping propagation of enter`);
        e.preventDefault();
        e.stopPropagation();
      }

      if (e.key === "ArrowUp" && selectedIndexRef.current > 0) {
        setSelectedIndex(selectedIndexRef.current - 1);
      }

      if (
        e.key === "ArrowDown" &&
        selectedIndexRef.current < usersRef.current.length - 1
      ) {
        setSelectedIndex(selectedIndexRef.current + 1);
      }

      if (e.key === "Escape") {
        close();
      }

      if (e.code === "Space") {
        if (usersRef.current[selectedIndexRef.current] != null) {
          console.log(`Selecting user based on space`);
          selectUser(usersRef.current[selectedIndexRef.current], true, true);
        } else {
          setUserBasedOnSpace();
        }
      }

      if (e.key === "Enter") {
        if (usersRef.current[selectedIndexRef.current] != null) {
          selectUser(usersRef.current[selectedIndexRef.current], true);
        } else {
          // The user is hitting enter to create a new tag
          selectUser(textRef.current, true);
        }
      }
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', function handleClickOutsideBox(event) {
      const mention = document.getElementById('en-mention-popover');
      if (mention != null && !mention.contains(event.target)) {
        close();
      }
    });
    document.body.addEventListener("keydown", keysOverrider, {
      capture: true,
    });
    return () => {
      document.body.removeEventListener("keydown", keysOverrider, {
        capture: true,
      });
      setLoading(undefined);
      setSelectedIndex(undefined);
      setUsers(undefined);
    };
  }, []);

  const searchUsers = async () => {
    setLoading(true);
    try {
      const searchResults = await searchFunctions[prefixIndexRef.current](text.trim());
      setUsers(searchResults);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    searchUsers();
    setFriendlyName(Parser.makeFriendly(text));
  }, [text]);

  return (
    <React.Fragment>
      <div
        id="en-mention-popover"
        className="shadow rounded-4"
        style={{
          background: "white",
          maxHeight: "27rem",
          overflow: "auto",
          padding: "1rem",
          boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
          width: "25rem",
          marginTop: "1.25rem",
          marginLeft: `-${measureText(text, 16).width}px`,
          zIndex: 10000,
          ...style,
          position: "absolute",
          top: window.scrollY + position.y,
          left: window.scrollX + position.x,
        }}
      >
        {loading && loader}
        {users != null && users.length > 0 &&
          <h6 className="pb-2 fw-semibold">{prefix == "@" ? <React.Fragment>Select the person...</React.Fragment> : <React.Fragment>Select the topic...</React.Fragment>}</h6>
        }
        {users.map((user, i) => (
          <div
            key={user[uniqueKeys[prefixIndexRef.current]]}
            onClick={() => { selectUser(user); close(); }}
            style={{ cursor: "pointer" }}
          >
            {searchResultBuilders[prefixIndexRef.current](user, i, selectedIndex)}
          </div>
        ))}
        <h6 className={users != null || users.length == 0 ? "pt-2 pb-2 fw-semibold" : "border-top pt-3 pb-2 fw-semibold"}>{prefix == "@" ? <React.Fragment>{users != null && users.length > 0 ? <React.Fragment>...or add</React.Fragment> : <React.Fragment>Add</React.Fragment>} a new person</React.Fragment> : <React.Fragment>{users != null && users.length > 0 ? <React.Fragment>...or add</React.Fragment> : <React.Fragment>Add</React.Fragment>} a new topic</React.Fragment>}</h6>
        <form>
          <div className="mb-3">
            <input type="text" disabled className="form-control" placeholder="Alias" id="quick-add-alias" value={text.toLowerCase()} />
          </div>
          <div className="mb-3">
            <input type="text" className="form-control" id="quick-add-friendly-name" placeholder="Friendly name" value={friendlyName} onChange={handleFriendlyNameChange} />
          </div>
          {prefix == "@" &&
            <div className="input-group mb-3">
              <input type="email" className="form-control" id="quick-add-email" placeholder="Email address (optional)" value={email} onChange={handleEmailChange} />
              <div className="input-group-text">
                <input checked={sendInvite} onChange={() => setSendInvite(!sendInvite)} className="form-check-input mt-0 me-1" id="quick-add-email-invite" type="checkbox" value="" aria-label="Checkbox for following text input" /><label htmlFor="quick-add-email-invite" className="text-muted">Invite <i className="bi bi-send-fill"></i></label>
              </div>
            </div>
          }
          <div className="mb-3">
            <select className="form-select" id="quick-add-sharing" value={referenceIdSharing} onChange={handleReferenceIdSharingChange}>
              <option value="0">Do not share this tag</option>
              <option value="2">Share this tag with everyone {Editor.getInstance().getTenant() != null ? Editor.getInstance().getTenant().domain : ""}</option>
            </select>
          </div>
          <button className="btn btn-secondary" id="en-tag-add-button" onClick={() => { selectUser(text); close();}}>Create New {prefix == "@" ? "Person" : "Topic"}: {prefix + text.toLowerCase()}</button>
        </form>
      </div>
    </React.Fragment>
  );
};

export default MentionPopover;
