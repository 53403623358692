import React, { useEffect, useState } from "react";

import { NotifyProvider, Storage } from "../../../utils/storage";

import { Card, Row, Col } from 'react-bootstrap';

import EventObjectIntelligenceCardMetadata from "./EventObjectIntelligenceCardMetadata";
import FlexpaneViewIntelligenceCardSource from "./FlexpaneViewIntelligenceCardSource";

const EventObjectIntelligenceCard = (props) => {
  const [eventObject, setEventObject] = useState(null);
  const [loading, setLoading] = useState(false);

  const acknowledgeIntelligence = async(eventType, eventObjectToAcknowledge) => {
    if (eventType == "event") {
      await Storage.getInstance().ignoreIntelligenceEvent(eventObjectToAcknowledge.sys_id);
      props.refreshIntelligence();
    }
    if (eventType == "recommendation") {
      Storage.getInstance().ignoreIntelligenceRecommendation(eventObjectToAcknowledge.sys_id);
      props.refreshIntelligence();
    }
  }

  const stopIntelligence = async(eventType, eventObjectToAcknowledge) => {
    if (eventType == "recommendation") {
      await Storage.getInstance().stopMonitoringIntelligenceEvents(eventObjectToAcknowledge.sys_id_registered_event_object);
      props.refreshIntelligence();
    }
  }

  const saveIntelligence = async(eventType, eventObjectToAcknowledge) => {
    if (eventType == "recommendation") {
      await Storage.getInstance().saveIntelligenceRecommendation(eventObjectToAcknowledge.sys_id_registered_event_object, eventObjectToAcknowledge);
      props.refreshIntelligence();
    }
  }

  const loadEventObjectIntelligence = async(eventObjectIntelligence) => {
    console.log(eventObjectIntelligence);
    if (eventObjectIntelligence.sys_type.indexOf(NotifyProvider.Salesforce) >= 0) {
      console.log(`This is a salesforce resource`);
      if (eventObjectIntelligence.sys_type.toLowerCase() == "salesforce.account") {
        eventObjectIntelligence.title = "Salesforce Account";
        eventObjectIntelligence.logo = "./assets/salesforce/account.svg";
        eventObjectIntelligence.backgroundColor = "#1798c1";
        eventObjectIntelligence.textColor = "text-white";
        eventObjectIntelligence.link = eventObjectIntelligence.full_url;
      } else if (eventObjectIntelligence.sys_type.toLowerCase() == "salesforce.case") {
        eventObjectIntelligence.title = "Salesforce Case";
        eventObjectIntelligence.logo = "./assets/salesforce/case.svg";
        eventObjectIntelligence.backgroundColor = "#1798c1";
        eventObjectIntelligence.textColor = "text-white";
        eventObjectIntelligence.link = eventObjectIntelligence.full_url;
      } else if (eventObjectIntelligence.sys_type.toLowerCase() == "salesforce.contact") {
        eventObjectIntelligence.title = "Salesforce Contact";
        eventObjectIntelligence.logo = "./assets/salesforce/contact.svg";
        eventObjectIntelligence.backgroundColor = "#1798c1";
        eventObjectIntelligence.textColor = "text-white";
        eventObjectIntelligence.link = eventObjectIntelligence.full_url;
      } else if (eventObjectIntelligence.sys_type.toLowerCase() == "salesforce.lead") {
        eventObjectIntelligence.title = "Salesforce Lead";
        eventObjectIntelligence.logo = "./assets/salesforce/lead.svg";
        eventObjectIntelligence.backgroundColor = "#1798c1";
        eventObjectIntelligence.textColor = "text-white";
        eventObjectIntelligence.link = eventObjectIntelligence.full_url;
      } else if (eventObjectIntelligence.sys_type.toLowerCase() == "salesforce.opportunity") {
        eventObjectIntelligence.title = "Salesforce Opportunity";
        eventObjectIntelligence.logo = "./assets/salesforce/opportunity.svg";
        eventObjectIntelligence.backgroundColor = "#1798c1";
        eventObjectIntelligence.textColor = "text-white";
        eventObjectIntelligence.link = eventObjectIntelligence.full_url;
      }

      // Get the image for the link
      console.log(`Getting metadata and data for event object intelligence`);
      const metadataResponse = await Storage.getInstance().getPreviewRecordMetadataForLink(eventObjectIntelligence.sys_id_external, eventObjectIntelligence.sys_type);
      console.log(metadataResponse);

      // Append the details of the record to the bottom of the card
      eventObjectIntelligence.metadata_fields = metadataResponse;
    }

    setLoading(false);
    setEventObject(eventObjectIntelligence);
  }

  useEffect(() => {
    setLoading(true);
    loadEventObjectIntelligence(props.eventObject);
    console.log(props.eventObject);
  }, [props.eventObject]);

  return (
    <React.Fragment>
      {eventObject != null &&
        <Card className="border-0 shadow-sm mt-3">
          <Card.Header style={{backgroundColor: eventObject.backgroundColor}}>
            <Row>
              <Col lg="9">
                <img src={eventObject.logo} className="me-1" style={{maxHeight: "30px"}}/>
                <span className={`fw-bold align-middle ${eventObject.textColor}`}>{eventObject.title}</span>
              </Col>
              <Col>
                <div className="d-grid d-md-flex justify-content-md-end">
                  <a href={eventObject.link} target="_blank" className="btn btn-sm btn-light"><i className="bi bi-box-arrow-up-right"></i></a>
                </div>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <EventObjectIntelligenceCardMetadata stop={stopIntelligence} save={saveIntelligence} acknowledge={acknowledgeIntelligence} eventType={props.eventType} eventObject={eventObject} />
            {eventObject.sys_id_section != null && eventObject.sys_id_section.trim().length > 0 &&
              <Row>
                <Col>
                  <h6 className="mt-3 text-muted fw-bolder">Source of recommendation:</h6>
                  <FlexpaneViewIntelligenceCardSource processChange={props.processChange} sys_id_section={eventObject.sys_id_section} viewNote={props.viewNote} handleLoadPinnedSections={props.handleLoadPinnedSections} />
                </Col>
              </Row>
            }
            {eventObject.sections_referenced != null && eventObject.sections_referenced.length > 0 &&
              <Row>
                <Col>
                  <h6 className="mt-3 text-muted fw-bolder">Referenced here:</h6>
                  {eventObject.sections_referenced.map((section_referenced, index) => (
                    <FlexpaneViewIntelligenceCardSource key={`event-object-intelligence-card-source-${index}`} processChange={props.processChange} sys_id_section={section_referenced.sys_id_section} viewNote={props.viewNote} handleLoadPinnedSections={props.handleLoadPinnedSections} />
                  ))}
                </Col>
              </Row>
            }
          </Card.Body>
        </Card>
      }
      {loading == true &&
        <Card className="border-0 shadow-sm mt-3">
          <Card.Body>
            <Row>
              <Col className="flex-grow-1 position-relative" style={{height: "100px"}}>
                <div className="position-absolute top-50 start-50 translate-middle">
                  <div className="inner-circles-loader">
                    Loading...
                  </div>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      }
    </React.Fragment>
  );
};

export default EventObjectIntelligenceCard;
