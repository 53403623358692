import React, { useEffect, useState } from "react";

import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { Storage } from "../../../utils/storage";
import Realtime from "../../../utils/realtime";

const FlexpaneButtonIntelligence = (props) => {
  const [showIntelligenceButton, setShowIntelligenceButton] = useState(false);

  const refreshIntelligenceEvents = async() => {
    const intelligenceEventsResult = await Storage.getInstance().getIntelligenceEvents();
    const intelligenceRecommendationsResult = await Storage.getInstance().getIntelligenceRecommendations();

    // console.log(intelligenceEventsResult);
    // console.log(intelligenceRecommendationsResult);

    if ((intelligenceEventsResult != null && intelligenceEventsResult.length > 0) ||
        (intelligenceRecommendationsResult != null && intelligenceRecommendationsResult.length > 0)) {
      setShowIntelligenceButton(true);
    } else {
      setShowIntelligenceButton(false);
    }
  }

  useEffect(() => {
    if (props.tenant != null) {
      refreshIntelligenceEvents();

      // console.log(`tenant.${props.tenant.sys_id}`);
      const tenant_channel = Realtime.getInstance().getTenantChannel(props.tenant.sys_id);
      tenant_channel.bind('event.update', data => {
        console.log(`Recieved event.update`, data);
        refreshIntelligenceEvents();
      });
      tenant_channel.bind('event.delete.one', data => {
        console.log(`Recieved event.delete.one`, data);
        refreshIntelligenceEvents();
      });
      tenant_channel.bind('recommendation.update', data => {
        console.log(`Recieved recommendation.update`, data);
        refreshIntelligenceEvents();
      });
      tenant_channel.bind('recommendation.delete.one', data => {
        console.log(`Recieved recommendation.delete.one`, data);
        refreshIntelligenceEvents();
      });
    }
  }, [props.tenant]);

  return (
    <React.Fragment>
      {showIntelligenceButton == true &&
        <OverlayTrigger placement="bottom" overlay={<Tooltip>Intelligence has stuff for you to check out</Tooltip>}>
          <button className="btn btn-sm btn-light ms-2" onClick={() => props.viewIntelligence()}><div className="notify-inner-circles-loader" style={{marginTop: "5px"}}>Intelligence</div></button>
        </OverlayTrigger>
      }
    </React.Fragment>
  );
};

export default FlexpaneButtonIntelligence;
