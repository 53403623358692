import React, { useEffect, useState } from "react";

import Block from "./Block";

import { Alert, Col, Row, Card, OverlayTrigger, Tooltip } from "react-bootstrap";

import { Parser } from "../../../utils/parser";
import { Storage } from "../../../utils/storage";

import ViewNoteControls from "./ViewNoteControls";
import Tags from "./Tags";

const ViewNotePanel = (props) => {
  const [note, setNote] = useState(null);
  const [removeMargin, setRemoveMargin] = useState(false);
  const [unread, setUnread] = useState(false);

  const reactionCallback = (callback) => {
    // console.log(callback);
    if (callback != null && callback.sys_id_sharing_event == null) {
      // console.log(`Setting note sharing to be off`);
      setUnread(false);
    }
  }

  useEffect(() => {
    // console.log(`Rendering note`);
    // console.log(`Calling use effect`);
    if (props.removeMargin == true) {
      setRemoveMargin(true);
    }

    if (props.note != null) {
      Storage.getInstance().getNote(props.note.sys_id).then((noteResponse) => {
        // console.log(noteResponse);
        if (noteResponse.is_private == true) {
          setNote(noteResponse);
        } else {
          Parser.convertToBlockForHistory(noteResponse).then((editorNote) => {
            // console.log(editorNote);
            if (editorNote.sys_id_sharing_event != null && editorNote.sys_id_sharing_event.trim().length > 0) {
              setUnread(true);
            }

            setNote(editorNote);
          });
        }
      });
    } else {
      setNote(null);
    }
  }, [props.note, props.scrolling]);

  const getRenderedBlocks = (note) => {
    // console.log(`Rerendering blocks in note`);
    if (note.editor_blocks != null && note.editor_blocks.length > 0) {
      // console.log(editor_blocks);
      let isAction = false;
      const renderedBlocks = [];

      for (let x = 0; x < note.editor_blocks.length; x++) {
        // console.log(`Text for block: ${editor_blocks[x].data.text}`);
        if (note.editor_blocks[x].type == "action") {
          // console.log(`Block is an action`);
          isAction = true;
        }

        // Cancel the action block if we have a blank or header
        if ((note.editor_blocks[x].data.text != null && note.editor_blocks[x].data.text.trim().length == 0) ||
             note.editor_blocks[x].type == "header") {
          // console.log(`Block is an empty paragraph or header`);
          // console.log(`${editor_blocks[x].type}`);
          // console.log(editor_blocks[x].data);
          if (note.editor_blocks[x].data.text != null && note.editor_blocks[x].data.text.trim().length == 0) {
            note.editor_blocks[x].data.text = "&nbsp;";
          }
          isAction = false;
        }

        const uniqueIdentifier = crypto.randomUUID();

        if (isAction == true && note.editor_blocks[x].type != "action") {
          // console.log(`Block is part of an action`);
          renderedBlocks.push(<div key={"render-note-panel-block-" + uniqueIdentifier} style={{marginLeft: "0px", borderLeft: "4px solid lightgray", paddingLeft: note.editor_blocks[x].type == "paragraph" ? "60px" : "40px"}}>
            <Block key={"render-note-panel-block-block-" + uniqueIdentifier} note={note} block={note.editor_blocks[x]} in_action={true} />
          </div>);
        } else {
          // console.log(`Block is not part of an action`);
          renderedBlocks.push(<Block key={"render-note-panel-block-block-block" + uniqueIdentifier} note={note} block={note.editor_blocks[x]} in_action={false} />);
        }
      }
      return <React.Fragment>{renderedBlocks}</React.Fragment>
    } else if (note.sys_id_note == null || note.sys_id_note.trim().length == 0) {
      return <Alert variant="info">You don't have permission to view this note unfortunately <span className="fs-5">🥹</span></Alert>;
    } else {
      return <React.Fragment></React.Fragment>;
    }
  }

  return (
    <React.Fragment>
      {note != null &&
        <React.Fragment>
          <Card style={unread == true ? {borderColor: "#FFD57E"} : {borderColor: "#FFFFFF"}} className={`rounded shadow-sm ${props.removeTopBottom == true ? "" : "mt-1"} ${props.scrolling == false ? (props.removeTopBottom == true ? "" : "mb-4") : (props.removeTopBottom == true ? "" : "mt-3")} ${removeMargin == false ? "ms-2 me-2" : ""}`}>
            <Card.Body>
              {props.scrolling == true &&
                <Row className="pb-3">
                  <Col>
                    <span className="fs-6 text-muted"><i className="bi bi-journal me-2"></i> <span className="fw-bolder">Note</span></span>
                    <div className="float-end">
                      <OverlayTrigger placement="top" overlay={<Tooltip>Close this note</Tooltip>}>
                        <button className="btn btn-sm btn-light text-muted" onClick={props.closeNote}><i className="bi bi-x-lg"></i></button>
                      </OverlayTrigger>
                    </div>
                  </Col>
                </Row>
              }
              {(note.is_private == null || note.is_private == false) &&
                <React.Fragment>
                  <Row className="pb-3">
                    <Col>
                      <ViewNoteControls reactionCallback={reactionCallback} processChange={props.processChange} handleLoadPinnedNotes={props.handleLoadPinnedNotes} note={note} sys_id_note={note.sys_id_note} loadNote={props.loadNote} />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-break">
                      <Tags note={note} />
                      <h2 dangerouslySetInnerHTML={{__html: note.title}}/>
                    </Col>
                  </Row>
                  {props.scrolling == true &&
                    <Row className="d-flex flex-column" style={{height: "62vh"}}>
                      <Col className="overflow-auto flex-grow-1">
                        {getRenderedBlocks(note)}
                      </Col>
                    </Row>
                  }
                  {props.scrolling == false &&
                    <React.Fragment>
                      {getRenderedBlocks(note)}
                    </React.Fragment>
                  }
                </React.Fragment>
              }
              {note.is_private == true &&
                <Alert variant="info">You don't have permission to view this note unfortunately <span className="fs-5">🥹</span></Alert>
              }
            </Card.Body>
          </Card>
        </React.Fragment>
      }
      {note == null &&
        <Card className={`rounded border-0 shadow-sm mt-1 ${props.scrolling == false ? "mb-4" : "mt-3"} ${removeMargin == false ? "ms-2 me-2" : ""}`}>
          <Card.Body>
            <div className="box-placeholder">
              <div className="excerpt p-4">
                <div className="text line"></div>
                <div className="text line"></div>
                <div className="text"></div>
              </div>
            </div>
          </Card.Body>
        </Card>
      }
    </React.Fragment>
  );
};

export default ViewNotePanel;
