import React, { useRef, useState, useEffect } from "react";
import { render } from "react-dom";

import { gtag, install } from 'ga-gtag';
import { Parser } from "./utils/parser";
import { NotifyProvider, Storage } from "./utils/storage";
import { Editor } from "./utils/editor";
import { Formatter } from "./utils/formatter";
import { FunPack } from "./utils/funpack";
import Gravitar from "./utils/gravitar";
import Blocks from "./utils/blocks";

import MentionPopover from "./react/editor/components/MentionPopover";
import SectionResultPanel from "./react/editor/components/SectionResultPanel";
import SectionCommentPanel from "./react/editor/components/SectionCommentPanel";

import Converter from "./tunes/converter";
import NotifySection from "./tunes/section";
import SignInWithNotifyLinks from "./tunes/siwnl";
import EditorJS from '@editorjs/editorjs';
import Shortcut from '@codexteam/shortcuts';
import DatePicker from 'react-datepicker';

import { Collapse, OverlayTrigger, Overlay, Popover, Card, Tooltip, Nav, Navbar, Row, Col, Form, Modal, Toast, Button } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';

import Events from "./utils/events";
import Realtime from "./utils/realtime";

import ViewNetworkPanel from "./react/editor/components/ViewNetworkPanel";
import ViewNoteControlSharing from "./react/editor/components/ViewNoteControlSharing";
import ViewNoteControlComments from "./react/editor/components/ViewNoteControlComments";
import SurfaceNotesView from "./react/editor/components/SurfaceNotesView";
import TagPeopleMenuButton from "./react/editor/components/TagPeopleMenuButton";
import TagTopicsMenuButton from "./react/editor/components/TagTopicsMenuButton";
import FlexpaneViewReference from "./react/editor/components/FlexpaneViewReference";
import ReferenceCardSettings from "./react/editor/components/ReferenceCardSettings";
import ReferenceCardDetails from "./react/editor/components/ReferenceCardDetails";
import ViewNotePanel from "./react/editor/components/ViewNotePanel";
import FlexpaneViewIntelligence from "./react/editor/components/FlexpaneViewIntelligence";
import FlexpaneViewSharingEvents from "./react/editor/components/FlexpaneViewSharingEvents";
import FlexpaneButtonIntelligence from "./react/editor/components/FlexpaneButtonIntelligence";
import FlexpaneViewIntelligenceSearch from "./react/editor/components/FlexpaneViewIntelligenceSearch";

const EDITOR_HOLDER_ID = 'ellie_editorjs';
const mentionPrefixes = ["#", "@"];

install('G-60RWZGDQVP');

const findUsers = async (search) => {
  let tenant = Editor.getInstance().getTenant();
  let people = await Storage.getInstance().findPeople(search, null);
  // console.log(search);

  if (search != null && (search.toLowerCase() == "m" || search.toLowerCase() == "me") &&
      tenant != null && tenant.reference != null) {
    let found = false;
    // Check to see if the search contains the tenant reference
    for (let x = 0; x < people.length; x++) {
      if (tenant.reference.sys_id == people[x].sys_id) {
        people[x].alias = `${people[x].alias} (me)`;
        found = true;
      }
    }
    if (found == false) {
      // console.log(`Prepending to peeople list`);
      people = [tenant.reference, ...people];
    }
  }

  return people;
};

const findTopics = async (search) => {
  return await Storage.getInstance().findTopics(search, null);
};

const getDateForValue = (value) => {
  const year = value.getFullYear();
  const month = String(value.getMonth() + 1).padStart(2, '0');
  const day = String(value.getDate()).padStart(2, '0');

  // console.log(year);
  // console.log(month);
  // console.log(day);
  return `${year}-${month}-${day}`;
}

const App = () => {
  const [showLogin, setShowLogin] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [loadingFilterReferences, setLoadingFilterReferences] = useState(false);
  const [tenant, setTenant] = useState(null);
  const [showInviteToast, setShowInviteToast] = useState(false);
  const [inviteToastEmail, setInviteToastEmail] = useState(null);
  const [showTimelineToast, setShowTimelineToast] = useState(false);
  const [showStillSavingToast, setShowStillSavingToast] = useState(false);
  const [showNoteNotAvailableToast, setShowNoteNotAvailableToast] = useState(false);
  const [showTagSavedToast, setShowTagSavedToast] = useState(false);
  const [viewIndex, setViewIndex] = useState(1);
  const [filterGraphDays, setFilterGraphDays] = useState(0);
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [commentsCount, setCommentsCount] = useState(0);

  const [movedNoteId, setMovedNoteId] = useState(null);

  const [focusMode, setFocusMode] = useState(false);
  
  const [references, setReferences] = useState(null);
  const [tenantReference, setTenantReference] = useState(null);
  const [sysIdNote, setSysIdNote] = useState(null);

  const [showReferenceDetail, setShowReferenceDetail] = useState(false);
  const [wasShowingReferenceDetail, setWasShowingReferenceDetail] = useState(false);
  const [referenceDetailReferenceId, setReferenceDetailReferenceId] = useState(null);
  const [showNoteDetail, setShowNoteDetail] = useState(false);
  const [wasShowingNoteDetail, setWasShowingNoteDetail] = useState(false);
  const [noteDetailSectionResult, setNoteDetailSectionResult] = useState(null);
  const [showIntelligenceDetail, setShowIntelligenceDetail] = useState(false);
  const [wasShowingIntelligenceDetail, setWasShowingIntelligenceDetail] = useState(false);
  const [showSharingEventsDetail, setShowSharingEventsDetail] = useState(false);
  const [showIntelligenceSearchDetail, setShowIntelligenceSearchDetail] = useState(false);
  const [wasShowingIntelligenceSearchDetail, setWasShowingIntelligenceSearchDetail] = useState(false);
  const [wasShowingSharingEventsDetail, setWasShowingSharingEventsDetail] = useState(false);
  const [originalNoteForView, setOriginalNoteForView] = useState(null);

  const [showReferenceCard, setShowReferenceCard] = useState(false);
  const [reference, setReference] = useState(null);

  const [showProviderAuthentication, setShowProviderAuthentication] = useState(false);
  const [providerForAuthentication, setProviderForAuthentication] = useState("");
  const [sysIdExternalForAuthentication, setSysIdExternalForAuthentication] = useState("");

  const [resourceLinks, setResourceLinks] = useState(null);

  const [showSectionDetails, setShowSectionDetails] = useState(false);
  const [sectionResult, setSectionResult] = useState(null);

  const [filterReferences, setFilterReferences] = useState(null);
  
  const [position, setPosition] = useState();
  const [range, setRange] = useState();
  const [mentionText, setMentionText] = useState("");
  const [prefix, setPrefix] = useState("");

  const [showNoteDetailsButton, setShowNoteDetailsButton] = useState(false);
  const [showConnectButton, setShowConnectButton] = useState(false);
  
  const [loadingPeople, setLoadingPeople] = useState(false);
  const [loadingTopics, setLoadingTopics] = useState(false);
  const [loadingPinnedNotes, setLoadingPinnedNotes] = useState(false);
  const [loadingPinnedSections, setLoadingPinnedSections] = useState(false);
  const [people, setPeople] = useState(null);
  const [topics, setTopics] = useState(null);
  const [pinnedNotes, setPinnedNotes] = useState(null);
  const [pinnedSections, setPinnedSections] = useState(null);
  const [filterByPinned, setFilterByPinned] = useState(false);
  const [filterByUnread, setFilterByUnread] = useState(false);
  const [filterByAssignedToMe, setFilterByAssignedToMe] = useState(false);
  const [filterByDate, setFilterByDate] = useState(null);
  const [calendarFilterByDate, setCalendarFilterByDate] = useState(new Date());
  const [showFilterByDate, setShowFilterByDate] = useState(false);
  const [showPinnedSysType, setShowPinnedSysType] = useState(false);
  const [showPinnedForSysType, setShowPinnedForSysType] = useState(null);
  const [showCreateNewSysType, setShowCreateNewSysType] = useState(false);
  const [showCreateNewForSysType, setShowCreateNewForSysType] = useState(null);
  const [loadingManuallyPinnedTags, setLoadingManuallyPinnedTags] = useState(false);
  const [manuallyPinnedTags, setManuallyPinnedTags] = useState([]);
  const [manuallyUnpinnedTags, setManuallyUnpinnedTags] = useState([]);

  const [intelligenceSearch, setIntelligenceSearch] = useState('');
  const [intelligenceSearchHtml, setIntelligenceSearchHtml] = useState('');

  const [pinned, setPinned] = useState(false);

  const [magicLinkEmail, setMagicLinkEmail] = useState("");

  const [friendlyName, setFriendlyName] = useState("");
  const [email, setEmail] = useState("");
  const [referenceIdSharing, setReferenceIdSharing] = useState(2);
  const [sendInvite, setSendInvite] = useState(false);
  const [alias, setAlias] = useState("");
  const [details, setDetails] = useState({});
  const [existingTagsResponse, setExistingTagsResponse] = useState(null);
  const [createNewSharingReference, setCreateNewSharingReference] = useState({});
  const [createNewDetailsReference, setCreateNewDetailsReference] = useState({});

  const [showDuplicateTagsWarning, setShowDuplicateTagsWarning] = useState(false);
  const [showTagsWarningOverlay, setShowTagsWarningOverlay] = useState(false);
  const [tagWarning, setTagWarning] = useState(null);

  const [onboardingStep, setOnboardingStep] = useState(1);

  const [showInvitorRequest, setShowInvitorRequest] = useState(false);
  const [invitor, setInvitor] = useState(null);

  const [sharingEvents, setSharingEvents] = useState([]);

  const ejInstance = useRef();
  const tagsContentEditable = useRef(null);
  const createNewAlias = useRef(null);
  const moveNoteTarget = useRef(null);

  const manageFilterByAssignedToMeReference = (filter) => {
    // console.log(filter);
    let references = Editor.getInstance().references;
    if (references == null) {
      references = [];
    }

    // console.log(references);
    if (references != null) {
      if (filter == true) {
        // console.log(`Filter`);
        if (references.length == 0) {
          // Add me to the filter
          // console.log(`Apply me reference`, tenantReference);
          setNoteViewForReference(tenantReference);
        } else {
          let found = false;
          for (let x = 0; x < references.length; x++) {
            if (references[x].sys_id_reference == tenantReference.sys_id) {
              found = true;
            }
          }
          if (found == false) {
            // Add me to the filter
            setNoteViewForReference(tenantReference);
          }
        }
      } else {
        let found = false;
        for (let x = 0; x < references.length; x++) {
          if (references[x].sys_id_reference == tenantReference.sys_id) {
            found = true;
          }
        }
        if (found == true) {
          // Remove me from the filter
          setNoteViewForReference(tenantReference);
        }
      }
    }
  }

  const handleFilterByReference = (reference) => {
    setFilterByPinned(false);
    setFilterByUnread(false);
    setFilterByAssignedToMe(false);

    handleFilterByDate(false);
    manageFilterByAssignedToMeReference(false);

    setNoteViewForReference(reference);
  }

  const handleFilterByPinned = (filter) => {
    setFilterByPinned(filter);
    setFilterByUnread(false);
    setFilterByAssignedToMe(false);

    handleFilterByDate(false);
    manageFilterByAssignedToMeReference(false);
    setNoteViewForReference(null);
  }

  const handleFilterByUnread = (filter) => {
    setFilterByPinned(false);
    setFilterByUnread(filter);
    setFilterByAssignedToMe(false);

    handleFilterByDate(false);
    manageFilterByAssignedToMeReference(false);
    setNoteViewForReference(null);
  }

  const handleFilterByAssignedToMe = (filter) => {
    setFilterByPinned(false);
    setFilterByUnread(false);
    setFilterByAssignedToMe(filter);

    handleFilterByDate(false);
    manageFilterByAssignedToMeReference(filter);
  }

  const handleFilterByDate = (show) => {
    if (show == true) {
      const filterDate = new Date();
      setFilterByDate(getDateForValue(filterDate));
      setCalendarFilterByDate(filterDate);
      setShowFilterByDate(true);
      setFilterByPinned(false);
      setFilterByUnread(false);
      setFilterByAssignedToMe(false);  

      manageFilterByAssignedToMeReference(false);
      setNoteViewForReference(null);
    } else {
      setFilterByDate(null);
      setCalendarFilterByDate(null);
      setShowFilterByDate(false);
    }
  }

  const refreshSharingEvents = async() => {
    // Load the sharing events immediately as we need the UI to reflect a valid state on load
    const sharingEventsResult = await Storage.getInstance().getSharingEvents();
    setSharingEvents(sharingEventsResult);

    // const commentEventsResult = await Storage.getInstance().getCommentEvents();
    // setCommentEvents(commentEventsResult);

    // console.log(sharingEventsResult);
    // console.log(commentEventsResult);
  }

  const hideDuplicateTagsWarning = () => {
    setShowDuplicateTagsWarning(false);
  }

  const hideTagsWarningOverlay = () => {
    setShowTagsWarningOverlay(false);
  }

  const handleFriendlyNameChange = (event) => setFriendlyName(event.target.value);

  const handlePersonsCollapseState = (event) => {
    setTimeout(() => {
      const personsCollapse = document.getElementById("nfy-people-collapse");
      if (personsCollapse.classList.contains("show")) {
        window.localStorage.setItem('persons_collapse_state', 'show');
      } else {
        window.localStorage.setItem('persons_collapse_state', '');
      }
    }, 1000);    
  }

  const handleTopicsCollapseState = (event) => {
    setTimeout(() => {
      const topicsCollapse = document.getElementById("nfy-topic-collapse");
      if (topicsCollapse.classList.contains("show")) {
        window.localStorage.setItem('topics_collapse_state', 'show');
      } else {
        window.localStorage.setItem('topics_collapse_state', '');
      }
    }, 1000);
  }

  const handleCreateNewDetailsReferenceUpdate = async(createNewReference) => {
    setDetails(createNewReference.details);
    setCreateNewDetailsReference(createNewReference);
    return createNewReference;
  }

  const handleCreateNewSharingReferenceUpdate = (createNewReference) => {
    setReferenceIdSharing(createNewReference.sys_id_sharing);
    setCreateNewSharingReference(createNewReference);
  }

  const handleFilterByDateChange = (value) => {
    console.log(value);
    console.log(value.toISOString());

    const year = value.getFullYear();
    const month = String(value.getMonth() + 1).padStart(2, '0');
    const day = String(value.getDate()).padStart(2, '0');

    setCalendarFilterByDate(value);
    setFilterByDate(getDateForValue(value));
  }

  const handleMagicLinkEmailChange = async(event) => {
    setMagicLinkEmail(event.target.value);
  }

  const handleAliasChange = async(event) => {
    const alias = Parser.convertAlias(event.target.value);

    setAlias(alias);
    setFriendlyName(Parser.makeFriendly(alias));

    let existingTags = [];
    if (alias != null && alias.trim().length > 1) {
      if (showCreateNewForSysType == "topic") {
        existingTags = await findTopics(alias);
      } else {
        existingTags = await findUsers(alias);
      }
    }

    setExistingTagsResponse(existingTags);
  }

  const handleAliasDuplicate = async() => {
    if (existingTagsResponse != null && existingTagsResponse.length > 0) {
      setShowDuplicateTagsWarning(true);
    } else {
      setShowDuplicateTagsWarning(false);
    }
  }

  const handleEmailChange = (event) => {
    // console.log(event.target);
    // console.log(event.target.value);
    if (event.target.value.trim().length > 0) {
      // console.log(`Setting send invite to true`);
      setSendInvite(true);
    } else {
      setSendInvite(false);
    }

    setEmail(event.target.value);
  }

  const handleCreateNewTag = async() => {
    const tagToCreate = {
      alias: alias,
      friendly_name: friendlyName,
      email: showCreateNewForSysType == "topic" ? "" : email,
      sys_id_sharing: referenceIdSharing,
      invite: (email != null && email.trim().length > 0) ? sendInvite : false,
      shared_tenants: createNewSharingReference.shared_tenants,
      details: details,
      auto_share_note: createNewSharingReference.auto_share_note,
      auto_share_block: createNewSharingReference.auto_share_block,
    }

    let tagResponse = null;
    if (showCreateNewForSysType == "topic") {
      tagResponse = await Storage.getInstance().setTopic(tagToCreate);
    } else {
      tagResponse = await Storage.getInstance().setPeople(tagToCreate);
    }

    // Pin the reference so the user doesn't think it's disappeared
    await Storage.getInstance().pinReference(tagResponse);

    // Refresh the pinned list for this type
    handlePinned(showCreateNewForSysType);

    setAlias("");
    setEmail("");
    setFriendlyName("");
    setReferenceIdSharing(2);
    setSendInvite(false);    
    setCreateNewSharingReference({
      sys_type: showCreateNewForSysType,
      sys_id_sharing: 2,
    });
    setShowTagSavedToast(true);
    setExistingTagsResponse(null);
    setShowDuplicateTagsWarning(false);
    setDetails(null);
    setCreateNewDetailsReference({
      details: {
        link: null,
        phone: null,
        twitter: null,
        linked_in: null,
        description: null,
      }
    });
  }

  const handleOnboardingDone = () => {
    window.localStorage.setItem('welcome2', 'done');
    window.localStorage.setItem('sharing_sys_id_reference', '');

    setShowOnboarding(false);
    setOnboardingStep(0);

    FunPack.celebrate();
  }

  const isOnboarding = () => {
    if (window.localStorage.getItem('welcome2') == null ||
        window.localStorage.getItem('welcome2').trim().length == 0) {
      return true;
    }
    return false;
  }

  const isLoggedIn = () => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    // Capture any sharing parameters
    if (params.sharing_sys_id_reference != null && params.sharing_sys_id_reference.trim().length > 0) {
      // The reference is being invited to create an account or connect their existing account
      window.localStorage.setItem('sharing_sys_id_reference', params.sharing_sys_id_reference);
    }
    if (params.sharing_sys_id_note != null && params.sharing_sys_id_note.trim().length > 0) {
      // The reference has a note being shared with them and they need to create or connect an existing account
      window.localStorage.setItem('sharing_sys_id_note', params.sharing_sys_id_note);
    }
    if (params.sharing_sys_id_section != null && params.sharing_sys_id_section.trim().length > 0) {
      // The reference has a section being shared with them and they need to create or connect an existing account
      window.localStorage.setItem('sharing_sys_id_section', params.sharing_sys_id_section);
    }

    if (params.token != null && params.token.trim().length > 0) {
      window.localStorage.setItem('auth_token', params.token);
      // Delete the legacy entries
      window.localStorage.setItem('token', '');
      window.localStorage.setItem('account', '');
      // Refresh the page
      // props.history.push("/");
      console.log(`Pushing location: ${process.env.REACT_APP_FRONTEND_BASE_URL}`);
      window.location.replace(process.env.REACT_APP_FRONTEND_BASE_URL);
    } else if (params.tenantId != null && params.tenantId.trim().length > 0) {
      window.localStorage.setItem('token', params.tenantId);
      window.localStorage.setItem('account', params.tenantId);
      // Refresh the page
      // props.history.push("/");
      console.log(`Pushing location: ${process.env.REACT_APP_FRONTEND_BASE_URL}`);
      window.location.replace(process.env.REACT_APP_FRONTEND_BASE_URL);
    }

    if ((window.localStorage.getItem('token') != null &&
         window.localStorage.getItem('token').trim().length > 0) ||
        (window.localStorage.getItem('auth_token') != null &&
         window.localStorage.getItem('auth_token').trim().length > 0)) {
      Storage.getInstance().getTenant().then((tenant) => {
        // console.log(tenant);
        if (tenant.reference != null) {
          tenant.reference.alias = `${tenant.reference.alias} (me)`;
        }
        setTenant(tenant);
        Editor.getInstance().setTenant(tenant);

        handleLoadPinnedNotes();
        handleLoadPinnedSections();
        handleLoadPinnedPeople();
        handleLoadPinnedTopics();

        refreshSharingEvents();
        // console.log(`tenant.${tenant.sys_id}`);
        const tenant_channel = Realtime.getInstance().getTenantChannel(tenant.sys_id);
        tenant_channel.bind('share.add', data => {
          console.log(`Recieved share.add`, data);
          refreshSharingEvents();
        });
        tenant_channel.bind('share.delete.one', data => {
          console.log(`Recieved share.delete.one`, data);
          refreshSharingEvents();
        });
        tenant_channel.bind('comment.add', data => {
          console.log(`Recieved comment.add`, data);
          refreshSharingEvents();
        });
        tenant_channel.bind('comment.delete.one', data => {
          console.log(`Recieved comment.delete.one`, data);
          refreshSharingEvents();
        });

        // Check to see if we have a shared reference identifier - this means the user is inviting us to connect
        // their reference with our tenant reference - and make sure we don't show it when the user is onboarding!
        if (window.localStorage.getItem('sharing_sys_id_reference') != null &&
            window.localStorage.getItem('sharing_sys_id_reference').trim().length > 0 &&
            window.localStorage.getItem('welcome2') != null &&
            window.localStorage.getItem('welcome2').trim().length > 0) {
          Storage.getInstance().getInvitorInfo(window.localStorage.getItem('sharing_sys_id_reference')).then((invitorInfo) => {
            console.log(invitorInfo);
            // If the invitor is null, then the request has already happened (e.g. the reference has been merged already)
            if (invitorInfo != null && invitorInfo.sys_id_domain) {
              setInvitor(invitorInfo);
              setShowInvitorRequest(true);
            } else {
              // Clear the reference id
              window.localStorage.setItem('sharing_sys_id_reference', '');
            }
          });
        }
      });

      return true;
    }

    // console.log(`User is not logged in`);
    return false;
  };

  const handleConnectWithInvitor = async() => {
    const connectResponse = Storage.getInstance().connectReference(window.localStorage.getItem('sharing_sys_id_reference'));
    console.log(connectResponse);

    setShowInvitorRequest(false);
    setInvitor(null);

    window.localStorage.setItem('sharing_sys_id_reference', '');
  }

  const handleNotConnectWithInvitor = async() => {
    setShowInvitorRequest(false);
    setInvitor(null);

    window.localStorage.setItem('sharing_sys_id_reference', '');
  }

  const handleIntelligencePrompt = async(event) => {
    gtag('event', 'intelligence prompt', {
      'event_category': `engagement`,
      'event_label': 'Intelligence Prompt',
      'value': 'complete',
    });

    processChange();

    if (event.key === "Enter") {
      event.preventDefault();

      // This stops us from adding the carriage return to the html
      setIntelligenceSearchHtml(event.target.innerHTML);

      // Parse out any references so we're sending the appropriate content to search
      const result = Parser.parseReferencesFromHTML(null, new Map(), event.target.innerHTML);
      // console.log(content);

      // Set the value of the intelligence prompt so we can pass it into the flexpane
      setIntelligenceSearch(result);

      setViewIndex(1);
      viewIntelligenceSearch();

      event.target.innerHTML = '';
    }
  }

  const logOut = () => {
    window.localStorage.setItem('token', '');
    window.localStorage.setItem('account', '');
    setTenant(null);
    Editor.getInstance().setTenant(null);
    setShowLogin(true);
  };

  const handlePinTag = async(reference) => {
    const response = await Storage.getInstance().pinReference(reference);
    console.log(response);

    handleManagePinnedTags(document.getElementById("nft-pinned-tags-modal-search").value, reference.sys_type);
  }

  const handlePinNote = async() => {
    const response = await Storage.getInstance().pinNote(Editor.getInstance().getNote().sys_id, !pinned);
    console.log(response);
    setPinned(response.pinned);

    handleLoadPinnedNotes();
  }

  const handleManageCreateNewTags = async(sys_type) => {
    // Create a blank reference object so we have one
    setShowPinnedSysType(false);
    setShowCreateNewSysType(true);
    setCreateNewSharingReference({
      sys_type: sys_type,
      sys_id_sharing: 2,
    });
    setShowCreateNewForSysType(sys_type);
    setExistingTagsResponse(null);
    setShowDuplicateTagsWarning(false);
    setCreateNewDetailsReference({
      details: {
        link: null,
        phone: null,
        twitter: null,
        linked_in: null,
        description: null,
      }
    });
  }

  const handleCloseCreateNewTags = async(sys_type) => {
    setAlias("");
    setEmail("");
    setFriendlyName("");
    setReferenceIdSharing(2);
    setSendInvite(false);
    setCreateNewSharingReference({
      sys_type: sys_type,
      sys_id_sharing: 2,
    });
    setShowCreateNewSysType(false);
    setExistingTagsResponse(null);
    setShowDuplicateTagsWarning(false);
    setCreateNewDetailsReference({
      details: {
        link: null,
        phone: null,
        twitter: null,
        linked_in: null,
        description: null,
      }
    });
  }

  const handleManagePinnedTags = async(search, sys_type) => {
    setShowPinnedSysType(true);
    setShowPinnedForSysType(sys_type);
    setLoadingManuallyPinnedTags(true);
    setManuallyPinnedTags([]);
    setManuallyUnpinnedTags([]);

    let pinnedResponse = [];
    let unpinnedResponse = [];
    if (sys_type == "topic") {
      // console.log(`Loading pinned topics`)
      pinnedResponse = await Storage.getInstance().findTopics(search, "yes");
      unpinnedResponse = await Storage.getInstance().findTopics(search, "no");
    } else {
      // console.log(`Loading pinned people`)
      pinnedResponse = await Storage.getInstance().findPeople(search, "yes");
      unpinnedResponse = await Storage.getInstance().findPeople(search, "no");
    }

    setLoadingManuallyPinnedTags(false);
    setManuallyPinnedTags(pinnedResponse);
    setManuallyUnpinnedTags(unpinnedResponse);
  }

  const handleClosePinnedTags = async(sys_type) => {
    setShowPinnedSysType(false);

    if (sys_type == "topic") {
      await handleLoadPinnedTopics();
    } else {
      await handleLoadPinnedPeople();
    }
  }

  const handleLoadPinnedNotes = async() => {
    setLoadingPinnedNotes(true);
    Storage.getInstance().getPinnedNotes()
      .then(function (response) {
        setLoadingPinnedNotes(false);
        setPinnedNotes(response);
      }).catch((error) => {
        console.log(`Failed to load pinned notes: ${error}`);
      });
  }

  const handleLoadPinnedSections = async() => {
    setLoadingPinnedSections(true);
    Storage.getInstance().getPinnedSections()
      .then(function (response) {
        setLoadingPinnedSections(false);
        setPinnedSections(response);
      }).catch((error) => {
        console.log(`Failed to load pinned sections: ${error}`);
      });
  }

  const handlePinned = async(sys_type) => {
    if (sys_type == "topic") {
      await handleLoadPinnedTopics();
    } else {
      await handleLoadPinnedPeople();
    }
  }

  const handleLoadPinnedPeople = async() => {
    setLoadingPeople(true);
    let tenantResponse = null;
    // console.log(`Loading tenant information`, Editor.getInstance().getTenant());
    if (Editor.getInstance().getTenant() != null && Editor.getInstance().getTenant().reference != null) {
      // console.log(`Loading reference for tenant`);
      tenantResponse = await Storage.getInstance().getReference(Editor.getInstance().getTenant().reference.sys_id);
    }
    const peopleResponse = await Storage.getInstance().getPinnedReferences("person");
    setLoadingPeople(false);
    setTenantReference(tenantResponse);
    setPeople(peopleResponse);
  }

  const handleLoadPinnedTopics = async() => {
    setLoadingTopics(true);
    const topicsResponse = await Storage.getInstance().getPinnedReferences("topic");
    setLoadingTopics(false);
    setTopics(topicsResponse);
  }

  function debounce(func, timeout = 0){
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { 
        // console.log(`Applying final change`, args);
        func.apply(this, args); 
      }, timeout);
    };
  }

  const handleTitleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }

    const titleElement = document.getElementById("en-title-input");
    console.log(titleElement.innerText);
    if (titleElement.innerText != null && titleElement.innerText.trim().length > 0) {
      setShowNoteDetailsButton(true);
    }

    Editor.getInstance().setNoteTitle(document.getElementById("en-title-input").innerHTML);
    Editor.getInstance().setNoteTags(document.getElementById("en-tags-input").innerHTML);
    Editor.getInstance().save(ejInstance.current, false);

    if (event.key === "Enter") {
      ejInstance.current.focus();
    }
  }

  const handleTagsKeyUp = (event) => {
    if (event.key === "Enter") {
      console.log(`Enter key up`);

      event.preventDefault();
      event.stopPropagation();
      return;
    } else {
      processChange();
    }

    const tagForWarning = Parser.isTypingTagsOnly(event.target.innerText);
    if (tagForWarning != null) {
      setTagWarning(tagForWarning);
      setShowTagsWarningOverlay(true);
    } else {
      setTagWarning(null);
      setShowTagsWarningOverlay(false);
    }
  }

  const handleTagsKeyDown = (event) => {
    if (event.key === "Enter") {
      console.log(`Enter key down`);
      event.preventDefault();
      event.stopPropagation();

      updateNoteView();

      const titleElement = document.getElementById("en-title-input");
      titleElement.focus();
    } else {
      // processChange();
    }

    Editor.getInstance().setNoteTags(event.target.innerHTML);
    Editor.getInstance().save(ejInstance.current, false);
  }

  // This is a proxy call for debouncing the handle mention logic
  const processChange = debounce((e) => handleMention());
  // const processChange = () => { handleMention(); };

  const handleSearchTags = (event) => {
    if (event.key === 'Enter') {
      handleManagePinnedTags(document.getElementById("nft-pinned-tags-modal-search").value, showPinnedForSysType);
    }
  }

  const handleSearchTagsClick = () => {
    handleManagePinnedTags(document.getElementById("nft-pinned-tags-modal-search").value, showPinnedForSysType);
  }

  const handleMention = () => {
    const isSupported = typeof window.getSelection !== "undefined";
    if (isSupported) {
      // console.log(`Handling key down`);
      // console.log(`Is supported`);
      // Get the selection for the window so we can find the cursor
      const selection = window.getSelection();
      // Check to make sure we have at least one range in the selection - e.g. the cursor is active in the document
      if (selection.rangeCount !== 0) {
        // console.log(`Selection range count ok`);
        // Get the first range - we're assuming we're not dealing with content the user has highlighted all over the place
        const range = selection.getRangeAt(0);

        // Check to see the end of the range has a node in it - this will be the node in the document that
        // is in front of the cursor (everything)
        if (range.endContainer.data) {
          // console.log(`Range end container has data`);
          // Grab the raw data in the node starting at the beginning right to the position of the cursor
          const relevantData = range.endContainer.data.slice(
            0,
            range.endOffset
          );

          // Check to see if the data contains a prefix somewhere
          const prefixIndex = mentionPrefixes.findIndex((pf) => {
            if (relevantData.includes(pf)) {
              return true;
            } else {
              return false;
            }
          });

          if (prefixIndex !== -1) {
            // console.log(`Has prefix index`);
            // Our content does have a prefix in it - so split the string on that index so we have just the
            // relevant tag the user is attempting to create/insert (without the prefix)
            const mentionSplit = relevantData.split(
              mentionPrefixes[prefixIndex]
            );
            // console.log(mentionSplit);
            let mentionData = "";

            if (mentionSplit.length > 1) {
              // Check to see if this is really a mention by checking the entry before the prefix
              const prementionData = mentionSplit[mentionSplit.length -2];

              if (prementionData.length > 0) {
                // Get the last character in the string
                const lastCharacter = prementionData.charAt(prementionData.length - 1);

                if (lastCharacter.trim().length < lastCharacter.length ||
                    lastCharacter.includes(" ")) {
                  mentionData = mentionSplit[mentionSplit.length - 1];
                } else {
                  // If the last character isn't a space, then we don't have a proper mention - we have a URL or perhaps an email
                  console.log(`Last character is`, lastCharacter);
                  mentionData = "";
                }
              } else {
                mentionData = mentionSplit[mentionSplit.length - 1];
              }
            } else {
              mentionData = mentionSplit[mentionSplit.length - 1];
            }
            // console.log(mentionData);

            // console.log(`Mention data: ${mentionData}`);
            if (mentionData) {
              // console.log(`We have our mention to process`, mentionData);
              // We have some content in the mention, so we want to open the mention popover at this stage (e.g.
              // we have our mention, position, and range as well as the prefix to cause the component to show)
              setMentionText(mentionData);
              setPosition({
                x: range.getBoundingClientRect().x,
                y: range.getBoundingClientRect().y,
              });
              setRange(range);
              setPrefix(mentionPrefixes[prefixIndex]);
            } else {
              setMentionText("");
              setPosition(undefined);
              setRange(undefined);
              setPrefix(undefined);
            }
          } else {
            setMentionText("");
            setPosition(undefined);
            setRange(undefined);
            setPrefix(undefined);
          }
        } else {
          setMentionText("");
          setPosition(undefined);
          setRange(undefined);
          setPrefix(undefined);
        }
      }
    }
  }

  const handleShowStillSavingToast  = async(show) => {
    setShowStillSavingToast(show);
  }

  const handleShowInviteToast = async(email, show) => {
    setInviteToastEmail(email);
    setShowInviteToast(show);

    if (show == true) {
      gtag('event', 'invite_person', {
        'event_category': `engagement`,
        'event_label': 'Invite Person',
        'value': 'full',
      });
    }
  }

  const handleShowTimelineToast = async(show) => {
    setShowTimelineToast(show);

    if (show == true) {
      gtag('event', 'timeline_tags_full', {
        'event_category': `engagement`,
        'event_label': 'Timeline Tags Full',
        'value': 'full',
      });
    }
  }
  
  const handleShowTagSavedToast = async(show) => {
    setShowTagSavedToast(show);    

    if (show == true) {
      gtag('event', 'tag_saved', {
        'event_category': `engagement`,
        'event_label': 'Tag Saved',
        'value': 'saved',
      });
    }
  }

  const handleShowNoteNotAvailableToast = async(show) => {
    setShowNoteNotAvailableToast(show);    

    if (show == true) {
      gtag('event', 'note_not_available', {
        'event_category': `engagement`,
        'event_label': 'Note Not Available',
        'value': 'not_available',
      });
    }
  }

  const insertAtCursor = (myField, myValue) => {
    //IE support
    if (document.selection) {
        myField.focus();
        sel = document.selection.createRange();
        sel.innerText = myValue;
    }
    //MOZILLA and others
    else if (myField.selectionStart || myField.selectionStart == '0') {
        var startPos = myField.selectionStart;
        var endPos = myField.selectionEnd;
        myField.innerText = myField.innerText.substring(0, startPos)
            + myValue
            + myField.innerText.substring(endPos, myField.innerText.length);
    } else {
        myField.innerText += myValue;
    }
  }

  useEffect(() => {
    if (window.localStorage.getItem('persons_collapse_state') == null) {
      window.localStorage.setItem('persons_collapse_state', 'show');
    }
    if (window.localStorage.getItem('topics_collapse_state') == null) {
      window.localStorage.setItem('topics_collapse_state', 'show');
    }

    // This adds a click handler to the entire application - if the user clicks on anything that's a reference,
    // we want to have the software actually load the reference
    // console.log(document.getElementById("root"));
    document.getElementById("root").addEventListener("click", function(event) {
      // Check if the clicked element is an li element
      // console.log(event.target);
      if (event.target && event.target.classList.contains("en-mention") == true) {
        // Handle the click event on the dynamic li element
        // alert("You clicked on a dynamic list item!");
        Editor.getInstance().executeViewReference(event.target.getAttribute("data-sys-id"));
      }
    });

    const loggedIn = isLoggedIn();
    const onboarding = isOnboarding();

    if (onboarding == true) {
      if (loggedIn == true) {
        setOnboardingStep(3);
      } else {
        setOnboardingStep(1);
      }
      setShowOnboarding(true);
    } else {
      setShowLogin(!loggedIn);
    }

    if (loggedIn == true) {
      if (!ejInstance.current) {
        initEditor();

        // console.log(`Creating shortcut event`);
        let cmdS = new Shortcut({
          name : 'CMD+S',
          on : document.body,
          callback: function(event) {
            event.preventDefault();
            event.stopPropagation();

            if (Editor.getInstance().getNote() != null) {
              handleWrite(true);
            }
          }
        });   

        let cmdD = new Shortcut({
          name : 'CMD+D',
          on : document.body,
          callback: function(event) {
            event.preventDefault();
            event.stopPropagation();

            if (Editor.getInstance().getNote() != null) {
              handleWrite(true);
            }
          }
        });
      }

      document.getElementById("en-title-input").addEventListener('paste', (event) => {
        const clipboardData = event.clipboardData;
        const pastedData = clipboardData.getData('text');

        insertAtCursor(document.getElementById("en-title-input"), Parser.removeHTMLTags(pastedData));

        event.preventDefault();
        event.stopPropagation();
      });

      setTimeout(function() {
        document.getElementById("en-tags-input").focus();
        Storage.getInstance();
      }, 1000);

      return () => {
        ejInstance.current.destroy();
        ejInstance.current = null;
      }
    }
  }, []);

  const initEditor = () => {
    // console.log(`Initializing the editor`);
    const holderElement = document.getElementById(EDITOR_HOLDER_ID);

    const editor = new EditorJS({
      holder: holderElement,
      logLevel: "ERROR",
      data: Editor.DEFAULT_DOCUMENT,
      tools: {
        header: Blocks.getConfig("header"),
        paragraph: Blocks.getConfig("paragraph"),
        list: Blocks.getConfig("list"),
        action: Blocks.getConfig("action"),
        image: Blocks.getConfig("image"),
        recorder: Blocks.getConfig("recorder"),
        converter: {
          class: Converter,
        },
        notify_section: {
          class: NotifySection,
        },
        notify_links: {
          class: SignInWithNotifyLinks,
        },
      },
      onReady: () => {
        ejInstance.current = editor;
        Editor.getInstance().setSetNoteViewForReferenceProxy(setNoteViewForReference);
        Editor.getInstance().setViewReferenceProxy(viewReference);
        Editor.getInstance().setClearNoteProxy(proxyClearNote);
        Editor.getInstance().setEditorAPI(ejInstance.current);
        Editor.getInstance().setHandleShowReferenceCards(handleShowReferenceCards);
        Editor.getInstance().setHandleShowSectionDetails(handleShowSectionDetails);
        Editor.getInstance().setHandleShowReferenceCard(handleShowReferenceCard);
        Editor.getInstance().setHandleShowProviderAuthentication(handleShowProviderAuthentication);
        Editor.getInstance().setHandleLoadPinnedTags(handleRefreshPinnedTags);

        // Events.clickEnableMentionsOnParentElement(document.getElementById(EDITOR_HOLDER_ID));

        const params = new Proxy(new URLSearchParams(window.location.search), {
          get: (searchParams, prop) => searchParams.get(prop),
        });
    
        // Load the note if we have one
        if (params.sys_id_note != null && params.sys_id_note.trim().length > 0) {
          loadNote({ 
            sys_id: params.sys_id_note,
            sys_id_external: params.sys_id_external,
          });
        }
      },
      onChange: async(api, event) => {
        // console.log(event);
        handleMention(event);

        Editor.getInstance().setNoteTags(document.getElementById("en-tags-input").innerHTML);
        Editor.getInstance().setNoteTitle(document.getElementById("en-title-input").innerHTML);

        await Editor.getInstance().save(ejInstance.current, false);

        if (event.type == "block-changed") {
          // We add the extra button condition so this only fires once
          if (Editor.getInstance().getNote() != null && showNoteDetailsButton == false) {
            changeTagPlaceholderForActiveNote();
            window.history.pushState(Editor.getInstance().getNote().sys_id, Editor.getInstance().getNoteTitle(), `${process.env.REACT_APP_FRONTEND_BASE_URL}?sys_id_note=${Editor.getInstance().getNote().sys_id}`);
            setShowNoteDetailsButton(true);
            handleNoteComments();
            handleConnectButtonStatus();
          }
        }
      }
    });
  };

  const changeTagPlaceholderForActiveNote = () => {
    const tagsElement = document.getElementById("en-tags-input");
    tagsElement.setAttribute("data-placeholder", "Tag your note");
  }

  const handleNoteComments = () => {
    // console.log(`Loading comments for note: ${Editor.getInstance().getNote().sys_id}`);
    Storage.getInstance().getCommentsForNoteId(Editor.getInstance().getNote().sys_id).then((commentsResult) => {
      // console.log(`Comments for note are: ${commentsResult.length}`);
      assignCommentsCount(commentsResult.length);
    });
  }

  const handleConnectButtonStatus = () => {
    // setShowConnectButton(false);

    // const loader = document.getElementById("loading-indicator");

    // const loaderCheck = setInterval(() => {
      // Keep checking the loading indicator to see if the save is done
      // if (loader.classList.contains("heartbeat-loader-still")) {
        setShowConnectButton(true);
        // clearInterval(loaderCheck);
      // }
    // }, 1000);
  }

  const handleWrite = async(save) => {
    setViewIndex(1);

    if (save == true) {
      Editor.getInstance().setNoteTitle(document.getElementById("en-title-input").innerHTML);
      Editor.getInstance().setNoteTags(document.getElementById("en-tags-input").innerHTML);
  
      // console.log(`Saving note`);
      await Editor.getInstance().save(ejInstance.current, true);
    } else {
      // console.log(`Bypassing note save`);
    }

    // If the heartbeat loader is currently live, don't clear the editor
    // if (document.getElementById("loading-indicator").classList.contains('heartbeat-loader') == false) {
    window.location.replace(process.env.REACT_APP_FRONTEND_BASE_URL);
    // } else {
      // Tell the user what's happening
      // setShowStillSavingToast(true);
    // }
  }

  const proxyClearNote = (partial) => {
    Editor.getInstance().setNote(null);
    Editor.getInstance().createRegistry();
    Editor.getInstance().setNoteTitle(null);
    Editor.getInstance().setNoteTags(null);
    Editor.getInstance().resetSectionCache();

    if (partial != true) {
      Editor.getInstance().references = null;
    }

    window.history.pushState("", "", `${process.env.REACT_APP_FRONTEND_BASE_URL}`);
    
    if (partial != true) {
      setReferences(null);
    }

    setSysIdNote(null);
    setPinned(false);
    setShowNoteDetailsButton(false);

    document.getElementById("en-title-input").innerHTML = '';
    document.getElementById("en-tags-input").innerHTML = '';
    document.getElementById("en-tags-input").focus();

    ejInstance.current.render(Editor.DEFAULT_DOCUMENT);
  }

  const removeReferenceReferences = (sys_id_reference) => {
    // Remove this references from the array
    let references = Editor.getInstance().references.filter(e => e.sys_id_reference !== sys_id_reference);
    // console.log(`Reference removed: ${JSON.stringify(references)}`);

    Editor.getInstance().references = references;
    // console.log(`Updating references`);
    // console.log(`Setting references`);
    setReferences(references);

    // console.log(`Updating note view`);
    updateNoteView();
  }

  const handleViewReferenceClose = () => {
    setShowReferenceDetail(false);
    setWasShowingReferenceDetail(false);
    setReferenceDetailReferenceId(null);
    setReference(null);

    if (wasShowingIntelligenceDetail == true) {
      viewIntelligence();
    } else if (wasShowingNoteDetail == true) {
      viewNote(noteDetailSectionResult);
    } else if (wasShowingSharingEventsDetail == true) {
      viewSharingEvents();
    }
  }

  const viewReference = (sys_id_reference) => {
    setShowReferenceDetail(false);
    setReference(null);

    console.log(sys_id_reference);
    Storage.getInstance().getReference(sys_id_reference)
      .then((reference) => {
        if (typeof reference === 'string') {
          console.error(reference);
        } else {
          setReference(reference);
          setShowNoteDetail(false);
          setShowIntelligenceDetail(false);
          setShowSharingEventsDetail(false);
          setShowIntelligenceSearchDetail(false);
          setShowReferenceDetail(true);
          setWasShowingReferenceDetail(true);
          setReferenceDetailReferenceId(sys_id_reference);
        }
      });
  }

  const updateNoteView = () => {
    // console.log(references_referenced);
    // console.log(references);
    setViewIndex(1);

    const tagsElement = document.getElementById("en-tags-input");
    const references_referenced = Parser.getNoteReferencesReferencedFromContent(tagsElement.innerHTML);

    Editor.getInstance().references = references_referenced;
    // console.log(`Setting references`);
    setReferences(references_referenced);

    // Finally, normalize the tag element
    tagsElement.normalize();
  }

  const setNoteViewForReference = async(reference) => {
    // console.log(reference);

    // If the user has the timeline embedded, then we switch to the note view when a node is clicked in the network
    // console.log(`Change the the view back to the note and timeline`);
    setViewIndex(1);

    // If we have an active note, we save it and pin it and clear the editor
    if (Editor.getInstance().getNote() != null) {
      // Register the note we're moving
      setMovedNoteId(Editor.getInstance().getNote().sys_id);

      // Save the note
      await Editor.getInstance().save(ejInstance.current, false);

      // Pin the note - this is a bit like sticking it into draft as they didn't explicitly close the note
      await Storage.getInstance().pinNote(Editor.getInstance().getNote().sys_id, true);

      // Now clear out the note - this is without refreshing the page as is normal for new notes
      proxyClearNote(true);

      // Load the pinned notes again so it's clear the note moved
      await handleLoadPinnedNotes();

      setTimeout(() => {
        // The logic for telling the user we moved their cheese should now have executed so we clear the moved note
        setMovedNoteId(null);
      }, 250);
    }

    // If we pass in a null reference, assume we want the references cleared
    // TODO - this is to make the view switching logic a little simpler with the above note saving, etc
    if (reference == null) {
      document.getElementById("en-tags-input").innerHTML = '';
      Editor.getInstance().setNoteTags('');
      Editor.getInstance().references = [];
      setReferences([]);
      return;
    }

    const tagsElement = document.getElementById("en-tags-input");
    const referenceHtml = Parser.constructReferenceString(reference.sys_type == "topic" ? "#" : "@", reference);

    // Spoof this to a reference_referenced
    reference.sys_id_reference = reference.sys_id;

    let alreadyAssigned = false;
    if (Editor.getInstance().references != null && Editor.getInstance().references.length > 0) {
      for (let x = 0; x < Editor.getInstance().references.length; x++) {
        if (Editor.getInstance().references[x].sys_id_reference == reference.sys_id) {
          Editor.getInstance().references.splice(x, 1);
          setReferences(Editor.getInstance().references);
          alreadyAssigned = true;
          break;
        }
      }
    }

    if (alreadyAssigned == false) {
      // Reset the references to the provided tag
      Editor.getInstance().references = [reference];
      // console.log(`Setting references`);
      setReferences([reference]);

      // If we don't have a note loaded, then simply replace the tag
      // console.log(referenceHtml.html);

      tagsElement.innerHTML = referenceHtml.html;
      Editor.getInstance().setNoteTags(referenceHtml.html);
      // Events.clickEnableMentionsOnParentElement(tagsElement);

      // Set the title for the note, just in case we start writing something
      // assignTitle(true);
    } else {
      let referencesHtml = '';
      if (Editor.getInstance().references != null && Editor.getInstance().references.length > 0) {
        for (let x = 0; x < Editor.getInstance().references.length; x++) {
          referencesHtml += Parser.constructReferenceString(Editor.getInstance().references[x].sys_type == "topic" ? "#" : "@", Editor.getInstance().references[x]).html;
        }
      }

      tagsElement.innerHTML = referencesHtml;
      Editor.getInstance().setNoteTags(referencesHtml);
      // Events.clickEnableMentionsOnParentElement(tagsElement);
    }
  }

  const stopAutoSave = () => {
    Editor.getInstance().setAutoSave(false);
  }

  const startAutoSave = () => {
    Editor.getInstance().setAutoSave(true);
  }

  const closeNote = () => {
    setShowNoteDetail(false);
    setWasShowingNoteDetail(false);
    setOriginalNoteForView(null);
    setNoteDetailSectionResult(null);

    if (wasShowingIntelligenceDetail == true) {
      viewIntelligence();
    } else if (wasShowingReferenceDetail == true) {
      viewReference(referenceDetailReferenceId);
    } else if (wasShowingSharingEventsDetail == true) {
      viewSharingEvents();
    } else if (wasShowingIntelligenceSearchDetail == true) {
      viewIntelligenceSearch();
    }
  }

  const closeSharingEvents = () => {
    setShowSharingEventsDetail(false);
    setWasShowingSharingEventsDetail(false);

    if (wasShowingReferenceDetail == true) {
      viewReference(referenceDetailReferenceId);
    } else if (wasShowingNoteDetail == true) {
      viewNote(noteDetailSectionResult);
    } else if (wasShowingIntelligenceDetail == true) {
      viewIntelligence();
    } else if (wasShowingIntelligenceSearchDetail == true) {
      viewIntelligenceSearch();
    }
  }

  const closeIntelligence = () => {
    setShowIntelligenceDetail(false);
    setWasShowingIntelligenceDetail(false);

    if (wasShowingReferenceDetail == true) {
      viewReference(referenceDetailReferenceId);
    } else if (wasShowingNoteDetail == true) {
      viewNote(noteDetailSectionResult);
    } else if (wasShowingSharingEventsDetail == true) {
      viewSharingEvents();
    } else if (wasShowingIntelligenceSearchDetail == true) {
      viewIntelligenceSearch();
    }
  }

  const closeIntelligenceSearch = () => {
    setShowIntelligenceSearchDetail(false);
    setWasShowingIntelligenceSearchDetail(false);

    if (wasShowingReferenceDetail == true) {
      viewReference(referenceDetailReferenceId);
    } else if (wasShowingNoteDetail == true) {
      viewNote(noteDetailSectionResult);
    } else if (wasShowingSharingEventsDetail == true) {
      viewSharingEvents();
    } else if (wasShowingIntelligenceDetail == true) {
      viewIntelligence();
    }
  }

  const loadNote = (selectedNote) => {
    setViewIndex(1);

    gtag('event', 'load_note', {
      'event_category': `engagement`,
      'event_label': 'Load Note',
      'value': 'complete',
    });

    // console.log(selectedNote);
    stopAutoSave();

    // Close any current note view
    closeNote();

    // Set the note so we have it for referencing in saves, etc
    // console.log(`Selected note to load: ${JSON.stringify(selectedNote)}`);
    Editor.getInstance().createRegistry();

    // Get the note from storage
    Storage.getInstance().getNote(selectedNote.sys_id).then((loadedNote) => {
      // Parse the content so it's ready for the editor
      Parser.parseNoteContentForEditor(loadedNote).then(() => {
        // console.log(loadedNote);

        if (loadedNote == null || loadedNote.is_private == true) {
          // console.log(`The note is private or not available for some reason.`);
          handleShowNoteNotAvailableToast(true);
          window.history.pushState("", "", `${process.env.REACT_APP_FRONTEND_BASE_URL}`);
          setShowNoteDetailsButton(false);
          setCommentsCount(0);
        } else {
          Editor.getInstance().setNote(loadedNote);
          Editor.getInstance().setNoteTitle(loadedNote.title);
          Editor.getInstance().setNoteTags(loadedNote.tags);
          Editor.getInstance().setFilter(loadedNote.filter);

          Editor.getInstance().setTagsCache(loadedNote.references_referenced);

          document.getElementById("en-title-input").innerHTML = loadedNote.title;
          document.getElementById("en-tags-input").innerHTML = loadedNote.tags;

          changeTagPlaceholderForActiveNote();
      
          // Events.clickEnableMentionsOnParentElement(document.getElementById("en-tags-input"));
      
          Editor.getInstance().setSectionCache(loadedNote.sections);
          ejInstance.current.render({
            time: new Date().getTime(),
            blocks: Parser.getBlocksForEditor(loadedNote),
            version: "2.25.0",
          });

          // Do this to make the carousel a little less jittery
          Editor.getInstance().references = loadedNote.references_referenced;

          setSysIdNote(loadedNote.sys_id);
          setPinned(loadedNote.pinned);

          // Update the note view
          updateNoteView();

          startAutoSave();

          // Replace the URL as we have a loaded note
          window.history.pushState(loadedNote.sys_id, loadedNote.title, `${process.env.REACT_APP_FRONTEND_BASE_URL}?sys_id_note=${loadedNote.sys_id}`);
          setShowNoteDetailsButton(true);
          handleNoteComments();
          handleConnectButtonStatus();

          // If we have a link id, then we want to finish off the work of generating the link preview which likely prompted this
          // redirection/refresh sequence
          // console.log(selectedNote);
          if (selectedNote.sys_id_external != null && selectedNote.sys_id_external.trim().length > 0) {
            // Added a 3 second delay for the editor to finish loading the note
            setTimeout(() => {
              const urls = document.getElementsByTagName('a');
              let link = null;
  
              if (urls != null && urls.length > 0) {
                // console.log(`URLs exist on the page`);
                for (let url in urls) {
                  // console.log(`Current URL is: ${urls[url]}`);
                  if (urls[url].href != null && 
                      urls[url].href.trim().length > 0 && 
                      urls[url].href.indexOf(selectedNote.sys_id_external) > 0) {
                    // A link with this identifier has been found, that's good enough (even if it's not exactly the one we expected)
                    // console.log(`Link found: ${urls[url].href}`);
                    link = urls[url].href;
                    break;
                  }
                }
              }
            }, 3000);
          }
        }
      });
    });
  }

  const deleteNote = (sys_id_note) => {
    gtag('event', 'delete_note', {
      'event_category': `engagement`,
      'event_label': 'Delete Note',
      'value': 'complete',
    });

    setViewIndex(1);
    if (sys_id_note == null || sys_id_note.trim().length == 0) {
      // console.log(`We're looking to delete the active note`);
      if (Editor.getInstance().getNote() != null) {
        sys_id_note = Editor.getInstance().getNote().sys_id;
        if (sys_id_note == null || sys_id_note.trim().length == 0) {
          console.log(`There's no active note to delete`);
          return;
        }
      } else {
        console.log(`There's no active note to delete`);
        return;
      }
    }

    if (confirm('Are you sure you want to delete this note?')) {
      stopAutoSave();

      Storage.getInstance().deleteNote(sys_id_note).then(() => {
        // Now the note is deleted, refresh the UI as lots may have changed
        window.location.replace(process.env.REACT_APP_FRONTEND_BASE_URL);

/*         if (Editor.getInstance().getNote() != null && Editor.getInstance().getNote().sys_id == sys_id_note) {
          // Reset the editor as the current note got deleted
          Editor.getInstance().setNote(null);
          Editor.getInstance().setNoteTitle("");
          Editor.getInstance().setNoteTags("");
          Editor.getInstance().references = null;
          console.log(`Setting references`);
          setReferences(null);
          setSysIdNote(null);
          setPinned(false);

          document.getElementById("en-title-input").innerHTML = "";
          document.getElementById("en-tags-input").innerHTML = "";

          Editor.getInstance().createRegistry();
          ejInstance.current.render(Editor.DEFAULT_DOCUMENT);

          setShowNoteDetailsButton(false);
          setCommentsCount(0);

          handleLoadPinnedNotes();

          setTimeout(() => {
            document.getElementById("en-tags-input").focus();
          }, 1000);
        }
    
        startAutoSave(); */
      });
    }
  }

  const generateSectionResultFromSection = async(section) => {
    if (section.blocks_referenced != null && section.blocks_referenced.length > 0) {
      section.blocks = [];
      for (let x = 0; x < section.blocks_referenced.length; x++) {
        // console.log(section.blocks_referenced[x]);
        const block = Editor.getInstance().getCachedBlock(section.blocks_referenced[x].sys_id_block);
        section.blocks.push(block);
      }
    }

    // Took this from the TimelineSectionResultPanel
    if (section != null && section.blocks != null) {
      // console.log(props.section.blocks);
      const editor_blocks = await Parser.convertToBlocksForEditor(section.blocks);
      
      // console.log(editor_blocks);
      return {
        sys_id: section.sys_id,
        sys_id_note: section.sys_id_note,
        sys_type: section.sys_type,
        editor_blocks: editor_blocks,
        ellie_blocks: section.blocks,
        tenant: section.tenant,
        sentiment: section.sentiment,
        sizing: section.sizing,
        status: section.status,
        dates: section.dates,
        references_referenced: section.references_referenced,
        sys_date_created: section.sys_date_created,
      };
    }
    return null;
  }

  const handleRefreshPinnedTags = async() => {
    await handleLoadPinnedPeople();
    await handleLoadPinnedTopics();  
  }

  const handleShowSectionDetails = async(section) => {
    gtag('event', 'show_section_details', {
      'event_category': `engagement`,
      'event_label': 'Show Section Details',
      'value': 'complete',
    });

    setSectionResult(await generateSectionResultFromSection(section));
    setShowSectionDetails(true);
  }

  const handleShowReferenceCard = async(reference) => {
    gtag('event', 'show_tag_details', {
      'event_category': `engagement`,
      'event_label': 'Show Section Details',
      'value': 'complete',
    });

    reference = await Storage.getInstance().getReference(reference.sys_id);

    if (typeof reference === 'string') {
      console.error(reference);
    } else {
      const linksFilter = {
        tidbits: false,
        actions: true,
        notes: false,
        events: false,
        links: true,
        past: true,
        future: false,
        overdue: false,
        direction: "desc",
      };

      const blockReferences = [
        {
          sys_id_reference: reference.sys_id,
          sys_type: reference.sys_type,
          sys_id_block: "",
          status: 1,
        }
      ];

      const resourceLinks = await Storage.getInstance().getLinksForTopicsOrPersons(null, linksFilter, false, blockReferences);

      setReference(reference);
      setShowReferenceCard(true);
      setResourceLinks(resourceLinks);
    }
  }

  const handleShowProviderAuthentication = async(sys_provider, sys_id_external) => {
    gtag('event', 'show_provider_authentication', {
      'event_category': `engagement`,
      'event_label': 'Show Provider Authentication',
      'value': 'complete',
    });

    setShowProviderAuthentication(true);
    setProviderForAuthentication(sys_provider);
    setSysIdExternalForAuthentication(sys_id_external);
  }

  const handleShowReferenceCards = async() => {
    gtag('event', 'show_tags', {
      'event_category': `engagement`,
      'event_label': 'Show Tags',
      'value': 'complete',
    });

    setViewIndex(1);
    setLoadingFilterReferences(true);

    Storage.getInstance().findReferenceSummaries("")
      .then(function (response) {
        setFilterReferences(response);
        setLoadingFilterReferences(false);
      }).catch((error) => {
        console.log(`Failed to load tags: ${error}`);
      });
  }

  const viewNote = (sectionResult) => {
    gtag('event', 'view_note', {
      'event_category': `engagement`,
      'event_label': 'View Note',
      'value': 'complete',
    });

    // console.log(sectionResult);
    Storage.getInstance().getNote(sectionResult.sys_id_note)
      .then((originalNote) => {
        setOriginalNoteForView(originalNote);
        setShowIntelligenceDetail(false);
        setShowIntelligenceSearchDetail(false);
        setShowReferenceDetail(false);
        setShowSharingEventsDetail(false);
        setShowNoteDetail(true);
        setWasShowingNoteDetail(true);
        setNoteDetailSectionResult(sectionResult);
      });
  }

  const viewIntelligenceSearch = () => {
    gtag('event', 'view_intelligence_search', {
      'event_category': `engagement`,
      'event_label': 'View Intelligence Search',
      'value': 'complete',
    });

    setShowReferenceDetail(false);
    setShowNoteDetail(false);
    setShowSharingEventsDetail(false);
    setShowIntelligenceDetail(false);
    setShowIntelligenceSearchDetail(true);
    setWasShowingIntelligenceSearchDetail(true);
  }

  const viewIntelligence = () => {
    gtag('event', 'view_intelligence_recommendations', {
      'event_category': `engagement`,
      'event_label': 'View Intelligence Recommendations',
      'value': 'complete',
    });

    setShowReferenceDetail(false);
    setShowNoteDetail(false);
    setShowSharingEventsDetail(false);
    setShowIntelligenceSearchDetail(false);
    setShowIntelligenceDetail(true);
    setWasShowingIntelligenceDetail(true);
  }

  const viewSharingEvents = () => {
    gtag('event', 'view_sharing_events', {
      'event_category': `engagement`,
      'event_label': 'View Sharing Events',
      'value': 'complete',
    });

    setShowReferenceDetail(false);
    setShowNoteDetail(false);
    setShowIntelligenceDetail(false);
    setShowIntelligenceSearchDetail(false);
    setShowSharingEventsDetail(true)
    setWasShowingSharingEventsDetail(true);
  }

  const handleConnectRedirect = () => {
    if (showConnectButton) {
      window.location = `${process.env.REACT_APP_BACKEND_BASE_URL}auth/connect?sys_provider=${providerForAuthentication}&sys_id_external=${sysIdExternalForAuthentication}&sys_id_note=${Editor.getInstance().getNote() != null ? Editor.getInstance().getNote().sys_id : ""}`;
    }
  }

  const changeViewIndex = (viewIndex) => {
    setViewIndex(viewIndex);
  }

  const handleShowCommentBox = (show) => {
    setShowCommentBox(show);
  }

  const assignCommentsCount = (commentsCount) => {
    setCommentsCount(commentsCount);
  }

  const handleReferenceDetailsUpdate = async(updatedReference) => {
    if (updatedReference.sys_type == "person") {
      reference.alias = updatedReference.alias;
      reference.friendly_name = updatedReference.friendly_name;
      reference.email = updatedReference.email;
      reference.details = updatedReference.details;
      reference.invite = updatedReference.invite;
      reference.auto_share_note = updatedReference.auto_share_note;
      reference.auto_share_block = updatedReference.auto_share_block;

      if (reference.invite == true) {
        handleShowInviteToast(reference.email, true);
      }
  
      await Storage.getInstance().updatePeople(reference);
    } else {
      reference.alias = updatedReference.alias;
      reference.friendly_name = updatedReference.friendly_name;
      reference.details = updatedReference.details;
      reference.auto_share_note = updatedReference.auto_share_note;
      reference.auto_share_block = updatedReference.auto_share_block;

      await Storage.getInstance().updateTopic(reference);
    }

    const savedReference = await Storage.getInstance().getReference(reference.sys_id);
    
    if (typeof savedReference === 'string') {
      console.error(savedReference);
    } else {
      setReference(savedReference);
    }

    return savedReference;
  }

  const handleReferenceSettingsUpdate = async(updatedReference) => {
    reference.shared_tenants = updatedReference.shared_tenants;
    reference.sys_id_sharing = updatedReference.sys_id_sharing;

    if (updatedReference.sys_type == "person") {
      await Storage.getInstance().updatePeople(reference, reference.shared_tenants, null);
    } else {
      await Storage.getInstance().updateTopic(reference, reference.shared_tenants, null);
    }

    const savedReference = await Storage.getInstance().getReference(reference.sys_id);

    if (typeof savedReference === 'string') {
      console.error(savedReference);
    } else {
      setReference(savedReference);
    }

    return savedReference;
  }

  const createTag = (prefix, selection, isNew) => {
    // console.log(selection);
    if (prefix == "#") {
      if (isNew) {
        const temp_identifier = crypto.randomUUID();

        // Store the person record and then replace the temp mention with the full version
        Storage.getInstance().setTopic(selection).then((topic) => {
          if (topic.created == true && selection.auto_tag == true) {
            Storage.getInstance().getReference(topic.sys_id).then((fullReference) => {
              setReference(fullReference);
              setShowReferenceDetail(true);  
            });
          }

          let referenceTemp = document.getElementById(temp_identifier);
          let reference = document.createElement("span");
          reference.innerHTML = `#${topic.alias}`;
          reference.classList.add("en-mention");
          reference.classList.add("topic");
          reference.setAttribute("data-sys-id", topic.sys_id);
          reference.setAttribute("data-type", "topic");
          reference.setAttribute("data-reference-type", topic.sys_type);
          reference.setAttribute("data-action-setting", topic.sys_id_action_setting);
          reference.setAttribute("contenteditable", "false"); 
          reference.setAttribute("id", crypto.randomUUID()); 

          // console.log(`Adding click handling for new reference`);
          const referenceDone = referenceTemp.parentNode.replaceChild(reference, referenceTemp);
          // Events.clickEnableMentionsOnElement(referenceDone);
        });

        return `<span class="en-mention topic" contenteditable="false" id=${temp_identifier}>#${selection.alias}</span>`;
      }

      const referenceResult = Parser.constructReferenceString("#", selection);

      // Wait half a second to add the click handler
/*       setTimeout(() => {
        // console.log(`Adding referencing clicking support for: ${referenceResult.id}`);
        const reference = document.getElementById(referenceResult.id);
        Events.clickEnableMentionsOnElement(reference);
      }, 500); */
      
      return referenceResult.html;
    } else {
      if (isNew) {
        const temp_identifier = crypto.randomUUID();

        // Store the person record and then replace the temp mention with the full version
        Storage.getInstance().setPeople(selection).then((person) => {
          if (person.created == true && selection.auto_tag == true) {
            Storage.getInstance().getReference(person.sys_id).then((fullReference) => {
              setReference(fullReference);
              setShowReferenceDetail(true);  
            });
          }

          let referenceTemp = document.getElementById(temp_identifier);
          let reference = document.createElement("span");
          reference.innerHTML = `@${person.alias}`;
          reference.classList.add("en-mention");
          reference.classList.add("person");
          reference.setAttribute("data-sys-id", person.sys_id);
          reference.setAttribute("data-type", "person");
          reference.setAttribute("data-reference-type", person.sys_type);
          reference.setAttribute("data-action-setting", person.sys_id_action_setting);
          reference.setAttribute("contenteditable", "false"); 
          reference.setAttribute("id", crypto.randomUUID()); 

          // console.log(`Adding click handling for new reference`);
          const referenceDone = referenceTemp.parentNode.replaceChild(reference, referenceTemp);
          Events.clickEnableMentionsOnElement(referenceDone);

          // The invite happens in the backend - so don't need to do a separate API call
          if (selection.invite == true) {
            console.log(`Sending invite to: ${selection.email}`);
            handleShowInviteToast(selection.email, true);
          }
        });

        return `<span class="en-mention person" contenteditable="false" id=${temp_identifier}>@${selection.alias}</span>`;
      }

      const referenceResult = Parser.constructReferenceString("@", selection);

      // Wait half a second to add the click handler
/*       setTimeout(() => {
        // console.log(`Adding referencing clicking support for: ${referenceResult.id}`);
        const reference = document.getElementById(referenceResult.id);
        Events.clickEnableMentionsOnElement(reference);
      }, 500); */
      
      return referenceResult.html;
    }
  }

  return (
    <React.Fragment>
      <Navbar expand="lg" className="shadow-sm sticky-top">
        <div className={(Editor.getInstance().getNote() == null || focusMode == false) ? "container-fluid" : "container"}>
          <Navbar.Brand href="#home" onClick={() => handleWrite(false)}><i className="bi bi-arrow-up-right-circle-fill me-1" style={{color: "#fcc419"}}></i><span className="fs-3">notify</span></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav
              className="me-auto my-2 my-lg-0"
              navbarScroll
            >
              {(Editor.getInstance().getNote() == null || focusMode == false) &&
                <div 
                  id="nfy-intelligence-prompt-input"
                  contentEditable
                  onKeyDown={handleIntelligencePrompt}
                  type="input"
                  className="form-control"
                  style={{width: "500px", marginLeft: "11.6vw"}}
                  data-placeholder={`${Editor.getInstance().getTenant() != null ? Editor.getInstance().getTenant().given_name : "You"}GPT: Ask a question about your notes`}
                />
              }
              {focusMode == false &&
                <OverlayTrigger placement="bottom" overlay={<Tooltip>Toggle your knowledge graph</Tooltip>}>
                  <button className="btn btn-sm btn-light ms-4" onClick={() => { viewIndex == 0 ? setViewIndex(1) : setViewIndex(0) }}><i className="bi bi-bullseye"></i></button>
                </OverlayTrigger>
              }
              {viewIndex == 0 &&
                <React.Fragment>
                  <div className="align-middle fw-bolder ms-5 text-white-50 notify-nav-text">
                    Filter:
                  </div>
                  <OverlayTrigger placement="bottom" overlay={<Tooltip>Show last 7 days</Tooltip>}>
                    <button style={filterGraphDays == 7 ? {"--bs-btn-bg": "#d3d4d5"} : null} className="btn btn-sm btn-light ms-2" onClick={() => setFilterGraphDays(7)}>7</button>
                  </OverlayTrigger>
                  <OverlayTrigger placement="bottom" overlay={<Tooltip>Show last 28 days</Tooltip>}>
                    <button style={filterGraphDays == 28 ? {"--bs-btn-bg": "#d3d4d5"} : null} className="btn btn-sm btn-light ms-2" onClick={() => setFilterGraphDays(28)}>28</button>
                  </OverlayTrigger>
                  <OverlayTrigger placement="bottom" overlay={<Tooltip>Show all</Tooltip>}>
                    <button style={filterGraphDays <= 0 ? {"--bs-btn-bg": "#d3d4d5"} : null} className="btn btn-sm btn-light ms-2" onClick={() => setFilterGraphDays(0)}>All</button>
                  </OverlayTrigger>
                </React.Fragment>
              }
{/*               {focusMode == false &&
                <FlexpaneButtonSharing viewSharingEvents={viewSharingEvents} tenant={tenant} />
              } */}
              {focusMode == false &&
                <FlexpaneButtonIntelligence viewIntelligence={viewIntelligence} tenant={tenant} />
              }
            </Nav>
            <Form className="d-flex" style={{marginTop: "-12px"}}>
              {tenant != null &&
                <React.Fragment>
                  <OverlayTrigger placement="bottom" overlay={<Tooltip>{tenant.given_name} {tenant.family_name}</Tooltip>}>
                    <div className="me-2" style={{paddingTop: "10px"}}>
                      <img style={{height: "30px", width: "30px"}} className="rounded-circle border border-1" src={tenant.picture != null && tenant.picture.trim().length > 0 ? tenant.picture : '/assets/person.png'}></img>
                    </div>
                  </OverlayTrigger>
                  <div className="me-3" style={{paddingTop: "10px"}}>
                    <button className="btn btn-sm btn-light" onClick={logOut}>Logout</button>
                  </div>
                </React.Fragment>
              }
            </Form>
          </Navbar.Collapse>
        </div>
      </Navbar>

      <Modal show={showLogin} backdrop="static" className="modal-xl" keyboard={false}>
        <Modal.Header>
          <Modal.Title>Sign in</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={4}>
              <img src="./assets/create-account-1.png" className="img-fluid"/>
            </Col>
            <Col xs={8}>
              Click on one of the buttons below to sign in using your Slack, Google or Salesforce account. You can also use a 🔮 magic link if you already have an account.<br/><br/>
              <p>
                <a href={`${process.env.REACT_APP_BACKEND_BASE_URL}auth/login?sys_provider=${NotifyProvider.Slack}&sharing_sys_id_reference=${window.localStorage.getItem('sharing_sys_id_reference')}`} style={{alignItems:"center", color:"#000", backgroundColor:"#fff", border:"1px solid #ddd", borderRadius:"4px", display:"inline-flex", fontFamily:"Lato, sans-serif", fontSize:"14px", fontWeight:"600", height:"40px", justifyContent:"center", textDecoration:"none", width:"198px"}}>
                  <svg xmlns="http://www.w3.org/2000/svg" style={{height:"16px", width:"16px", marginRight:"20px"}} viewBox="0 0 122.8 122.8">
                    <path d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z" fill="#e01e5a"></path>
                    <path d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z" fill="#36c5f0"></path>
                    <path d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z" fill="#2eb67d"></path>
                    <path d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z" fill="#ecb22e"></path>
                  </svg>
                  Sign in with Slack
                </a>
              </p>
              <p>
                <a href={`${process.env.REACT_APP_BACKEND_BASE_URL}auth/login?sys_provider=${NotifyProvider.Google}&sharing_sys_id_reference=${window.localStorage.getItem('sharing_sys_id_reference')}`}><img src="/assets/sign_in_with_google.png" style={{maxHeight: "44px"}} /></a>
              </p>
              <p>
                <a href={`${process.env.REACT_APP_BACKEND_BASE_URL}auth/login?sys_provider=${NotifyProvider.Salesforce}&sharing_sys_id_reference=${window.localStorage.getItem('sharing_sys_id_reference')}`}><img src="/assets/sign_in_with_salesforce.png" style={{maxHeight: "38px"}} /></a>
              </p>
              <p className="mt-5">
                <label htmlFor="nfy-magic-link-email" className="form-label">Sign in with a 🔮 magic link (if you have an account already):</label>
                <input type="text" className="form-control" id="nfy-magic-link-email" placeholder="Email" value={magicLinkEmail} onChange={handleMagicLinkEmailChange} />
                <a role="button" className="btn btn-light btn-sm mt-2" href={`${process.env.REACT_APP_BACKEND_BASE_URL}public/v1/magic?email=${magicLinkEmail}`}>Send Link</a>
              </p>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal show={showOnboarding} backdrop="static" className="modal-xl" keyboard={false}>
        {onboardingStep == 1 &&
          <React.Fragment>
            <Modal.Header>
              <Modal.Title>Welcome to Notify!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col xs={4}>
                  <img src="./assets/too-busy-1.png" className="img-fluid"/>
                </Col>
                <Col xs={8}>
                  If you're like most people, you probably struggle to stay organized. Let's face it, <b><i>you're busy</i></b>. Most of us take notes to stay on top of things, but for some reason, they never seem as helpful as they should!<br/>
                  <br/>
                  That's why we created Notify. Notify makes it easy to capture the stuff you care about, and it does the work of keeping you organized and updated.<br/>
                  <br/>
                  We'd love to get you set up and show you around a little. It only takes a few minutes.
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => setOnboardingStep(2)}>Let's go! <i className="bi bi-chevron-right"></i></Button>
            </Modal.Footer>
          </React.Fragment>
        }
        {onboardingStep == 2 &&
          <React.Fragment>
            <Modal.Header>
              <Modal.Title>Sign up</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col xs={4}>
                  <img src="./assets/create-account-1.png" className="img-fluid"/>
                </Col>
                <Col xs={8}>
                  First, let's get you signed in. Click on one of the buttons below to sign in using your Slack, Google or Salesforce account.<br/>
                  <br/>
                  Don't worry, we just want your basic details so we can keep your notes safe. We don't have access to any of your data in those systems.<br/><br/>
                  <p>
                    <a href={`${process.env.REACT_APP_BACKEND_BASE_URL}auth/login?sys_provider=${NotifyProvider.Slack}&sharing_sys_id_reference=${window.localStorage.getItem('sharing_sys_id_reference')}`} style={{alignItems:"center", color:"#000", backgroundColor:"#fff", border:"1px solid #ddd", borderRadius:"4px", display:"inline-flex", fontFamily:"Lato, sans-serif", fontSize:"14px", fontWeight:"600", height:"40px", justifyContent:"center", textDecoration:"none", width:"198px"}}>
                      <svg xmlns="http://www.w3.org/2000/svg" style={{height:"16px", width:"16px", marginRight:"20px"}} viewBox="0 0 122.8 122.8">
                        <path d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z" fill="#e01e5a"></path>
                        <path d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z" fill="#36c5f0"></path>
                        <path d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z" fill="#2eb67d"></path>
                        <path d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z" fill="#ecb22e"></path>
                      </svg>
                      Sign in with Slack
                    </a>
                  </p>
                  <p>
                    <a href={`${process.env.REACT_APP_BACKEND_BASE_URL}auth/login?sys_provider=${NotifyProvider.Google}&sharing_sys_id_reference=${window.localStorage.getItem('sharing_sys_id_reference')}`}><img src="/assets/sign_in_with_google.png" style={{maxHeight: "44px"}} /></a>
                  </p>
                  <p>
                    <a href={`${process.env.REACT_APP_BACKEND_BASE_URL}auth/login?sys_provider=${NotifyProvider.Salesforce}&sharing_sys_id_reference=${window.localStorage.getItem('sharing_sys_id_reference')}`}><img src="/assets/sign_in_with_salesforce.png" style={{maxHeight: "38px"}} /></a>
                  </p>
                  <p className="mt-5">
                    <label htmlFor="nfy-magic-link-email" className="form-label">Sign in with a 🔮 Magic link (if you have an account already):</label>
                    <input type="text" className="form-control" id="nfy-magic-link-email" placeholder="Email" value={magicLinkEmail} onChange={handleMagicLinkEmailChange} />
                    <a role="button" className="btn btn-light btn-sm mt-2" href={`${process.env.REACT_APP_BACKEND_BASE_URL}public/v1/magic?email=${magicLinkEmail}`}>Send Link</a>
                  </p>
                </Col>
              </Row>
            </Modal.Body>
          </React.Fragment>
        }
        {onboardingStep == 3 &&
          <React.Fragment>
            <Modal.Header>
              <Modal.Title>Welcome {Editor.getInstance().getTenant() != null ? Editor.getInstance().getTenant().given_name : ""}!</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
              <Row>
                <Col xs={4}>
                  <div className="h-100" style={{backgroundColor: "#F7F7F7"}}>
                    <img src="./assets/done.png" className="img-fluid"/>
                  </div>
                </Col>
                <Col xs={8}>
                  <div className="p-3">
                    <b>Welcome {Editor.getInstance().getTenant() != null ? Editor.getInstance().getTenant().given_name : ""}!</b> Let's now take you through some of the basics. Notify uses social tags to help it track your notes:<br/>
                    <br/>
                    <ul>
                      <li>Use <span className="nfy-mention-onboarding person-onboarding">@mentions</span> for people (for example: co-workers, customers, partners, etc)</li>
                      <li>Use <span className="nfy-mention-onboarding topic-onboarding">#hashtags</span> for topics (for example teams, projects, companies, etc)</li>
                    </ul>
                    <br/>
                    You can use the tags in a blocks of text if you're writing about a customer, for example, <span className="nfy-mention-onboarding topic-onboarding">#acme-inc</span> (👈 just like that). And you can reference <span className="nfy-mention-onboarding person-onboarding">@rachel</span> (👈 maybe you know a Rachel!). But here's where things get a little more interesting. You can create actions by starting a line with either an <span className="nfy-mention-onboarding person-onboarding">@mention</span> or <span className="nfy-mention-onboarding topic-onboarding">#hashtag</span> like this:<br/>
                    <br/>
                    <img src="./assets/action-animation.gif" className="img-fluid" />
                    In the above example, Notify will automatically track this action for <span className="nfy-mention-onboarding person-onboarding">@rachel</span> (as well as <span className="nfy-mention-onboarding topic-onboarding">#acme</span>) and assign the due date to tomorrow.<br/>
                  </div>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => setOnboardingStep(4)}>Got it! <i className="bi bi-chevron-right"></i></Button>
            </Modal.Footer>
          </React.Fragment>
        }
        {onboardingStep == 4 &&
          <React.Fragment>
            <Modal.Header>
              <Modal.Title>All done!</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
              <Row>
                <Col xs={4}>
                  <div className="h-100" style={{backgroundColor: "#BCD0C8"}}>
                    <img src="./assets/get-job-promotion-1.png" className="img-fluid mt-4"/>
                  </div>
                </Col>
                <Col xs={8}>
                  <div className="p-3">
                    <b>You're all set!</b> There's plenty more to explore, but that's probably enough for now. To get you going, try using Notify in your next one on one! Just like this:<br/>
                    <img src="./assets/one-on-one-animation.gif" className="img-fluid mt-2" style={{maxHeight: "400px"}} />
                  </div>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleOnboardingDone}>Start writing! <i className="bi bi-chevron-right"></i></Button>
            </Modal.Footer>
          </React.Fragment>
        }
      </Modal>

      <Modal show={showProviderAuthentication} onHide={() => setShowProviderAuthentication(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Connect with {providerForAuthentication == NotifyProvider.Google ? "Google" : ""}{providerForAuthentication == NotifyProvider.Salesforce ? "Salesforce" : ""}{providerForAuthentication == NotifyProvider.Slack ? "Slack" : ""}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              {providerForAuthentication == NotifyProvider.Google &&
                <React.Fragment>
                  <Row>
                    <Col>
                      We'd like to connect with your Google Drive account. The reason we want to do this is so we can generate a nice preview image of your document. We don't use it for anything else.
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col className="text-center">
                      <img src="./assets/google_drive_logo.png" className="img-responsive" style={{maxWidth: "100px"}} />
                    </Col>
                    <div className="col-1 mt-3 text-center"><i className="bi bi-arrow-left-right"></i></div>
                    <Col className="text-center">
                    <i className="bi bi-arrow-up-right-circle-fill me-1 fs-5" style={{color: "#fcc419"}}></i><span className="fs-3">notify</span>
                    </Col>
                  </Row>
                </React.Fragment>
              }
              {providerForAuthentication == NotifyProvider.Salesforce &&
                <React.Fragment>
                  <Row>
                    <Col>
                      We'd like to connect with your Salesforce account. The reason we want to do this is so we can generate a nice preview of your record data. We don't store any of your data, we just look it up when you need it.
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col className="text-center">
                      <img src="./assets/salesforce_logo.png" className="img-responsive" style={{maxWidth: "100px"}} />
                    </Col>
                    <div className="col-1 mt-3 text-center"><i className="bi bi-arrow-left-right"></i></div>
                    <Col className="text-center">
                      <i className="bi bi-arrow-up-right-circle-fill me-1 fs-5" style={{color: "#fcc419"}}></i><span className="fs-3">notify</span>
                    </Col>
                  </Row>
                </React.Fragment>
              }
              {providerForAuthentication == NotifyProvider.Slack &&
                <React.Fragment>
                  <Row>
                    <Col>
                      We'd like to connect with your Slack account. The reason we want to do this is so we can generate a nice preview of your message and thread. We don't store any of your data, we just look it up when you need it.
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col className="text-center">
                      <img src="./assets/slack_logo.png" className="img-responsive" style={{maxWidth: "100px", paddingTop: "12px"}} />
                    </Col>
                    <div className="col-1 mt-3 text-center"><i className="bi bi-arrow-left-right"></i></div>
                    <Col className="text-center">
                    <i className="bi bi-arrow-up-right-circle-fill me-1 fs-5" style={{color: "#fcc419"}}></i><span className="fs-3">notify</span>
                    </Col>
                  </Row>
                </React.Fragment>
              }
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={() => { setShowProviderAuthentication(false) }}>Cancel</button>
          <button type="button" className="btn btn-primary" disabled={!showConnectButton} onClick={() => handleConnectRedirect()}>
            {showConnectButton == false &&
              <React.Fragment>
                <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                Saving Note...
              </React.Fragment>
            }
            {showConnectButton == true &&
              <React.Fragment>
                Connect to {providerForAuthentication == NotifyProvider.Google ? "Google" : ""}{providerForAuthentication == NotifyProvider.Salesforce ? "Salesforce" : ""}{providerForAuthentication == NotifyProvider.Slack ? "Slack" : ""}
              </React.Fragment>
            }
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={showInvitorRequest} onHide={() => handleNotConnectWithInvitor()}>
        <Modal.Header closeButton>
          <Modal.Title>Connect with {invitor != null ? `${invitor.given_name} ${invitor.family_name}` : ""}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Row>
                <Col>
                  {invitor != null ? invitor.given_name : ""} would like to connect with you on Notify! Once you're connected, you can share Notes, Actions and Blocks. Though, don't worry! All of your information is private unless you explicitly share it with them.
                </Col>
              </Row>
              <Row>
                <Col className="text-center mt-3 mb-3">
                  {invitor != null &&
                    <img style={{height: "100px", width: "100px"}} className="rounded-circle border border-1" src={Gravitar.getPersonImageUrl(invitor.email)}></img>
                  }
                  <h5 className="fw-bolder">{invitor != null ? `${invitor.given_name} ${invitor.family_name}` : ""}</h5>
                  <div className="text-muted">{invitor != null ? invitor.email : ""}</div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={() => { handleNotConnectWithInvitor() }}>Nope</button>
          <button type="button" className="btn btn-primary" onClick={() => handleConnectWithInvitor()}>Connect</button>
        </Modal.Footer>
      </Modal>

      <Modal show={showPinnedSysType} size="lg" onHide={() => handleClosePinnedTags(showPinnedForSysType)}>
        <Modal.Header closeButton>
          <Modal.Title><i className="bi bi-pin-fill me-2"></i>Pinned {showPinnedForSysType == "topic" ? "Topics" : "People"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className="mb-3">
              <Row>
                <Col xs={6}>
                  <div className="input-group mb-3">
                    <input type="text" id="nft-pinned-tags-modal-search" className="form-control" onKeyUp={handleSearchTags} placeholder={`Search for ${showPinnedForSysType == "topic" ? "topics" : "people"}`} />
                    <button className="btn btn-outline-secondary" type="button" onClick={handleSearchTagsClick}><i className="bi bi-search me-2"></i>Search</button>
                  </div>
                </Col>
                <Col>
                  <button className="btn btn-outline-secondary float-end" type="button" onClick={() => handleManageCreateNewTags(showPinnedForSysType)}><i className="bi bi-hash me-2"></i>Create New {showPinnedForSysType == "topic" ? "Topic" : "Person"}</button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Row>
                <Col className="ms-2 mb-2">
                  <span className="fw-bolder text-muted">Unpinned (click the <i className={`bi bi-pin`}></i> to pin)</span>
                </Col>
              </Row>
              <Row className="d-flex flex-column" style={{height: "40vh"}}>
                <Col className="overflow-auto flex-grow-1">
                  {manuallyUnpinnedTags != null && manuallyUnpinnedTags.length > 0 && manuallyUnpinnedTags.map((tag, index) => (
                    <Row key={`nfy-manual-tag-row-${index}`}>
                      <Col key={`nfy-manual-tag-row-col-${index}`} className="mb-2">
                        <OverlayTrigger key={`nfy-manual-tag-row-col-overlay-${index}`} placement="top" overlay={<Tooltip>{`Pin this ${tag.sys_type}`}</Tooltip>}>
                          <button key={`nfy-manual-tag-row-col-button-${index}`} className={`btn btn-sm btn-light text-muted me-2`} onClick={() => handlePinTag(tag)}><i className={`bi bi-pin`}></i></button>
                        </OverlayTrigger>
                        {tag.friendly_name != null && tag.friendly_name.trim().length > 0 ? tag.friendly_name : tag.alias}
                      </Col>
                    </Row>
                  ))}
                  {(manuallyUnpinnedTags == null || manuallyUnpinnedTags.length == 0) && loadingManuallyPinnedTags == false && 
                    <Row>
                      <Col className="ms-2">
                        <span className="text-muted">None</span>
                      </Col>
                    </Row>
                  }
                </Col>
              </Row>
            </Col>
            <Col xs={6}>
              <Row>
                <Col className="ms-2 mb-2">
                  <span className="fw-bolder text-muted">Pinned (click the <i className={`bi bi-pin-fill`}></i> to unpin)</span>
                </Col>
              </Row>
              <Row className="d-flex flex-column" style={{height: "40vh"}}>
                <Col className="overflow-auto flex-grow-1">
                  {manuallyPinnedTags != null && manuallyPinnedTags.length > 0 && manuallyPinnedTags.map((tag, index) => (
                    <Row key={`nfy-manual-tag-row-${index}`}>
                      <Col key={`nfy-manual-tag-row-col-${index}`} className="mb-2">
                        <OverlayTrigger key={`nfy-manual-tag-row-col-overlay-${index}`} placement="top" overlay={<Tooltip>{`Unpin this ${tag.sys_type}`}</Tooltip>}>
                          <button key={`nfy-manual-tag-row-col-button-${index}`} className={`btn btn-sm btn-light me-2`} style={{ backgroundColor: "#dee2e6"}} onClick={() => handlePinTag(tag)}><i className={`bi bi-pin-fill`}></i></button>
                        </OverlayTrigger>
                        {tag.friendly_name != null && tag.friendly_name.trim().length > 0 ? tag.friendly_name : tag.alias}
                      </Col>
                    </Row>
                  ))}
                  {(manuallyPinnedTags == null || manuallyPinnedTags.length == 0) && loadingManuallyPinnedTags == false && 
                    <Row>
                      <Col className="ms-2">
                        <span className="text-muted">None</span>
                      </Col>
                    </Row>
                  }
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-light" onClick={() => handleClosePinnedTags(showPinnedForSysType)}>Close</button>
        </Modal.Footer>
      </Modal>

      <Modal show={showCreateNewSysType} size="lg" onHide={() => handleCloseCreateNewTags(showCreateNewSysType)}>
        <Modal.Header closeButton>
          <Modal.Title><i className={`bi ${showCreateNewForSysType == "topic" ? "bi-hash" : "bi-at"} me-2`}></i>Create New {showCreateNewForSysType == "topic" ? "Topic" : "Person"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
              <Col>
                <Overlay
                show={showDuplicateTagsWarning}
                target={createNewAlias.current}
                placement="bottom"
                rootClose={true}
                rootCloseEvent="click"
                onHide={hideDuplicateTagsWarning}
              >
                <Popover>
                  <Popover.Body>
                    <span className="fs-5">👋</span> <strong>Howdy!</strong> Are you sure you need to create this {showCreateNewForSysType}? The following exist already:<br/>
                    {existingTagsResponse != null && existingTagsResponse.length > 0 && 
                      <ul className="mt-2">
                        {existingTagsResponse.map((existingReference, index) => (
                          <li key={`existing-tag-response-${index}`}>
                            {existingReference.friendly_name != null && existingReference.friendly_name.trim().length > 0 &&
                              <span key={`existing-tag-response-friendly-name-1-${index}`}>{existingReference.friendly_name}&nbsp;(</span>
                            }
                            {showCreateNewForSysType == "person" ? "@" : "#"}{existingReference.alias}
                            {existingReference.friendly_name != null && existingReference.friendly_name.trim().length > 0 &&
                              <span key={`existing-tag-response-friendly-name-2-${index}`}>)</span>
                            }
                          </li>
                        ))}
                      </ul>
                    }
                  </Popover.Body>
                </Popover>
              </Overlay>
              <div className="input-group mb-3">
                <span className="input-group-text">{showCreateNewForSysType == "person" ? "@" : "#"}</span>
                <input type="text" ref={createNewAlias} className="form-control" placeholder="name (e.g. my-team)" id="quick-add-alias" value={alias} onBlur={handleAliasDuplicate} onChange={handleAliasChange} />
              </div>
              <div className="mb-3">
                <input type="text" className="form-control" placeholder="Friendly name" id="quick-add-friendly-name" value={friendlyName} onChange={handleFriendlyNameChange} />
              </div>
              {showCreateNewForSysType == "person" &&
                <div className="input-group mb-3">
                  <input type="email" className="form-control" id="quick-add-email" placeholder="Email address (optional)" value={email} onChange={handleEmailChange} />
                  <div className="input-group-text">
                    <input checked={sendInvite} onChange={() => setSendInvite(!sendInvite)} className="form-check-input mt-0 me-1" id="quick-add-email-invite" type="checkbox" value="" aria-label="Checkbox for following text input" /><label htmlFor="quick-add-email-invite" className="text-muted">Invite <i className="bi bi-send-fill"></i></label>
                  </div>
                </div>
              }
              <ReferenceCardDetails extension="modal" tenant={tenant} reference={createNewDetailsReference} handleReferenceUpdate={handleCreateNewDetailsReferenceUpdate} />
              <div className="mt-5 mb-3">
                <ReferenceCardSettings tenant={tenant} reference={createNewSharingReference} handleReferenceUpdate={handleCreateNewSharingReferenceUpdate} />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" id="en-tag-add-button" onClick={handleCreateNewTag}>Create New {showCreateNewForSysType == "topic" ? "Topic" : "Person"}</button>
          <button type="button" className="btn btn-light" onClick={() => handleCloseCreateNewTags(showCreateNewForSysType)}>Close</button>
        </Modal.Footer>
      </Modal>

      <Modal show={showSectionDetails} size="xl" onHide={() => setShowSectionDetails(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{sectionResult != null && sectionResult.sys_type == "action" ? "Action Details" : "Block Details"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Card className="rounded border-0 shadow-sm mt-2">
                <Card.Body>
                  <SectionResultPanel sectionResult={sectionResult} isAction={sectionResult != null && sectionResult.sys_type == "action" ? true : false} viewNote={null} />
                </Card.Body>
              </Card>
              <SectionCommentPanel section={sectionResult} />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-light" onClick={() => setShowSectionDetails(false)}>Close</button>
        </Modal.Footer>
      </Modal>

      <div style={{position: "absolute", top: "60px", left: "10px", zIndex: "10000"}}>
        <Toast show={showInviteToast} position="top-start" onClose={() => handleShowInviteToast(null, false)} delay={6000} autohide>
          <Toast.Header>
            <i className="bi bi-arrow-up-right-circle-fill" style={{color: "#fcc419"}}></i>
            <strong className="me-auto ps-1">notify</strong>
            <small>Just now</small>
          </Toast.Header>
          <Toast.Body>Invite sent to {inviteToastEmail}.</Toast.Body>
        </Toast>
      </div>
      <div style={{position: "absolute", top: "60px", left: "10px", zIndex: "10000"}}>
        <Toast show={showTimelineToast} position="top-start" onClose={() => handleShowTimelineToast(false)} delay={6000} autohide>
          <Toast.Header>
            <i className="bi bi-arrow-up-right-circle-fill" style={{color: "#fcc419"}}></i>
            <strong className="me-auto ps-1">notify</strong>
            <small>Just now</small>
          </Toast.Header>
          <Toast.Body>You can only have two tags in the timeline currently.</Toast.Body>
        </Toast>
      </div>
      <div style={{position: "absolute", top: "60px", left: "10px", zIndex: "10000"}}>
        <Toast show={showStillSavingToast} position="top-start" onClose={() => handleShowStillSavingToast(false)} delay={6000} autohide>
          <Toast.Header>
            <i className="bi bi-arrow-up-right-circle-fill" style={{color: "#fcc419"}}></i>
            <strong className="me-auto ps-1">notify</strong>
            <small>Just now</small>
          </Toast.Header>
          <Toast.Body>We're still saving your note! Try again in a sec <i className="bi bi-heart-fill text-muted"></i></Toast.Body>
        </Toast>
      </div>
      <div style={{position: "absolute", top: "60px", left: "10px", zIndex: "10000"}}>
        <Toast show={showNoteNotAvailableToast} position="top-start" onClose={() => handleShowNoteNotAvailableToast(false)} delay={6000} autohide>
          <Toast.Header>
            <i className="bi bi-arrow-up-right-circle-fill" style={{color: "#fcc419"}}></i>
            <strong className="me-auto ps-1">notify</strong>
            <small>Just now</small>
          </Toast.Header>
          <Toast.Body>The note you're trying to load is not available.</Toast.Body>
        </Toast>
      </div>
      <div style={{position: "absolute", top: "60px", left: "10px", zIndex: "10000"}}>
        <Toast show={showTagSavedToast} position="top-start" onClose={() => handleShowTagSavedToast(false)} delay={6000} autohide>
          <Toast.Header>
            <i className="bi bi-arrow-up-right-circle-fill" style={{color: "#fcc419"}}></i>
            <strong className="me-auto ps-1">notify</strong>
            <small>Just now</small>
          </Toast.Header>
          <Toast.Body>Tag saved successfully</Toast.Body>
        </Toast>
      </div>

      <ViewNetworkPanel filterGraphDays={filterGraphDays} viewIndex={viewIndex} changeViewIndex={changeViewIndex} />

      <div className={focusMode == false ? "container-fluid" : "container"}>
        <Row>
          <Col>
            {position && prefix && mentionText && range && (
              <MentionPopover
                mentionableProperties={["alias", "alias"]}
                mentionNodeName="SPAN"
                mentionPrefixes={mentionPrefixes}
                prefix={prefix}
                position={position}
                text={mentionText}
                createTag={createTag}
                onMention={(selection, prefix, isTagsInput) => {
                  if (typeof selection === "string") {
                    // This is a new mention we don't previously have
                    if (prefix === "@") {
                      // Storage.getInstance().setPeople(selection);
                    } else {
                      // Storage.getInstance().setTopic(selection);
                    }
                  } else {
                    // Search for the selected entry
                    // This is needed for situations where the user clicks on the selection
                    // console.log(isTagsInput);
                    if (isTagsInput == true) {
                      updateNoteView();
                    }
                  }
                }}
                uniqueKeys={["sys_id", "sys_id"]}
                range={range}
                searchFunctions={[findTopics, findUsers]}
                searchResultBuilders={[
                  (item, index, selectedIndex) => (
                    <div
                      className="row"
                      style={{
                        cursor: "pointer",
                        padding: "0.5rem",
                        borderRadius: "0.5rem",
                        borderBottom: "1px solid #eee",
                        background: selectedIndex === index ? "#40bcf3" : undefined,
                      }}
                    >
                      <div className="col-1 p-0">
                        <img style={{height: "30px", width: "30px"}} className="rounded-circle border border-1 mx-auto d-block mt-1" src={Gravitar.getTopicImageUrl('')}></img>
                      </div>
                      <div className="col-10 p-0">
                        <div className="ps-2">{item.friendly_name}</div>
                        <div className="ps-2" style={{fontSize: "14px"}}>
                          <i className="bi bi-hash"></i>{item.alias}
                        </div>
                      </div>
                      <div className="col-1 p-0">
                        {item.sys_id_sharing == 0 &&
                          <i className="bi bi-person-fill-lock text-muted"></i>
                        }
                        {item.sys_id_sharing == 1 &&
                          <i className="bi bi-person-fill-check text-muted"></i>
                        }
                        {item.sys_id_sharing == 2 &&
                          <i className="bi bi-people-fill text-muted"></i>
                        }
                      </div>
                    </div>
                  ),
                  (item, index, selectedIndex) => (
                    <div
                      className="row"
                      style={{
                        cursor: "pointer",
                        padding: "0.5rem",
                        borderRadius: "0.5rem",
                        borderBottom: "1px solid #eee",
                        background: selectedIndex === index ? "#40bcf3" : undefined,
                      }}
                    >
                      <div className="col-1 p-0">
                        <img style={{height: "30px", width: "30px"}} className="rounded-circle border border-1 mx-auto d-block mt-1" src={Gravitar.getPersonImageUrl(item.email)}></img>
                      </div>
                      <div className="col-10 p-0">
                        <div className="ps-2">{item.friendly_name}</div>
                        <div className="ps-2" style={{fontSize: "14px"}}>
                          <i className="bi bi-at"></i>{item.alias}
                        </div>
                      </div>
                      <div className="col-1 p-0">
                          {item.sys_id_sharing == 0 &&
                            <i className="bi bi-person-fill-lock text-muted"></i>
                          }
                          {item.sys_id_sharing == 1 &&
                            <i className="bi bi-person-fill-check text-muted"></i>
                          }
                          {item.sys_id_sharing == 2 &&
                            <i className="bi bi-people-fill text-muted"></i>
                          }
                        </div>
                    </div>
                  ),
                ]}
                mentionBuilders={[
                  (selection, isNew) => {
                    return createTag("#", selection, isNew);
                  },
                  (selection, isNew) => {
                    return createTag("@", selection, isNew);
                  },
                ]}
                style={{}}
                close={() => {
                  setPosition(undefined);
                  setMentionText(undefined);
                  setRange(undefined);
                }}
                loader={
                  <div style={{ textAlign: "center", marginBottom: "1rem" }}>
                    Loading...
                  </div>
                }
              />
            )}
          </Col>
        </Row>
        <Row style={viewIndex == 0 ? { display: "none" } : {}}>
          {focusMode == false &&
            <div className="col-xl-2 col-md-3 d-none d-md-block">
              <Row className="bg-light d-flex flex-column" style={{height: "calc(100vh - 68px)"}}>
                <Col className="overflow-auto flex-grow-1">
                  <Row>
                    <Col className="pt-2 pb-2 border-bottom">
                      <span className="text-muted mt-5 mb-2 fw-bolder"><i className="bi bi-funnel me-1"></i>View</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mt-3 mb-3">
                      <Row>
                        <Col>
                          <div type="button" className="btn btn-light btn-sm w-100 text-start" style={filterByAssignedToMe == true ? {color: "#FFFFFF", backgroundColor: "#3F0F3F"} : {}} onClick={() => { handleFilterByAssignedToMe(!filterByAssignedToMe); }}>
                            <Row>
                              <Col className="text-truncate">
                                <i className="bi bi-inbox-fill me-2 mb-1"></i>
                                <span>My actions & notes</span>
                              </Col>
                              {tenantReference != null && tenantReference.actions_incomplete > 0 &&
                                <Col xs={2} className="me-3">
                                  <span className="badge" style={{backgroundColor: "#FCC419"}}>{tenantReference.actions_incomplete}</span>
                                </Col>
                              }
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div type="button" className="btn btn-light btn-sm w-100 text-start" style={filterByUnread == true ? {color: "#FFFFFF", backgroundColor: "#3F0F3F"} : {}} onClick={() => handleFilterByUnread(!filterByUnread)}>
                            <Row>
                              <Col className="text-truncate">
                                <i className="bi bi-envelope-heart me-2 mb-1"></i>
                                <span>Shared & unread</span>
                              </Col>
                              {sharingEvents != null && sharingEvents.length > 0 &&
                                <Col xs={2} className="me-3">
                                  <span className="badge" style={{backgroundColor: "#FCC419"}}>{sharingEvents.length}</span>
                                </Col>
                              }
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div type="button" className="btn btn-light btn-sm w-100 text-start" style={filterByPinned == true ? {color: "#FFFFFF", backgroundColor: "#3F0F3F"} : {}} onClick={() => handleFilterByPinned(!filterByPinned)}>
                            <Row>
                              <Col className="text-truncate">
                                <i className="bi bi-pin-fill me-2 mb-1"></i>
                                <span ref={moveNoteTarget}>Pinned</span>
                              </Col>
                            </Row>
                          </div>
                          <Overlay
                            show={movedNoteId != null && movedNoteId.trim().length > 0 ? true : false}
                            target={moveNoteTarget.current}
                            placement="right"
                            rootClose={true}
                            rootCloseEvent="click"
                          >
                            <Popover>
                              <Popover.Body>
                                <span className="fs-5">👋</span> <strong>Hello!</strong> Your note has been pinned while you explore! Click here to view all of your pinned stuff.
                              </Popover.Body>
                            </Popover>
                          </Overlay>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div type="button" className="btn btn-light btn-sm w-100 text-start" style={showFilterByDate == true ? {color: "#FFFFFF", backgroundColor: "#3F0F3F"} : {}} onClick={() => handleFilterByDate(!showFilterByDate)}>
                            <Row>
                              <Col className="text-truncate">
                                <i className="bi bi-calendar-check me-2 mb-1"></i>
                                <span>By calendar date</span>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      {showFilterByDate == true &&
                        <Row>
                          <Col>
                            <DatePicker 
                              onChange={handleFilterByDateChange} 
                              selected={calendarFilterByDate} 
                              customInput={<button className={`btn btn-sm btn-light text-muted`} type="button"><i className="bi bi-calendar3 me-2 text-muted"></i>{Formatter.datesContent(calendarFilterByDate)}</button>}
                            />
                          </Col>
                        </Row>
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pt-2 pb-2 border-bottom border-top">
                      <span className="text-muted fw-bolder"><i className="bi bi-bookmark me-1"></i>Tags</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mt-3">
                      <a data-bs-toggle="collapse" onClick={handleTopicsCollapseState} style={{textDecoration: "none"}} className="text-reset nfy-topic-collapse ms-2" href="#nfy-topic-collapse" role="button" aria-expanded={window.localStorage.getItem('topics_collapse_state') == "show" ? "true" : "false"} aria-controls="nfy-topic-collapse">
                        <svg xmlns="http://www.w3.org/2000/svg" style={{marginBottom: "3px"}} width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill me-1 text-muted nfy-collapse-toggle" viewBox="0 0 16 16">
                          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                        </svg>
                      </a>
                      <button className="btn btn-sm btn-light text-muted dropdown-toggle nfy-overflow-button" type="button" data-bs-toggle="dropdown" aria-expanded="true">Topics<i className="ms-2 bi bi-chevron-down"></i></button>
                      <ul className="dropdown-menu">
                        <li><a className="dropdown-item" style={{fontSize: "14px"}} href="#" onClick={() => handleManageCreateNewTags("topic")}>Create a new topic</a></li>
                        <li><a className="dropdown-item" style={{fontSize: "14px"}} href="#" onClick={() => handleManagePinnedTags("", "topic")}>Pin an existing topic</a></li>
                      </ul>
                      <div className={`collapse ${window.localStorage.getItem('topics_collapse_state')} pt-2`} id="nfy-topic-collapse">
                        {topics != null && topics.length > 0 && topics.map((topic, index) => (
                          <TagTopicsMenuButton key={`nfy-topic-${index}`} references_referenced={references} setNoteViewForReference={handleFilterByReference} reference={topic} />
                        ))}
                        <button type="button" className="btn btn-light btn-sm w-100 text-start" onClick={() => handleManagePinnedTags("", "topic")}>
                          <i className="bi bi-pin me-2"></i>Pin Topics
                        </button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mt-3">
                      <a data-bs-toggle="collapse" onClick={handlePersonsCollapseState} style={{textDecoration: "none"}} className="text-reset nfy-people-collapse ms-2" href="#nfy-people-collapse" role="button" aria-expanded={window.localStorage.getItem('persons_collapse_state') == "show" ? "true" : "false"} aria-controls="nfy-people-collapse">
                        <svg xmlns="http://www.w3.org/2000/svg" style={{marginBottom: "3px"}} width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill me-1 text-muted nfy-collapse-toggle" viewBox="0 0 16 16">
                          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                        </svg>
                      </a>
                      <button className="btn btn-sm btn-light text-muted dropdown-toggle nfy-overflow-button" type="button" data-bs-toggle="dropdown" aria-expanded="false">People<i className="ms-2 bi bi-chevron-down"></i></button>
                      <ul className="dropdown-menu">
                        <li><a className="dropdown-item" style={{fontSize: "14px"}} href="#" onClick={() => handleManageCreateNewTags("person")}>Create a new person</a></li>
                        <li><a className="dropdown-item" style={{fontSize: "14px"}} href="#" onClick={() => handleManagePinnedTags("", "person")}>Pin an existing person</a></li>
                      </ul>
                      <div className={`collapse ${window.localStorage.getItem('persons_collapse_state')} pt-2`} id="nfy-people-collapse">
                        {people != null && people.length > 0 && people.map((person, index) => (
                          <TagPeopleMenuButton key={`nfy-people-${index}`} references_referenced={references} setNoteViewForReference={handleFilterByReference} reference={person} />
                        ))}
                        <button type="button" className="btn btn-light btn-sm w-100 text-start" onClick={() => handleManagePinnedTags("", "person")}>
                          <i className="bi bi-pin me-2"></i>Pin People
                        </button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mt-3">&nbsp;</Col>
                  </Row>
                </Col>
              </Row>
            </div>
          }
          <div className={focusMode == true ? "col-12" : (showIntelligenceSearchDetail == true || showSharingEventsDetail == true || showIntelligenceDetail == true || showReferenceDetail == true || showNoteDetail == true ? "col-xl-6 col-md-5 col-col-xs-4" : "col-xl-10 col-md-9")}>
            <Row className="d-flex flex-column" style={{height: "calc(100vh - 78px)"}}>
              <Col id="nfy-main-scroll-pane" className="overflow-auto flex-grow-1">
                <Row>
                  <Col>
                    <div className={focusMode == false ? "card rounded border-0 shadow-sm mt-3" : "mt-5"}>
                      <div className={focusMode == false ? "card-body" : ""}>
                        <Row>
                          <Col>
                            <Row style={{marginLeft: "40px", marginRight: "40px"}}>
                              <Col className="col-9 p-0 en-title-and-tags">
                                <Overlay
                                  show={showTagsWarningOverlay}
                                  target={tagsContentEditable.current}
                                  placement="top"
                                  rootClose={true}
                                  rootCloseEvent="click"
                                  onHide={hideTagsWarningOverlay}
                                >
                                  <Popover>
                                    <Popover.Body>
                                      <span className="fs-5">👋</span> <strong>Hello there!</strong> For tags to work, you need to use social notation. Try changing {tagWarning} to @{tagWarning} (if it's a person) or #{tagWarning} (if it's a topic).
                                    </Popover.Body>
                                  </Popover>
                                </Overlay>
                                <div 
                                  id="en-tags-input"
                                  contentEditable
                                  onKeyDown={handleTagsKeyDown}
                                  onKeyUp={handleTagsKeyUp}
                                  className="w-100" 
                                  data-placeholder="Tags"
                                  style={{border: 0, outline: 0, display: "inline-block"}}
                                  ref={tagsContentEditable}
                                />
                              </Col>
                              <Col className="col-3 p-0">
                                {showNoteDetailsButton == true &&
                                  <div className="d-grid d-md-flex justify-content-md-end">
                                    <ViewNoteControlSharing note={Editor.getInstance().getNote()} sys_id_note={Editor.getInstance().getNote() != null ? Editor.getInstance().getNote().sys_id : null} />
                                    <OverlayTrigger rootClose={true} placement="left" trigger="click" overlay={
                                      <Popover>
                                        <Popover.Body>
                                          <ViewNoteControlComments  processChange={processChange} padding={false} assignCommentsCount={assignCommentsCount} note={Editor.getInstance().getNote()} show={true} />
                                        </Popover.Body>
                                      </Popover>
                                    }>
                                      <button className="btn btn-sm btn-light text-muted me-2" onClick={() => handleShowCommentBox(!showCommentBox)}><i className="bi bi-chat"></i>{commentsCount > 0 ? <span> <span className="badge text-bg-secondary">{commentsCount}</span></span> : null}</button>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="top" overlay={<Tooltip>{pinned == true ? "Pinned" : "Pin this note"}</Tooltip>}>
                                      <button className={`btn btn-sm btn-light ${pinned == true ? "" : "text-muted"} me-2`} style={pinned == true ? { backgroundColor: "#dee2e6"} : {}} onClick={() => handlePinNote()}><i className={`bi bi-pin${pinned == true ? '-fill' : ''}`}></i></button>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="top" overlay={<Tooltip>{focusMode == true ? "Exit focused mode" : "Enter focused mode"}</Tooltip>}>
                                      <button className={`btn btn-sm btn-light ${pinned == true ? "" : "text-muted"} me-2`} style={focusMode == true ? { backgroundColor: "#dee2e6"} : {}} onClick={() => setFocusMode(!focusMode)}><i className={`bi bi-balloon-heart${focusMode == true ? '-fill' : ''}`}></i></button>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="top" overlay={<Tooltip>Delete this note</Tooltip>}>
                                      <button className="btn btn-sm btn-light text-muted me-2" onClick={() => deleteNote(Editor.getInstance().getNote().sys_id)}><i className="bi bi-trash2-fill text-danger"></i></button>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="top" overlay={<Tooltip>Close this note</Tooltip>}>
                                      <button className="btn btn-sm btn-light text-muted" onClick={() => handleWrite(false)}><i className="bi bi-x-lg"></i></button>
                                    </OverlayTrigger>
                                  </div>
                                }
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row style={viewIndex == 0 ? { display: "none" } : {marginLeft: "27px", marginRight: "27px"}}>
                          <Col className="en-title-and-tags">
                            <div 
                              className={`w-100 ${showNoteDetailsButton == false ? "border-top" : null}`}
                              id="en-title-input"
                              contentEditable
                              onKeyDown={handleTitleKeyDown}
                              data-placeholder="Title"
                              style={{transition: showNoteDetailsButton == true ? "font-size 0.2s, padding-top 0.2s, margin-top 0.2s" : null, marginTop: showNoteDetailsButton == true ? "0px" : "10px", paddingTop: showNoteDetailsButton == true ? "0px" : "10px", fontSize: showNoteDetailsButton == true ? "2rem" : "1rem", border: 0, outline: 0, display: "inline-block"}}
                            />
                          </Col>
                        </Row>
                        <Row style={viewIndex == 0 ? { display: "none" } : {}}>
                          <Col>
                            <div id={Editor.SECTION_MODAL_ID}></div>
                            <div id={Editor.AUTH_MODAL_ID}></div>
                            <div id={EDITOR_HOLDER_ID}></div>
                          </Col>
                        </Row>
                        <Collapse in={showNoteDetailsButton}>
                          <Row style={{marginLeft: "25px", marginRight: "25px", marginTop: "30px"}}>
                            <Col>
                              <button className="btn btn-light text-muted" onClick={() => handleWrite(true)}>Done</button>
                            </Col>
                          </Row>
                        </Collapse>
                      </div>
                    </div>
                  </Col>
                </Row>
                {focusMode == false &&
                  <SurfaceNotesView 
                    processChange={processChange}
                    handleLoadPinnedNotes={handleLoadPinnedNotes} 
                    handleLoadPinnedSections={handleLoadPinnedSections}
                    viewIndex={viewIndex} 
                    tenant={tenant} 
                    references_referenced={references} 
                    loadNote={loadNote} 
                    viewNote={viewNote}
                    removeReferenceReferences={removeReferenceReferences}
                    filterByPinned={filterByPinned}
                    filterByDate={filterByDate}
                    filterByUnread={filterByUnread}
                    calendarFilterByDate={calendarFilterByDate} />
                }
              </Col>
            </Row>
          </div>
          {showIntelligenceSearchDetail == false && showSharingEventsDetail == false && showIntelligenceDetail == false && focusMode == false && showReferenceDetail == false && showNoteDetail == true &&
            <div className="col-xl-4 col-md-4 d-none d-md-block">
              <ViewNotePanel processChange={processChange} note={originalNoteForView} scrolling={true} closeNote={closeNote} loadNote={loadNote} handleLoadPinnedNotes={handleLoadPinnedNotes} />
            </div>
          }
          {showIntelligenceSearchDetail == false && showSharingEventsDetail == false && showIntelligenceDetail == false && focusMode == false && showReferenceDetail == true && showNoteDetail == false &&
            <div className="col-xl-4 col-md-4 d-none d-md-block">
              <FlexpaneViewReference processChange={processChange} handlePinned={handlePinned} handleClose={handleViewReferenceClose} reference={reference} tenant={tenant} handleReferenceDetailsUpdate={handleReferenceDetailsUpdate} handleReferenceSettingsUpdate={handleReferenceSettingsUpdate} handleLoadPinnedSections={handleLoadPinnedSections} />
            </div>
          }
          {showIntelligenceSearchDetail == false && showSharingEventsDetail == false && showIntelligenceDetail == true && focusMode == false && showReferenceDetail == false && showNoteDetail == false &&
            <div className="col-xl-4 col-md-4 d-none d-md-block">
              <FlexpaneViewIntelligence show={showIntelligenceDetail} processChange={processChange} viewNote={viewNote} closeIntelligence={closeIntelligence} handleLoadPinnedSections={handleLoadPinnedSections} />
            </div>
          }
          {showIntelligenceSearchDetail == false && showSharingEventsDetail == true && showIntelligenceDetail == false && focusMode == false && showReferenceDetail == false && showNoteDetail == false &&
            <div className="col-xl-4 col-md-4 d-none d-md-block">
              <FlexpaneViewSharingEvents show={showSharingEventsDetail} processChange={processChange} viewNote={viewNote} loadNote={loadNote} handleLoadPinnedNotes={handleLoadPinnedNotes} handleLoadPinnedSections={handleLoadPinnedSections} closeSharingEvents={closeSharingEvents} />
            </div>
          }
          {showIntelligenceSearchDetail == true && showSharingEventsDetail == false && showIntelligenceDetail == false && focusMode == false && showReferenceDetail == false && showNoteDetail == false &&
            <div className="col-xl-4 col-md-4 d-none d-md-block">
              <FlexpaneViewIntelligenceSearch search={intelligenceSearch} searchHtml={intelligenceSearchHtml} show={showIntelligenceSearchDetail} processChange={processChange} viewNote={viewNote} loadNote={loadNote} handleLoadPinnedNotes={handleLoadPinnedNotes} handleLoadPinnedSections={handleLoadPinnedSections} closeIntelligenceSearch={closeIntelligenceSearch} />
            </div>
          }
        </Row>
      </div>
    </React.Fragment>
  );
}

render(<App />, document.getElementById("root"));
