import React, { useEffect, useState } from "react";
import { Row, Col, Card, OverlayTrigger, Tooltip } from "react-bootstrap";

import { Editor } from "../../../utils/editor";
import { Storage } from "../../../utils/storage";
import { Formatter } from "../../../utils/formatter";

const SectionCommentPanel = (props) => {
  const [section, setSection] = useState(null);
  const [comments, setComments] = useState(null);
  const [loading, setLoading] = useState(true);
  const [comment, setComment] = useState('');
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [boxId, setBoxId] = useState(null);

  const handleShowCommentBox = (show) => {
    setShowCommentBox(show);
    setTimeout(() => {
      // Give react a second to catch up
      document.getElementById(boxId).focus();
    }, 200);
  }

  const handleChange = (event) => {
    setComment(event.target.value)
  }

  const deleteComment = (sys_id_comment) => {
    Storage.getInstance().deleteCommentForSectionId(section.sys_id, sys_id_comment).then((commentsResult) => {
      console.log(commentsResult);
      setComments(commentsResult);
      setLoading(false);
    });
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      console.log(`The user hit enter`);
      event.preventDefault();

      Storage.getInstance().setCommentForSectionId(section.sys_id, { content: comment }).then((commentsResult) => {
        console.log(commentsResult);
        setComments(commentsResult);
        setComment("");
        setLoading(false);
      });

      return false;
    }
  }

  useEffect(() => {
    if (props.section != null) {
      Storage.getInstance().getCommentsForSectionId(props.section.sys_id).then((commentsResult) => {
        setComments(commentsResult);
        setLoading(false);
        setBoxId(crypto.randomUUID());
      });
    }

    setSection(props.section);
  }, [props.section]);

  return (
    <div className={(comments != null && comments.length > 0) || showCommentBox == true ? "ms-5 p-3 bg-light border border-light border-top-0 rounded-bottom" : "ms-5 p-1 bg-light border border-light border-top-0 rounded-bottom"}>
      {comments != null && comments.length > 0 &&
        <Row>
          <Col>
            <Card className="border-0 mb-2">
              <Card.Body>
                  <React.Fragment>
                    {comments.map((comment, index) => (
                      <React.Fragment key={"section-comment-fragment-" + comment.sys_id}>
                        <Row key={"section-comment-row-" + comment.sys_id} className={index > 0 ? "border-top mt-3 pt-2" : ""}>
                          <Col key={"section-comment-col-" + comment.sys_id}>
                            {comment.sys_id_tenant_origin == Editor.getInstance().getTenant().sys_id &&
                              <div key={"section-comment-col-float-trigger-div-rando-" + comment.sys_id} className="float-end">
                                <OverlayTrigger key={"section-comment-col-float-trigger-" + comment.sys_id} placement="top" overlay={<Tooltip>Delete this comment</Tooltip>}>
                                  <div key={"section-comment-col-float-trigger-div-" + comment.sys_id} className="btn-close" onClick={() => deleteComment(comment.sys_id)} style={{display: "inline-block", cursor: "pointer"}}></div>
                                </OverlayTrigger>
                              </div>
                            }
                            {comment.content}
                          </Col>
                        </Row>
                        <Row key={"section-comment-row2-" + comment.sys_id}>
                          <Col key={"section-comment-col2-" + comment.sys_id} className="mt-2">
                            {comment.tenant != null &&
                              <div key={"section-comment-tenant-" + comment.sys_id} className="me-2" style={{display: "inline-block"}}>
                                <img key={"section-comment-tenant-image-" + comment.sys_id} style={{height: "30px", width: "30px"}} className="rounded-circle border border-1" src={comment.tenant.picture}></img> <span className="text-muted fw-semibold">{comment.tenant.given_name} {comment.tenant.family_name}</span>
                              </div>
                            }
                            {comment.tenant == null && Editor.getInstance().getTenant() != null &&
                              <div key={"section-comment-tenant-" + comment.sys_id} className="me-2" style={{display: "inline-block"}}>
                                <img key={"section-comment-tenant-image-" + comment.sys_id} style={{height: "30px", width: "30px"}} className="rounded-circle border border-1" src={Editor.getInstance().getTenant().picture}></img> <span className="text-muted fw-semibold">{Editor.getInstance().getTenant().given_name} {Editor.getInstance().getTenant().family_name}</span>
                              </div>
                            }
                            <span className="ms-3 text-muted">{Formatter.createFormattedDate(comment.sys_date_created)}</span>
                          </Col>
                        </Row>
                      </React.Fragment>
                    ))}
                  </React.Fragment>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      }
      <Row>
        <Col>
          {showCommentBox == true &&
            <textarea id={boxId} className="form-control" style={{overflow: "auto"}} onKeyDown={handleKeyDown} onChange={handleChange} value={comment} />
          }
          {showCommentBox == false &&
            <span className="ps-2 text-muted" style={{cursor: "pointer"}} onClick={() => handleShowCommentBox(true)}><i className="bi bi-chat-quote text-muted me-2"></i>Comment</span>
          }
        </Col>
      </Row>
    </div>
  );
};

export default SectionCommentPanel;
