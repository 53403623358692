import React, { useEffect, useState } from "react";

import { Row, Col } from 'react-bootstrap';

const ResourceCardMetadata = (props) => {
  const [metadata, setMetadata] = useState(null);
  const [key, setKey] = useState(crypto.randomUUID());

  const openLink = (url) => {
    window.open(url);
  }

  useEffect(() => {
    setMetadata(props.metadata);
    // console.log(props.metadata);
  }, [props.metadata]);

  return (
    <React.Fragment>
      {metadata != null &&
        <Row>
          <Col>
            <form>
              {metadata.fields != null && metadata.fields.length > 0 && metadata.fields.map((field, index) => (
                <div key={`${key}-form-div-${index}`}>
                  <label key={`${key}-${field.name}-${index}`} htmlFor={`${key}-${field.name}`} className="form-label">{field.label}</label>
                  {field.type == "text" &&
                    <input key={`${key}-form-text-input-${index}`} type={field.type} className="form-control mb-3" id={`${key}-${field.name}`} value={field.value} />
                  }
                  {field.type == "select" &&
                    <select key={`${key}-form-select-input-${index}`} className="form-select mb-3" id={`${key}-${field.name}`} aria-label={field.label} value={field.value}>
                      {field.options != null && field.options.length > 0 && field.options.map((option, optionIndex) => (
                        <option key={`${key}-form-select-option-${index}-${optionIndex}`} value={option.value}>{option.label}</option>
                      ))}
                    </select>
                  }
                  {field.type == "reference" &&
                    <div key={`${key}-form-link-input-group-${index}`} className="input-group mb-3">
                      <input key={`${key}-form-link-input-${index}`} readOnly type="text" className="form-control" id={`${key}-${field.name}`} value={field.value} />
                      <span key={`${key}-form-link-group-link-${index}`} onClick={() => {openLink(field.url)}} className="input-group-text" style={{cursor: "pointer"}}><i className="bi bi-box-arrow-up-right"></i></span>
                    </div>
                  }
                  {field.type == "currency" &&
                    <input key={`${key}-form-currency-input-${index}`} readOnly type="text" className="form-control mb-3" id={`${key}-${field.name}`} value={field.value} />
                  }
                  {field.type == "date" &&
                    <input key={`${key}-form-text-date-${index}`} type="text" className="form-control mb-3" id={`${key}-${field.name}`} value={field.value} />
                  }
                  {field.type == "multiline" &&
                    <textarea key={`${key}-form-text-area-${index}`} id={`${key}-${field.name}`} className="form-control mb-3" aria-label={field.label}>{field.value}</textarea>
                  }
                </div>
              ))}
            </form>
          </Col>
        </Row>
      }
    </React.Fragment>
  );
};

export default ResourceCardMetadata;
