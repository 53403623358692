import React, { useEffect, useState } from "react";

import { Storage } from "../../../utils/storage";
import { Formatter } from "../../../utils/formatter";

import { Col, Row } from "react-bootstrap";

import ViewNotePanel from "./ViewNotePanel";
import ViewActionPanel from "./ViewActionPanel";
import ViewTidbitPanel from "./ViewTidbitPanel";

const SharingEventCard = (props) => {
  const [sharingEvent, setSharingEvent] = useState(null);
  const [section, setSection] = useState(null);
  const [note, setNote] = useState(null);

  const acknowledgeEvent = async() => {
    await Storage.getInstance().ignoreSharedEvent(sharingEvent.sys_id);
    // Set the sharing event to null so it disappears
    setSharingEvent(null);

    props.refreshSharingEvents();
  }

  const loadSection = async(sys_id_section) => {
    const section = await Storage.getInstance().getSection(sys_id_section);
    setSection(section);
  }

  const loadNote = async(sys_id_note) => {
    const note = await Storage.getInstance().getNote(sys_id_note);
    setNote(note);
  }

  useEffect(() => {
    // console.log(`Loading sharing event card`);
    // console.log(props.sharingEvent);
    setSharingEvent(props.sharingEvent);

    if (props.sharingEvent != null) {
      if (props.sharingEvent.sys_id_section != null && props.sharingEvent.sys_id_section.trim().length > 0) {
        // console.log(`Loading section`);
        loadSection(props.sharingEvent.sys_id_section);
        setNote(null);
      } else {
        // console.log(`Loading note`);
        loadNote(props.sharingEvent.sys_id_note);
        setSection(null);
      }
    }
  }, [props.sharingEvent]);

  return (
    <React.Fragment>
      {sharingEvent != null && sharingEvent.tenant != null &&
        <Row>
          <Col>
            {note != null && section != null ? sharingEvent.sys_id : ""}
            <table border={0}>
              <tbody>
                <tr>
                  <td style={{verticalAlign: "top"}}>
                    <img style={{height: "30px", width: "30px"}} className="rounded-circle border border-1 me-1" src={sharingEvent.tenant.picture}></img>
                  </td>
                  <td>
                    <span><b>{sharingEvent.tenant.given_name} {sharingEvent.tenant.family_name}</b> shared {note != null ? "a note" : ""}{section != null ? (section.sys_type == "action" ? "an action" : "a block") : ""} with you on {Formatter.formatDate(sharingEvent.sys_date_created)}:</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      }
      {section != null &&
        <Row className="mb-3">
          <Col>
            {section.sys_type == "action" &&
              <ViewActionPanel processChange={props.processChange} action={section} viewNote={props.viewNote} handleLoadPinnedSections={props.handleLoadPinnedSections} />
            }
            {section.sys_type == "tidbit" &&
              <ViewTidbitPanel processChange={props.processChange} tidbit={section} viewNote={props.viewNote} handleLoadPinnedSections={props.handleLoadPinnedSections} />
            }
          </Col>
        </Row>
      }
      {note != null &&
        <Row>
          <Col>
            <ViewNotePanel processChange={props.processChange} note={note} scrolling={false} removeMargin={true} loadNote={props.loadNote} handleLoadPinnedNotes={props.handleLoadPinnedNotes} />
          </Col>
        </Row>
      }
      <Row className="mb-5">
        <div className="d-grid gap-2">
          <React.Fragment>
            <button className="btn btn-info text-white fw-bolder" onClick={acknowledgeEvent}>👍 Got it!</button>
          </React.Fragment>
        </div>
      </Row>
    </React.Fragment>
  );
};

export default SharingEventCard;
