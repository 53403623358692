import Pusher from 'pusher-js';

export default class Realtime {
  static _realtime = null;

  static getInstance = () => {
    if (this._realtime == null) {
      this._realtime = new Realtime();
    }
    return this._realtime;
  }

  constructor() {
    this._pusher = new Pusher(
      '38a534006972bf66c726', {
        cluster: 'us3',
        encrypted: true
      }
    );
  }

  getTenantChannel(sys_id_tenant) {
    return this._pusher.subscribe(`tenant.${sys_id_tenant}`);
  }

  getDomainChannel(sys_id_domain) {
    return this._pusher.subscribe(`domain.${sys_id_domain}`);
  }

  getGlobalChannel() {
    return this._pusher.subscribe(`global`);
  }
}
