import React, { useEffect, useState } from "react";

import { Col, Row, Card, OverlayTrigger, Tooltip, Tab, Nav } from "react-bootstrap";

import EventObjectIntelligenceCard from "./EventObjectIntelligenceCard";

import { Editor } from "../../../utils/editor";
import { Storage } from "../../../utils/storage";
import Realtime from "../../../utils/realtime";

const FlexpaneViewIntelligence = (props) => {
  const [intelligenceEvents, setIntelligenceEvents] = useState(null);
  const [intelligenceRecommendations, setIntelligenceRecommendations] = useState(null);
  const [activeTab, setActiveTab] = useState("changes");
  const [activeTabSet, setActiveTabSet] = useState(false);

  const refreshIntelligence = async() => {
    // Load the intelligence recommendations immediately as we need the UI to reflect a valid state on load
    const intelligenceRecommendationsResult = await Storage.getInstance().getIntelligenceRecommendations();
    setIntelligenceRecommendations(intelligenceRecommendationsResult);

    // Load the intellience events immediately as we need the UI to reflect a valid state on load
    const intelligenceEventsResult = await Storage.getInstance().getIntelligenceEvents();
    setIntelligenceEvents(intelligenceEventsResult);

    // Assign the correct tab
    if (intelligenceRecommendationsResult != null && intelligenceRecommendationsResult.length > 0 && activeTabSet == false) {
      setActiveTab("recommendations");
      setActiveTabSet(true);
    } else if (intelligenceEventsResult != null && intelligenceEventsResult.length > 0 && activeTabSet == false) {
      setActiveTab("changes");
      setActiveTabSet(true);
    }

    console.log(intelligenceRecommendationsResult);
    console.log(intelligenceEventsResult);

    if ((intelligenceEventsResult == null || intelligenceEventsResult.length == 0) &&
        (intelligenceRecommendationsResult == null || intelligenceRecommendationsResult.length == 0)) {
      props.closeIntelligence();
    } else {
      if ((intelligenceEventsResult == null || intelligenceEventsResult.length == 0) && activeTab == "recommendations") {
        setActiveTab("changes");
      }
      if ((intelligenceEventsResult == null || intelligenceEventsResult.length == 0) && activeTab == "changes") {
        setActiveTab("recommendations");
      }  
    }
  }

  const handlTabChange = async(tabKey) => {
    setActiveTab(tabKey);
  }

  useEffect(() => {
    if (Editor.getInstance().getTenant() != null) {
      refreshIntelligence();

      console.log(`tenant.${Editor.getInstance().getTenant().sys_id}`);
      const tenant_channel = Realtime.getInstance().getTenantChannel(Editor.getInstance().getTenant().sys_id);
      tenant_channel.bind('event.update', data => {
        console.log(`Recieved event.update`, data);
        refreshIntelligence();
      });
      tenant_channel.bind('event.delete.one', data => {
        console.log(`Recieved event.delete.one`, data);
        refreshIntelligence();
      });
      tenant_channel.bind('recommendation.update', data => {
        console.log(`Recieved recommendation.update`, data);
        refreshIntelligence();
      });
      tenant_channel.bind('recommendation.delete.one', data => {
        console.log(`Recieved recommendation.delete.one`, data);
        refreshIntelligence();
      });
    }
  }, [props.show]);

  return (
    <React.Fragment>
      {((intelligenceEvents != null && intelligenceEvents.length > 0) || (intelligenceRecommendations != null && intelligenceRecommendations.length > 0)) &&
        <React.Fragment>
          <Card className="rounded border-0 shadow-sm mt-1 mt-3">
            <Card.Body>
              <Row className="pb-3">
                <Col>
                  <span className="fs-6 text-muted"><div className="notify-inner-circles-loader">Intelligence</div> <span className="fw-bolder">Intelligence</span></span>
                  <div className="float-end">
                    <OverlayTrigger placement="top" overlay={<Tooltip>Close the intelligence view</Tooltip>}>
                      <button className="btn btn-sm btn-light text-muted" onClick={props.closeIntelligence}><i className="bi bi-x-lg"></i></button>
                    </OverlayTrigger>
                  </div>
                </Col>
              </Row>
              <Row className="pb-3">
                <Col>
                  <Tab.Container
                    activeKey={activeTab}
                    onSelect={(tabKey) => handlTabChange(tabKey)}
                    variant="pills"
                  >
                    <Nav variant="pills mb-2">
                      {intelligenceEvents != null && intelligenceEvents.length > 0 &&
                        <Nav.Item>
                          <Nav.Link eventKey="changes"><i className="bi bi-asterisk me-2"></i>Changes</Nav.Link>
                        </Nav.Item>
                      }
                      {intelligenceRecommendations != null && intelligenceRecommendations.length > 0 &&
                        <Nav.Item>
                          <Nav.Link eventKey="recommendations"><i className="bi bi-database-down me-2"></i>Recommendations</Nav.Link>
                        </Nav.Item>
                      }
                    </Nav>
                    {intelligenceEvents != null && intelligenceEvents.length > 0 &&
                      <Tab.Content>
                        <Tab.Pane eventKey="changes">
                          <Row className="d-flex flex-column" style={{height: "69vh"}}>
                            <Col className="overflow-auto flex-grow-1">
                              {intelligenceEvents.map((intelligenceEvent, index) => (
                                <EventObjectIntelligenceCard key={`event-object-intelligence-card-for-intelligence-event-${index}`} processChange={props.processChange} viewNote={props.viewNote} eventType="event" eventObject={intelligenceEvent} refreshIntelligence={refreshIntelligence} handleLoadPinnedSections={props.handleLoadPinnedSections} />
                              ))}
                            </Col>
                          </Row>
                        </Tab.Pane>
                      </Tab.Content>
                    }
                    {intelligenceRecommendations != null && intelligenceRecommendations.length > 0 &&
                      <Tab.Content>
                        <Tab.Pane eventKey="recommendations">
                          <Row className="d-flex flex-column" style={{height: "69vh"}}>
                            <Col className="overflow-auto flex-grow-1">
                              {intelligenceRecommendations.map((intelligenceRecommendation, index) => (
                                <EventObjectIntelligenceCard key={`event-object-intelligence-card-for-intelligence-recommendation-${index}`} processChange={props.processChange} viewNote={props.viewNote} eventType="recommendation" eventObject={intelligenceRecommendation} refreshIntelligence={refreshIntelligence} handleLoadPinnedSections={props.handleLoadPinnedSections} />
                              ))}
                            </Col>
                          </Row>
                        </Tab.Pane>
                      </Tab.Content>
                    }
                  </Tab.Container>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default FlexpaneViewIntelligence;
