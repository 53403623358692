import React, { useEffect, useState, useRef } from "react";

import { Row, Col, OverlayTrigger, Tooltip, Collapse, Popover } from "react-bootstrap";
import DatePicker from 'react-datepicker';

import { Editor } from "../../../utils/editor";
import { Formatter } from "../../../utils/formatter";
import { Storage } from "../../../utils/storage";
import { Parser } from "../../../utils/parser";
import Realtime from "../../../utils/realtime";
import Gravitar from "../../../utils/gravitar";
import ViewEmojiControls from "./ViewEmojiControls";

const ViewSectionControls = (props) => {
  const [section, setSection] = useState(null);
  const [pinned, setPinned] = useState(null);
  const [isShared, setIsShared] = useState(false);
  const [referencesForSharing, setReferencesForSharing] = useState([]);
  const [comments, setComments] = useState(null);
  const [commentsCount, setCommentsCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [comment, setComment] = useState('');
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const commentRef = useRef(null);

  const handlePinSection = async() => {
    const response = await Storage.getInstance().pinSection(section.sys_id, section.sys_type, !pinned);
    // console.log(response);
    setPinned(response.pinned);

    if (section.sys_id_sharing_event != null && section.sys_id_sharing_event.trim().length > 0) {
      await Storage.getInstance().ignoreSharedEvent(section.sys_id_sharing_event);
      props.reactionCallback({
        sys_id_sharing_event: null,
      });
    }

    if (props.handleLoadPinnedSections != null) {
      props.handleLoadPinnedSections();
    }
  }

  const handleSharing = () => {
    Storage.getInstance().getSection(section.sys_id).then((sectionForSharing) => {
      // console.log(sectionForSharing);
      const forSharing = [];      
      if (sectionForSharing.references != null && sectionForSharing.references.length > 0) {
        for (let x = 0; x < sectionForSharing.references.length; x++) {
          const reference = sectionForSharing.references[x];
    
          // Don't include yourself in the sharing
          if (Editor.getInstance().getTenant() != null && Editor.getInstance().getTenant().sys_id == reference.sys_id_tenant_verified) {
            continue;
          }

          for (let y = 0; y < sectionForSharing.references_referenced.length; y++) {
            if (sectionForSharing.references_referenced[y].sys_id_reference == reference.sys_id) {
              reference.shared = sectionForSharing.references_referenced[y].shared;
              break;
            }
          }
          forSharing.push(reference);
        }
      }

      // console.log(`Setting references for sharing`);
      // console.log(forSharing);
      setReferencesForSharing(forSharing);
    });
  }

  const handleSharingChange = (reference, shared) => {
    const references_referenced = [];
    let sharedWithSomething = false;
    if (referencesForSharing != null && referencesForSharing.length > 0) {
      for (let x = 0; x < referencesForSharing.length; x++) {
        if (reference.sys_id == referencesForSharing[x].sys_id) {
          referencesForSharing[x].shared = shared;
        }

        if (referencesForSharing[x].shared == true) {
          sharedWithSomething = true;
        }
        
        references_referenced.push({
          sys_id_reference: referencesForSharing[x].sys_id,
          sys_type: referencesForSharing[x].sys_type,
          shared: referencesForSharing[x].shared,
        })
      }
    }

    Storage.getInstance().saveSectionSharingChange(section.sys_id, references_referenced).then(() => {
      // console.log("Section sharing changed");
      // Not sure this is needed, but have updated the sharing settings for the references so the view needs to be updated
      setReferencesForSharing(referencesForSharing);
      setIsShared(sharedWithSomething);
    });
  }

  const handleDueDateChange = (date) => {
    Storage.getInstance().saveSectionDueDateChange(section.sys_id, date).then(() => {
      // console.log("Section due date changed");
      // Not sure this is needed, but have updated the sharing settings for the references so the view needs to be updated
      setSelectedDate(date.date_start);
    });
  }

  const handleDateSelectionChange = (value) => {
    // console.log(value);
    const selectionDate = {
      sys_id: crypto.randomUUID(),
      sys_id_section: section.sys_id,
      content_index: -1,
      content: '',
      date_start: value,
      date_end: null,
      all_day: true,
      due_date: false,
    }
    
    handleDueDateChange(selectionDate);
  }

  const handleShowCommentBox = (show) => {
    setShowCommentBox(show);
    if (show == true) {
      setTimeout(() => {
        // Give react a second to catch up
        commentRef.current.focus();
      }, 200);
    }
  }

  const deleteComment = async(sys_id_comment) => {
    const commentsResult = Storage.getInstance().deleteCommentForSectionId(section.sys_id, sys_id_comment);
    // console.log(commentsResult);
    setCommentsCount(commentsResult.length);
    setComments(await Parser.parseCommentsContentForEditor(commentsResult));
    setLoading(false);
  }

  const handleKeyDown = async(event) => {
    if (event.key === 'Enter') {
      // console.log(`The user hit enter`);
      event.preventDefault();

      const commentsResult = await Storage.getInstance().setCommentForSectionId(section.sys_id, Parser.getCommentForStorage(commentRef.current.innerHTML));

      // console.log(commentsResult);
      commentRef.current.innerHTML = "";

      setComments(await Parser.parseCommentsContentForEditor(commentsResult));
      setCommentsCount(commentsResult.length);
      setLoading(false);

      return false;
    }

    // console.log(`Processing change`, props.processChange);
    props.processChange();
  }

  const generateSectionDates = (section) => {
    if (section.dates != null && section.dates.length > 0) {
      // TODO - Order the dates
      // Push the date created into the list as we'll need that so they can change back as needed
      section.dates.push({
        date_start: section.sys_date_created,
      });
    }

    setSelectedDate(section.due_date);
  }

  const loadSectionComments = async() => {
    const commentsResult = await Storage.getInstance().getCommentsForSectionId(props.section.sys_id);

    setComments(await Parser.parseCommentsContentForEditor(commentsResult));
    setCommentsCount(commentsResult.length);
    setLoading(false);
  }

  useEffect(() => {
    if (props.section != null && props.section.ellie_blocks != null && props.section.ellie_blocks.length > 0) {
      let tempIsShared = false;
      if (props.section.references_referenced != null && props.section.references_referenced.length > 0) {
        for (let x = 0; x < props.section.references_referenced.length; x++) {
          if (props.section.references_referenced[x].shared == true) {
            tempIsShared = true;
            break;
          }
        }
      }

      setIsShared(tempIsShared);
    }

    if (props.section != null) {
      loadSectionComments();

      // console.log(props.section);
      setPinned(props.section.pinned);

      if (Editor.getInstance().getTenant() != null) {
        // console.log(`global`);
        const global_channel = Realtime.getInstance().getGlobalChannel();
        global_channel.bind('comment.add', data => {
          console.log(`Recieved comment.add`, data, props.section);
          if (data.sys_id_section != null && props.section != null && props.section.sys_id == data.sys_id_section) {
            // We have an update to the section comments for this section
            loadSectionComments();
          }
        });
        global_channel.bind('comment.delete.one', data => {
          console.log(`Recieved comment.delete.one`, data, props.section);
          if (data.sys_id_section != null && props.section != null && props.section.sys_id == data.sys_id_section) {
            // We have an update to the section comments for this section
            loadSectionComments();
          }
        });
      }
    }

    generateSectionDates(props.section);
    setSection(props.section);
  }, [props.section]);

  return (
    <React.Fragment>
      {section != null &&
        <React.Fragment>
          <Row className={props.includePadding == true ? "pt-3" : ""}>
            <Col>
              {section.tenant != null &&
                <OverlayTrigger placement="top" overlay={<Tooltip>{section.tenant.given_name} {section.tenant.family_name}</Tooltip>}>
                  <div className="me-2 d-inline">
                    <img style={{height: "30px", width: "30px"}} className="rounded-circle border border-1" src={section.tenant.picture != null && section.tenant.picture.trim().length > 0 ? section.tenant.picture : '/assets/person.png'}></img>
                  </div>
                </OverlayTrigger>
              }
              {section.tenant == null && Editor.getInstance().getTenant() != null &&
                <OverlayTrigger placement="top" overlay={<Tooltip>{Editor.getInstance().getTenant().given_name} {Editor.getInstance().getTenant().family_name}</Tooltip>}>
                  <div className="me-2 d-inline">
                    <img style={{height: "30px", width: "30px"}} className="rounded-circle border border-1" src={Editor.getInstance().getTenant().picture != null && Editor.getInstance().getTenant().picture.trim().length > 0 ? Editor.getInstance().getTenant().picture : '/assets/person.png'}></img>
                  </div>
                </OverlayTrigger>
              }
              <OverlayTrigger placement="top" overlay={<Tooltip>View original note</Tooltip>}>
                <button className="btn btn-sm btn-light text-muted me-2" onClick={() => props.viewNote(section)}><i className="bi bi-journal-medical"></i></button>
              </OverlayTrigger>
              {(section.dates == null || section.dates.length == 0) &&
                <DatePicker
                  portalId="root-portal"
                  className="me-2"
                  onChange={handleDateSelectionChange} 
                  selected={new Date(selectedDate)} 
                  customInput={<button className={`btn btn-sm btn-light text-muted`} type="button"><i className="bi bi-calendar3 me-2 text-muted"></i>{Formatter.datesContent(selectedDate)}</button>}
                />
              }
              {section.dates != null && section.dates.length > 0 &&
                <OverlayTrigger
                  trigger="click"
                  rootClose={true}
                  placement="bottom" 
                  overlay={
                    <Popover>
                      <Row>
                        <Col>
                          <div className="list-group">
                            <button type="button" className="list-group-item list-group-item-action fw-semibold" disabled>Suggested dates:</button>
                            {section.dates.map((date, index) => (
                              <button key={`view-section-controls-rando-panel-div-${index}`} type="button" className="list-group-item list-group-item-action" onClick={() => handleDueDateChange(date)}>
                                <i className="bi bi-calendar3 me-2 text-muted"></i>{Formatter.datesContent(date.date_start)}
                              </button>
                            ))}
                            <button type="button" className="list-group-item list-group-item-action fw-semibold" disabled>Select specific date:</button>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <DatePicker
                            portalId="root-portal"
                            selected={new Date(selectedDate)} 
                            onChange={handleDateSelectionChange} 
                            inline
                          />
                        </Col>
                      </Row>
                    </Popover>}>
                  <button className={`btn btn-sm btn-light text-muted me-2`} type="button"><i className="bi bi-calendar3 me-2 text-muted"></i>{Formatter.datesContent(selectedDate)}</button>
                </OverlayTrigger>
              }
              {section.shared_by_note == false &&
                <React.Fragment>
                  <OverlayTrigger key={"section-sharing-col-float-trigger-" + comment.sys_id} placement="top" overlay={<Tooltip>Change who can see this</Tooltip>}>
                    <button onClick={handleSharing} className="btn btn-sm btn-light text-nowrap dropdown-toggle me-2" disabled={section.tenant == null ? false : true} type="button" data-bs-toggle="dropdown" aria-expanded="false"><span className={isShared == true ? "badge text-bg-success me-1" : "badge text-bg-light me-1"}><i className={isShared == true ? "bi bi-people-fill" : "bi bi-person-fill-lock"}></i></span>{isShared == false ? "Private" : "Shared"}</button>
                  </OverlayTrigger>
                  {section.tenant == null &&
                    <ul className="dropdown-menu">
                      {referencesForSharing != null && referencesForSharing.length > 0 &&
                        <React.Fragment>
                          <li><h6 className="dropdown-header">Share this {section.sys_type == "action" ? "action" : "block"} with:</h6></li>
                          {referencesForSharing.map((referenceForSharing, index) => (
                            <li key={`view-section-controls-rando-panel-div-${index}`} className="dropdown-item">
                              <input key={`view-section-controls-rando-panel-div-input-${index}`} type="checkbox" onChange={() => handleSharingChange(referenceForSharing, !referenceForSharing.shared)} className="me-1 align-top mt-1" id={"sharing-" + section.sys_id + referenceForSharing.sys_id} defaultChecked={referenceForSharing.shared} />
                              <label key={`view-section-controls-rando-panel-div-label-${index}`} className="form-check-label" htmlFor={"sharing-" + section.sys_id + referenceForSharing.sys_id}>
                                {referenceForSharing.sys_type == "topic" ? "#" : "@"}{referenceForSharing.alias}<span style={{fontSize: "0.75em"}}><br/></span>
                                {referenceForSharing.sys_type == "topic" &&
                                  <React.Fragment>
                                    {referenceForSharing.sys_id_sharing == 0 &&
                                      <span style={{fontSize: "0.75em"}}><i className="bi bi-person-fill-lock text-muted"></i> Private to you</span>
                                    }
                                    {referenceForSharing.sys_id_sharing == 1 &&
                                      <span style={{fontSize: "0.75em"}}><i className="bi bi-person-fill-check text-muted"></i> Share with:
                                        {referenceForSharing.shared_with != null && referenceForSharing.shared_with.length > 0 &&
                                          <ul>
                                            {referenceForSharing.shared_with.map((sharedWith, sharedIndex) => (
                                              <li key={sharedWith.sys_id + " " + sharedIndex}>{sharedWith.sys_type == "topic" ? "#" : "@"}{sharedWith.alias} {Editor.getInstance().getTenant() != null && sharedWith.sys_id_tenant_verified == Editor.getInstance().getTenant().sys_id ? "(You!)" : ""}</li>
                                            ))}
                                          </ul>
                                        }
                                      </span>
                                    }
                                    {referenceForSharing.sys_id_sharing == 2 &&
                                      <span style={{fontSize: "0.75em"}}><i className="bi bi-people-fill text-muted"></i> Visible to everyone at {Editor.getInstance().getTenant() != null ? Editor.getInstance().getTenant().domain : ""}</span>
                                    }
                                  </React.Fragment>
                                }
                                {referenceForSharing.sys_type == "person" &&
                                  <React.Fragment>
                                    {referenceForSharing.sys_id_tenant_verified != null && referenceForSharing.sys_id_tenant_verified.trim().length > 0 &&
                                      <span style={{fontSize: "0.75em"}}><i className="bi bi-person-fill-lock text-muted"></i> Share with {referenceForSharing.email}</span>
                                    }
                                    {referenceForSharing.email != null && referenceForSharing.email.trim().length > 0 && (referenceForSharing.sys_id_tenant_verified == null || referenceForSharing.sys_id_tenant_verified.trim().length == 0) &&
                                      <span style={{fontSize: "0.75em"}}><i className="bi bi-person-fill-lock text-muted"></i> Share with {referenceForSharing.email} (they don't have an account)</span>
                                    }
                                    {(referenceForSharing.email == null || referenceForSharing.email.trim().length == 0) && (referenceForSharing.sys_id_tenant_verified == null || referenceForSharing.sys_id_tenant_verified.trim().length == 0) &&
                                      <span style={{fontSize: "0.75em"}}><i className="bi bi-person-fill-lock text-muted"></i> Will not be shared until user is connected with you</span>
                                    }
                                  </React.Fragment>
                                }
                              </label>
                            </li>
                          ))}
                        </React.Fragment>
                      }
                      {(referencesForSharing == null || referencesForSharing.length == 0) &&
                        <li key={`view-section-controls-rando-panel-div-nothing`} className="dropdown-item">
                          <h6 className="dropdown-header">No tags for sharing</h6>
                        </li>
                      }
                    </ul>
                  }
                </React.Fragment>
              }
              {section.shared_by_note == true &&
                <OverlayTrigger key={"section-note-sharing-col-float-trigger-" + comment.sys_id} placement="top" overlay={<Tooltip>Shared by the note</Tooltip>}>
                  <button className="btn btn-sm btn-light me-2 disabled opacity-50" type="button"><span className="badge text-bg-success me-1"><i className="bi bi-people-fill"></i></span>Shared Note</button>
                </OverlayTrigger>
              }
              <OverlayTrigger placement="top" overlay={<Tooltip>{pinned == true ? "Pinned" : (section.sys_type == "action" ? "Pin this action" : "Pin this block")}</Tooltip>}>
                <button className={`btn btn-sm btn-light ${pinned == true ? "" : "text-muted"} me-2`} style={pinned == true ? { backgroundColor: "#dee2e6"} : {}} onClick={() => handlePinSection()}><i className={`bi bi-pin${pinned == true ? '-fill' : ''}`}></i></button>
              </OverlayTrigger>
              <OverlayTrigger placement="top" overlay={<Tooltip>Comment</Tooltip>}>
                <button className="btn btn-sm btn-light text-muted me-2" style={showCommentBox == true ? {"--bs-btn-bg": "#d3d4d5"} : null} onClick={() => handleShowCommentBox(!showCommentBox)}><i className="bi bi-chat"></i>{commentsCount > 0 ? <span> <span className="badge text-bg-secondary">{commentsCount}</span></span> : null}</button>
              </OverlayTrigger>
              <ViewEmojiControls reactionCallback={props.reactionCallback} sys_id_section={section.sys_id} sys_id_sharing_event={section.sys_id_sharing_event} />
            </Col>
          </Row>
          <Collapse in={showCommentBox}>
            <Row>
              <Col>
                {comments != null && comments.length > 0 &&
                  <Row className="m-2 p-2 bg-info bg-opacity-10 rounded-3">
                    <Col>
                      {comments.map((comment, index) => (
                        <React.Fragment key={"section-comment-fragment-row-" + comment.sys_id}>
                          <Row key={"section-comment-row-" + comment.sys_id}>
                            <Col key={"section-comment-col-" + comment.sys_id}>
                              {comment.sys_id_tenant_origin == Editor.getInstance().getTenant().sys_id &&
                                <div key={"section-comment-col-float-trigger-div-rando-" + comment.sys_id} className="float-end">
                                  <OverlayTrigger key={"section-comment-col-float-trigger-" + comment.sys_id} placement="top" overlay={<Tooltip>Delete this comment</Tooltip>}>
                                    <button key={"section-comment-col-float-trigger-div-" + comment.sys_id} className="btn btn-sm btn-light text-muted me-2" onClick={() => deleteComment(comment.sys_id)}><i key={"section-comment-col-float-trigger-div-trash-icon" + comment.sys_id} className="bi bi-trash2-fill"></i></button>
                                  </OverlayTrigger>
                                </div>
                              }
                              <div className="mt-3" dangerouslySetInnerHTML={{ __html: comment.content }}/>
                            </Col>
                          </Row>
                          <Row key={"section-comment-row2-" + comment.sys_id}>
                            <Col key={"section-comment-col2-" + comment.sys_id} className="mt-2">
                              {comment.tenant != null &&
                                <div key={"section-comment-tenant-" + comment.sys_id} className="me-2" style={{display: "inline-block"}}>
                                  <img key={"section-comment-tenant-image-" + comment.sys_id} style={{height: "30px", width: "30px"}} className="rounded-circle border border-1" src={comment.tenant.picture}></img> <span className="text-muted fw-semibold">{comment.tenant.given_name} {comment.tenant.family_name}</span>
                                </div>
                              }
                              {comment.tenant == null && comment.person == null && Editor.getInstance().getTenant() != null &&
                                <div key={"section-comment-tenant-" + comment.sys_id} className="me-2" style={{display: "inline-block"}}>
                                  <img key={"section-comment-tenant-image-" + comment.sys_id} style={{height: "30px", width: "30px"}} className="rounded-circle border border-1" src={Editor.getInstance().getTenant().picture}></img> <span className="text-muted fw-semibold">{Editor.getInstance().getTenant().given_name} {Editor.getInstance().getTenant().family_name}</span>
                                </div>
                              }
                              {comment.tenant == null && comment.person != null &&
                                <div key={"section-comment-tenant-" + comment.sys_id} className="me-2" style={{display: "inline-block"}}>
                                  <img key={"section-comment-tenant-image-" + comment.sys_id} style={{height: "30px", width: "30px"}} className="rounded-circle border border-1" src={Gravitar.getPersonImageUrl(comment.person.email)}></img> <span className="text-muted fw-semibold me-2">{comment.person.friendly_name}</span><i class="bi bi-envelope-check text-muted"></i>
                                </div>
                              }
                              <span className="ms-3 text-muted">{Formatter.createFormattedDate(comment.sys_date_created)}</span>
                            </Col>
                          </Row>
                        </React.Fragment>
                      ))}
                    </Col>
                  </Row>
                }
                <Row className="mt-3">
                  <Col>
                    <div className="text-muted ps-2 ms-2 fw-bolder">Comment:</div>
                    <div 
                      ref={commentRef}
                      contentEditable
                      onKeyDown={handleKeyDown}
                      className="w-100 mb-2 ms-2 me-2 mt-0 pb-2 ps-2 pe-2 pt-0 nfy-comment-input" 
                      data-placeholder="Comment"
                      style={{border: 0, outline: 0, overflow: "auto"}}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Collapse>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default ViewSectionControls;
