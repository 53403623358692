import Paragraph from "@editorjs/paragraph";
import Header from "@editorjs/header";
import NestedList from '@editorjs/nested-list';
import ImageTool from '@editorjs/image';
import Action from "../blocks/action";
import Recorder from "../blocks/recorder";

const sanitizeConfig = {
  br: true,
  span: true,
  mark: true,
  b: true,
}

Object.defineProperty(Header, 'sanitize', {
  get() {
    return {
      text: sanitizeConfig,
    };
  }
});

Object.defineProperty(Paragraph, 'sanitize', {
  get() {
    return {
      text: sanitizeConfig,
    };
  }
});

Object.defineProperty(Action, 'sanitize', {
  get() {
    return {
      text: sanitizeConfig,
    };
  }
});

Object.defineProperty(NestedList, 'sanitize', {
  get() {
    return {
      style: {},
      items: sanitizeConfig,
    };
  }
});

// https://github.com/codex-team/editor.js/issues/985
const EllieHeader = (function (original) {
  function Header() {
    original.apply(this, arguments) 
    this.validate = function (savedData) {
      return true
    }
  }
  Header.prototype = original.prototype 
  Header.prototype.constructor = Header 
  return Header
})(Header);
// https://github.com/codex-team/editor.js/issues/985

const api = { 
  styles: {
    block: "cdx-block",
    button: "cdx-button",
    inlineToolButton: "ce-inline-tool",
    inlineToolButtonActive: "ce-inline-tool--active",
    input: "cdx-input",
    loader: "cdx-loader",
    settingsButton: "cdx-settings-button",
    settingsButtonActive: "cdx-settings-button--active",
  },
  i18n: {
    t: (value) => { return value; }
  }
}

export default class Blocks {
  static getConfig(type) {
    if (type == "paragraph") {
      return {
        class: Paragraph,
        tunes: ['notify_section', 'converter', 'notify_links',],
        inlineToolbar: true,
        config: {
          placeholder: "Note",
          preserveBlank: true,
        },
      };
    } else if (type == "action") {
      return {
        class: Action,
        tunes: ['notify_section', 'converter', 'notify_links',],
        inlineToolbar: true,
      };
    } else if (type == "header") {
      return {
        class: EllieHeader,
        tunes: ['notify_section', 'converter', 'notify_links',],
        inlineToolbar: true,
        config: {
          placeholder: "Title",
          defaultLevel: 1,
        },         
      };
    } else if (type == "list") {
      return {
        class: NestedList,
        inlineToolbar: true,
        tunes: ['notify_section', 'converter', 'notify_links',],
        config: {
          defaultStyle: 'unordered'
        }
      };
    } else if (type == "image") {
      return {
        class: ImageTool,
        config: {
          endpoints: {
            byFile: `${process.env.REACT_APP_BACKEND_BASE_URL}image`,
            byUrl: `${process.env.REACT_APP_BACKEND_BASE_URL}api/v1/link/preview`,
          },
          additionalRequestHeaders: {
            "Access-Token": window.localStorage.getItem('token') != null ? window.localStorage.getItem('token').toString() : '',
            "EllieNotes-Account": window.localStorage.getItem('account') != null ? window.localStorage.getItem('account').toString() : '',
            "nfy-auth-token": window.localStorage.getItem('auth_token') != null ? window.localStorage.getItem('auth_token').toString() : '',
          }
        }
      }
    } else if (type == "recorder") {
      return {
        class: Recorder,
      };
    }
  }

  static getImplementation(type) {
    if (type == "paragraph") {
      return Paragraph;
    } else if (type == "action") {
      return Action;
    } else if (type == "header") {
      return EllieHeader;
    } else if (type == "list") {
      return NestedList;
    } else if (type == "image") {
      return ImageTool;
    } else if (type == "recorder") {
      return Recorder;
    }
  }

  static render(sys_id_block, type, data) {
    // console.log(api);

    // Add the identifier to the data so we can reference it for action callbacks
    if (data != null) {
      data.sys_id_block = sys_id_block;
    }

    if (type == "paragraph") {
      return new Paragraph({ data: data, config: Blocks.getConfig(type), api: api, readOnly: true, block: null}).render();
    } else if (type == "action") {
      const action = new Action({ data: data, config: Blocks.getConfig(type), api: api, readOnly: true, block: null});
      // action.setNoteHandleStateChange(handleNoteStateChange);
      return action.render();
    } else if (type == "header") {
      return new EllieHeader({ data: data, config: Blocks.getConfig(type), api: api, readOnly: true, block: null}).render();
    } else if (type == "list") {
      return new NestedList({ data: data, config: Blocks.getConfig(type), api: api, readOnly: true, block: null}).render();
    } else if (type == "image") {
      return new ImageTool({ data: data, config: Blocks.getConfig(type), api: api, readOnly: true, block: null}).render();
    } else if (type == "recorder") {
      return new Recorder({ data: data, config: Blocks.getConfig(type), api: api, readOnly: true, block: null}).render();
    }
  }
}
