import React, { useEffect, useState } from "react";

import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';

const ResourceCardMessagesMessage = (props) => {
  const [message, setMessage] = useState(null);
  const [key, setKey] = useState(crypto.randomUUID());

  useEffect(() => {
    setMessage(props.message);
    // console.log(props.messages);
  }, [props.message]);

  return (
    <React.Fragment>
      {message != null &&
        <Row className="pb-2">
          <Col>
            <table border={0}>
              <tbody>
                <tr>
                  <td rowSpan={3} style={{verticalAlign: "top"}}>
                    <img className="rounded mt-1 me-1" src={message.user.picture} height={48} width={48} />
                  </td>
                  <td>
                    <OverlayTrigger placement="top" overlay={<Tooltip>{message.user.friendly_name}, {message.user.email}</Tooltip>}>
                      <span className="fw-bolder">@{message.user.alias}</span>
                    </OverlayTrigger>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span dangerouslySetInnerHTML={{ __html: message.html }}/>
                  </td>
                </tr>
                <tr>
                  <td>
                    {message.reactions != null && message.reactions.length > 0 && message.reactions.map((reaction, index) => (
                      <span key={`${key}-message-reply-reaction-${index}`} className="badge rounded-pill text-bg-light fw-normal text-muted" style={{fontSize: "1em"}} dangerouslySetInnerHTML={{ __html: `${reaction.emoji_html} ${reaction.users.length}` }}/>
                    ))}
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      }
    </React.Fragment>
  );
};

export default ResourceCardMessagesMessage;
