import { Editor } from "../utils/editor";
import { Parser } from "../utils/parser";
import { NotifyProvider, Storage } from "../utils/storage";

export default class SignInWithNotifyLinks {
  constructor({data, config, api, block}) {
    this.api = api;
    this.data = data;
    this.config = config;
    this.block = block;
  }

  save() {
    return {};
  }

  static get pasteConfig() {
    return {
      patterns: {
        google: '/docs/i'
      }
    }
  }

  wrap(blockContent) {
    this.api.listeners.on(blockContent, 'paste', (event) => {
      // console.log(event);
      // Get the clipboard data and check if it's a URL
      const clipboardData = event.clipboardData || window.clipboardData;
      const pastedData = clipboardData.getData('text');
      const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
      const targetElement = event.target;

      setTimeout(() => {
        if (urlRegex.test(pastedData)) {
          // Prevent the default paste behavior
          event.preventDefault();
  
          // Insert a hyperlink with the pasted URL using the linkTool module
          const blockIndex = this.api.blocks.getCurrentBlockIndex();

          // Create a NodeIterator that will traverse the text nodes of the element
          const nodeIterator = document.createNodeIterator(
            targetElement,
            NodeFilter.SHOW_TEXT,
            {
              acceptNode(node) {
                // Filter out nodes that are whitespace or empty
                if (!node.textContent.trim()) {
                  return NodeFilter.FILTER_REJECT;
                }
                return NodeFilter.FILTER_ACCEPT;
              },
            }
          );

          // Traverse the text nodes and replace any instances of 'foo' with 'bar'
          let currentNode;
          while ((currentNode = nodeIterator.nextNode())) {          
            // Traverse the text nodes and replace any instances of 'foo' with 'bar'
            // console.log(currentNode);
            const matchIndex = currentNode.textContent.indexOf(pastedData);
            // console.log(matchIndex);
            if (matchIndex >= 0) {
              // console.log('Match found');
              // Create a new <a> element with the matching text
              const linkElement = document.createElement('a');
              linkElement.textContent = pastedData;
              linkElement.href = pastedData;
              linkElement.target = "_blank";
          
              // Replace the text node with the new <a> element
              const parent = currentNode.parentNode;
              const beforeNode = currentNode.splitText(matchIndex);
              let afterNode = beforeNode.splitText(pastedData.length);
              parent.replaceChild(linkElement, beforeNode);
  
              // Update the remaining text content of the parent element
              if (afterNode.textContent) {
                parent.insertBefore(afterNode, linkElement.nextSibling);
              }
  
              // Set focus on the inserted link element
              this.api.caret.setToBlock(blockIndex, "end");

              if (Parser.getLinkProvider(pastedData) == NotifyProvider.Google) {
                Storage.getInstance().isAppAuthenticated(NotifyProvider.Google)
                  .then((authenticationResponse) => {
                    // Get the link identifier out from the actual link
                    const identifier = Parser.getGoogleDocId(pastedData);          

                    // Check the authentication status
                    if (authenticationResponse.is_authenticated == false) {
                      // Prompt the user to connect with Google - making sure the note is saved before doing the redirection
                      Editor.getInstance().showProviderAuthentication(NotifyProvider.Google, identifier.sys_id_external);
                    }
                  });
              } else if (Parser.getLinkProvider(pastedData) == NotifyProvider.Salesforce) {
                Storage.getInstance().isAppAuthenticated(NotifyProvider.Salesforce)
                  .then((authenticationResponse) => {
                    // Get the link identifier out from the actual link
                    const identifier = Parser.getSalesforceRecordId(pastedData);

                    // Check the authentication status
                    if (authenticationResponse.is_authenticated == false) {
                      // Prompt the user to connect with Salesforce - making sure the note is saved before doing the redirection
                      Editor.getInstance().showProviderAuthentication(NotifyProvider.Salesforce, identifier.sys_id_external);
                    }
                  });
              } else if (Parser.getLinkProvider(pastedData) == NotifyProvider.Slack) {
                Storage.getInstance().isAppAuthenticated(NotifyProvider.Slack)
                  .then((authenticationResponse) => {
                    // Get the link identifier out from the actual link
                    const identifier = Parser.getSlackObjectId(pastedData);

                    // Check the authentication status
                    if (authenticationResponse.is_authenticated == false) {
                      // Prompt the user to connect with Slack - making sure the note is saved before doing the redirection
                      Editor.getInstance().showProviderAuthentication(NotifyProvider.Slack, identifier.sys_id_external);
                    }
                  });
              }
              
              // We need to get out of the while loop of we'll end up in an infinite situation
              break;
            }
          }
        }
  
      }, 200);
    }, false);

    return blockContent;
  }

  render() {
    return document.createElement("span");
  }

  static get isTune() {
    return true;
  }
}