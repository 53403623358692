import React, { useEffect, useState } from "react";

import { Col, Row, Card, OverlayTrigger, Tooltip, Tab, Nav } from "react-bootstrap";

import SharingEventCard from "./SharingEventCard";
import CommentEventCard from "./CommentEventCard";

import { Editor } from "../../../utils/editor";
import { Storage } from "../../../utils/storage";
import Realtime from "../../../utils/realtime";

const FlexpaneViewSharingEvents = (props) => {
  const [sharingEvents, setSharingEvents] = useState(null);
  const [commentEvents, setCommentEvents] = useState(null);
  const [activeTab, setActiveTab] = useState("sharing");
  const [activeTabSet, setActiveTabSet] = useState(false);

  const refreshSharingEvents = async() => {
    // Load the sharing events immediately as we need the UI to reflect a valid state on load
    const sharingEventsResult = await Storage.getInstance().getSharingEvents();
    setSharingEvents(sharingEventsResult);

    // Load the comment events immediately as we need the UI to reflect a valid state on load
    const commentEventsResult = await Storage.getInstance().getCommentEvents();
    setCommentEvents(commentEventsResult);

    // Assign the correct tab
    if (sharingEventsResult != null && sharingEventsResult.length > 0 && activeTabSet == false) {
      setActiveTab("sharing");
      setActiveTabSet(true);
    } else if (commentEventsResult != null && commentEventsResult.length > 0 && activeTabSet == false) {
      setActiveTab("comments");
      setActiveTabSet(true);
    }

    console.log(sharingEventsResult);
    console.log(commentEventsResult);

    if ((sharingEventsResult == null || sharingEventsResult.length == 0) &&
        (commentEventsResult == null || commentEventsResult.length == 0)) {
      props.closeSharingEvents();
    } else {
      if ((sharingEventsResult == null || sharingEventsResult.length == 0) && activeTab == "sharing") {
        setActiveTab("comments");
      }
      if ((commentEventsResult == null || commentEventsResult.length == 0) && activeTab == "comments") {
        setActiveTab("sharing");
      }  
    }
  }

  const handlTabChange = async(tabKey) => {
    setActiveTab(tabKey);
  }

  useEffect(() => {
    if (Editor.getInstance().getTenant() != null) {
      refreshSharingEvents();

      const tenant_channel = Realtime.getInstance().getTenantChannel(Editor.getInstance().getTenant().sys_id);
      tenant_channel.bind('share.add', data => {
        console.log(`Recieved share.add`, data);
        refreshSharingEvents();
      });
      tenant_channel.bind('share.delete.one', data => {
        console.log(`Recieved share.delete.one`, data);
        refreshSharingEvents();
      });
      tenant_channel.bind('comment.add', data => {
        console.log(`Recieved comment.add`, data);
        refreshSharingEvents();
      });
      tenant_channel.bind('comment.delete.one', data => {
        console.log(`Recieved comment.delete.one`, data);
        refreshSharingEvents();
      });
    }
  }, [props.show]);

  return (
    <React.Fragment>
      {((sharingEvents != null && sharingEvents.length > 0) || (commentEvents != null && commentEvents.length > 0)) &&
        <React.Fragment>
          <Card className="rounded border-0 shadow-sm mt-1 mt-3">
            <Card.Body>
              <Row className="pb-3">
                <Col>
                  <span className="fs-6 text-muted"><i className="bi bi-asterisk"></i> <span className="fw-bolder">Sharing & Comments</span></span>
                  <div className="float-end">
                    <OverlayTrigger placement="top" overlay={<Tooltip>Close the view</Tooltip>}>
                      <button className="btn btn-sm btn-light text-muted" onClick={props.closeSharingEvents}><i className="bi bi-x-lg"></i></button>
                    </OverlayTrigger>
                  </div>
                </Col>
              </Row>
              <Row className="pb-3">
                <Col>
                  <Tab.Container
                    activeKey={activeTab}
                    onSelect={(tabKey) => handlTabChange(tabKey)}
                    variant="pills"
                  >
                    <Nav variant="pills mb-2">
                      {sharingEvents != null && sharingEvents.length > 0 &&
                        <Nav.Item>
                          <Nav.Link eventKey="sharing"><i className="bi bi-people-fill me-2"></i>Sharing</Nav.Link>
                        </Nav.Item>
                      }
                      {commentEvents != null && commentEvents.length > 0 &&
                        <Nav.Item>
                          <Nav.Link eventKey="comments"><i className="bi bi-chat me-2"></i>Comments</Nav.Link>
                        </Nav.Item>
                      }
                    </Nav>
                    {sharingEvents != null && sharingEvents.length > 0 &&
                      <Tab.Content>
                        <Tab.Pane eventKey="sharing">
                          <Row className="d-flex flex-column" style={{height: "69vh"}}>
                            <Col className="overflow-auto flex-grow-1">
                              {sharingEvents.map((sharingEvent, index) => (
                                <SharingEventCard key={`sharing-event-card-for-${index}`} processChange={props.processChange} viewNote={props.viewNote} loadNote={props.loadNote} sharingEvent={sharingEvent} handleLoadPinnedNotes={props.handleLoadPinnedNotes} handleLoadPinnedSections={props.handleLoadPinnedSections} refreshSharingEvents={refreshSharingEvents} />
                              ))}
                            </Col>
                          </Row>
                        </Tab.Pane>
                      </Tab.Content>
                    }
                    {commentEvents != null && commentEvents.length > 0 &&
                      <Tab.Content>
                        <Tab.Pane eventKey="comments">
                          <Row className="d-flex flex-column" style={{height: "69vh"}}>
                            <Col className="overflow-auto flex-grow-1">
                              {commentEvents.map((commentEvent, index) => (
                                <CommentEventCard key={`comment-event-card-for-${index}`} processChange={props.processChange} viewNote={props.viewNote} loadNote={props.loadNote} commentEvent={commentEvent} handleLoadPinnedNotes={props.handleLoadPinnedNotes} handleLoadPinnedSections={props.handleLoadPinnedSections} refreshSharingEvents={refreshSharingEvents} />
                              ))}
                            </Col>
                          </Row>
                        </Tab.Pane>
                      </Tab.Content>
                    }
                  </Tab.Container>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default FlexpaneViewSharingEvents;
