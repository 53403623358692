import React, { useEffect, useState } from "react";

import { Row, Col, Card, OverlayTrigger, Tooltip } from "react-bootstrap";

import Block from "./Block";

import { Parser } from "../../../utils/parser";

import ViewSectionControls from "./ViewSectionControls";
import ViewSectionNoteTags from "./ViewSectionNoteTags";
import Realtime from "../../../utils/realtime";

const ViewActionPanel = (props) => {
  const [action, setAction] = useState(null);
  const [unread, setUnread] = useState(false);

  const reactionCallback = (callback) => {
    console.log(callback);
    if (callback != null && callback.sys_id_sharing_event == null) {
      setUnread(false);
    }
  }

  const getEditorBlocksForAction = async(action) => {
    const editorBlocks = await Parser.convertToBlocksForEditor(action.blocks);

    // Apply the editor blocks
    action.editor_blocks = editorBlocks;

    // We have to do a little interface adjustment
    action.ellie_blocks = action.blocks;

    setAction(action);
  }

  useEffect(() => {
    if (props.action != null) {
      // console.log(props.action);
      getEditorBlocksForAction(props.action);

      if (props.action.sys_id_sharing_event != null && props.action.sys_id_sharing_event.trim().length > 0) {
        setUnread(true);
      }

      const global_channel = Realtime.getInstance().getGlobalChannel();
      global_channel.bind('action.update', realtime_data => {
        console.log(`Recieved action.update`, realtime_data, realtime_data.sys_id_section);
        if (realtime_data.sys_id_section == props.action.sys_id) {
          // We have an event, so the user has interacted with this item (likely the checked it off)
          setUnread(false);
        }
      });  
    }
  }, [props.action]);

  return (
    <React.Fragment>
      {action != null && action.editor_blocks != null && action.editor_blocks.length > 0 &&
        <React.Fragment>
          <Card style={unread == true ? {borderColor: "#FFD57E"} : {borderColor: "#FFFFFF"}} className={`rounded shadow-sm ${props.removeTopBottom == true ? '' : 'mt-2 mb-1'}`}>
            <Card.Body key={`section-action-${action.sys_id}`}>
              {(action.is_private == null || action.is_private == false) &&
                <React.Fragment>
                  {action.note_title != null && action.note_title.trim().length > 0 && action.note_tags != null && action.note_tags.trim().length > 0 &&
                    <Row className="mb-1">
                      <Col>
                        {action.note_title != null && action.note_title.trim().length > 0 &&
                          <React.Fragment>
                            <span className="opacity-50 me-1" style={{fontSize: "0.8em"}}>Note:</span><span className="opacity-50 me-2 fw-semibold" style={{fontSize: "0.8em"}} dangerouslySetInnerHTML={{ __html: action.note_title }} />
                          </React.Fragment>
                        }
                        {action.note_tags != null && action.note_tags.trim().length > 0 &&
                          <ViewSectionNoteTags note_tags={action.note_tags} note_references={action.note_references} note_references_referenced={action.note_references_referenced} />
                        }
                      </Col>
                    </Row>
                  }
                  {action.editor_blocks.map((block, index) => (
                    <Row key={`view-action-action-panel-row-${index}-${block.sys_id}`}>
                      <Col key={`view-action-action-panel-col-${index}`}>
                        <Block key={`view-action-action-panel-block-${index}`} block={block} isAction={true} />
                      </Col>
                    </Row>
                  ))}
                  <ViewSectionControls reactionCallback={reactionCallback} processChange={props.processChange} includePadding={true} section={action} viewNote={props.viewNote} handleLoadPinnedSections={props.handleLoadPinnedSections} />
                </React.Fragment>
              }
              {action.is_private == true &&
                <Alert variant="info">You don't have permission to view this action unfortunately <span className="fs-5">🥹</span></Alert>
              }
            </Card.Body>
          </Card>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default ViewActionPanel;