import React, { useEffect, useState, useRef } from "react";

import Blocks from "../../../utils/blocks";
import Realtime from "../../../utils/realtime";
import { Editor } from "../../../utils/editor";

const Block = (props) => {
  const blockRef = useRef(null);

  useEffect(() => {
    // console.log(`Loading block`);
    blockRef.current.appendChild(Blocks.render(props.block.sys_id_block, props.block.type, props.block.data));

    const global_channel = Realtime.getInstance().getGlobalChannel();
    global_channel.bind('action.update', realtime_data => {
      console.log(`Recieved action.update`, realtime_data, realtime_data.sys_id_section);
      if (realtime_data.sys_id_section == props.block.data.sys_id_block) {
        console.log(`This is the block that registered the event and should be updated if the status doesn't match`);
        if (realtime_data.status == 3 && props.block.data.status != 3) {
          const data = props.block.data;
          data.status = 3;

          // console.log(`The block is not checked and should be`);
          if (blockRef.current != null) {
            if (blockRef.current.childNodes.length > 0) {
              blockRef.current.removeChild(blockRef.current.childNodes[0]);
            }
            blockRef.current.appendChild(Blocks.render(props.block.sys_id_block, props.block.type, data));
          }

          Editor.getInstance().loadPinnedTags();
        } else if (realtime_data.status == 1 && props.block.data.status != 1) {
          const data = props.block.data;
          data.status = 1;

          // console.log(`The block is checked and should note be`);
          // console.log(blockRef.current);
          // console.log(blockRef.current.childNodes[0]);
          if (blockRef.current != null) {
            if (blockRef.current.childNodes.length > 0) {
              blockRef.current.removeChild(blockRef.current.childNodes[0]);
            }
            blockRef.current.appendChild(Blocks.render(props.block.sys_id_block, props.block.type, data));  
          }

          Editor.getInstance().loadPinnedTags();
        }
      }
    });
  }, [props.block]);

  return (
    <div ref={blockRef} className="text-break" style={props.block.type != "action" && props.isAction == true ? {marginLeft: "0px", borderLeft: "4px solid lightgray", paddingLeft: props.block.type == "paragraph" ? "19px" : "50px" } : {}}>
    </div>
  );
};

export default Block;
