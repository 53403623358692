const { getTimeDiff } = require("time-difference-js");

export class Formatter {
  static formatDate(utcTimestamp: string): string {
    const language = navigator.language;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const options: any = { year: 'numeric', month: 'short', day: 'numeric', timeZone: timezone };

    // console.log(`Formatting time: ${utcTimestamp}`);
    return new Date(utcTimestamp).toLocaleDateString(language, options);
  }

  static datesContent(utcTimestamp: string): string {
    // Get the date difference in plain language
    // const dueDateDiff = getTimeDiff(new Date(), new Date(dueDate));

    return `${Formatter.formatDate(utcTimestamp)}`;
  }

  static createFormattedDate(utcTimestamp: string): string {
    const dateNow = new Date().getTime();
    const date = new Date(utcTimestamp).getTime();

    // Get the date difference in plain language
    const dueDateDiff = getTimeDiff(new Date(dateNow), new Date(date));

    return `${Formatter.formatDate(utcTimestamp)} (${dueDateDiff.value} ${dueDateDiff.suffix}${dateNow > date ? " ago" : ""})`;
  }

  static formatDateNumber(number: number, digits: number): string {
    return "0".repeat(digits - number.toString().length) + number;
  }

  static formatDateFromParts(year: number, month: number, day: number, hour: number, minute: number, second: number) {
    const date: string = `${Formatter.formatDateNumber(year, 4)}-${Formatter.formatDateNumber(month, 2)}-${Formatter.formatDateNumber(day, 2)}T${Formatter.formatDateNumber(hour, 2)}:${Formatter.formatDateNumber(minute, 2)}:${Formatter.formatDateNumber(second, 2)}`;
    return date;
  }
}
