import React, { useEffect, useState } from "react";

import { Storage } from "../../../utils/storage";

import { Col, Row } from "react-bootstrap";

import ViewActionPanel from "./ViewActionPanel";
import ViewTidbitPanel from "./ViewTidbitPanel";

const FlexpaneViewIntelligenceCardSource = (props) => {
  const [section, setSection] = useState(null);

  const getSectionById = async(sys_id_section) => {
    const section = await Storage.getInstance().getSection(sys_id_section);

    setSection(section);
  }

  useEffect(() => {
    getSectionById(props.sys_id_section);
  }, [props.sys_id_section]);

  return (
    <React.Fragment>
      {section != null &&
        <Row>
          <Col>
            {section.sys_type == "action" &&
              <ViewActionPanel processChange={props.processChange} action={section} viewNote={props.viewNote} handleLoadPinnedSections={props.handleLoadPinnedSections} />
            }
            {section.sys_type == "tidbit" &&
              <ViewTidbitPanel processChange={props.processChange} tidbit={section} viewNote={props.viewNote} handleLoadPinnedSections={props.handleLoadPinnedSections} />
            }
          </Col>
        </Row>
      }
    </React.Fragment>
  );
};

export default FlexpaneViewIntelligenceCardSource;
