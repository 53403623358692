import React, { useEffect, useState } from "react";

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const EventObjectIntelligenceCardMetadataField = (props) => {
  const [field, setField] = useState(null);
  const [eventType, setEventType] = useState('');
  const [key, setKey] = useState(crypto.randomUUID());
  const [changed, setChanged] = useState(false);
  const [value, setValue] = useState('');

  const undoChange = (event) => {
    event.preventDefault();
    event.stopPropagation();

    field.changed = false;
    field.new_value = field.old_value;
    field.value = field.old_value;
    console.log(field);

    props.undoChange(field);

    setField(field);
    setChanged(false);
    setValue(field.value);
  }

  useEffect(() => {
    setField(props.field);
    if (props.field != null) {
      setChanged(props.field.changed);
      if (props.field.changed == true) {
        setValue(props.field.new_value);
      } else {
        setValue(props.field.value);
      }
    }
    setEventType(props.eventType);
  }, [props.field]);

  return (
    <React.Fragment>
      {field != null &&
        <div className={`p-2 ${changed == true ? (eventType == "event" ? "rounded bg-info-subtle mt-1" : "rounded bg-warning-subtle mt-1") : ""}`}>
          <label htmlFor={`${key}-${field.name}`} className={`form-label ${changed == true ? "fw-bolder" : ""}`}>{field.label}</label>
          {changed == true && props.eventType == "recommendation" &&
            <div className="float-end">
              <OverlayTrigger placement="top" overlay={<Tooltip>Undo this change</Tooltip>}>
                <button className="btn btn-sm btn-light text-muted" onClick={undoChange} style={{"--bs-btn-padding-y": ".1rem", "--bs-btn-padding-x": ".3rem"}}><i className="bi bi-arrow-counterclockwise"></i></button>
              </OverlayTrigger>
            </div>
          }
          {field.type == "text" &&
            <React.Fragment>
              <input type={field.type} className="form-control" id={`${key}-${field.name}`} value={value} />
              {changed == true &&
                <React.Fragment>
                  {props.eventType == "event" &&
                    <div className="form-text">
                      Changed from <span className="fw-bolder">{field.old_value}</span>
                    </div>
                  }
                  {props.eventType == "recommendation" &&
                    <div className="form-text">
                      Recommended change:<br/><span className="fw-bolder">{field.old_value}</span> to <span className="fw-bolder">{field.new_value}</span>
                    </div>
                  }
                </React.Fragment>
              }
            </React.Fragment>
          }
          {field.type == "select" &&
            <React.Fragment>
              <select className="form-select" id={`${key}-${field.name}`} aria-label={field.label} value={value}>
                {field.options != null && field.options.length > 0 && field.options.map((option, optionIndex) => (
                  <option key={`${key}-${field.name}-${optionIndex}`} value={option.value}>{option.label}</option>
                ))}
              </select>
              {changed == true &&
                <React.Fragment>
                  {props.eventType == "event" &&
                    <div className="form-text">
                      Changed from <span className="fw-bolder">{field.old_value}</span>
                    </div>
                  }
                  {props.eventType == "recommendation" &&
                    <div className="form-text">
                      Recommended change:<br/><span className="fw-bolder">{field.old_value}</span> to <span className="fw-bolder">{field.new_value}</span>
                    </div>
                  }
                </React.Fragment>
              }
            </React.Fragment>
          }
          {field.type == "reference" &&
            <React.Fragment>
              <div className="input-group">
                <input readOnly type="text" className="form-control" id={`${key}-${field.name}`} value={value} />
                <span onClick={() => {props.openLink(field.url)}} className="input-group-text" style={{cursor: "pointer"}}><i className="bi bi-box-arrow-up-right"></i></span>
              </div>
              {changed == true &&
                <React.Fragment>
                  {props.eventType == "event" &&
                    <div className="form-text">
                      Changed from <span className="fw-bolder">{field.old_value}</span>
                    </div>
                  }
                  {props.eventType == "recommendation" &&
                    <div className="form-text">
                      Recommended change:<br/><span className="fw-bolder">{field.old_value}</span> to <span className="fw-bolder">{field.new_value}</span>
                    </div>
                  }
                </React.Fragment>
              }
            </React.Fragment>
          }
          {field.type == "currency" &&
            <React.Fragment>
              <input readOnly type="text" className="form-control" id={`${key}-${field.name}`} value={value} />
              {changed == true &&
                <React.Fragment>
                  {props.eventType == "event" &&
                    <div className="form-text">
                      Changed from <span className="fw-bolder">{field.old_value}</span>
                    </div>
                  }
                  {props.eventType == "recommendation" &&
                    <div className="form-text">
                      Recommended change:<br/><span className="fw-bolder">{field.old_value}</span> to <span className="fw-bolder">{field.new_value}</span>
                    </div>
                  }
                </React.Fragment>
              }
            </React.Fragment>
          }
          {field.type == "date" &&
            <React.Fragment>
              <input type="text" className="form-control" id={`${key}-${field.name}`} value={value} />
              {changed == true &&
                <React.Fragment>
                  {props.eventType == "event" &&
                    <div className="form-text">
                      Changed from <span className="fw-bolder">{field.old_value}</span>
                    </div>
                  }
                  {props.eventType == "recommendation" &&
                    <div className="form-text">
                      Recommended change:<br/><span className="fw-bolder">{field.old_value}</span> to <span className="fw-bolder">{field.new_value}</span>
                    </div>
                  }
                </React.Fragment>
              }
            </React.Fragment>
          }
          {field.type == "multiline" &&
            <React.Fragment>
              <textarea id={`${key}-${field.name}`} className="form-control" aria-label={field.label}>{value}</textarea>
              {changed == true &&
                <React.Fragment>
                  {props.eventType == "event" &&
                    <div className="form-text">
                      Changed from <span className="fw-bolder">{field.old_value}</span>
                    </div>
                  }
                  {props.eventType == "recommendation" &&
                    <div className="form-text">
                      Recommended change:<br/><span className="fw-bolder">{field.old_value}</span> to <span className="fw-bolder">{field.new_value}</span>
                    </div>
                  }
                </React.Fragment>
              }
            </React.Fragment>
          }
        </div>
      }
    </React.Fragment>
  );
};

export default EventObjectIntelligenceCardMetadataField;
