import React, { useEffect, useState, useRef } from "react";

import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";

import { Editor } from "../../../utils/editor";
import { Formatter } from "../../../utils/formatter";
import { Storage } from "../../../utils/storage";
import { Parser } from "../../../utils/parser";
import Realtime from "../../../utils/realtime";

const ViewNoteControlComments = (props) => {
  const [note, setNote] = useState(null);
  const [comments, setComments] = useState(null);
  const [loading, setLoading] = useState(true);

  const commentRef = useRef(null);

  const deleteComment = async(sys_id_comment) => {
    const commentsResult = await Storage.getInstance().deleteCommentForNoteId(note.sys_id, sys_id_comment);

    setComments(await Parser.parseCommentsContentForEditor(commentsResult));
    setLoading(false);

    props.assignCommentsCount(commentsResult.length);
  }

  const handleKeyDown = async(event) => {
    if (event.key === 'Enter') {
      // console.log(`The user hit enter`);
      event.preventDefault();

      const commentsResult = await Storage.getInstance().setCommentForNoteId(note.sys_id, Parser.getCommentForStorage(commentRef.current.innerHTML));

      commentRef.current.innerHTML = "";

      setComments(await Parser.parseCommentsContentForEditor(commentsResult));
      setLoading(false);

      props.assignCommentsCount(commentsResult.length);

      return false;
    }

    props.processChange();
  }

  const loadNoteComments = async(tempNote) => {
    if (tempNote == null) {
      tempNote = note;
    }

    const commentsResult = await Storage.getInstance().getCommentsForNoteId(tempNote.sys_id);

    setComments(await Parser.parseCommentsContentForEditor(commentsResult));
    setLoading(false);

    props.assignCommentsCount(commentsResult.length);
  }

  useEffect(() => {
    if (props.show == true) {
      setTimeout(() => {
        // Give react a second to catch up
        commentRef.current.focus();
      }, 200);
    }
  }, [props.show]);

  useEffect(() => {
    if (props.note != null) {
      // Correct for the fact that sometimes this is the editor note object and sometimes it's a raw note object
      const tempNote = props.note;
      if (tempNote.sys_id_note != null && tempNote.sys_id == null) {
        tempNote.sys_id = tempNote.sys_id_note;
      }

      loadNoteComments(tempNote);
      setNote(tempNote);

      if (Editor.getInstance().getTenant() != null) {
        // console.log(`global`);
        const global_channel = Realtime.getInstance().getGlobalChannel();
        global_channel.bind('comment.add', data => {
          console.log(`Recieved comment.add`, data, props.note);
          if (data.sys_id_note != null && props.note != null && props.note.sys_id == data.sys_id_note) {
            // We have an update to the note comments for this note
            loadNoteComments(props.note);
          }
        });
        global_channel.bind('comment.delete.one', data => {
          console.log(`Recieved comment.delete.one`, data, props.note);
          if (data.sys_id_note != null && props.note != null && props.note.sys_id == data.sys_id_note) {
            // We have an update to the note comments for this note
            loadNoteComments(props.note);
          }
        });
      }
    } else {
      setNote(null);
    }
  }, [props.note]);

  return (
    <Col>
      {note != null && comments != null && comments.length > 0 &&
        <Row className={`${props.padding == false ? '' : 'm-2'} p-2 bg-info bg-opacity-10 rounded-3`}>
          <Col>
            {comments.map((comment, index) => (
              <React.Fragment key={"note-comment-fragment-row-" + comment.sys_id}>
                <Row key={"note-comment-row-" + comment.sys_id}>
                  <Col key={"note-comment-col-" + comment.sys_id}>
                    {comment.sys_id_tenant_origin == Editor.getInstance().getTenant().sys_id &&
                      <div key={"note-comment-col-float-trigger-div-rando-" + comment.sys_id} className="float-end">
                        <OverlayTrigger key={"note-comment-col-float-trigger-" + comment.sys_id} placement="top" overlay={<Tooltip>Delete this comment</Tooltip>}>
                          <button key={"note-comment-col-float-trigger-div-" + comment.sys_id} className="btn btn-sm btn-light text-muted me-2" onClick={() => deleteComment(comment.sys_id)}><i key={"note-comment-col-float-trigger-div-trash-icon" + comment.sys_id} className="bi bi-trash2-fill"></i></button>
                        </OverlayTrigger>
                      </div>
                    }
                    <div className="mt-3" dangerouslySetInnerHTML={{ __html: comment.content }}/>
                  </Col>
                </Row>
                <Row key={"note-comment-row2-" + comment.sys_id}>
                  <Col key={"note-comment-col2-" + comment.sys_id} className="mt-2">
                    {comment.tenant != null &&
                      <div key={"note-comment-tenant-" + comment.sys_id} className="me-2" style={{display: "inline-block"}}>
                        <img key={"note-comment-tenant-image-" + comment.sys_id} style={{height: "30px", width: "30px"}} className="rounded-circle border border-1" src={comment.tenant.picture}></img> <span className="text-muted fw-semibold">{comment.tenant.given_name} {comment.tenant.family_name}</span>
                      </div>
                    }
                    {comment.tenant == null && Editor.getInstance().getTenant() != null &&
                      <div key={"note-comment-tenant-" + comment.sys_id} className="me-2" style={{display: "inline-block"}}>
                        <img key={"note-comment-tenant-image-" + comment.sys_id} style={{height: "30px", width: "30px"}} className="rounded-circle border border-1" src={Editor.getInstance().getTenant().picture}></img> <span className="text-muted fw-semibold">{Editor.getInstance().getTenant().given_name} {Editor.getInstance().getTenant().family_name}</span>
                      </div>
                    }
                    <span className="ms-3 text-muted">{Formatter.createFormattedDate(comment.sys_date_created)}</span>
                  </Col>
                </Row>
              </React.Fragment>
            ))}
          </Col>
        </Row>
      }
      <Row className="mt-2">
        <Col>
          <div className="text-muted ps-2 ms-2 fw-bolder">Comment:</div>
          <div 
            ref={commentRef}
            contentEditable
            onKeyDown={handleKeyDown}
            className="w-100 ms-2 me-2 mt-0 pb-2 ps-2 pe-2 pt-0 nfy-comment-input" 
            data-placeholder="Comment"
            style={{border: 0, outline: 0, overflow: "auto"}}
          />
        </Col>
      </Row>
    </Col>
  );
};

export default ViewNoteControlComments;
