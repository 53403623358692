import React, { useEffect, useState } from "react";

import { Row, Col, OverlayTrigger, Tooltip, Collapse } from "react-bootstrap";

import { Editor } from "../../../utils/editor";
import { Formatter } from "../../../utils/formatter";
import { Storage } from "../../../utils/storage";

import ViewNoteControlSharing from "./ViewNoteControlSharing";
import ViewNoteControlComments from "./ViewNoteControlComments";
import ViewEmojiControls from "./ViewEmojiControls";

const ViewNoteControls = (props) => {
  const [note, setNote] = useState(null);
  const [pinned, setPinned] = useState(null);
  const [commentsCount, setCommentsCount] = useState(0);
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [canEdit, setCanEdit] = useState(true);

  const handleShowCommentBox = (show) => {
    setShowCommentBox(show);
  }

  const handlePinNote = async() => {
    const response = await Storage.getInstance().pinNote(note.sys_id_note, !pinned);
    // console.log(response);
    setPinned(response.pinned);

    if (note.sys_id_sharing_event != null && note.sys_id_sharing_event.trim().length > 0) {
      await Storage.getInstance().ignoreSharedEvent(note.sys_id_sharing_event);
      props.reactionCallback({
        sys_id_sharing_event: null,
      });
    }

    props.handleLoadPinnedNotes();
  }

  const assignCommentsCount = (commentsCount) => {
    setCommentsCount(commentsCount);
  }

  useEffect(() => {
    setNote(props.note);

    if (props.note != null) {
      setPinned(props.note.pinned);
      // console.log(props.note.tenant);

      // console.log(props.note.tenant);
      // console.log(Editor.getInstance().getTenant());

      // The tenant will be null if we created the note
      if (props.note.tenant != null && Editor.getInstance().getTenant() != null) {
        if (props.note.tenant.email != Editor.getInstance().getTenant().email) {
          // You can only edit the notes you created
          setCanEdit(false);
        }
      }
    }
  }, [props.note, props.sys_id_note]);

  return (
    <React.Fragment>
      {note != null &&
        <React.Fragment>
          <Row>
            <Col>
              {note.tenant != null &&
                <OverlayTrigger placement="top" overlay={<Tooltip>{note.tenant.given_name} {note.tenant.family_name}</Tooltip>}>
                  <div className="me-2 d-inline">
                    <img style={{height: "30px", width: "30px"}} className="rounded-circle border border-1" src={note.tenant.picture != null && note.tenant.picture.trim().length > 0 ? note.tenant.picture : '/assets/person.png'}></img>
                  </div>
                </OverlayTrigger>
              }
              {note.tenant == null && Editor.getInstance().getTenant() != null &&
                <OverlayTrigger placement="top" overlay={<Tooltip>{Editor.getInstance().getTenant().given_name} {Editor.getInstance().getTenant().family_name}</Tooltip>}>
                  <div className="me-2 d-inline">
                    <img style={{height: "30px", width: "30px"}} className="rounded-circle border border-1" src={Editor.getInstance().getTenant().picture != null && Editor.getInstance().getTenant().picture.trim().length > 0 ? Editor.getInstance().getTenant().picture : '/assets/person.png'}></img>
                  </div>
                </OverlayTrigger>
              }
              <OverlayTrigger placement="top" overlay={<Tooltip>Edit this note{canEdit == false ? " (disabled)" : ""}</Tooltip>}>
                <button className="btn btn-sm btn-light text-muted me-2" disabled={!canEdit} onClick={() => props.loadNote({ sys_id: note.sys_id_note })}><i className="bi bi-pencil-square"></i></button>
              </OverlayTrigger>
              <OverlayTrigger key={"note-date-col-float-trigger-" + note.sys_id_note} placement="top" overlay={<Tooltip>Date created</Tooltip>}>
                <button className={`btn btn-sm btn-light text-muted me-2`} type="button"><i className="bi bi-calendar3 me-2 text-muted"></i>{Formatter.datesContent(note.sys_date_updated)}</button>
              </OverlayTrigger>
              <ViewNoteControlSharing note={note} sys_id_note={note.sys_id_note} />
              <OverlayTrigger placement="top" overlay={<Tooltip>{pinned == true ? "Pinned" : "Pin this note"}</Tooltip>}>
                <button className={`btn btn-sm btn-light ${pinned == true ? "" : "text-muted"} me-2`} style={pinned == true ? { backgroundColor: "#dee2e6"} : {}} onClick={() => handlePinNote()}><i className={`bi bi-pin${pinned == true ? '-fill' : ''}`}></i></button>
              </OverlayTrigger>
              <OverlayTrigger placement="top" overlay={<Tooltip>Comment on this note</Tooltip>}>
                <button className="btn btn-sm btn-light text-muted me-2" style={showCommentBox == true ? {"--bs-btn-bg": "#d3d4d5"} : null} onClick={() => handleShowCommentBox(!showCommentBox)}><i className="bi bi-chat"></i>{commentsCount > 0 ? <span> <span className="badge text-bg-secondary">{commentsCount}</span></span> : null}</button>
              </OverlayTrigger>
              <ViewEmojiControls reactionCallback={props.reactionCallback} sys_id_note={note.sys_id_note} sys_id_sharing_event={note.sys_id_sharing_event} />
            </Col>
          </Row>
          <Collapse in={showCommentBox}>
            <Row>
              <ViewNoteControlComments processChange={props.processChange} padding={true} show={showCommentBox} assignCommentsCount={assignCommentsCount} note={note} />
            </Row>
          </Collapse>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default ViewNoteControls;
