import { Editor } from '../utils/editor';
import { Parser } from '../utils/parser';

class Action {
  constructor({data, config, api, readOnly, block}) {
    this.data = data;
    this.config = config;
    this.api = api;
    this.readOnly = readOnly;
    this.block = block;

    // Get the CSS from the editor to use for tools etc
    this.css = {
      block: this.api.styles.block,
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,
      wrapper: 'ce-paragraph',
    };

    // Bind the editing events with "this" so it represents the block
    if (!this.readOnly) {
      // console.log(`Readonly action`);
      this.onKeyUp = this.onKeyUp.bind(this);
      this.onClick = this.onClick.bind(this);
    }

    this.onChange = this.onChange.bind(this);

    // Draw the base view that will be used to hide/display task information
    this._drawBaseView();

    // Initialize the data object if we don't have one
    if (this.data == null) {
      // console.log(`Data for component is null`);
      this.data = {};
    }

    if (this.data.text) {
      this.contentEditable.innerHTML = this.data.text;
    } else {
      // console.log(`This action has no content at time of creation.`);
      // Get the details of the tenant, we'll use that to mark this action as one for them personally
      if (Editor.getInstance().getTenant() != null && Editor.getInstance().getTenant().reference != null) {
        this.contentEditable.innerHTML = Parser.constructReferenceString("@", Editor.getInstance().getTenant().reference).html;
      }
    }
    this._updateBaseView();
  }

  // setNoteHandleStateChange(handleNoteStateChange) {
  //   this.handleNoteStateChange = handleNoteStateChange;
  // }

  render() {
    return this.wrapper;
  }
 
  merge(data) {
    let newData = {
      text: this.data.text + data.text
    };

    this.data = newData;
  }
 
  validate(savedData) {
    if (savedData.text == null || savedData.text.trim().length == 0) {
      return false;
    }

    return true;
  }
 
  save(blockElement) {
    const editableContent = blockElement.querySelectorAll('[contenteditable=true]');
    
    return {
      text: editableContent[0].innerHTML,
      status: this.mainCheckBoxInput && this.mainCheckBoxInput.checked ? 3 : 1,
    };
  }
  
  static get conversionConfig() {
    return {
      export: 'text',
      import: 'text',
    };
  }
 
  static get sanitize() {
    return {
      text: {
        br: true,
        b: true,
      }
    };
  }
 
  static get isReadOnlySupported() {
    return true;
  }

  static get pasteConfig() {
    return {
      tags: [ 'P' ]
    };
  }

  static get toolbox() {
    return {
      icon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="-2 -2 20 20"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/></svg>',
      title: 'Action'
    };
  }

  onKeyUp(e) {
    this._updateBaseView();

    if (e.code !== 'Backspace' && e.code !== 'Delete') {
      return;
    }

    const {textContent} = this.contentEditable;

    if (textContent === '') {
      this.contentEditable.innerHTML = '';
    }
  }

  onChange(e) {
    console.log(`Ticking the box for the action`);
    // console.log(this.readOnly);
    // console.log(this.data.sys_id_block);
    if (this.readOnly == true && this.data.sys_id_block != null) {
      // Set the status change in the data for the action
      this.data.status = this.mainCheckBoxInput && this.mainCheckBoxInput.checked ? 3 : 1;

      // if (this.handleNoteStateChange != null) {
      //   // Send the state change info up to the note so any UI refresh doesn't lose the checked status
      //   this.handleNoteStateChange(this.data);
      // }
      
      // console.log(`It's read only!`);
      e.preventDefault();
      e.stopPropagation();
      Editor.getInstance().saveActionStatusChange(this.data.sys_id_block, this.mainCheckBoxInput && this.mainCheckBoxInput.checked ? 3 : 1);
    } else {
      Editor.getInstance().save(this.api);
    }
  }

  onClick(e) {
    this._updateBaseView();
  }

  onPaste(event) {
    this.data.text = event.detail.data.innerHTML;
  }
 
  _drawBaseView() {
    this.wrapper = document.createElement('div');
    this.wrapper.classList.add("en-paragraph-task");
    this.wrapper.classList.add('en-task');

    this.contentEditable = document.createElement('div');
    this.contentEditable.classList.add(this.css.wrapper, this.css.block);
    this.contentEditable.contentEditable = false;

    if (!this.readOnly) {
      this.contentEditable.contentEditable = true;
      this.contentEditable.addEventListener('keyup', this.onKeyUp);
      this.contentEditable.addEventListener('blur', this.onBlur);
    }

    const taskDetailsWrapper = document.createElement('div');
    taskDetailsWrapper.classList.add('en-task-details-wrapper');
    taskDetailsWrapper.appendChild(this.contentEditable);

    const checkbox = document.createElement("div");
    checkbox.classList.add("pretty");
    checkbox.classList.add("p-round");
    checkbox.classList.add("p-pulse");
    checkbox.classList.add("p-icon");

    this.mainCheckBoxInput = document.createElement("input");
    this.mainCheckBoxInput.setAttribute("type", "checkbox");
    this.mainCheckBoxInput.addEventListener('change', this.onChange, false);

    const checkboxDiv = document.createElement("div");
    checkboxDiv.setAttribute("style", "font-size: 37px");
    checkboxDiv.setAttribute("class", "state p-info");

    const icon = document.createElement("i");
    icon.setAttribute("class", "icon bi-check");

    const label = document.createElement("label");
    label.innerText = '';

    checkboxDiv.appendChild(icon);
    checkboxDiv.appendChild(label);

    checkbox.appendChild(this.mainCheckBoxInput);
    checkbox.appendChild(checkboxDiv);

    this.checkboxWrapper = document.createElement('div');
    this.checkboxWrapper.classList.add('en-task-checkbox-wrapper');
    this.checkboxWrapper.appendChild(checkbox);

    this.wrapper.appendChild(this.checkboxWrapper);
    this.wrapper.appendChild(taskDetailsWrapper);

    return this.contentEditable;
  }

  _updateBaseView() {
    // console.log(`Status for the action is: ${JSON.stringify(this.data)}`);
    if (this.data.status == 3) {
      // console.log(`Checking the box`);
      this.mainCheckBoxInput.checked = true;
    }
  }
}

export default Action;
