import React, { useEffect, useState } from "react";

import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { Storage } from "../../../utils/storage";
import { Editor } from "../../../utils/editor";

const ViewNoteControlSharing = (props) => {
  const [isShared, setIsShared] = useState(false);
  const [referencesForSharing, setReferencesForSharing] = useState([]);

  const handleSharing = async() => {
    // console.log(props.sys_id_note);
    const noteSharing = await Storage.getInstance().getNoteSharing(props.sys_id_note);
    const forSharing = [];

    // console.log(noteSharing);
    if (noteSharing.references != null && noteSharing.references.length > 0) {
      for (let x = 0; x < noteSharing.references.length; x++) {
        const reference = noteSharing.references[x];
  
        // Don't include yourself in the sharing
        if (Editor.getInstance().getTenant() != null && Editor.getInstance().getTenant().sys_id == reference.sys_id_tenant_verified) {
          continue;
        }
        
        for (let y = 0; y < noteSharing.references_referenced.length; y++) {
          if (noteSharing.references_referenced[y].sys_id_reference == reference.sys_id) {
            reference.shared = noteSharing.references_referenced[y].shared;
            break;
          }
        }

        if (reference.shared == true) {
          setIsShared(true);
        }

        if (reference.sys_id_sharing == 1) {
          // If the sharing is based on specific tenants, load the full reference
          const fullReference = await Storage.getInstance().getReference(reference.sys_id);
          reference.shared_with = fullReference.shared_tenants;
        }
  
        forSharing.push(reference);
      }
    }

    // console.log(`Setting references for sharing`);
    // console.log(forSharing);
    setReferencesForSharing(forSharing);
  }

  const handleSharingChange = (reference, shared) => {
    const references_referenced = [];
    let sharedWithSomething = false;
    if (referencesForSharing != null && referencesForSharing.length > 0) {
      for (let x = 0; x < referencesForSharing.length; x++) {
        if (reference.sys_id == referencesForSharing[x].sys_id) {
          referencesForSharing[x].shared = shared;
        }

        if (referencesForSharing[x].shared == true) {
          sharedWithSomething = true;
        }
        
        references_referenced.push({
          sys_id_reference: referencesForSharing[x].sys_id,
          sys_type: referencesForSharing[x].sys_type,
          shared: referencesForSharing[x].shared,
        })
      }
    }

    Storage.getInstance().saveNoteSharingChange(props.sys_id_note, references_referenced).then(() => {
      // console.log("Note sharing changed");
      // Not sure this is needed, but have updated the sharing settings for the references so the view needs to be updated
      setReferencesForSharing(referencesForSharing);
      setIsShared(sharedWithSomething);
    });
  }

  useEffect(() => {
    // console.log(`The note:`, props.note);
    if (props.note != null) {
      // console.log(`The note is:`, props.note);
      // console.log(`The note identifier is:`, props.sys_id_note);
      handleSharing();
    }
  }, [props.note, props.sys_id_note]);

  return (
    <React.Fragment>
      {props.note != null && props.sys_id_note != null && props.sys_id_note.trim().length > 0 &&
        <React.Fragment>
          <OverlayTrigger key={"note-sharing-col-float-trigger-" + props.sys_id_note} placement="top" overlay={<Tooltip>Change who can see this</Tooltip>}>
            <button onClick={handleSharing} disabled={props.note.tenant == null ? false : true} className="btn btn-sm btn-light text-nowrap dropdown-toggle me-2" type="button" data-bs-toggle="dropdown" aria-expanded="false"><span className={isShared == true ? "badge text-bg-success me-1" : "badge text-bg-light me-1"}><i className={isShared == true ? "bi bi-people-fill" : "bi bi-person-fill-lock"}></i></span>{isShared == false ? "Private" : "Shared"}</button>
          </OverlayTrigger>
          {props.note.tenant == null &&
            <ul className="dropdown-menu">
              {referencesForSharing != null && referencesForSharing.length > 0 &&
                <React.Fragment>
                  <li><h6 className="dropdown-header">Share this note with:</h6></li>
                  {referencesForSharing.map((referenceForSharing, index) => (
                    <li key={`view-note-controls-rando-panel-div-${index}`} className="dropdown-item">
                      <input key={`view-note-controls-rando-panel-div-input-${index}`} type="checkbox" onChange={() => handleSharingChange(referenceForSharing, !referenceForSharing.shared)} className="me-1 align-top mt-1" id={"sharing-" + props.sys_id_note + referenceForSharing.sys_id} defaultChecked={referenceForSharing.shared} />
                      <label key={`view-note-controls-rando-panel-div-label-${index}`} className="form-check-label" htmlFor={"sharing-" + props.sys_id_note + referenceForSharing.sys_id}>
                        {referenceForSharing.sys_type == "topic" ? "#" : "@"}{referenceForSharing.alias}<span style={{fontSize: "0.75em"}}><br/></span>
                        {referenceForSharing.sys_type == "topic" &&
                          <React.Fragment>
                            {referenceForSharing.sys_id_sharing == 0 &&
                              <span style={{fontSize: "0.75em"}}><i className="bi bi-person-fill-lock text-muted"></i> Private to you</span>
                            }
                            {referenceForSharing.sys_id_sharing == 1 &&
                              <span style={{fontSize: "0.75em"}}><i className="bi bi-person-fill-check text-muted"></i> Share with:
                                {referenceForSharing.shared_with != null && referenceForSharing.shared_with.length > 0 &&
                                  <ul>
                                    {referenceForSharing.shared_with.map((sharedWith, sharedIndex) => (
                                      <li key={sharedWith.sys_id + " " + sharedIndex}>{sharedWith.tenant != null ? `${sharedWith.tenant.given_name} ${sharedWith.tenant.family_name}` : 'Not found'} {Editor.getInstance().getTenant() != null && sharedWith.sys_id_tenant == Editor.getInstance().getTenant().sys_id ? "(You!)" : ""}</li>
                                    ))}
                                  </ul>
                                }
                              </span>
                            }
                            {referenceForSharing.sys_id_sharing == 2 &&
                              <span style={{fontSize: "0.75em"}}><i className="bi bi-people-fill text-muted"></i> Visible to everyone at {Editor.getInstance().getTenant() != null ? Editor.getInstance().getTenant().domain : ""}</span>
                            }
                          </React.Fragment>
                        }
                        {referenceForSharing.sys_type == "person" &&
                          <React.Fragment>
                            {referenceForSharing.sys_id_tenant_verified != null && referenceForSharing.sys_id_tenant_verified.trim().length > 0 &&
                              <span style={{fontSize: "0.75em"}}><i className="bi bi-person-fill-lock text-muted"></i> Share with {referenceForSharing.email}</span>
                            }
                            {referenceForSharing.email != null && referenceForSharing.email.trim().length > 0 && (referenceForSharing.sys_id_tenant_verified == null || referenceForSharing.sys_id_tenant_verified.trim().length == 0) &&
                              <span style={{fontSize: "0.75em"}}><i className="bi bi-person-fill-lock text-muted"></i> Share with {referenceForSharing.email} (they don't have an account)</span>
                            }
                            {(referenceForSharing.email == null || referenceForSharing.email.trim().length == 0) && (referenceForSharing.sys_id_tenant_verified == null || referenceForSharing.sys_id_tenant_verified.trim().length == 0) &&
                              <span style={{fontSize: "0.75em"}}><i className="bi bi-person-fill-lock text-muted"></i> Will not be shared until user is connected with you</span>
                            }
                          </React.Fragment>
                        }
                      </label>
                    </li>
                  ))}
                </React.Fragment>
              }
              {(referencesForSharing == null || referencesForSharing.length == 0) &&
                <li key={`view-note-controls-rando-panel-div-nothing`} className="dropdown-item">
                  <h6 className="dropdown-header">No tags for sharing</h6>
                </li>
              }
            </ul>
          }
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default ViewNoteControlSharing;
